import React, { useState } from "react";

import ModalContainer from "../../../../shared/ModalContainer";
import EventPartnerForm from "./EventPartnerForm";

const NewEventPartnerModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(true);
  };

  const editEventFormSubmitCallback = (data, index, orgData, orgUsers) => {
    setIsSubmitReady(false);
    props.formSubmitCallBack(data, index, null, orgData, orgUsers);
    props.toggle();
  };

  return (
    <ModalContainer
      modalTitle="Edit Partner"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={() => modalContainerSubmitCallback()}
    >
      <EventPartnerForm
        isPopulatingForm={true}
        isSubmitReady={isSubmitReady}
        partner={props.item}
        submitCallback={(data, index, orgData, orgUsers) =>
          editEventFormSubmitCallback(data, index, orgData, orgUsers)
        }
      />
    </ModalContainer>
  );
};

export default NewEventPartnerModal;
