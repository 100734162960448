import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CustomerOrgUsers from "../../../models/database/CustomerOrgUsers";
import Contacts from "models/database/Contacts";
import User from "../../../models/database/User";
import CustomSelect from "creative-tim/components/CustomSelect";

const createSingleSelect = (value, label, itemClass) => {
  return {
    value,
    label,
    itemClass,
  };
};

function UsersDropDownList(props) {
  const isMounted = useRef(false);
  const [usersData, setUsersData] = useState([]);
  const [value, setValue] = useState(props.fixedOptions || []);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const usersNameOptions = () => {
    return usersData
      .filter((val) => {
        if (props.roleFilter)
          return (
            Boolean(val) &&
            val?.roleType &&
            val.roleType.toLowerCase() == props.roleFilter.toLowerCase()
          );
        else return Boolean(val);
      })
      .map((type, index) =>
        createSingleSelect(
          type?.uid || type?.itemClass?.id,
          type?.email,
          type?.itemClass
        )
      );
  };

  const filterUsers = (allUsers) => {
    if (!props.isPartnersOnly) return allUsers;
    if (!props.eventData?.partners) return [];
    const partnerOrgIds = Object.values(props.eventData.partners).reduce(
      (mapArr, partner) => {
        if (partner?.orgId) {
          mapArr.push(partner.orgId);
        }
        return mapArr;
      },
      []
    );
    const partnerContacts = allUsers.filter((user) =>
      partnerOrgIds.includes(user?.itemClass?.orgId) || 
      partnerOrgIds.includes(user?.itemClass?.organization)
    );
    return partnerContacts;
  };

  const handleChange = (newValue = []) => {
    props.onChange(newValue);
    setValue(newValue);
  };

  async function fetchUser(id) {
    const getUser = await new User().get(id);
    return getUser && Object.values(getUser)[0];
  }

  let placeholder = props.multiSelect ? "Emails" : "Email";
  useEffect(() => {
    async function getUsers() {
      const users = await new CustomerOrgUsers().getAllEntities();
      let idx = await users.map(({ usersId }) => {
        if (usersId) {
          return fetchUser(usersId);
        }
      });

      await Promise.all(idx).then(async (res) => {
        const contacts = await new Contacts().getAllEntities();
        const emailContacts = contacts.filter((c) => c.email);
        const allUsers = res.concat(emailContacts);
        if (isMounted.current) {
          setUsersData(filterUsers([...allUsers]));
        }
        props.dispatch({
          type: "setOrgUsers",
          data: allUsers,
        });
      });
    }
    if (!props.orgUsers) {
      getUsers();
    } else {
      (async function () {
        const contacts = await new Contacts().getAllEntities();
        const prevEmails = props.orgUsers.map((o) => o.email);
        const emailContacts = contacts.filter(
          (c) => c.email && !prevEmails.includes(c.email)
        );
        const allUsers = props.orgUsers.concat(emailContacts);
        if (isMounted.current) {
          setUsersData(filterUsers([...allUsers]));
        }
      })();
    }
  }, []);

  return (
    <CustomSelect
      value={value}
      options={usersNameOptions()}
      defaultSelected={props.defaultSelected}
      placeholder={placeholder}
      callback={handleChange}
      multiSelect={props.multiSelect}
      isClearable
      searchable
      isNotPadded
      size="9"
      whiteSpace="normal"
    />
  );
}

const mapStateToProps = (state) => ({
  orgUsers: state.orgUsers,
  eventData: state.eventData,
});

export default connect(mapStateToProps)(React.memo(UsersDropDownList));
