import React, { Component } from "react";

import AccountDeliverable from "models/database/AccountDeliverable";
import AccountDeliverables from "models/database/AccountDeliverables";
import CrudActionsTable from "../../../shared/CrudActionsTable";
import EditDeliverablesModal from "./EditDeliverablesModal";


class DeliverablesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : []
        };
    }
    getDeliverables = async () => {
        const deliverables = await new AccountDeliverables().getAllEntities();
        this.setState({ data: deliverables });
    }
    componentDidMount() {
        this.getDeliverables();
    }

    render() {
        const pageSize = 10;

        return (
            <>
                <CrudActionsTable
                    data={this.state.data}
                    dataModel={AccountDeliverable}
                    filterable={true}
                    newItem={this.props.newRow}
                    resizable={true}
                    defaultPageSize={pageSize}
                    modalName="deliverable"
                    editModal={EditDeliverablesModal}
                />
            </>
        );
    }
}

export default DeliverablesTable;
