import CustomSelect from "creative-tim/components/CustomSelect";
import DeliverablesDropdown from "layouts/Plan/shared/DeliverablesDropdown";
import IconTooltip from "layouts/Plan/shared/IconTooltip";
import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { sponsorshipTypes } from "utilities/constants/sponsorship-terms";

const NewSponsorshipForm = (props) => {
    const [name, setSponsorshipName] = useState("");
    const [type, setType] = useState("");
    const [description, setDescription] = useState("");
    const [deliverables, setDeliverables] = useState([]);

    const [editingIndex, setEditingIndex] = useState("");
    const [isPopulatingForm, setIsPopulatingForm] = useState(
        props.isPopulatingForm
    );

    const clearHooks = () => {
        setSponsorshipName("");
        setType("");
        setDescription("");
        setDeliverables([]);
    };

    const arrayToObject = (arr) => {
        return arr.reduce((map, obj) => {
            if (obj && obj.id) {
                if (obj.label) {
                    obj.name = `${obj.label}`;
                    obj.reserved=0;
                    delete obj.label;
                }
                map[obj.id] = obj;
            }
            return map;
        }, {});
    };

    const submit = () => {
        const sponsorship = {
            name,
            type,
            description,
            deliverables: arrayToObject(Object.values(deliverables)),
        };

        props.submitCallback(sponsorship, editingIndex);
        clearHooks();
    };

    useEffect(() => {
        if (props.isSubmitReady) {
            submit();
        }
        if (isPopulatingForm) {
            const {
                name,
                type,
                description,
                deliverables,
            } = props.sponsorship.data;
            
            setEditingIndex(props.sponsorship.index);

            setSponsorshipName(name);
            setType(type);
            setDescription(description);

            const deliverablesData = deliverables
                ? Object.values(deliverables)
                : [];
            setDeliverables(deliverablesData);

            setIsPopulatingForm(false);
        }
    }, [props]);

    const onChangeHandler = (event, setState) => {
        const { value } = event.target;
        setState(value);
    };

    const deliverablesDropdownCallback = (dropdownData) => {
        setDeliverables(dropdownData);
    };

    const createSingleSelect = (value, label) => {
        return {
            value,
            label,
        };
    };

    const sponsorshipTypeDropDown = () => {
        if (!type && props.isPopulatingForm) return <></>;
        const typeOptions = sponsorshipTypes.map((type, index) =>
            createSingleSelect(index, type)
        );
        let defaultSelected;
        let placeholder = "Type";
        if (type) {
            let index = sponsorshipTypes.indexOf(type);
            defaultSelected = typeOptions[index];
        }
        return (
          <CustomSelect
            options={typeOptions}
            placeholder={placeholder}
            defaultSelected={defaultSelected}
            value={type}
            callback={(value) => setType(value.label)}
          />
        );
    };

    const deliverablesDropdown = (
        <Row>
            <Col md="2"></Col>
            <DeliverablesDropdown
                callback={deliverablesDropdownCallback}
                filterItems={false}
                defaultValue={
                    props?.sponsorship?.data?.deliverables
                        ? Object.values(
                              props.sponsorship.data.deliverables
                          ).map((d) => ({
                              ...d,
                              label: d.name,
                          }))
                        : null
                }
                multiSelect={true}
            />
        </Row>
    );

    const nameRow = (
        <Row>
            <Label md="3">Sponsorship Name</Label>
            <Col md="9">
                <FormGroup>
                    <Input
                        type="text"
                        value={name}
                        onChange={(event) =>
                            onChangeHandler(event, setSponsorshipName)
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
    );

    const descriptionRow = (
        <Row>
            <Label md="3">Description</Label>
            <Col md="9">
                <FormGroup>
                    <Input
                        type="text"
                        value={description}
                        onChange={(event) =>
                            onChangeHandler(event, setDescription)
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
    );

    const typeRow = (
        <Row id="sponsorshipTypeContainer">
            <Col md="3" className="text-right">
              <Label>Type</Label>
              <IconTooltip 
                iconClass="fas fa-info-circle pl-1 arcat icon-md"
                text="Each partner added to an event must be given a Sponsorship Level designation, and can 
                only have one per event. You can add unlimited A La Carte sponsorships to event partners"
              />
            </Col>
            {sponsorshipTypeDropDown()}
        </Row>
    );

    const deliverablesRow = (
        <>
            <Label md="3">Deliverables</Label>
            {deliverablesDropdown}
        </>
    );

    const form = () => {
        return (
            <Fragment>
                {nameRow}
                {descriptionRow}
                {typeRow}
                {deliverablesRow}
            </Fragment>
        );
    };

    return form();
};

export default NewSponsorshipForm;
