import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Booth from "models/database/Booth";
import CrudActionsTable from "../../../../shared/CrudActionsTable";
import EditBoothsModal from "./EditBoothsModal";

function BoothsTable(props) {
  const [fetchedData, setFetchedData] = useState(false);

  const deleteCallback = (item) => {
    props.dispatch({
      type: "filterEventData",
      id: item.id,
      collection: "booths",
    });
    getData();
    props.deleteCallback(item);
  };

  const editCallback = (data, item, deleteItem) => {
    props.setData(data);
    props.dispatch({
      type: "editEventData",
      newData: item,
      deleteId: deleteItem.id,
      collection: "booths",
    });
    props.editCallback(deleteItem);
  };

  const getData = async (addChildDeliverables = false) => {
    const booths = await new Booth().getAll(
      props.eventData.booths,
      props.eventData.id
    );
    // console.log("booths table data", JSON.parse(JSON.stringify(booths)));
    props.setData(booths, addChildDeliverables);
  };

  useEffect(() => {
    (function () {
      if (fetchedData || !props.eventData || props.data.length) return;
      setFetchedData(true);
      getData(true);
    })();
  });

  const pageSize = props.data.length + 5;

  return (
    <>
      <CrudActionsTable
        data={props.data}
        dataModel={Booth}
        filterable={true}
        newItem={props.newRow}
        resizable={true}
        defaultPageSize={pageSize}
        modalName="booth"
        editModal={EditBoothsModal}
        editCallback={editCallback}
        deleteCallback={deleteCallback}
        hasNoActions={!props.isEventEditable}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(BoothsTable);
