import React from "react";
import CrudActionsTable from "../../../../shared/CrudActionsTable";
import Document from "../../../../../../models/database/Document";
import { connect } from "react-redux";
// react component for creating dynamic tables

class DocumentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  async fetchDocuments(event) {
    const documents = await new Document().getAllEventDocs(event);
    return documents;
  }

  async componentDidUpdate(prevProps) {
    let { event } = this.props;
    if (prevProps.event != event) {
      this._isMounted = true;
      let docs = await this.fetchDocuments(event);
      if (this._isMounted) this.setState({ data: docs });
    }
  }

  async componentDidMount() {
    let { event } = this.props;
    this._isMounted = true;
    let docs = await this.fetchDocuments(event);
    if (this._isMounted) this.setState({ data: docs });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const pageSize = 10;
    return (
      <>
        <CrudActionsTable
          hasDownloadAction={true}
          hasEditAction={false}
          hasDuplicateAction={false}
          data={this.state.data}
          dataModel={Document}
          newItem={this.props.newRow}
          defaultPageSize={pageSize}
          modalName="contacts"
          editModal={null}
          isExportable={false}
          hasNoActions={!this.props.isEventEditable}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(DocumentsTable);
