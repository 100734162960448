import React, { useState } from "react";
import ModalContainer from "../../shared/ModalContainer";
import EditContactForm from "../../shared/forms/ContactForm";

export default function EditContactModal(props) {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(true);
  };

  const editContactFormSubmitCallback = (data, index, orgData) => {
    setIsSubmitReady(false);
    props.formSubmitCallBack(data, index, null, orgData);
    props.toggle();
  };

  return (
    <ModalContainer
      modalTitle="Edit Contact"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={() => modalContainerSubmitCallback()}
    >
      <EditContactForm
        isPopulatingForm={true}
        isSubmitReady={isSubmitReady}
        contact={props.item}
        submitForm={setIsSubmitReady}
        submitCallback={(data, index, orgData) =>
          editContactFormSubmitCallback(data, index, orgData)
        }
      />
    </ModalContainer>
  );
}
