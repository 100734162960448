import FirebaseAdmin from "models/backend/FirebaseAdmin";
import {
  usersFirebaseUrl,
  usersFirebaseJsonUrl,
} from "../../utilities/constants/urls";
import PersistentBase from "./PersistentBase";
import CustomerOrgUser from "./CustomerOrgUser";

export default class User extends PersistentBase {
  constructor(id, firstName, lastName, email, organization, roleType, uid, disabled) {
    super();
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.organization = organization;
    this.roleType = roleType;
    this.uid = uid;
    this.disabled = disabled;
    this.csvTitle = "User";
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = usersFirebaseUrl;
    this.jsonUrl = usersFirebaseJsonUrl;
  }

  async get(userUid) {
    this.firebaseId = userUid;
    this.jsonUrl = await this.appendAuth(`${usersFirebaseUrl}/${userUid}.json`);
    return super.get();
  }

  async getAllUsers(usersData, withTableFormat = true) {
    const users = [];
    let index = 0;
    for (const userData of usersData) {
      if (!userData?.usersId) continue;
      this.jsonUrl = await this.appendAuth(`${usersFirebaseUrl}/${userData.usersId}.json`);
      const response = await super.get();
      if (!response) continue;
      const responseObj = response.hasOwnProperty("uid") ? response : Object.values(response)[0];
      let user = new this.constructor().createWithObject({ ...responseObj, id: userData.id });
      if (withTableFormat) {
        user = user.tableRowFormat(index++);
      }
      users.push(user);
    }
    return users;
  }

  async createUser(payload) {
    this.jsonUrl = await this.appendAuth(
      `${usersFirebaseUrl}/${payload.uid}.json`
    );
    return super.update(this.jsonUrl, payload);
  }

  async delete(id, eventId, itemClass) {
    if (!itemClass?.uid) return;
    const response = await new FirebaseAdmin().deleteUser(itemClass.uid);
    if (!response?.success) return this;
    await new CustomerOrgUser().delete(itemClass.id);
    this.url = usersFirebaseUrl;
    const user = await super.delete(itemClass.uid);
    return user;
  }

  async update(payload) {
    const url = await super.appendAuth(
      `${usersFirebaseUrl}/${payload.uid}.json`
    );
    delete payload.jsonUrl;
    delete payload.url;
    const response = await super.update(url, payload);
    const userData = new this.constructor().createWithObject(response);
    return userData;
  }

  tableColumnsAndValues() {
    return {
      name: {
        cellText: `${this.firstName} ${this.lastName}`,
      },
      email: {
        cellText: this.email,
      },
      "role type": {
        cellText: this.centerAlignDiv(this.roleType || "-"),
        width: 150,
      },
    };
  }
}
