import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";

export default class ChildDeliverables extends PersistentBase {
  constructor(id, name, value) {
    super();
    this.id = id;
    this.name = name;
    this.value = value;
  }

  createWithObject(payload) {
    let deliverables = payload || this;
    this.id = deliverables?.id;
    this.name = deliverables.name;
    this.value = deliverables?.value;
    return this;
  }

  metaData() {
    return {
      id: this.id,
      name: this.name,
      value: this.value,
    };
  }
  async get(parentId, category) {
    if (parentId == null) return;
    const userOrgId = await super.getUserOrgId();
    if (category == "booth")
      this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrgId}/account-booths/${parentId}/deliverables.json`;
    if (category == "sponsorship")
      this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrgId}/account-sponsorships/${parentId}/deliverables.json`;
    this.jsonUrl = await super.appendAuth(this.jsonUrl);
    const response = await super.get();
    return response;
  }
  async update() {
    return this;
  }

  async delete(id, parentID, category) {
    if (parentID == null) return;
    const userOrgId = await super.getUserOrgId();
    if (category == "booth")
      this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/account-booths/${parentID}/deliverables`;
    if (category == "sponsorship")
      this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/account-sponsorships/${parentID}/deliverables`;
    const response = await super.delete(id);
    return response;
  }

  tableColumnsAndValues() {
    return {
      name: this.centerAlignDiv(this.name),
      // value: this.centerAlignDiv(this.value),
    };
  }
}
