import React, { useEffect } from "react";
import CrudActionsTable from "layouts/Plan/shared/CrudActionsTable";
import { connect } from "react-redux";
import CustomerOrgs from "models/database/CustomerOrgs";
import CustomerOrg from "models/database/CustomerOrg";
import EditCustomerOrgModal from "./EditCustomerOrgModal";

function CustomerOrgsTable(props) {

    useEffect(() => {
        (async function() {
            const customerOrgsData = await new CustomerOrgs().getAllEntities();
            props.setData(customerOrgsData);
        })();
    }, []);

    return (
        <>
            <CrudActionsTable
                data={props.data}
                dataModel={CustomerOrg}
                filterable={true}
                resizable={true}
                defaultPageSize={8}
                modalName="Organization"
                editModal={EditCustomerOrgModal}
                hasDuplicateAction={false}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        eventData: state.eventData
    }
};

export default connect(mapStateToProps)(CustomerOrgsTable);
