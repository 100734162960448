import { createStore } from "redux";
import handlers from "./handlers";
import initialState from "./initialState";

function reducer(state = initialState, event) {
  const handler = handlers[event.type];
  if (handler) {
    return handler(state, event);
  }
  return state;
}

export default createStore(reducer);
