import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
export default class CustomerOrgUsers extends PersistentBase {
  constructor(id, orgId, name, email, roleType) {
    super();
    this.id = id;
    this.orgId = orgId;
    this.name = name;
    this.email = email;
    this.roleType = roleType;
    this.csvTitle = "Customer Organization User";
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = `${customerOrgsFirebaseUrl}`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}.json`;
  }

  async createOrgUser(payload) {
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${this.orgId}/users.json`;
    const response = await super.asyncCreate(payload);
    return response;
  }

  async delete(id) {
    const userOrgId = await this.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/users`;
    const response = await super.delete(id);
    return response;
  }

  tableColumnsAndValues() {
    return {
      name: {
        cellText: this.centerAlignDiv(this.name || "-"),
      },
      email: {
        cellText: this.centerAlignDiv(this.email),
      },
      "role type": {
        cellText: this.centerAlignDiv(this.roleType || "-"),
        width: 150,
      },
    };
  }
}
