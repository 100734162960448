import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";
import NewEmailModal from "./emails/NewEmailModal";
import SentEmailsTable from "./emails/SentEmailsTable";
import MailgunEmail from "models/backend/MailgunEmail";
import Email from "models/database/Email";
import { connect } from "react-redux";
import updateReduxData from "utilities/methods/updateReduxData";

function EmailsSection(props) {
  const isMounted = useRef(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [sentEmailsData, setSentEmailsData] = useState([]);
  
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const newEmailSubmit = async (data) => {
    const mailgunResponse = await new MailgunEmail()
      .createWithObject(data.emailForm)
      .create();
    const error =
      mailgunResponse?.error || mailgunResponse?.find?.((res) => res.error);
    if (error) {
      if (isMounted.current) {
        setIsNotificationVisible(true);
        setNotificationMessage(error.error || error);
      }
      return;
    }
    const mailgunIds = mailgunResponse
      .map((res) => res.id?.replace(/[<,>]/g, ""))
      .join();
    const newEmail = await new Email()
      .create({ ...data, mailgunIds }, props.eventData, sentEmailsData.length);
    if (newEmail) {
      await updateReduxData.event(props.eventData.code);
    }
    if (isMounted.current) {
      setSentEmailsData((prevData) => [...prevData, newEmail]);
    }
  };

  const errorNotification = (
    <Alert
      style={{
        position: "fixed",
        top: "20px",
        right: "20px",
        zIndex: "99999999",
        minWidth: "300px",
      }}
      color="info"
      isOpen={isNotificationVisible}
      toggle={() => setIsNotificationVisible(!isNotificationVisible)}
    >
      <span style={{ marginRight: "12px" }} data-notify="message">
        {notificationMessage}
      </span>
    </Alert>
  );

  const emailsTable = (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader
          className="mr-2"
          style={{ display: 'flex', justifyContent: "space-between" }}>
          <Col className="d-flex justify-content-end">
            <NewEmailModal submitCallback={newEmailSubmit} />
          </Col>
        </CardHeader>
        <CardBody>
          <SentEmailsTable data={sentEmailsData} setData={(data) => {
            if (isMounted.current) {
              setSentEmailsData(data);
            }
          }} />
        </CardBody>
      </Card>
    </Col>
  );

  return (
    <>
      {errorNotification}
      <Row>{emailsTable}</Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(EmailsSection);
