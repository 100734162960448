import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import ContactTypeDropDown from "../ContactTypeDropDown";
import IconTooltip from "../IconTooltip";
import InputText from "../inputs/InputText";
import PhoneNumberInput from "../inputs/PhoneNumberInput";

function ContactInfoForm({ getContactFormValue, sendBackValues }) {
  const [firstName, setContactFirstName] = useState("");
  const [lastName, setContactLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contactType, setContactType] = useState("");
  const [isBlur, setIsBlur] = useState(false);
  const [isSetValue, setIsSetValue] = useState(false);

  useEffect(() => {
    getContactFormValue &&
      getContactFormValue({
        firstName,
        lastName,
        title,
        email,
        phone,
        contactType,
      });

    if (
      sendBackValues &&
      Object.keys(sendBackValues).length > 0 &&
      !isSetValue
    ) {
      let {
        firstName,
        lastName,
        title,
        email,
        contactType,
        phone,
      } = sendBackValues;
      setContactFirstName(String(firstName));
      setContactLastName(String(lastName));
      setTitle(String(title));
      setEmail(String(email));
      setContactType(String(contactType));
      setPhone(String(phone));
      setIsSetValue((prevState) => !prevState);
    }
  }, [isBlur]);

  const firstAndLastName = (
    <>
      <Row>
        <Col sm="6">
          <InputText
            label="First Name"
            colSize={8}
            labelSize={4}
            value={firstName}
            type="text"
            inputHandler={(val) => setContactFirstName(val)}
            handleBlur={() => setIsBlur((prev) => !prev)}
          />
        </Col>
        <Col sm="6">
          <InputText
            label="Last Name"
            colSize={8}
            labelSize={4}
            value={lastName}
            type="text"
            inputHandler={(val) => setContactLastName(val)}
            handleBlur={() => setIsBlur((prev) => !prev)}
          />
        </Col>
      </Row>
    </>
  );
  const titleInput = (
    <>
      <Row>
        <Col sm="12">
          <InputText
            label="Title"
            colSize={8}
            labelSize={2}
            value={title}
            type="text"
            inputHandler={(val) => setTitle(val)}
            handleBlur={() => setIsBlur((prev) => !prev)}
          />
        </Col>
      </Row>
    </>
  );

  const emailInput = (
    <>
      <Row>
        <Col sm="12">
          <InputText
            label="Email"
            colSize={8}
            labelSize={2}
            value={email}
            type="text"
            inputHandler={(val) => setEmail(val)}
            handleBlur={() => setIsBlur((prev) => !prev)}
          />
        </Col>
      </Row>
    </>
  );

  const contactTypeRow = (
    <Row>
      <Col xs={12} md={2} className="my-md-auto mx-auto px-md-0 text-right">
        <Label>
          Contact Type
        </Label>
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="Emails are sent out according to contact type"
        />
      </Col>
      <Col xs={12} md={4} className="px-0">
        <div id="contactTypeContainer">
          <ContactTypeDropDown
            getContactType={(val) => {
              setIsBlur(!isBlur);
              setContactType(val?.label);
            }}
            defaultSelected={sendBackValues?.contactType}
          />
        </div>
      </Col>
      <Col sm={12} md={6}>
        <div>
          <PhoneNumberInput
            value={phone}
            handleBlur={(val) => {
              setIsBlur(!isBlur);
              setPhone(val);
            }}
          />
        </div>
      </Col>
    </Row>
  );

  return (
    <>
      {firstAndLastName}
      {contactTypeRow}
      {emailInput}
      {titleInput}
    </>
  );
}

export default React.memo(ContactInfoForm);
