import { firebaseUsersBackendUrl } from "../../utilities/constants/urls";
import PersistentBase from "./PersistentBase";

export default class FirebaseAdmin extends PersistentBase {
  constructor() {
    super();
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = firebaseUsersBackendUrl;
  }

  async deleteUser(uid) {
    const payload = { uid };
    this.url = await super.appendAuth(`${firebaseUsersBackendUrl}/delete`);
    const response = await super.post(payload);
    return response;
  }

  async toggleUserActiveStatus(uid, isDisabled) {
    const payload = { uid, isDisabled };
    this.url = await super.appendAuth(`${firebaseUsersBackendUrl}/toggle`);
    const response = await super.post(payload);
    return response;
  }

  async getOrgContractualUsers(orgId) {
    this.url = `${firebaseUsersBackendUrl}/contractual-users/${orgId}`;
    const response = await super.get();
    return Boolean(response);
  }
}
