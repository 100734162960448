import React from "react";
import { CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import SectionContainer from "../../../SectionContainer";
import DeliverablesCalendar from "layouts/Plan/shared/DeliverablesCalendar";

const CalendarSection = () => {
  return (
    <Row>
      <Col md={12} className="mx-0 px-0">
        <SectionContainer>
          <div>
            <CardBody>
              <DeliverablesCalendar 
                isEventLevel 
              />
            </CardBody>
          </div>
        </SectionContainer>
      </Col>
    </Row>
  );
}

export default CalendarSection;
