import React, { Fragment, useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

let tooltipIdCount = 0;

/**
 * Component for creating an icon with tooltip when hovered
 *
 * {@link https://demos.creative-tim.com/blk-design-system/docs/1.0/foundation/icons.html | Nucleo Icons}
 * {@link https://fontawesome.com/v5.15/icons | FontAwesome Icons}
 */
const IconTooltip = (props) => {
  const [tooltipId, setTooltipId] = useState(`IconTooltip-${tooltipIdCount++}`);
  const [textBlocks, setTextBlocks] = useState([]);

  useEffect(() => {
    const blocks = props.text.split("<br>");
    setTextBlocks(blocks);
  }, []);

  return (
    <>
      <i id={tooltipId} className={props.iconClass}>
        {props.icon}
      </i>
      <UncontrolledTooltip
        delay={props.delay || 0}
        target={tooltipId}
        placement={props.placement || "auto-start"}
        popperClassName="arcat tooltip-dark"
      >
        {textBlocks.map((text, index) => (
        <Fragment key={index}>
          {text}
          <br />
        </Fragment>
        ))}
      </UncontrolledTooltip>
    </>
  );
}

export default IconTooltip;
