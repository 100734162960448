import PersistentBase from "./PersistentBase";

import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";

export default class AccountEmailTemplate extends PersistentBase {
  constructor(id, name, subject, usedVariables, html) {
    super();
    this.id = id;
    this.name = name;
    this.subject = subject;
    this.usedVariables = usedVariables;
    this.html = html;
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/account-emails`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/account-emails.json`;
  }

  metaData() {
    return {
      name: this.name,
      subject: this.subject,
      usedVariables: this.usedVariables,
      html: this.html,
    };
  }

  variablesToObject = (variables) => {
    if (!variables) return {};
    const variablesObj = variables.reduce((mapObj, variable) => {
      if (mapObj[variable.category]) {
        mapObj[variable.category][variable.key] = variable.value || "";
      } else {
        mapObj[variable.category] = { [variable.key]: variable.value || "" };
      }
      return mapObj;
    }, {});
    return variablesObj;
  };

  async update(payload) {
    const updatedEntity = this.createWithObject(payload);
    if (!this.url) await this.setRestUrl();
    const url = await super.appendAuth(super.buildUrl(this.url, this.id));
    super.update(url, updatedEntity.metaData());
    return this;
  }

  tableColumnsAndValues() {
    return {
      name: this.name,
      subject: this.subject,
      content: this.html,
    };
  }
}
