import React, { useEffect, useState } from "react";
import { Row, Col, Input, Label } from "reactstrap";
import RichTextEditor from "layouts/Plan/shared/RichTextEditor";
import EmailFormVariables from "layouts/Plan/shared/EmailFormVariables";

const NewEmailTemplateForm = (props) => {
  const [usedVariables, setUsedVariables] = useState([]);
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState(" ");

  const changeHandler = (value, setValue) => {
    setValue(value);
  };

  useEffect(() => {
    props.setData({ name, subject, html, usedVariables });
  }, [name, subject, html, usedVariables]);

  const nameRow = (
    <Row className="mt-2 d-flex align-items-center">
      <Col md="1">
        <Label>Name</Label>
      </Col>
      <Col md="6">
        <Input
          type="text"
          value={name}
          onChange={(e) => changeHandler(e.target.value, setName)}
          required
        />
      </Col>
    </Row>
  );

  const subjectRow = (
    <Row className="mt-2 d-flex align-items-center">
      <Col md="1">
        <Label for="emailSubject">Subject</Label>
      </Col>
      <Col md="6">
        <Input
          id="emailSubject"
          type="text"
          value={subject}
          onChange={(e) => changeHandler(e.target.value, setSubject)}
          required
        />
      </Col>
    </Row>
  );

  const variablesRow = (
    <EmailFormVariables
      subject={subject}
      html={html}
      setUsedVariables={setUsedVariables}
    />
  );

  const bodyRow = (
    <Row className="mt-3">
      <Col md="12">
        <RichTextEditor
          onBlur={(value) => changeHandler(value, setHtml)}
          required
        />
      </Col>
    </Row>
  );

  return (
    <>
      {nameRow}
      {subjectRow}
      {variablesRow}
      {bodyRow}
    </>
  );
};

export default NewEmailTemplateForm;
