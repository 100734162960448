import moment from "moment";
import { toString } from "lodash";
import { isDate } from "./isDate";
import arrayToDisplayString from "./arrayToDisplayString";

const formatEmailVariable = (val) => {
  if (Array.isArray(val)) {
    return arrayToDisplayString(val);
  } else if (isDate(val)) {
    return moment(val.toISOString?.() || val, moment.ISO_8601, true).format(
      "dddd, MMMM Do"
    );
  } else return toString(val);
};

export default formatEmailVariable;
