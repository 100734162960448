import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Label, Button, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import InputText from "../../../../shared/inputs/InputText";
import OrganizationsDropDown from "../../../../shared/OrganizationsDropDownList";
import OfferingSponDropDown from "layouts/Plan/shared/inputs/OfferingSponDropDown";
import OfferingBoothsDropDown from "layouts/Plan/shared/inputs/OfferingBoothDropDown";
import { connect } from "react-redux";
import EventDeliverablesMap from "layouts/Plan/shared/EventDeliverablesMap";
import { CurrencyToNumber } from "layouts/Plan/shared/CurrencyFormatter";
import { NumberToCurrency } from "layouts/Plan/shared/CurrencyFormatter";
import IconButton from "layouts/Plan/shared/IconButton";
import CustomLabel from "layouts/Plan/shared/CustomLabel";
import IconTooltip from "layouts/Plan/shared/IconTooltip";
import ContactsDropdown from "layouts/Plan/shared/ContactsDropdown";
import formatContactLabel from "utilities/methods/formatContactLabel";
import moment from "moment";
import _ from "lodash";

const EventPartnerForm = (props) => {
  const [boothNumber, setBoothNumber] = useState("");
  const [boothSize, setBoothSize] = useState("");
  const [totalSpend, setTotalSpend] = useState("");
  const [company, setCompany] = useState({});
  const [regCode, setRegCode] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [contractReceived, setContractReceived] = useState(moment());
  const [eventContacts, setEventContacts] = useState([]);
  const [sponsorships, setSponsorships] = useState([{ index: 0 }]);
  const [carteSponsorships, setCarteSponsorships] = useState([{ index: 0 }]);
  const [booths, setBooths] = useState([{ index: 0 }]);
  const [notes, setNotes] = useState("");

  const [currentEventState, setCurrentEventState] = useState(props.event)

  const makeObjectWithKeys = (obj, collection) => {
    if (!obj.value) return {};
    let newObj = {};
    obj[`account${collection}Id`] = obj.value;
    newObj[obj.value] = obj;
    delete newObj.value;
    return newObj;
  };

  const isFormValid = () => {
    return !!(
      company.value
    );
  }

  const submitEventPartnerForm = () => {
    if (!isFormValid()) return;
    const eventContactsData = eventContacts.reduce((mapObj, contact) => {
      mapObj[contact.itemClass.id] = contact.itemClass;
      return mapObj;
    }, {});
    const boothsData = {
      booths: makeObjectWithKeys(booths, "booth"),
    };
    const carteSponsors = carteSponsorships.reduce((mapObj, sponsor) => {
      if (sponsor?.value) {
        mapObj[sponsor.value] = {
          ...sponsor,
          isCarteSponsor: true,
          accountsponsorId: sponsor.value
        };
      }
      return mapObj;
    }, {});

    const sponsorsData = {
      sponsorships: {
        ...makeObjectWithKeys(sponsorships, "sponsor"),
        ...carteSponsors,
      },
    };

    const newPartnerPayload = {
      orgId: company.value,
      boothNumber,
      totalSpend,
      ...sponsorsData,
      ...boothsData,
      eventContacts: eventContactsData,
      regCode,
      salesPerson,
      contractReceived: moment(contractReceived).toISOString(),
      notes,
      carteSponsorships,
      id: props?.partner?.data?.id,
    }

    props.submitCallback(
      newPartnerPayload,
      props?.partner?.index,
      props?.orgData,
      props?.orgUsers
    );

    if (!currentEventState.partners) {
      currentEventState.partners = {
        [company.value]: newPartnerPayload
      }
      setCurrentEventState(currentEventState)
    } else {
      const currentEvent = { ...currentEventState }
      currentEvent.partners[company.value] = newPartnerPayload
      setCurrentEventState(currentEvent)
    }
  };

  const carteSponsorshipsCallback = (dropdownData) => {
    setCarteSponsorships(prevState => {
      const newState = [...prevState];
      newState[dropdownData.index] = {
        ...prevState[dropdownData.index],
        ...dropdownData,
      };
      return newState;
    });
  }

  const sponsorshipDeleteCallback = (index) => {
    setCarteSponsorships((prevState) => {
      let newState = [...prevState];
      delete newState[index];
      return newState;
    });
  }

  const addSponsorship = () => {
    setCarteSponsorships([
      ...carteSponsorships,
      { index: carteSponsorships.length },
    ]);
  }

  useEffect(() => {
    if (props.isPopulatingForm) {
      const {
        booth,
        boothNumber,
        company,
        sponsorLevel,
        totalSpend,
        orgId,
        boothId,
        sponsorId,
        regCode,
        salesPerson,
        contractReceived,
        notes,
        eventContacts,
        boothsObj,
        booths,
        sponsorships,
        carteSponsorships
      } = props.partner.data;

      const boothObject = booths?.[boothId] || boothsObj?.boothId;
      const sponsorshipObject = sponsorships?.[sponsorId] || Object.values(sponsorships || {}).find((sponsor) => !sponsor.isCarteSponsor);
      const carteSponsors = carteSponsorships || Object.values(sponsorships || {}).filter((sponsor) => sponsor.isCarteSponsor);
      const eventContactsData = Object.values(eventContacts || {}).map((contact, index) => ({ 
        label: formatContactLabel(contact, true),
        value: index,
        itemClass: contact, 
      }));

      setBoothNumber(boothNumber);
      setCompany({ label: company, value: orgId });
      setEventContacts(eventContactsData);
      setSponsorships({
        ...sponsorshipObject,
        label: sponsorLevel,
        value: sponsorId,
      });
      setCarteSponsorships(carteSponsors?.length ? carteSponsors : [{ index: 0 }]);
      setTotalSpend(totalSpend);
      setBooths({ ...boothObject, label: booth, value: boothId });
      setRegCode(regCode || "");
      setSalesPerson(salesPerson || "");
      setContractReceived(moment(contractReceived));
      setNotes(notes);
    }
  }, []);

  useEffect(() => {
    if (props?.isSubmitReady) {
      submitEventPartnerForm();
    }
  });

  const updateTotalSpend = () => {
    const total = [...carteSponsorships, sponsorships, booths].reduce((mapNum, entity) => {
      const deliverablesSpend = Object.values(entity?.deliverables || {})
        .reduce((dMapNum, deliverable) => dMapNum + (Number(CurrencyToNumber(deliverable?.rate)) || 0), 0);
      return mapNum + (deliverablesSpend || 0) + (Number(CurrencyToNumber(entity?.itemClass?.rate)) || 0);
    }, 0);
    setTotalSpend(NumberToCurrency(total));
  };

  useEffect(updateTotalSpend, [sponsorships, carteSponsorships, booths]);

  const updateSponsorshipsRate = (e, index = null) => {
    const value = NumberToCurrency(CurrencyToNumber(e.target.value));
    if (index != null) {
      setCarteSponsorships((prevState) => {
        const newState = [...prevState];
        _.set(newState[index], "itemClass.rate", value);
        return newState;
      });
    } else {
      setSponsorships((prevState) => {
        const newState = { ...prevState };
        _.set(newState, "itemClass.rate", value);
        return newState;
      });
    }
  }

  const updateBoothRate = (e) => {
    const value = NumberToCurrency(CurrencyToNumber(e.target.value));
    setBooths((prevState) => {
      const newState = { ...prevState };
      _.set(newState, "itemClass.rate", value);
      return newState;
    })
  }

  const sponsorshipDropdown = (
    <Fragment>
    <Row className="pt-2 pb-1">
      <Label sm={3}>Sponsorship Level</Label>
      <Col sm={sponsorships.label ? 6 : 9} className="pl-0">
        <OfferingSponDropDown
          defaultSelected={sponsorships.label && sponsorships}
          callback={setSponsorships}
          cloneData={true}
          filterByType="Level"
        />
      </Col>
      {!!sponsorships.label && (
        <Col sm={3} className="pl-0 mt-2">
          <Input
            placeholder="Rate"
            value={NumberToCurrency(sponsorships.itemClass?.rate) || ""}
            type="text"
            onChange={updateSponsorshipsRate}
          />
        </Col>
      )}
      {sponsorships.deliverables && (
      <Row className="pl-5 mt-1 pr-3">
        <Col sm={3} />
        <Col sm={9} className="pl-2">
          <Label className="pl-0 ml-0 mb-0 mt-1">Deliverables</Label>
          <EventDeliverablesMap
            deliverables={sponsorships.deliverables}
            setEntityState={setSponsorships}
            isObj={true}
          />
        </Col>
      </Row>
    )}
    </Row>
    </Fragment>
  );

  const addSponsorshipButton = (
    <Row className="ml-3">
      <Button
        className="mt-2"
        size="md"
        onClick={addSponsorship}
        title="Add Sponsorship"
      >
        + Add Sponsorship
      </Button>
    </Row>
  );
  
  const carteSponsorshipsMap = carteSponsorships.map((sponsorship, index) => 
    !!sponsorship && (
    <Fragment key={index}>
      <Row>
        <Col sm={sponsorship?.label ? 7 : 12} className="pr-3 mr-0">
          <OfferingSponDropDown
            defaultSelected={sponsorship.label && sponsorship}
            callback={carteSponsorshipsCallback}
            cloneData={true}
            filterByType="A La Carte"
            index={index}
          />
        </Col>
        {!!sponsorship.label && (
          <Col sm={3} className="pl-0 mt-2">
            <Input
              placeholder="Rate"
              value={NumberToCurrency(sponsorship.itemClass?.rate) || ""}
              type="text"
              onChange={(e) => updateSponsorshipsRate(e, index)}
            />
          </Col>
        )}
        {!!(sponsorship.label && index > 0) && (
          <Col md="1" className="m-0 p-0 pt-2">
            <IconButton
              tooltipId={index}
              indexKey={index}
              onClick={() => sponsorshipDeleteCallback(index)}
              icon="icon-trash-simple"
              color="danger"
              tooltip="Delete"
            />
          </Col>
        )}
      </Row>
      {!!Object.keys(sponsorship.deliverables || {}).length && (
        <Row className="pl-5 mt-1 pr-3">
          <Label className="ml-0 mb-0 mt-1">Deliverables</Label>
          <EventDeliverablesMap
            deliverables={sponsorship.deliverables}
            setEntityState={setCarteSponsorships}
            index={index}
          />
        </Row>
      )}
      {
        !!(sponsorship.label && index === carteSponsorships.length - 1) && 
        addSponsorshipButton
      }
    </Fragment>
  ));

  const carteSponsorshipDropdown = (
    <Row className="p-0 pt-2 pb-1">
      <Label sm={3}>A La Carte Sponsorships</Label>
      <Col sm={9} className="pl-0 pb-1">{carteSponsorshipsMap}</Col>
    </Row>
  );

  const boothsDropdownCallback = (dropdownData) => {
    setBooths(dropdownData);
  };

  const boothDropdown = (
    <Row className="pt-0 pb-3">
      <Label sm={3}>Booth</Label>
      <Col sm={booths?.label ? 6 : 9} className="pl-0">
        <OfferingBoothsDropDown
          defaultSelected={booths.label && booths}
          callback={boothsDropdownCallback}
          cloneData={true}
        />
      </Col>
      {!!booths.label && (
        <Col sm={3} className="pl-0 mt-2">
          <Input
            placeholder="Rate"
            value={NumberToCurrency(booths.itemClass?.rate) || ""}
            type="text"
            onChange={updateBoothRate}
          />
        </Col>
      )}
      {booths.deliverables && (
        <Row className="mt-1 pr-3">
          <Col sm={3} />
          <Col sm={9} className="pl-5">
            <Label className="ml-0 mb-0 mt-1">Deliverables</Label>
            <EventDeliverablesMap
              deliverables={booths.deliverables}
              setEntityState={setBooths}
              isObj={true}
            />
          </Col>
        </Row>
      )}
    </Row>
  );

  const organizationDropDown = (
    <Row className="pt-2 pb-1">
      <Col sm="3" className="pr-0">
        <CustomLabel label="Organization Names" required />
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="Organization must be added in the Organizations tab before they can be added as a Partner to the event"
        />
      </Col>
      <Col id="organizationInputContainer" sm={9} className="pl-0">
        <OrganizationsDropDown
          event={currentEventState}
          defaultSelected={company}
          callback={(val) => setCompany(val)}
        />
      </Col>
    </Row>
  );

  const regCodeInput = (
    <InputText
      label="Reg Code"
      labelSize={3}
      colSize={6}
      value={regCode}
      type="text"
      inputHandler={setRegCode}
    />
  );

  const salesPersonInput = (
    <InputText
      label="Sales Contact(s)"
      labelSize={3}
      colSize={6}
      value={salesPerson}
      type="text"
      inputHandler={setSalesPerson}
    />
  );

  const contractReceivedInput = (
    <Row className="pt-1 pb-1">
      <Label sm={3}>Contract Received</Label>
      <Col sm={6} className="mt-1">
        <ReactDatetime
          value={contractReceived}
          onChange={setContractReceived}
          inputProps={{
            className: "form-control",
            placeholder: "Date",
          }}
          dateFormat="MMMM Do YYYY"
          timeFormat=""
          closeOnSelect={true}
        />
      </Col>
    </Row>
  );

  const eventContactsInput = (
    <Row className="pt-0 pb-3">
      <Label sm={3}>Event Specific Contact</Label>
      <Col sm={9} className="pl-0">
        <ContactsDropdown 
          callback={setEventContacts}
          defaultValue={eventContacts}
          index={1}
          filterByOrg={[company.value]}
          multiSelect 
          isTypeVisible
        />
      </Col>
    </Row>
  );

  const boothNumberInput = (
    <InputText
      label="Booth Number"
      labelSize={4}
      colSize={8}
      value={boothNumber}
      type="number"
      inputHandler={setBoothNumber}
      handleBlur={() => {}}
    />
  );

  const totalSpendInput = (
    <Row>
      <Label sm={3}>Total Spend</Label>
      <Label sm={9} className="text-left">{totalSpend}</Label>
    </Row>
  );

  const notesInput = (
    <InputText
      className="pl-2 pt-1"
      label="Notes"
      labelSize={3}
      colSize={9}
      value={notes}
      type="textarea"
      inputHandler={setNotes}
      handleBlur={() => {}}
    />
  );

  const requiredFieldsRow = (
    <Row className="pt-3">
      <Col>
        <CustomLabel label="Required Fields" required isInverted />
      </Col>
    </Row>
  );

  return (
    <Fragment>
      {organizationDropDown}
      {regCodeInput}
      {salesPersonInput}
      {contractReceivedInput}
      {eventContactsInput}
      <Row className="pl-5 mt-1 pr-3">
        <Row className="ml-3">
          {boothNumberInput}
        </Row>
      </Row>
      {totalSpendInput}
      {sponsorshipDropdown}
      {carteSponsorshipDropdown}
      {boothDropdown}
      {notesInput}
      {requiredFieldsRow}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  orgData: state.orgData,
  orgUsers: state?.orgUsers,
});
export default connect(mapStateToProps)(EventPartnerForm);
