import React, { Fragment, useState } from "react";
import IconButton from "layouts/Plan/shared/IconButton";
import ReactDatetime from "react-datetime";
import { Col, Row, Input, Label, CustomInput } from "reactstrap";
import DeliverablesDropdown from "./DeliverablesDropdown";
import { CurrencyToNumber, NumberToCurrency } from "./CurrencyFormatter";

let uniqueId = 0;

function EventDeliverablesMap(props) {
  const [uniqueMapId, setUniqueMapId] = useState(uniqueId++);

  const deliverableDeleteHandler = (deliverable) => {
    props.setEntityState((prevState) => {
      const newState = props.isObj ? { ...prevState } : [...prevState];
      if (!props.isObj) {
        delete newState[props.index]?.deliverables?.[deliverable.id];
      } else {
        delete newState.deliverables?.[deliverable.id];
      }
      return newState;
    });
  };

  const deliverableInputHandler = (deliverable, value, keyName) => {
    props.setEntityState((prevState) => {
      const newState = props.isObj ? { ...prevState } : [...prevState];
      if (!props.isObj && newState[props.index]?.deliverables?.[deliverable.id]) {
        newState[props.index].deliverables[deliverable.id][keyName] = value;
      } else if (newState.deliverables?.[deliverable.id]) {
        newState.deliverables[deliverable.id][keyName] = value;
      }
      return newState;
    });
  };

  const deliverableChangeHandler = (deliverable, newDeliverable) => {
    props.setEntityState((prevState) => {
      const newState = props.isObj ? { ...prevState } : [...prevState];
      if (!props.isObj && newState[props.index]?.deliverables?.[deliverable.id]) {
        delete newState[props.index].deliverables[deliverable.id];
        newState[props.index].deliverables[newDeliverable.id] = newDeliverable;
      } else if (newState.deliverables?.[deliverable.id]) {
        delete newState.deliverables[deliverable.id];
        newState.deliverables[newDeliverable.id] = newDeliverable;
      }
      return newState;
    });
  };

  const deliverableInputs = (deliverable, index) => (
    <>
      {!props.isEventLevel && (
        <Col md="1" className="m-0 p-0 pt-2" title="Deliverable Completed">
          <CustomInput
            id={`checkbox-${uniqueMapId}-${index}`}
            type="checkbox"
            inline={true}
            checked={deliverable.completed || false}
            onChange={(e) =>
              deliverableInputHandler(deliverable, e.target.checked, "completed")
            }
            aria-label="Completed"
          />
        </Col>
      )}
      <Col md="3" className="m-0 p-0">
        {props.isLabeled && <Label className="ml-0 mb-0">Quantity</Label>}
        <Input
          type="number"
          step="1"
          value={deliverable.quantity || ""}
          placeholder="Quantity"
          onChange={(e) =>
            deliverableInputHandler(deliverable, e.target.value, "quantity")
          }
        />
      </Col>
      <Col md="3" className="m-0 pl-2 pr-0">
        {props.isLabeled && <Label className="ml-0 mb-0">Rate</Label>}
        <Input
          type="text"
          value={NumberToCurrency(deliverable.rate) || ""}
          placeholder="Rate"
          onChange={(e) =>
            deliverableInputHandler(deliverable, CurrencyToNumber(e.target.value), "rate")
          }
        />
      </Col>
      <Col md="3" className="m-0 pl-2 pr-0">
        {props.isLabeled && <Label className="ml-0 mb-0">Deadline</Label>}
        <ReactDatetime
          onChange={(date) =>
            deliverableInputHandler(deliverable, date, "deadline")
          }
          value={deliverable.deadline || ""}
          inputProps={{
            className: "form-control",
            placeholder: "Deadline",
          }}
          closeOnSelect={true}
        />
      </Col>
      <Col md="1" className={`m-0 p-0 ${props.isLabeled ? "mt-4" : ""}`}>
        <IconButton
          tooltipId={`delete-${uniqueMapId}-${index}`}
          indexKey={index}
          onClick={() => deliverableDeleteHandler(deliverable)}
          icon="icon-trash-simple"
          color="danger"
          tooltip="Delete"
        />
      </Col>
    </>
  );
  
  const deliverablesMap = () =>
    Object.values(props.deliverables).map(
      (deliverable, index) => {
        const deliverableName = deliverable?.name || deliverable?.itemClass?.name;
        if (!deliverableName && !deliverable?.isNewDeliverable) return;
        return (
          <Col key={index} md="12" className="m-0 p-0 pl-3 mb-2">
            <div className="pl-3">
              {props.isLabeled && <Label className="ml-0 mb-0">Name</Label>}
              {props.isEventLevel ? 
                <DeliverablesDropdown 
                  isNotPadded
                  callback={(newDeliverable) => deliverableChangeHandler(deliverable, newDeliverable)}
                  defaultValue={{
                    ...deliverable,
                    label: deliverable.name || ""
                  }}
                /> : 
                <Label className="ml-0 mb-0 mt-0">
                  {deliverableName}
                </Label>
              }
            </div>
            <Row className="pt-2 pl-3 m-0 pr-3">
              {deliverableInputs(deliverable, index)}
            </Row>
          </Col>
        );
      }
    );

  return <Fragment>{deliverablesMap()}</Fragment>;
}

export default EventDeliverablesMap;
