import distinctColors from "distinct-colors";
import { nanoid } from "nanoid";
import DeliverablesDropdown from "layouts/Plan/shared/DeliverablesDropdown";
import EventsDropdown from "layouts/Plan/shared/EventsDropdown";
import StyledLink from "layouts/Plan/shared/StyledLink";
import Events from "models/database/Events";
import Organizations from "models/database/Organizations";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";
import { dateFormat } from "utilities/constants/date-format";
import { connect } from "react-redux";
import Calendar from "./Calendar";

const DeliverablesCalendar = (props) => {
  const [eventIds, setEventIds] = useState([]);
  const [colorMap, setColorMap] = useState(null);
  const [deliverablesDropdownData, setDeliverablesDropdownData] = useState([]);
  const [deliverablesData, setDeliverablesData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [deliverables, setDeliverables] = useState([]);

  const updateDeliverables = (newDeliverables, events) => {
    setDeliverables(formatDeliverables(newDeliverables, events));
    setDeliverablesDropdownData(newDeliverables.map((deliverable) => {
      let name = deliverable.name || deliverable.label || "-";
      if (!props.isEventLevel) name = `${name} (${deliverable.event.name})`;
      return { ...deliverable, name, itemClass: null };
    }));
  }

  const handleDeliverablesFilterChange = (deliverables) => {
    const deliverableIds = Object.values(deliverables || {}).reduce((mapArr, deliverable) => {
      if (deliverable?.itemClass?.exactId) mapArr.push(deliverable?.itemClass?.exactId);
      return mapArr;
    }, []);
    let filteredDeliverables = deliverableIds.length ? 
      deliverablesData.filter((deliverable) => deliverableIds.includes(deliverable.exactId)) 
      : deliverablesData;
    if (eventIds.length) {
      filteredDeliverables = filteredDeliverables.filter((deliverable) => eventIds.includes(deliverable.event.id));
    }
    setDeliverables(formatDeliverables(filteredDeliverables, eventsData));
  }

  const handleEventsFilterChange = (events) => {
    const eventIds = events.map((event) => event?.itemClass?.id);
    const filteredDeliverables = eventIds.length ? 
      deliverablesData.filter((deliverable) => eventIds.includes(deliverable.event.id)) 
      : deliverablesData;
    updateDeliverables(filteredDeliverables, eventsData);
    setEventIds(eventIds);
  };

  const modalContent = (deliverable) => (
    <div className="text-left">
      <Row>
        <Col md="4" className="text-right pr-1">
          <CardText>Event:</CardText>
        </Col>
        <Col md="8" className="text-left pl-1">
          <StyledLink to={`/plan/event/${deliverable.event.code}`}>
            {deliverable.event.name || deliverable.event.label || "-"}
          </StyledLink>
        </Col>
        <Col md="4" className="text-right pr-1">
          <CardText>Partner:</CardText>
        </Col>
        <Col md="8" className="text-left pl-1">
          <StyledLink to={`/plan/organization/${deliverable.partnerOrg.name}`}>
            {deliverable.partnerOrg.name || "-"}
          </StyledLink>
        </Col>
        <Col md="4" className="text-right pr-1">
          <CardText>Deadline:</CardText>
        </Col>
        <Col md="8" className="text-left pl-1">
          <CardText>{moment(deliverable.deadline).format(dateFormat)}</CardText>
        </Col>
        <Col md="4" className="text-right pr-1">
          <CardText>Completed:</CardText>
        </Col>
        <Col md="8" className="text-left pl-1">
          <CardText>{deliverable.completed ? "Yes" : "No"}</CardText>
        </Col>
      </Row>
    </div>
  );

  const generateEventColorMap = (events) => {
    if (props.isEventLevel) return { [events[0]?.id]: "#cf009f" };
    const colors = distinctColors({ count: events.length });
    const eventColorMap = events.reduce((mapObj, event, index) => ({
      ...mapObj,
      [event?.id]: event?.eventColor || colors[index]?.toString()
    }), {});
    setColorMap(eventColorMap);
    return eventColorMap;
  }

  const formatDeliverables = (deliverables, events) => {
    const eventColorMap = colorMap || generateEventColorMap(events);
    return deliverables.reduce((mapArr, deliverable) => {
      const deadline = moment(deliverable.deadline);
      if (!deliverable.deadline || !deadline.isValid() || !deliverable.partnerOrg) return mapArr;
      mapArr.push({
        title: deliverable.name || deliverable.label || "-",
        content: modalContent(deliverable),
        start: deadline.subtract(30, "minutes").toDate(),
        end: deadline.toDate(),
        allDay: true,
        colorCode: eventColorMap[deliverable.event.id]
      });
      return mapArr;
    }, []);
  };

  const getDeliverables = async (eventData = null) => {
    if (props.isEventLevel && !eventData) return;
    const events = props.isEventLevel ? [eventData] : await new Events().getAllEntities(false);
    if (!events?.length) return;
    const orgs = await new Organizations().getAllEntities(false);
    const deliverables = events.flatMap((event) =>
      Object.values(event?.partners || {}).flatMap((partner) => 
        Object.values({...(partner?.sponsorships || {}), ...(partner?.booths || {})})
          .flatMap((entity) => Object.values(entity.deliverables || {})
            .map((deliverable) => ({ 
              partnerOrg: orgs.find((org) => org.id === partner.orgId), 
              ...deliverable, 
              exactId: nanoid(),
              event 
            }))))
    );
    setDeliverablesData(deliverables);
    setEventsData(events);
    updateDeliverables(deliverables, events);
  };

  useEffect(() => {
    if (!props.isEventLevel || !props.eventData) return;
    getDeliverables(props.eventData);
  }, [props.eventData]);

  useEffect(() => { getDeliverables() }, []);

  return (
      <div className="content">
        <Card>
          {!props.isEventLevel && (
            <CardHeader>
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <CardTitle tag="h4">Calendar</CardTitle>
              </div>
            </CardHeader>
          )}
          <CardBody>
            <Row>
              {!props.isEventLevel && (
                <Col md="6">
                  <EventsDropdown 
                    multiSelect 
                    options={eventsData}
                    callback={handleEventsFilterChange}
                  />
                </Col>
              )}
              <Col md="6">
                <DeliverablesDropdown 
                  multiSelect 
                  options={deliverablesDropdownData}
                  callback={handleDeliverablesFilterChange}
                />
              </Col>
            </Row>
            <Calendar
              events={deliverables}
            />
          </CardBody>
        </Card>
      </div>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(DeliverablesCalendar);
