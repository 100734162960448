const firebaseDevRootUrl =
  "https://event-planner-5fe36-dev-rtdb.firebaseio.com/";
const firebaseDefaultRootUrl =
  "https://event-planner-5fe36-default-rtdb.firebaseio.com/";
const firebaseProdRootUrl = "https://arcatxp.firebaseio.com/";
export const firebaseRootUrl = process.env.NODE_ENV === "production" 
  ? firebaseProdRootUrl 
  : firebaseDefaultRootUrl;
// export const firebaseRootUrl = firebaseProdRootUrl;

export const eventsFirebaseUrl = `${firebaseRootUrl}events`;
export const eventsFirebaseJsonUrl = `${eventsFirebaseUrl}.json`;

export const deliverablesFirebaseUrl = `${firebaseRootUrl}deliverables`;
export const deliverablesFirebaseJsonUrl = `${firebaseRootUrl}deliverables.json`;

export const organizationsFirebaseUrl = `${firebaseRootUrl}organizations`;
export const organizationsFirebaseJsonUrl = `${organizationsFirebaseUrl}.json`;

export const contactsFirebaseUrl = `${firebaseRootUrl}contacts`;
export const contactsFirebaseJsonUrl = `${contactsFirebaseUrl}.json`;

export const accountSponsorshipsFirebaseUrl = `${firebaseRootUrl}account-sponsorships`;
export const accountSponsorshipsFirebaseJsonUrl = `${accountSponsorshipsFirebaseUrl}.json`;

export const usersFirebaseUrl = `${firebaseRootUrl}users`;
export const usersFirebaseJsonUrl = `${usersFirebaseUrl}.json`;

export const customerOrgsFirebaseUrl = `${firebaseRootUrl}customer-orgs`;
export const customerOrgsFirebaseJsonUrl = `${customerOrgsFirebaseUrl}.json`;

// Node.js Backend URLs
const backendLocalDevRootUrl = "http://localhost:8080/";
const backendDefaultRootUrl = "https://event-planner-5fe36.uc.r.appspot.com/";
export const backendRootUrl = backendDefaultRootUrl;

export const mailgunSenderDomain = "arcatxp.com";
export const mailgunBackendUrl = `${backendRootUrl}mailgun/`;
export const mailgunEmailsBackendUrl = `${mailgunBackendUrl}emails`;
export const mailgunTemplatesBackendUrl = `${mailgunBackendUrl}templates`;
export const mailgunEventsBackendUrl = `${mailgunBackendUrl}events`;

export const firebaseBackendUrl = `${backendRootUrl}firebase/`;
export const firebaseUsersBackendUrl = `${firebaseBackendUrl}users`;
