/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { connect } from "react-redux";

// react component for creating dynamic tables
import Contact from "../../../../models/database/Contact";
import Organizations from "models/database/Organizations";

import CrudActionsTable from "../../shared/CrudActionsTable";
import EditContactModal from "./editContactModal";

class ContactsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    if (!this.props.orgData) {
      const res = await new Organizations().getAllEntities();
      this.props.dispatch({ type: "setOrgData", data: res });
    }
    this._isMounted = true;
    const contacts = await new Contact().getAllEntities(this.props.orgData);
    if (this._isMounted) this.setState({ data: contacts });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const pageSize = 10;
    return (
      <>
        <CrudActionsTable
          data={this.state.data}
          dataModel={Contact}
          newItem={this.props.newRow}
          defaultPageSize={pageSize}
          modalName="contacts"
          editModal={EditContactModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orgData: state.orgData,
});

export default connect(mapStateToProps)(ContactsTable);
