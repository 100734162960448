import React, { Component } from "react";
// react component for creating dynamic tables
import {
    CardTitle,
    Col,
    Row,
} from "reactstrap";

import SectionContainer from "../SectionContainer";
import SponsorshipsTableContainer from "./sponsorships/SponsorshipsTableContainer";
import DeliverablesTableContainer from "./deliverables/DeliverablesTableContainer";
import BoothsTableContainer from "./booths/BoothsTableContainer";

class OfferingsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }

    render() {
        return (
            <>
                <div className="content">
                    <div>
                        <Col xs={6} md={6}>
                            <div style={{display: 'flex', justifyContent: "space-between"}}>
                                <CardTitle tag="h4">Offerings</CardTitle>
                            </div>
                        </Col>
                        <Row>
                            <SponsorshipsTableContainer />
                            <BoothsTableContainer />
                        </Row>
                        <DeliverablesTableContainer />
                    </div>
                </div>
            </>
        )
    }
}

export default OfferingsContainer;
