import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import CurrencyFormatter from "../../layouts/Plan/shared/CurrencyFormatter";
import moment from "moment";
import { dateFormat } from "utilities/constants/date-format";

/**
 * @description individual item that represent a discrete piece of work that is dictated in the Sales contract
 */
export default class Deliverable extends PersistentBase {
  constructor(
    id,
    name,
    quantity,
    rate,
    deadline,
    description,
    parentId,
    parentCollection,
    parentName,
    completed = false,
    eventId,
    partnerOrg,
    partnerCollection,
    partnerId,
    entityId
  ) {
    super();
    this.id = id;
    this.name = name;
    this.quantity = quantity;
    this.rate = rate;
    this.deadline = deadline;
    this.description = description;
    this.parentId = parentId;
    this.parentCollection = parentCollection;
    this.parentName = parentName;
    this.completed = completed;
    this.eventId = eventId;
    this.partnerOrg = partnerOrg;
    this.partnerCollection = partnerCollection;
    this.partnerId = partnerId;
    this.entityId = entityId;
    this.csvTitle = "Deliverable";
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl;
    this.jsonUrl = customerOrgsFirebaseUrl;
  }

  metaData() {
    return {
      name: this.name,
      quantity: this.quantity,
      rate: this.rate,
      deadline: this.deadline,
      description: this.description || this.itemClass?.description,
      id: this.id,
      parentId: this.parentId,
      parentCollection: this.parentCollection,
      parentName: this.parentName,
      completed: this.completed,
      eventId: this.eventId,
      partnerOrg: this.partnerOrg,
      partnerCollection: this.partnerCollection,
      partnerId: this.partnerId,
      entityId: this.entityId,
      itemClass: this.itemClass,
    };
  }

  emailVariables() {
    return {
      name: this.name,
      description: {
        value: this.description || this.itemClass?.description,
        draggableName: "Description"
      },
      quantity: this.quantity,
      rate: this.rate,
      deadline: this.deadline,
      completed: this.completed,
    };
  }

  async getAll(deliverablesData, eventId) {
    const userOrgId = await super.getUserOrgId();
    const deliverables = [];
    let index = 0;
    for (const deliverable of deliverablesData) {
      this.jsonUrl = await super.appendAuth(
        `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/deliverables/${deliverable.id}.json`
      );
      const response = await super.get();
      const formattedDeliverable = new this.constructor()
        .createWithObject({ ...response, eventId })
        .tableRowFormat(index++);
      deliverables.push(formattedDeliverable);
    }
    return deliverables;
  }

  async getById(id, eventId) {
    const userOrgId = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/deliverables/${id}.json`
    );
    const response = await super.get();
    return response;
  }

  async updatePartnerDeliverable(payload, event, partner) {
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event?.id}/partners/${partner.id}/${partner.collection}/${partner.entityId}/deliverables.json`
    );
    const response = await super.update(url, payload.deliverables || payload);
    const deliverable = new this.constructor().createWithObject(
      Object.values(response)[0]
    );
    return deliverable;
  }

  async updateChildDeliverable(payload, event, parent) {
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event.id}/offerings/${parent.collection}/${parent.id}/deliverables.json`
    );
    const response = await super.update(url, payload.deliverables || payload);
    const deliverable = new this.constructor().createWithObject(
      Object.values(response)[0]
    );
    return deliverable;
  }

  async update(
    payload,
    event,
    deleteItem,
    single = false,
    parent = null,
    orgData = null,
    orgUsers = null,
    partner = null
  ) {
    if (parent?.id && parent.collection) {
      return this.updateChildDeliverable(payload, event, parent);
    }
    if (partner?.id && partner.collection && partner.entityId) {
      return this.updatePartnerDeliverable(payload, event, partner);
    }
    if (deleteItem?.id && !Object.keys(payload?.deliverables || {}).includes(deleteItem.id)) {
      await this.delete(deleteItem.id, event.id);
    }
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event.id}/offerings/deliverables.json`
    );
    const response = await super.update(url, payload.deliverables || payload);
    const deliverables = [];
    for (const deliverableData of Object.values(response)) {
      const deliverable = new this.constructor().createWithObject(
        deliverableData
      );
      if (single) {
        return deliverable;
      }
      deliverables.push(deliverable);
    }
    return deliverables;
  }

  async delete(deliverableID, eventId) {
    const userOrgId = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/deliverables/`;
    const response = await super.delete(deliverableID);
    return response;
  }

  tableCsvData() {
    return {
      completed: this.completed,
      partner: this.partnerOrg?.name || "-",
      deliverable: this.name,
      description: this.description || this.itemClass?.description,
      quantity: this.quantity,
      rate: this.rate || "0",
      "due date": this.deadline ? moment(this.deadline).format(dateFormat) : "-",
    };
  }

  tableColumnsAndValues() {
    return {
      completed: {
        cellText: <div id={this.id} data-sort={this.completed}></div>,
        checked: this.completed,
        width: 100,
        filter : { isFilterable: false }
      },
      partner: this.centerAlignDiv(this.partnerOrg?.name || "-"),
      deliverable: {
        cellText: this.leftAlign(this.name),
        filter : { isFilterable: false }
      },
      quantity: {
        cellText: this.centerAlignDiv(this.quantity || "0"),
        width: 110,
        filter : { isFilterable: false }
      },
      "offering name": {
        cellText: this.leftAlign(this.parentName),
        filter : { isFilterable: false }
      },
      "offering type": {
        cellText: this.centerAlignDiv(this.partnerCollection),
        filter : { isFilterable: false }
      },
      rate: {
        cellText: this.centerAlignDiv(
          <CurrencyFormatter value={this.rate || "0"} />
        ),
        width: 110,
        filter : { isFilterable: false }
      },
      "due date": {
        cellText: this.centerAlignDiv(
          this.deadline ? moment(this.deadline).format(dateFormat) : "-"
        ),
        width: 140,
        filter : { isFilterable: false }
      },
    };
  }
}
