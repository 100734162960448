export const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: true,
  title: "",
  filename: "arcat",
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};
