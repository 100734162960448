import fetcher from "../../utilities/fetcher";

/**
 * @description HTTP verb methods wrapper for fetcher utility
 */
export default class Adapter {
  post(payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = fetcher(this.url, "POST", payload, headers);
    return response;
  }

  get() {
    const response = fetcher(this.url, "GET");
    return response;
  }
}
