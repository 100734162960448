import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label } from "reactstrap";

import ModalContainer from "../../../../shared/ModalContainer";
import BoothsDropdown from "../../../../shared/BoothsDropdown";
import { connect } from "react-redux";

const BoothsModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [booths, setBooths] = useState([{ index: 0 }]);

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  const toggle = () => {
    if (isModalOpen) {
      setBooths([{ index: 0 }]);
    }
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = (
    <Button color="info" size="md" onClick={toggle} disabled={!props.isEventEditable}>
      + Add Booth
    </Button>
  );

  const boothChangeHandler = (booth, value, type) => {
    setBooths((prevState) => {
      booth[type] = parseInt(value);
      const newState = [...prevState];
      newState[booth.index] = booth;
      return newState;
    });
  };

  const addBooth = () => {
    setBooths([...booths, { index: booths.length }]);
  };

  const boothsDropdownCallback = (dropdownData) => {
    setBooths((prevState) => {
      const newState = [...prevState];
      newState[dropdownData.index] = {
        ...prevState[dropdownData.index],
        ...dropdownData,
      };
      return newState;
    });
  };

  const formIsValid = () => {
    const isValid = !!booths.find(
      (booth) =>
        booth.id &&
        Number.isInteger(booth.quantity) &&
        Number.isInteger(booth.rate)
    );
    return isValid;
  };

  const arrayToObject = (arr) => {
    return arr.reduce((map, obj) => {
      if (obj.id) {
        if (obj.label) {
          obj.name = `${obj.label}`;
          obj.reserved = 0;
          delete obj.label;
        }
        map[obj.id] = obj;
      }
      return map;
    }, {});
  };

  const submitCallback = () => {
    if (!formIsValid()) return;
    const data = {
      booths: arrayToObject(booths),
    };
    props.submitCallback(data);
    toggle();
  };

  const boothDropdowns = booths.map((booth, index) => {
    return (
      <Fragment key={index}>
        <Row>
          <BoothsDropdown
            callback={boothsDropdownCallback}
            index={index}
            multiSelect={false}
          />
          {booth.label && index + 1 === booths.length && (
            <Button
              className="mt-2"
              size="sm"
              onClick={addBooth}
              title="Add Booth"
            >
              +
            </Button>
          )}
        </Row>
        {booth.label && (
          <Row className="pl-3 mt-2">
            <Col md="3" className="m-0 p-0">
              <Input
                type="number"
                step="1"
                placeholder="Quantity"
                onChange={(e) =>
                  boothChangeHandler(booth, e.target.value, "quantity")
                }
              />
            </Col>
            <Col md="3" className="m-0 pl-2">
              <Input
                type="number"
                step="1"
                placeholder="Rate"
                onChange={(e) =>
                  boothChangeHandler(booth, e.target.value, "rate")
                }
              />
            </Col>
          </Row>
        )}
      </Fragment>
    );
  });

  return (
    <ModalContainer
      buttonLabel="+ Add Booths"
      modalTitle="Add New Booths"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      button={OpenModalButton}
      isSubmitDisabled={!isSubmitReady}
    >
      <Label className="ml-1 mb-0 mt-0">Booths</Label>
      {boothDropdowns}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(BoothsModal);
