import IconButton from "../IconButton";
import React from "react";

const IconPencilButton = (props) => {
    return (
        <IconButton
            tooltipId={props.tooltipId}
            indexKey={props.itemIndex}
            onClick={(itemIndex) => props.onClick(itemIndex) }
            disabled={props.disabled}
            color="info"
            icon="icon-pencil"
            tooltip="Edit"
        />
    )
};

export default IconPencilButton;
