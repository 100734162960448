import React, { useState, useEffect } from "react";
import { Row, Col, Table, Card, CardHeader, CardBody } from "reactstrap";
import CustomerOrg from "../../../../models/database/CustomerOrg";
import { connect } from "react-redux";
import CustomerOrgsAccordion from "layouts/Plan/shared/CustomerOrgsAccordion";
import { adminEmails } from "utilities/constants/arcat-admin-emails";
import { defaultContractualUsers } from "utilities/constants/contractual-users";

function CustomerOrgPage(props) {
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && !adminEmails.includes(props.user.email)) {
      props.history.push("/auth/login");
      return;
    }
    (async function() {
      const { id: orgId } = props.match?.params;
      const response = await new CustomerOrg().getOrgById(orgId);
      setOrgData(response);
    })();
  }, []);

  const headingRow = (
    <Row>
      <Col xs={10} md={8} className="px-0">
        <Card className="">
          <CardHeader className="my-0">
            <h3 className="mb-3">ORGANIZATION INFORMATION</h3>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );
  
  const infoSection = (
    <Row className="mb-0">
      <Card md={8}>
        <CardHeader
          className="pb-0"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3 style={{ textTransform: "uppercase" }}>{orgData?.customerMetaData?.name || ""}</h3>
        </CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col md={12} style={{ fontSize: "0.99 rem" }}>
              <Table borderless>
                <thead>
                  <tr>
                    <th>URL</th>
                    <th>Location</th>
                    <th>Contractual Users</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{orgData?.customerMetaData?.URL}</td>
                    <td>
                      {orgData?.customerMetaData?.address1}{" "}
                      {orgData?.customerMetaData?.city}{", "}
                      {orgData?.customerMetaData?.state}{" "}
                      {orgData?.customerMetaData?.zip}
                    </td>
                    <td>{orgData?.customerMetaData?.contractualUsers || defaultContractualUsers}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card />
    </Row>
  );

  return (
    <div className="content">
      {headingRow}
      <Row>
        <Col md={8}>
          {infoSection}
          <CustomerOrgsAccordion orgData={orgData} />
        </Col>
        <Col md={4} />
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contactsData: state.contactsData,
  user: state.user,
});

export default connect(mapStateToProps)(CustomerOrgPage);
