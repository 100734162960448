/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react component for creating dynamic tables
import {
    CardBody,
    CardHeader,
    CardTitle,
} from "reactstrap";

import Event from "../../../../models/database/Event";

import EventsTable from "./EventsTable";
import NewEventModal from "./NewEventModal";
import SectionContainer from "../SectionContainer";
import { connect } from "react-redux";

class EventsSectionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor : "blue", //"#005BAA",
            sidebarMini : true,
            newEvent    : '',
        };
    }

    async submitEventHandler(eventProps) {
        const event = await new Event().create(eventProps);

        this.setState((previousState) => {
            return { newEvent : event }
        });
    }

    tableHeader = () => (
        <CardHeader>
            <div style={{display: 'flex', justifyContent: "space-between"}}>
                <CardTitle tag="h4">Events</CardTitle>
                <NewEventModal
                    formSubmitCallBack={(data) => this.submitEventHandler(data)}
                />
            </div>
        </CardHeader>
    );

    render() {
        return (
            <>
                <SectionContainer>
                    <div>
                        { this.tableHeader() }
                        <CardBody>
                            <EventsTable newRow={this.state.newEvent}/>
                        </CardBody>
                    </div>
                </SectionContainer>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
  user: state.user,
  firebase: state.firebase,
});


export default connect(mapStateToProps)(EventsSectionContainer);
