import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import { connect } from "react-redux";
import Contacts from "models/database/Contacts";
import formatContactLabel from "utilities/methods/formatContactLabel";

const createSingleSelect = (value, label, itemClass) => ({ value, label, itemClass });

function ContactsDropdown(props) {
  const isMounted = useRef(false);
  const [value, setValue] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const mapOptions = (contacts) =>
  contacts.map((value, index) =>
    createSingleSelect(
      index,
      formatContactLabel(value, props.isTypeVisible),
      value
    )
  );

  const handleChange = (val) => {
    props.callback(val);
    setValue(val);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  // useEffect(() => {
  //   if (!isMounted.current) return;
  //   setValue(props.initialValue);
  // }, [props.initialValue]);

  useEffect(() => {
    if (!isMounted.current) return;
    const filteredContacts = contacts.filter((c) => c.orgId && (!props.filterByOrg || props.filterByOrg.includes(c.orgId)));
    setContactOptions(mapOptions(filteredContacts));
  }, [props.filterByOrg, contacts]);

  useEffect(() => {
    if (!isMounted.current) return;
    const filteredContacts = contacts.filter((c) => !props.filterByType || c.contactType === props.filterByType);
    setContactOptions(mapOptions(filteredContacts));
  }, [props.filterByType, contacts]);

  useEffect(() => {
    (async function () {
      if (!props.eventData || contactOptions.length || fetchedData) return;
      setFetchedData(true);
      const contacts = await new Contacts().getAllEntities(false);
      if (isMounted.current) {
        setContacts(contacts);
      }
    })();
  });
  
  return (
    <CustomSelect
      options={contactOptions}
      placeholder="Contact"
      value={value}
      defaultSelected={props.defaultValue}
      callback={handleChange}
      multiSelect={props.multiSelect}
      searchable={true}
      isNotPadded={props.isNotPadded}
      size="9"
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(ContactsDropdown);
