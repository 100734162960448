/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { connect } from "react-redux";
import Organization from "models/database/Organization";

// reactstrap layout-components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  NavbarText,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip
} from "reactstrap";
import Events from "models/database/Events";
import moment from "moment";
import { dateFormat } from "utilities/constants/date-format";

const defaultNotifications = [{ label: "No Notifications" }];
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      orgName: "",
      notifications: [],
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    this.getUserOrgName();
    this.getNotifications();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  getUserOrgName = async () => {
    const orgData = await new Organization().getUserOrgData();
    this.setState({
      orgName: orgData?.name || ""
    });
  }
  getNotifications = async () => {
    const events = await new Events().getAllEntities(false);
    if (!events?.length) return;
    const now = Date.now();
    const ids = [];
    const deliverables = events.flatMap((event) =>
      Object.values(event?.partners || {}).flatMap((partner) => 
        Object.values({...(partner?.sponsorships || {}), ...(partner?.booths || {})})
          .flatMap((entity) => Object.values(entity.deliverables || {})
            .map((deliverable) => ({
              ...deliverable,
              label: this.formatDeliverableLabel(deliverable, event, now),
              url: `/plan/event/${event.code}`,
              eventCode: event.code,
            }))
          )
      )
    ).filter((deliverable, index, arr) => {
      if (!this.isDeliverableValid(deliverable, now, ids) || 
          this.isEarlierDeliverable(deliverable, index, arr, now)) return false;
      ids.push(deliverable.id);
      return true;
    }).sort((a, b) => moment(a.deadline).valueOf() - moment(b.deadline).valueOf());
    this.setState({
      notifications: deliverables
    });
  }
  formatDeliverableLabel = (deliverable, event, now) => {
    const dateValue = moment(deliverable.deadline).valueOf();
    return (
      <>
        {dateValue < now
          ? moment.duration(dateValue - now, "milliseconds").humanize(true)
          : moment(deliverable.deadline).format(dateFormat)}
        <p>
          <span style={{color: '#086DC7'}}> {event.code}</span>
          <span style={{color: '#cf009f'}}> &gt; </span>
          <span style={{fontWeight: '700'}}> {deliverable.name}</span>
        </p>
      </>
    );
  }
  isEarlierDeliverable = (deliverable, index, arr, now) => {
    const dateValue = moment(deliverable.deadline).valueOf();
    return arr.some((d, dIndex) => (
      dIndex !== index && 
      d?.id === deliverable.id && 
      this.isDeliverableValid(d, now) && 
      moment(d.deadline).valueOf() < dateValue
    ));
  }
  isDeliverableValid = (deliverable, now, ids = []) => {
    if (!deliverable?.deadline) return false;
    const deadline = moment(deliverable.deadline);
    return Boolean(
      deadline.isValid() &&
      deadline.valueOf() < now &&
      !deliverable.completed && 
      deliverable.id && 
      !ids.includes(deliverable.id)
    );
  }
  logout = () => {
    this.props.firebase.auth().signOut()
      .then(() => {
        localStorage.removeItem("userOrg");
        this.props.dispatch({ "type": "logOut" });
        console.log("Logged out user");
        this.props.history.push("/auth/login");
    }).catch(err => {
      console.error(err);
    });
  }
  notificationsMap = () => {
    let notifications = this.state.notifications || [];
    if (!notifications.length) notifications = defaultNotifications;
    return notifications.map((notification, index) => (
      <NavLink 
        key={index} 
        tag="li" 
        disabled={!notification.url} 
        onClick={() => {
          this.props.dispatch({ type: "setEventData", data: null });
          this.props.history.push("/plan");
          setTimeout(() => this.props.history.push(notification.url), 0);
        }}
      >
        <DropdownItem className="nav-item">
          {notification.label || "-"}
        </DropdownItem>
      </NavLink>
    ));
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };
  render() {
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", {
            [this.state.color]:
              this.props.location.pathname.indexOf("full-screen-map") === -1
          })}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                  <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209599"
                  placement="right"
                >
                  Sidebar toggle
                </UncontrolledTooltip>
              </div>
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#" onClick={e => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>

                {/*Search Modal Button*/}

                {/*<InputGroup className="search-bar" tag="li">*/}
                {/*  <Button*/}
                {/*    color="link"*/}
                {/*    data-target="#searchModal"*/}
                {/*    data-toggle="modal"*/}
                {/*    id="search-button"*/}
                {/*    onClick={this.toggleModalSearch}*/}
                {/*  >*/}
                {/*    <i className="tim-icons icon-zoom-split" />*/}
                {/*    <span className="d-lg-none d-md-block">Search</span>*/}
                {/*  </Button>*/}
                {/*</InputGroup>*/}

                <NavbarText className="navbar-brand" style={{ textTransform: "none", fontWeight: 300 }}>
                  {this.state.orgName}
                </NavbarText>
                <UncontrolledDropdown nav>
                 <DropdownToggle
                   caret
                   color="default"
                   data-toggle="dropdown"
                   nav
                 > 
                  {this.state.notifications.length > 0 && <div className="notification d-none d-lg-block d-xl-block" />}
                  <i className="tim-icons icon-sound-wave" />
                  <p className="d-lg-none">Notifications</p>
                 </DropdownToggle>
                 <DropdownMenu className="dropdown-navbar" right tag="ul">
                   {this.notificationsMap()}
                 </DropdownMenu>
               </UncontrolledDropdown> 
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <div className="photo text-center">
                      <i className="tim-icons icon-single-02" />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none" onClick={this.logout}>Log out</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    {/*<NavLink>*/}
                    {/*  <DropdownItem className="nav-item">Profile</DropdownItem>*/}
                    {/*</NavLink>*/}
                    <NavLink href="/admin/account-settings">
                      <DropdownItem className="nav-item">Settings</DropdownItem>
                    </NavLink>
                    {/*<NavLink href="/admin/help-center">*/}
                    <NavLink onClick={ () => window.open("https://arcatxp.zendesk.com/hc/en-us", '_blank').focus()}>
                      <DropdownItem className="nav-item">Help Center</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink onClick={this.logout}>
                      <DropdownItem className="nav-item">Log out</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
    user: state.user,
    firebase: state.firebase,
});

export default connect(mapStateToProps)(AdminNavbar);
