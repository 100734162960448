
const defaultOptions = { 
  fallback: 0, 
  precision: 2, 
  asString: true 
};

const toPercentage = (x, y, options = defaultOptions) => {
  options = { ...defaultOptions, ...options };
  let percent = parseFloat(((Number(x) / Number(y)) * 100).toFixed(options.precision));
  if (isNaN(percent) || percent === Infinity) {
    percent = options.fallback;
  }
  return options.asString && typeof percent === "number" ? `${percent}%` : percent;
}

export default toPercentage;
