import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "reactstrap";
import CountryDropDown from "../inputs/CountryDropDown";
import InputText from "../inputs/InputText";
import PhoneNumberInput from "../inputs/PhoneNumberInput";

function AddressInputForm({
  isPhone,
  getAddressFormValue,
  sameAddress,
  isSameAddress,
  updateAddress,
  colSize,
  labelSize,
  sendBackValues,
  withLabel,
  address1Invalid,
  address2Invalid,
  cityInvalid,
  blurOnUpdate = true
}) {
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setcountry] = useState("");
  const [state, setState] = useState("");
  const [isBlur, setIsBlur] = useState(false);
  const [zip, setZip] = useState("");
  const [isSetValue, setIsSetValue] = useState(false);

  useEffect(() => {
    // if user clicks on same address button this logic will be apply.

    if (isSameAddress) {
      let { address1, address2, country, state, city, zip } = sameAddress;
      setAddress1(address1);
      setAddress2(address2);
      setCity(city);
      setcountry(country);
      setState(state);
      setZip(zip);
      if (blurOnUpdate) {
        setIsBlur((prevState) => !prevState);
      }
    } else if (sameAddress) {
      if (!isSameAddress) {
        setAddress1("");
        setAddress2("");
        setCity("");
        setcountry("");
        setState("");
        setZip("");
      }
    }
  }, [isSameAddress, updateAddress]);

  useEffect(() => {
    let data = { phone, address1, address2, zip, country, state, city };
    //checking whether phone number will be returned or not.
    if (
      sendBackValues && // if the contact form needs the value for edit this logic will apply.
      Object.keys(sendBackValues).length > 0 &&
      !isSetValue
    ) {
      let { zip, country, address1, address2, state, city } = sendBackValues;
      setAddress1(String(address1));
      setAddress2(String(address2));
      setZip(String(zip));
      setcountry(String(country));
      setState(String(state));
      setCity(String(city));
      setIsSetValue(true);
    }
    let addressFormValue = isPhone
      ? { ...data }
      : { address1, address2, zip, country, state, city };
    getAddressFormValue && getAddressFormValue(addressFormValue);
  }, [isBlur]);

  return (
    <>
      <InputText
        label="Address 1"
        icon={`tim-icons icon-bank`}
        colSize={colSize ? colSize : 8}
        labelSize={labelSize ? labelSize : 2}
        value={address1}
        type="text"
        inputHandler={(val) => setAddress1(val)}
        handleBlur={() => setIsBlur(!isBlur)}
        withLabel={withLabel}
        placeholder="Address 1"
        invalid={address1Invalid}
      />
      <InputText
        icon={`tim-icons icon-bank`}
        label="Address 2"
        colSize={colSize ? colSize : 8}
        labelSize={labelSize ? labelSize : 2}
        value={address2}
        type="text"
        inputHandler={(val) => setAddress2(val)}
        handleBlur={() => setIsBlur(!isBlur)}
        withLabel={withLabel}
        placeholder="Address 2"
        invalid={address2Invalid}
      />
      {isPhone ? (
        <Row>
          <Col sm="8">
            <PhoneNumberInput
              value={phone}
              handleBlur={() => setIsBlur(!isBlur)}
            />
          </Col>
          <Col sm="4">
            <InputText
              label="City"
              colSize={colSize ? colSize : 8}
              labelSize={labelSize ? labelSize : 2}
              value={city}
              type="text"
              inputHandler={(val) => setCity(val)}
              handleBlur={() => setIsBlur(!isBlur)}
            />
          </Col>
        </Row>
      ) : (
        <InputText
          icon={`tim-icons icon-bank`}
          label="City"
          colSize={colSize ? colSize : 8}
          labelSize={2}
          value={city}
          type="text"
          inputHandler={(val) => setCity(val)}
          handleBlur={() => setIsBlur(!isBlur)}
          withLabel={withLabel}
          placeholder="City"
          invalid={cityInvalid}
        />
      )}
      <InputText
        icon={`tim-icons icon-bank`}
        label="ZIP code"
        colSize={colSize ? colSize : 8}
        labelSize={labelSize ? labelSize : 2}
        value={zip}
        type="text"
        inputHandler={(val) => setZip(val)}
        handleBlur={() => setIsBlur(!isBlur)}
        withLabel={withLabel}
        placeholder="ZIP code"
        invalid={address2Invalid}
      />
      <CountryDropDown
        colSize={3}
        labelSize={3}
        countryValue={country}
        stateValue={state}
        getCountryAndState={({ country, state }) => {
          setcountry(country);
          setState(state);
          setIsBlur(!isBlur);
        }}
        withLabel={withLabel}
      />
    </>
  );
}
export default React.memo(AddressInputForm);
