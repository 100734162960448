import React, { Component } from "react";

import AccountBooth from "models/database/AccountBooth";
import AccountBooths from "models/database/AccountBooths";
import CrudActionsTable from "../../../shared/CrudActionsTable";
import EditBoothsModal from "./EditBoothsModal";

class BoothsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : []
        };
    }
    getBooths = async () => {
        const booths = await new AccountBooths().getAllEntities();
        this.setState({ data: booths });
    }
    componentDidMount() {
        this.getBooths();
    }

    render() {
        const pageSize = 10;

        return (
            <>
                <CrudActionsTable
                    data={this.state.data}
                    dataModel={AccountBooth}
                    filterable={true}
                    newItem={this.props.newRow}
                    resizable={true}
                    defaultPageSize={pageSize}
                    modalName="booth"
                    editModal={EditBoothsModal}
                />
            </>
        );
    }
}

export default BoothsTable;
