import React, { useState, useEffect } from "react";
import DropDownList from "./DropDownList";

const createSingleSelect = (value, label) => {
  return {
    value,
    label,
  };
};

export default function CustomDropdown(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(props.options.map((type, index) =>
      createSingleSelect(index, type)
    ));
  }, []);

  return (
    <DropDownList
      className={props.className || ""}
      options={options}
      placeholder={props.placeholder}
      defaultSelected={props.defaultSelected}
      callback={props.callback}
      multiSelect={props.multiSelect}
      searchable={true}
    />
  );
}
