import React, { useEffect, useState } from "react";
import { Row, Col, Input, Label, CustomInput } from "reactstrap";
import { connect } from "react-redux";
import { mailgunSenderDomain } from "utilities/constants/urls";
import RichTextEditor from "../../../../shared/RichTextEditor";
import CreatableUsersDropdown from "layouts/Plan/shared/CreatableUsersDropdown";
import ContactTypeDropDown from "layouts/Plan/shared/ContactTypeDropDown";
import SponsorshipsDropdown from "layouts/Plan/shared/SponsorshipsDropdown";
import BoothsDropdown from "layouts/Plan/shared/BoothsDropdown";
import EmailFormVariables from "layouts/Plan/shared/EmailFormVariables";
import UsersDropDownList from "layouts/Plan/shared/UsersDropDownList";
import EmailTemplatesDropdown from "layouts/Plan/shared/EmailTemplatesDropdown";
import IconTooltip from "layouts/Plan/shared/IconTooltip";
import DeliverablesDropdown from "layouts/Plan/shared/DeliverablesDropdown";
import CustomDropdown from "layouts/Plan/shared/CustomDropdown";
import filterContacts from "utilities/methods/filterContacts";

const NewEmailForm = (props) => {
  const [htmlEditorValue, setHtmlEditorValue] = useState(null);
  const [contactsError, setContactsError] = useState("");
  const [contactsData, setContactsData] = useState({});
  const [recipientContactsData, setRecipientContactsData] = useState({});
  const [unfilteredContacts, setUnfilteredContacts] = useState({});
  const [eventSpecificContacts, setEventSpecificContacts] = useState({});
  const [recipients, setRecipients] = useState([]);
  const [usedVariables, setUsedVariables] = useState([]);
  const [sponsorshipFilterIds, setSponsorshipFilterIds] = useState([]);
  const [boothFilterIds, setBoothFilterIds] = useState([]);
  const [deliverableFilterIds, setDeliverableFilterIds] = useState([]);
  const [deliverableFilterType, setDeliverableFilterType] = useState(null);
  const [partnersData, setPartnersData] = useState({});
  const [defaultRecipients, setDefaultRecipients] = useState([]);
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [fromName, setFromName] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState(" ");
  const [from, setFrom] = useState("");
  const [autoSend, setAutoSend] = useState(false);
  const [isInitialStateSet, setIsInitialStateSet] = useState(false);

  const handleDropdownChange = (val, setValue) => {
    if (val?.length) {
      // multiSelect dropdown
      const joinedValues = val.map((val) => val.label).join();
      return handleInputChange(joinedValues, setValue);
    } else if (val?.label) {
      // Non-multiSelect dropdown
      return handleInputChange(val.label, setValue);
    }
    handleInputChange("", setValue);
  };

  const handleInputChange = (value, setValue) => {
    setValue(value);
  };

  const handleFromChange = (event) => {
    handleInputChange(event.target.value, setFromName);
    setFrom(`${event.target.value} <${props.user.email}>`.trimLeft());
  };

  const handleContactTypeChange = (val) => {
    const selectedContacts = eventSpecificContacts.filter(
      (contact) => contact.contactType === val.label
    );
    if (!selectedContacts.length) {
      setContactsError("Event has no contacts of this type.");
      setUnfilteredContacts({});
      updateContacts({});
      return;
    }
    let validContacts = selectedContacts.reduce((mapObj, contact) => {
      if (contact.email) {
        mapObj[contact.email] = contact.metaData?.() || contact;
      }
      return mapObj;
    }, {});
    setUnfilteredContacts({ ...validContacts });
    const filteredContacts = filterContacts(
      validContacts,
      partnersData,
      sponsorshipFilterIds,
      boothFilterIds,
      deliverableFilterIds,
      deliverableFilterType
    );
    setContactsError("");
    updateContacts(filteredContacts);
  };

  const updateContacts = (contacts, recipientValues = recipients) => {
    setContactsData(contacts);
    setTo([...new Set(Object.keys(contacts).concat(recipientValues))].join());
  };

  const handleFilterChange = (value, setIds) => {
    const ids = Object.values(value).reduce((mapArr, entity) => {
      if (entity?.id) mapArr.push(entity.id);
      return mapArr;
    }, []);
    setIds(ids);
  };

  const handleTemplateChange = (value) => {
    const template = value?.itemClass || {};
    setSubject(template.subject || subject);
    setHtml(template.html || html);
    setHtmlEditorValue(template.html || html);
    setUsedVariables(template.usedVariables || usedVariables);
  };

  useEffect(() => {
    props.setData({
      emailForm: {
        to,
        cc,
        bcc,
        from,
        replyTo,
        subject,
        html,
      },
      recipients,
      contactsData: { ...contactsData, ...recipientContactsData },
      usedVariables,
      autoSend,
    });
  }, [
    to,
    cc,
    bcc,
    from,
    replyTo,
    subject,
    autoSend,
    html,
    recipients,
    contactsData,
    recipientContactsData,
    usedVariables,
  ]);

  useEffect(() => {
    const validContacts = filterContacts(
      unfilteredContacts,
      partnersData,
      sponsorshipFilterIds,
      boothFilterIds,
      deliverableFilterIds,
      deliverableFilterType
    );
    updateContacts(validContacts);
  }, [sponsorshipFilterIds, boothFilterIds, deliverableFilterIds, deliverableFilterType])

  useEffect(() => {
    if (!props.data?.emailForm?.subject) return;
    setTo(props.data.emailForm.to || "");
    setCc(props.data.emailForm.cc || "");
    setBcc(props.data.emailForm.bcc || "");
    setReplyTo(props.data.emailForm.replyTo || "");
    setSubject(props.data.emailForm.subject || "");
    setHtml(props.data.emailForm.html || "");
    setHtmlEditorValue(props.data.emailForm.html || "");
    if (props.data.emailForm.to) {
      const recipientsValue = props.data.emailForm.to
        .split(",")
        .map((email, index) => ({ label: email, value: index }));
      setDefaultRecipients(recipientsValue);
    }
    if (isInitialStateSet) return;
    setUsedVariables(props.data.usedVariables || {});
    setContactsData(props.data.contactsData || {});
    setIsInitialStateSet(true);
  }, [props.data]);

  useEffect(() => {
    const recipientsValue = to.split(",").reduce((mapArr, email, index) => {
      if (email?.length) mapArr.push({ label: email, value: index });
      return mapArr;
    }, []);
    setDefaultRecipients(recipientsValue);
  }, [to]);

  useEffect(() => {
    setFrom(`<${props?.user?.email || mailgunSenderDomain}>`);
    setBcc(props.user.email);
    if (!props.eventData?.partners) return;
    const partners = Object.values(props.eventData.partners).reduce(
      (mapObj, partner) => {
        mapObj[partner.orgId] = partner;
        return mapObj;
      },
      {}
    );
    setPartnersData(partners);
    const partnerEventContacts = Object.values(partners).reduce((mapObj, partner) => {
      for (const eventContact of Object.values(partner.eventContacts || {})) {
        mapObj[eventContact.email] = eventContact;
      }
      return mapObj;
    }, {});
    setEventSpecificContacts(Object.values(partnerEventContacts));
    updateContacts(partnerEventContacts);
  }, []);

  const emailTemplateRow = (
    <Row className="d-flex align-items-center">
      <Col md="2">
        <Label>Template</Label>
      </Col>
      <Col md="6">
        <EmailTemplatesDropdown onChange={handleTemplateChange} />
      </Col>
    </Row>
  );

  const contactTypeRow = (
    <Row className="d-flex align-items-center">
      <Col md="2">
        <Label>Contact Type</Label>
      </Col>
      <Col md="6" style={{ paddingLeft: "0", paddingRight: "0" }}>
        <ContactTypeDropDown getContactType={handleContactTypeChange} />
      </Col>
      <Col md="4" className="pt-2 mt-1">
        <Row>
          <Label className="text-warning">{contactsError}</Label>
        </Row>
      </Col>
    </Row>
  );

  const recipientsRow = (
    <Row className="mt-2 d-flex align-items-center">
      <Col md="2">
        <Label>To</Label>
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="Select the contact type you want to receive the email"
        />
      </Col>
      <Col id="toInputContainer" md="6">
        <UsersDropDownList
          multiSelect
          isPartnersOnly
          defaultSelected={defaultRecipients}
          onChange={(val) => {
            const values = val?.map((val) => val.label) || [];
            const recipientContacts = val?.reduce((mapObj, recipient) => {
              if (recipient.itemClass) {
                mapObj[recipient.label] = recipient.itemClass;
              }
              return mapObj;
            }, {});
            setRecipientContactsData(recipientContacts);
            setRecipients(values);
            updateContacts(contactsData, values);
          }}
        />
      </Col>
    </Row>
  );

  const ccRow = (
    <Row className="mt-2 d-flex align-items-center">
      <Col md="2">
        <Label>CC</Label>
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="Any email added to this field will receive every email that is sent out"
        />
      </Col>
      <Col id="ccInputContainer" md="6">
        <CreatableUsersDropdown
          multiSelect
          onChange={(val) => handleDropdownChange(val, setCc)}
        />
      </Col>
    </Row>
  );

  const bccRow = (
    <Row className="mt-2 d-flex align-items-center">
      <Col md="2">
        <Label>BCC</Label>
      </Col>
      <Col md="6">
        <CreatableUsersDropdown
          multiSelect
          fixedOptions={[
            { value: props.user.uid, label: props.user.email, isFixed: true },
          ]}
          onChange={(val) => handleDropdownChange(val, setBcc)}
        />
      </Col>
    </Row>
  );

  const fromRow = (
    <Row className="mt-1 d-flex align-items-center">
      <Col md="2">
        <Label>From</Label>
      </Col>
      <Col md="6">
        <Input
          type="text"
          value={fromName}
          onChange={handleFromChange}
          onDrop={(e) => e.preventDefault()}
        />
      </Col>
      <Col md="4">
        <Row>
          <Label>Displays in Email as:</Label>
        </Row>
        <Row>
          <Label style={{ lineHeight: "1.4" }}>
            {from} {`via ${mailgunSenderDomain}`}
          </Label>
        </Row>
      </Col>
    </Row>
  );

  const replyToRow = (
    <Row className="d-flex align-items-center">
      <Col md="2">
        <Label>Reply To</Label>
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="Enter an email address here if it is different than the user's email address shown above"
        />
      </Col>
      <Col id="replyToInputContainer" md="6">
        <CreatableUsersDropdown
          multiSelect
          onChange={(val) => handleDropdownChange(val, setReplyTo)}
        />
      </Col>
    </Row>
  );

  const subjectRow = (
    <Row className="mt-1 d-flex align-items-center">
      <Col md="2">
        <Label for="emailSubject">Subject</Label>
      </Col>
      <Col md="6">
        <Input
          id="emailSubject"
          type="text"
          value={subject}
          onChange={(e) => handleInputChange(e.target.value, setSubject)}
          required
        />
      </Col>
    </Row>
  );

  const autoSendRow = (
    <Row className="mt-4 d-flex align-items-center">
      <Col md="2">
        <Label for="autoSend">Auto Send</Label>
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="If checked, this email will automatically be sent to new partners when they are added to the event"
        />
      </Col>
      <Col md="6">
        <CustomInput
          id="autoSend"
          type="checkbox"
          inline={true}
          checked={autoSend}
          onChange={(e) => handleInputChange(e.target.checked, setAutoSend)}
        />
      </Col>
    </Row>
  );

  const filtersRow = (
    <>
      <Row className="mt-3">
        <Label className="pl-3">Recipient Filters</Label>
      </Row>
      <Row>
        <Col md="6">
          <SponsorshipsDropdown
            callback={(e) => handleFilterChange(e, setSponsorshipFilterIds)}
            multiSelect
            isEventLevel
            index={1}
          />
        </Col>
        <Col md="6">
          <BoothsDropdown
            callback={(e) => handleFilterChange(e, setBoothFilterIds)}
            multiSelect
            isEventLevel
            index={2}
          />
        </Col>
        <Col md={deliverableFilterIds.length ? "6" : "12"}>
          <DeliverablesDropdown
            callback={e => handleFilterChange(e, setDeliverableFilterIds)}
            isEventLevel
            index={3}
          />
        </Col>
        {!!deliverableFilterIds.length &&  (
            <Col md="6" className="pr-4">
              <Row className="pr-1">
                <IconTooltip 
                  iconClass="fas fa-info-circle pl-0 pr-0 mt-3 arcat icon-md"
                  text={`All - All Partners with deliverable will receive email<br><br>
                  Incomplete - Partners who have NOT submitted the deliverable yet will receive it<br><br>
                  Completed - Partners who have already submitted the deliverable will receive it`}
                />
                <CustomDropdown
                  options={["All", "Incomplete", "Completed"]}
                  placeholder="Deliverable Status"
                  defaultSelected={deliverableFilterType && { label: deliverableFilterType }}
                  callback={(e) => setDeliverableFilterType(e.label)}
                  multiSelect={false}
                  searchable={true}
                />
              </Row>
            </Col>
          )}
      </Row>
    </>
  );

  const variablesRow = (
    <EmailFormVariables
      subject={subject}
      html={html}
      setUsedVariables={setUsedVariables}
      setHtmlEditorValue={setHtmlEditorValue}
      setHtml={setHtml}
    />
  );

  const bodyRow = (
    <Row className="mt-3">
      <Col md="12">
        <RichTextEditor
          onBlur={(value) => handleInputChange(value, setHtml)}
          value={htmlEditorValue}
          isDroppable
          required
        />
      </Col>
    </Row>
  );

  return (
    <>
      {emailTemplateRow}
      {contactTypeRow}
      {recipientsRow}
      {ccRow}
      {bccRow}
      {fromRow}
      {replyToRow}
      {subjectRow}
      {autoSendRow}
      {filtersRow}
      {variablesRow}
      {bodyRow}
    </>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  eventContacts: state.eventContacts,
  user: state.user,
});

export default connect(mapStateToProps)(NewEmailForm);
