/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import VectorMap from "./creative-tim/maps/VectorMap.js";
import GoogleMaps from "./creative-tim/maps/GoogleMaps.js";
import FullScreenMap from "./creative-tim/maps/FullScreenMap.js";
import ReactTables from "./creative-tim/tables/ReactTables.js";
import EventsSectionContainer from "./layouts/Plan/sections/events/EventsSectionContainer";
import OrgSectionContainer from "./layouts/Plan/sections/organizations/OrgSectionContainer";
import RegularTables from "./creative-tim/tables/RegularTables.js";
import ExtendedTables from "./creative-tim/tables/ExtendedTables.js";
import Wizard from "./creative-tim/forms/Wizard.js";
import ValidationForms from "./creative-tim/forms/ValidationForms.js";
import ExtendedForms from "./creative-tim/forms/ExtendedForms.js";
import RegularForms from "./creative-tim/forms/RegularForms.js";
import Calendar from "./creative-tim/Calendar.js";
import Widgets from "./creative-tim/Widgets.js";
import Charts from "./creative-tim/Charts.js";
import Dashboard from "./creative-tim/Dashboard.js";
import Buttons from "./creative-tim/components/Buttons.js";
import SweetAlert from "./creative-tim/components/SweetAlert.js";
import Notifications from "./creative-tim/components/Notifications.js";
import Grid from "./creative-tim/components/Grid.js";
import Typography from "./creative-tim/components/Typography.js";
import Panels from "./creative-tim/components/Panels.js";
import Icons from "./creative-tim/components/Icons.js";
import Pricing from "./creative-tim/pages/Pricing.js";
import Timeline from "./creative-tim/pages/Timeline.js";
import User from "./creative-tim/pages/User.js";
import Rtl from "./creative-tim/pages/Rtl.js";
import Lock from "./creative-tim/pages/Lock.js";

import Login from "./layouts/Auth/Login";
import Register from "./layouts/Auth/Register";
import AccountSettings from "./layouts/Admin/AccountSettings";
import HelpCenter from "./layouts/Admin/HelpCenter";
import Offerings from "./layouts/Plan/sections/offerings/OfferingsContainer";
import Contacts from "./layouts/Plan/sections/contacts/contactsSectionContainer";
import CustomerOrgRegister from "layouts/Auth/CustomerOrgRegister.js";
import EmailTemplatesContainer from "layouts/Plan/sections/email-templates/EmailTemplatesContainer.js";
import CustomerOrgPage from "layouts/Admin/accountSettingsPage/superAdmin/CustomerOrgPage.js";
import PartnerFormPage from "layouts/Auth/PartnerFormPage.js";
import DeliverablesCalendar from "layouts/Plan/shared/DeliverablesCalendar.js";

const routes1 = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        rtlName: "عالتسعير",
        mini: "P",
        rtlMini: "ع",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: Rtl,
        layout: "/rtl",
      },
      {
        path: "/timeline",
        name: "Timeline",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock-screen",
        name: "Lock Screen",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: Lock,
        layout: "/auth",
      },
      {
        path: "/user-profile",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: User,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: "tim-icons icon-molecule-40",
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MLT",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: Grid,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "tim-icons icon-notes",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: "tim-icons icon-puzzle-10",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: "tim-icons icon-settings",
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: "tim-icons icon-chart-bar-32",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: "tim-icons icon-time-alarm",
    component: Calendar,
    layout: "/admin",
  },
];

const routes = [
  {
    path: "/events",
    name: "Events",
    icon: "tim-icons icon-molecule-40",
    rtlName: "رد فعل الطاولة",
    mini: "RT",
    rtlMini: "در",
    component: EventsSectionContainer,
    sidebar: true,
    layout: "/plan",
  },
  {
    path: "/organizations",
    name: "Organizations",
    icon: "tim-icons icon-bank",
    rtlName: "رد فعل الطاولة",
    mini: "RT",
    rtlMini: "در",
    component: OrgSectionContainer,
    sidebar: true,
    layout: "/plan",
  },
  {
    path: "/contacts",
    name: "Contacts",
    icon: "tim-icons icon-single-02",
    mini: "L",
    component: Contacts,
    sidebar: true,
    layout: "/plan",
  },
  {
    path: "/email-templates",
    name: "Email Templates",
    icon: "tim-icons icon-email-85",
    component: EmailTemplatesContainer,
    sidebar: true,
    layout: "/plan",
  },
  {
    path: "/offerings",
    name: "Offerings",
    icon: "tim-icons icon-notes",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: Offerings,
    sidebar: true,
    layout: "/plan",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "tim-icons icon-calendar-60",
    mini: "L",
    component: DeliverablesCalendar,
    sidebar: true,
    layout: "/plan",
  },
  {
    path: "/register/organization",
    name: "Register",
    component: CustomerOrgRegister,
    sidebar: false,
    layout: "/auth",
  },
  {
    path: "/register/:id",
    name: "Register",
    component: Register,
    sidebar: false,
    layout: "/auth",
  },
  {
    path: "/partner/:orgId/:eventId/:partnerId",
    name: "Partner",
    component: PartnerFormPage,
    sidebar: false,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    icon: "tim-icons icon-settings",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: Login,
    sidebar: false,
    layout: "/auth",
  },
  {
    path: "/account-settings",
    name: "Settings",
    component: AccountSettings,
    sidebar: false,
    layout: "/admin",
  },
  {
    path: "/customer-org/:id",
    name: "Organization",
    component: CustomerOrgPage,
    sidebar: false,
    layout: "/admin",
  },
  {
    path: "/help-center",
    name: "Help Center",
    component: HelpCenter,
    sidebar: false,
    layout: "/admin",
  },
];

export { routes };
