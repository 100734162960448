import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
// import IconPencilButton from "layouts/Plan/shared/buttons/IconPencilButton";
import CrudActionsTable from "layouts/Plan/shared/CrudActionsTable";
import AddDeliverablesModal from "./sponsorships/AddDeliverablesModal";
import ChildDeliverables from "models/database/ChildDeliverables";
import arrayToObject from "utilities/methods/arrayToObject";

export default function EntityPage(props) {
  let { location, entity, entityClass } = props;
  const [accountEntityId, setAccountEntityId] = useState("");
  const [formattedDeliverables, setFormattedDeliverables] = useState([]);
  let entityDeliverables = location?.state[entity]?.deliverables;
  const { name, description, type ,id } = location?.state[entity];

  useEffect(() => {
    const { id, deliverables } = location?.state[entity];

    setAccountEntityId(id);
    let formattedData = [];
    if (deliverables) {
      let index = 0;
      for (let item of Object.values(deliverables)) {
        const data = new ChildDeliverables()
          .createWithObject(item)
          .tableRowFormat(index++);
        formattedData.push(data);
      }
    }
    setFormattedDeliverables(formattedData);
  }, [location?.state[entity]]);

  const updateDeliverableInHistory = (id, obj) => {
    const { history } = props;
    const state = { ...history.location.state };
    let newDeliverables =
      formattedDeliverables?.length &&
      formattedDeliverables
        .map(({ itemClass }) => {
          return itemClass?.id != id ? { ...itemClass } : null;
        })
        .filter((val) => Boolean(val));
    let deliverablesObj = arrayToObject(newDeliverables);
    if (obj) {
      deliverablesObj = { ...deliverablesObj, ...obj };
    }

    if (history.location && history.location.state) {
      history.replace({
        ...history.location,
        state: {
          ...state,
          [entity]: {
            ...props?.location?.state[entity],
            deliverables: { ...deliverablesObj },
          },
        },
      });
    }
  };

  const deleteCallback = async (item) => {
    let itemId = await item?.id;
    await new ChildDeliverables().delete(itemId,id,entity);
    updateDeliverableInHistory(itemId);
  };

  const headingRow = (
    <Row>
      <Col xs={10} md={8} className="pr-0">
        <Card className="">
          <CardHeader className="my-0">
            <h3 className="mb-3">
              {entity && entity.charAt(0).toUpperCase() + entity.slice(1)}{" "}
              Details
            </h3>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );

  const infoRow = (
    <Row>
      <Col xs={10} md={8} className=" pr-0">
        <Card className="mx-0">
          {/* Information Section */}
          <CardHeader
            className="pt-0 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>{name && name}</h3>
            {/* <IconPencilButton onClick={() => {}} /> */}
          </CardHeader>
          <CardBody>
            <b>Description:</b> {description && description} <br />
            <b>Type:</b> {type && type}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

  const onSubmitDeliverables = async (payload, accountId, deliverableId) => {
    let res = await new entityClass(deliverableId).updateDeliverables(
      payload,
      accountId
    );
    updateDeliverableInHistory("", res);
  };

  const tableRow = (
    <Row>
      <Col sm={12} className="mx-0 pr-0">
        <Card>
          <CardHeader>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <CardTitle tag="h4">Deliverables</CardTitle>
              <AddDeliverablesModal
                accountEntityId={accountEntityId}
                deliverables={
                  (entityDeliverables &&
                    Object.keys(entityDeliverables).length > 0 &&
                    Object.values(entityDeliverables)) ||
                  []
                }
                modalSubmitCallBack={onSubmitDeliverables}
              />
            </div>
          </CardHeader>
          <CardBody>
            <CrudActionsTable
              data={formattedDeliverables}
              dataModel={ChildDeliverables}
              newItem={null}
              defaultPageSize={10}
              modalName="deliverables"
              deleteCallback={(itemClass) => deleteCallback(itemClass)}
              hasEditAction={false}
              hasDuplicateAction={false}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

  return (
    <>
      <div className="rna-container"> </div>
      <div className="content">
        {headingRow}
        {infoRow}
        {tableRow}
      </div>
    </>
  );
}
