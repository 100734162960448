const getPartnerDeliverables = (
  partner,
  filterIds,
  filter = { property: null, value: null }
) => {
  const offerings = [
    ...Object.values(partner.sponsorships || {}),
    ...Object.values(partner.booths || {}),
  ];
  const deliverables = offerings.flatMap((offering) =>
    Object.values(offering.deliverables || {}).reduce((mapArr, deliverable) => {
      if (
        (!filter.property || deliverable[filter.property] === filter.value) &&
        (!filterIds || filterIds.includes(deliverable.id))
      ) {
        mapArr.push(deliverable.id);
      }
      return mapArr;
    }, [])
  );
  return [...new Set(deliverables)];
};

export default getPartnerDeliverables;
