// import EventsTypeDropDown from "layouts/Plan/shared/EventsDropDownList";
import AddressInputForm from "layouts/Plan/shared/forms/AddressInputForm";
import InputText from "layouts/Plan/shared/inputs/InputText";
import OrganizationContacts from "layouts/Plan/shared/OrgContacts";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
  Col,
  Collapse,
  Label,
  Progress,
  Row,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import ImageUpload from "../../../../creative-tim/layout-components/CustomUpload/ImageUpload";
import { storage } from "../../../../firebase-client/firebase-client";

const NewOrganizationForm = (props) => {
  const [name, setOrganizationName] = useState("");
  const [URL, setURL] = useState("https://");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  // addressForm holds the object that is being passed from addresInputForm
  const [addressForm, setAddressForm] = useState({});
  // orgcontactForm contains contact data of organizationContactForm
  const [orgcontactForm, setOrgContactForm] = useState({});
  // EventsDropDownValue contains data of events dropdown list, third section of organization form.
  const [eventType, setEventType] = useState("");
  const [isEdit, setisEdit] = useState(false);
  const [imageProgress, setimageProgress] = useState(0);
  const [isPopulatingForm, setIsPopulatingForm] = useState(
    props.isPopulatingForm
  );
  const [contactCollapse, setContactsCollapse] = useState(false);
  const [eventsCollapse, seteventsCollapse] = useState(false);

  const handleUpload = (image) => {
    const promise = new Promise((resolve, reject) => {
      if (image) {
        const uploadTask = storage
          .ref(`organization-logos/${image?.name}`)
          .put(image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            setimageProgress(progress);
            // console.log("progress", progress);
          },
          (error) => {
            // Error function ...
            reject(error);
          },
          () => {
            // complete function ...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      } else {
        resolve(props?.org?.data?.orgLogo);
      }
    });

    return promise;
  };

  const submitOrganization = async () => {
    //calling submit callback
    handleUpload(image).then((res) => {
      let eventIds = {};
      for (let index in eventType) {
        eventIds[eventType[index]?.value] = eventType[index]?.value;
      }

      props.org && props.org.index >= 0
        ? props.submitCallback(
            { name, ...addressForm, URL, phone, eventIds, orgLogo: res },
            props.org.index,
            orgcontactForm
          )
        : props.submitCallback(
            { name, ...addressForm, URL, phone, eventIds, orgLogo: res },
            null,
            orgcontactForm
          );
    });
  };

  useEffect(() => {
    if (props.isSubmitReady) {
      submitOrganization();
    }
  }, [props.isSubmitReady]); //separate it due to to much renders,

  useEffect(() => {
    // Checks whether the parent component is new organization modal or update organization modal.
    if (isPopulatingForm) {
      const {
        name,
        address1,
        address2,
        country,
        state,
        zip,
        URL,
        phone,
        contactId,
        eventIds,
        city,
        orgLogo,
      } = props.org.data;
      setisEdit(true);
      setAddressForm({ address1, address2, country, state, zip, URL, city });
      setOrganizationName(name);
      setURL(URL);
      setPhone(phone);
      setIsPopulatingForm(false);
      setEventType(() => {
        let values = eventIds && Object.values(eventIds);
        return values || [];
      });
    }
  }, []);

  //first section of organization form
  const OrganizationSection = (
    <Row>
      <Col className="mb-3" md="9">
        <InputText
          label="Org. Name"
          colSize={8}
          labelSize={2}
          value={name}
          type="text"
          inputHandler={(val) => setOrganizationName(val)}
        />
        <AddressInputForm
          sendBackValues={{ ...props?.org?.data }}
          getAddressFormValue={(val) => {
            setAddressForm(val);
          }}
          isPhone={false}
        />
        <InputText
          label="URL"
          colSize={8}
          labelSize={2}
          value={URL}
          type="url"
          handleBlur={(val) => {
            if (val && !val.match(/^https?:/)) {
              setURL(`http://${val}`);
            }
          }}
          inputHandler={(val) => setURL(val)}
        />
        <InputText
          label="Phone"
          colSize={8}
          labelSize={2}
          value={phone}
          type="tel"
          inputHandler={setPhone}
        />
      </Col>
      <Col md="3">
        <ImageUpload
          addBtnColor="info"
          addBtnClasses="btn-fill"
          getImage={(val) => {
            setImage(val);
          }}
          avatar={props?.org?.data?.orgLogo}
        />
        {imageProgress > 0 && imageProgress < 100 && (
          <div className="progress-container progress-info">
            <span className="progress-badge">Uploading</span>
            <Progress
              max="100"
              value={imageProgress}
              barClassName="progress-bar-info"
            >
              {/* <span className="progress-value">90%</span> */}
            </Progress>
          </div>
        )}
      </Col>
    </Row>
  );

  const ContactSection = (
    <Row>
      {/* <Col md="9">{organizationContactsRow}</Col> */}
      <Col md="9">
        <OrganizationContacts
          getOrgContactForm={(val) => setOrgContactForm(val)}
          addressForm={addressForm}
        />
      </Col>
      <Col md="3"></Col>
    </Row>
  );

  // const EventSection = (
  //   <Row>
  //     <Col md={9}>
  //       <EventsTypeDropDown
  //         callback={(val) => {
  //           setEventType(val);
  //         }}
  //         defaultSelected={eventType}
  //       />
  //     </Col>
  //     <Col md={3}></Col>
  //   </Row>
  // );

  const ContactEventAccordion = (
    <Row>
      <Col md={12}>
        <Card>
          <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
          >
            {!isEdit && (
              <Card className="card-plain mb-1">
                <CardHeader role="tab" style={{ backgroundColor: "#f8f9fa" }}>
                  <a
                    data-parent="#accordion"
                    data-toggle="collapse"
                    aria-expanded={contactCollapse}
                    onClick={(e) =>
                      setContactsCollapse((prevState) => !prevState)
                    }
                  >
                    Contacts
                    <i className="tim-icons icon-minimal-down" />
                  </a>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={contactCollapse}>
                  <CardBody>{ContactSection}</CardBody>
                </Collapse>
              </Card>
            )}
            {/* <Card className="card-plain">
              <CardHeader role="tab" style={{ backgroundColor: "#f8f9fa" }}>
                <a
                  data-parent="#accordion"
                  data-toggle="collapse"
                  aria-expanded={eventsCollapse}
                  onClick={(e) => seteventsCollapse((prevState) => !prevState)}
                >
                  Events
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse role="tabpanel" isOpen={eventsCollapse}>
                <CardBody>{EventSection}</CardBody>
              </Collapse>
            </Card> */}
          </div>
        </Card>
      </Col>
    </Row>
  );

  return (
    <Fragment>
      {OrganizationSection}
      {ContactEventAccordion}
    </Fragment>
  );
};

export default React.memo(NewOrganizationForm);
