import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import StyledLink from "../../layouts/Plan/shared/StyledLink";

export default class AccountBooth extends PersistentBase {
  constructor(id, name, depth, width, deliverables) {
    super();
    this.id = id;
    this.name = name;
    this.depth = depth;
    this.width = width;
    this.deliverables = deliverables;
    this.csvTitle = "Account Booth";
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/account-booths`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/account-booths.json`;
  }

  metaData() {
    return {
      name: this.name,
      depth: this.depth,
      width: this.width,
      deliverables: this.deliverables,
    };
  }

  async updateDeliverables(payload, boothId) {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/account-booths/${boothId}/deliverables`;
    const url = await super.appendAuth(super.buildUrl(this.url, this.id));
    let res = super.update(url, payload);
    return res;
  }

  async update(payload) {
    const updatedEntity = this.createWithObject(payload);
    if (!this.url) await this.setRestUrl();
    const url = await super.appendAuth(super.buildUrl(this.url, this.id));
    super.update(url, updatedEntity.metaData());
    return this;
  }

  tableColumnsAndValues() {
    const LinkTo = {
      pathname: `/plan/offerings/booth/${this.name}`,
      state: { booth: this },
    };
    return {
      booth: <StyledLink to={LinkTo}>{this.name}</StyledLink>,
      depth: { cellText: this.centerAlignDiv(this.depth) || " ", width: 100 },
      width: { cellText: this.centerAlignDiv(this.width) || " ", width: 100 },
    };
  }
}
