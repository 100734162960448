import React, {Component} from 'react';
import {Rings, ThreeDots} from "react-loading-icons";

class withSplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        try {
            // await auth0Client.loadSession();
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1500)
        } catch (err) {
            console.log(err);
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        console.log('this.state.loading', this.state.loading)
        // while checking user session, show "loading" message
        //if (this.state.loading) return LoadingMessage();

        // otherwise, show the desired route
        return (
            <div style={{"minHeight": "100vh", "height": "auto", "display" : "flex", "alignItems": "center", "justifyContent": "center"}}>
                <div style={{}}>
                    <Rings width="10em" height="10em" stroke="#7fd959" />
                    <p style={{"color": "white", "textAlign": "center", "marginTop": "20px", "fontSize": "16px", "display" : "flex", "justifyContent": "center"}}>
                        Loading
                        <div style={{marginTop: "4px", marginLeft: "2px"}}>
                            <ThreeDots width="1em" height="1em"/>
                        </div>
                    </p>
                </div>
            </div>
        )
    }
};

export default withSplashScreen;
