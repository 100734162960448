import React from "react";
import AddressInputForm from "../Plan/shared/forms/AddressInputForm";
import CustomerOrg from "../../models/database/CustomerOrg";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import User from "../../models/database/User";
import CustomerOrgUser from "../../models/database/CustomerOrgUser";
import InputText from "../Plan/shared/inputs/InputText";
import PhoneInput from "react-phone-input-2";
import RegisterMarketingCopy from "layouts/Plan/shared/RegisterMarketingCopy";
import RoleTypeDropDownList from "layouts/Plan/shared/RoleTypeDropDownList";
import { defaultContractualUsers } from "utilities/constants/contractual-users";

class CustomerOrgRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgName: "",
      url: "",
      contractualUsers: "",
      phone: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      roleType: "",
      isLoading: false,
      isAddressForm: null,
      isOrgNameInvalid: false,
      isContractualUsersInvalid: false,
      isURLInvalid: false,
      isFirstNameInvalid: false,
      isLastNameInvalid: false,
      isAddress1Invalid: false,
      isAddress2Invalid: false,
      isCityInvalid: false,
      isEmailInvalid: false,
      isPasswordInvlaid: false,
      isAddressFormInvalid: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    document.body.classList.toggle("register-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  onSelectRoleType = (roleType) => {
    this.setState({ roleType: roleType?.label });
  };

  logout = () => {
    this.props.firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userOrg");
        this.props.dispatch({ type: "logOut" });
        console.log("Logged out user");
        this.props.history.push("/auth/login");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleGetStarted = async () => {
    let {
      orgName,
      contractualUsers,
      firstName,
      lastName,
      email,
      roleType,
      isAddressForm,
      url,
      phone,
      password,
    } = this.state;

    if (!orgName) {
      this.setState({
        isOrgNameInvalid: true,
        errorMessage: "Enter Organization Name",
      });
    } else if (!url) {
      this.setState({
        isOrgNameInvalid: false,
        isURLInvalid: true,
        errorMessage: "Enter URL",
      });
    } else if (!firstName) {
      this.setState({
        isOrgNameInvalid: false,
        isFirstNameInvalid: true,
        errorMessage: "Enter First Name",
      });
    } else if (!lastName) {
      this.setState({
        isFirstNameInvalid: false,
        isLastNameInvalid: true,
        errorMessage: "Enter Last Name",
      });
    } else if (
      !isAddressForm.address1 ||
      !isAddressForm.city ||
      !isAddressForm.country ||
      !isAddressForm.state
    ) {
      let { address1, city, state, country } = isAddressForm;
      if (!address1) {
        this.setState({
          isLastNameInvalid: false,
          isAddress1Invalid: true,
          errorMessage: "Enter Address 1",
        });
      } else if (!city) {
        this.setState({
          isAddress2Invalid: false,
          isCityInvalid: true,
          errorMessage: "Enter City Name",
        });
      } else if (!country) {
        this.setState({
          isAddress2Invalid: false,
          errorMessage: "Select Country",
        });
      } else if (!state) {
        this.setState({
          errorMessage: "Select Region",
        });
      }
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      this.setState({
        isEmailInvalid: true,
        errorMessage: "The email address is badly formatted.",
      });
    } else if (!password) {
      this.setState({
        isEmailInvalid: false,
        isPasswordInvlaid: true,
        errorMessage: "Enter Password",
      });
    } else {
      await this.props.firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then(async (res) => {
          const org = await new CustomerOrg().newOrg({
            customerMetaData: {
              ...isAddressForm,
              name: orgName,
              contractualUsers: contractualUsers || defaultContractualUsers,
              URL: url,
              phone,
            },
          });
          const userPayload = {
            firstName,
            lastName,
            email,
            roleType,
            uid: res.user.uid,
            organization: org?.id,
          };
          await new User().createUser(userPayload);
          await new CustomerOrgUser(null, org?.id).createOrgUser({
            usersId: res?.user?.uid,
          });
          this.logout();
        })
        .catch((err) => {
          console.log("err :>> ", err);
          this.setState({ errorMessage: err.message });
        });
    }
  };

  render() {
    let {
      errorMessage,
    } = this.state;
    return (
      <>
        <div className="content">
          <Container>
            <Row>
              <RegisterMarketingCopy />
              <Col className="mr-auto" md="7">
                <Card className="card-register card-white">
                  <CardHeader>
                    <CardImg
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <CardTitle
                      className="arcat text-primary pt-4 pl-3"
                      tag="h1"
                    >
                      Register Organization
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form className="form">
                      <div className="text-center">
                        <i className="font-weight-bold text-danger">
                          {errorMessage}
                        </i>
                      </div>
                      <InputText
                        type="text"
                        placeholder="Organization Name"
                        icon={`tim-icons icon-bank`}
                        inputHandler={(val) => this.setState({ orgName: val })}
                        withLabel={false}
                        value={this.state.orgName}
                        invalid={this.state.isOrgNameInvalid}
                      />
                      <InputText
                        type="text"
                        placeholder="Company URL"
                        icon={`tim-icons icon-link-72`}
                        inputHandler={(val) => this.setState({ url: val })}
                        withLabel={false}
                        value={this.state.url}
                        invalid={this.state.isURLInvalid}
                      />
                      <InputText
                        type="number"
                        placeholder="Contractual Users"
                        icon={`tim-icons icon-badge`}
                        inputHandler={(val) => this.setState({ contractualUsers: val })}
                        withLabel={false}
                        value={this.state.contractualUsers}
                        invalid={this.state.isContractualUsersInvalid}
                      />
                      <RoleTypeDropDownList
                        onSelectRoleType={this.onSelectRoleType}
                        onAuthPage
                      />
                      <div className="mt-3 pt-1 mb-3">
                        <PhoneInput
                          inputStyle={{
                            borderColor: "#e0e1e4",
                            borderRadius: 8,
                            height: "5.5vh",
                            width: "100%",
                            paddingLeft: 52,
                          }}
                          country={"us"}
                          value={this.state.phone}
                          onChange={(phone) => this.setState({ phone })}
                        />
                      </div>
                      <InputText
                        type="text"
                        placeholder="First Name"
                        icon={`tim-icons icon-single-02`}
                        inputHandler={(val) =>
                          this.setState({ firstName: val })
                        }
                        withLabel={false}
                        value={this.state.firstName}
                        invalid={this.state.isFirstNameInvalid}
                      />

                      <InputText
                        type="text"
                        placeholder="Last Name"
                        icon={`tim-icons icon-single-02`}
                        inputHandler={(val) => this.setState({ lastName: val })}
                        withLabel={false}
                        value={this.state.lastName}
                        invalid={this.state.isLastNameInvalid}
                      />

                      <AddressInputForm
                        withLabel={false}
                        colSize={12}
                        labelSize={2}
                        getAddressFormValue={(val) => {
                          this.setState({ isAddressForm: val });
                        }}
                        isAddress1Invalid={this.state.isAddress1Invalid}
                        isAddress2Invalid={this.state.isAddress2Invalid}
                        isCityInvalid={this.state.isCityInvalid}
                      />

                      <InputText
                        icon={`tim-icons icon-email-85`}
                        placeholder="Email"
                        inputHandler={(val) => this.setState({ email: val })}
                        withLabel={false}
                        value={this.state.email}
                        invalid={this.state.isEmailInvalid}
                      />

                      <InputText
                        type="password"
                        icon={`tim-icons icon-lock-circle`}
                        placeholder="Password"
                        inputHandler={(val) => this.setState({ password: val })}
                        withLabel={false}
                        isPasswordInput={true}
                        value={this.state.password}
                        invalid={this.state.isPasswordInvlaid}
                      />
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleGetStarted();
                      }}
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  firebase: state.firebase,
});

export default connect(mapStateToProps)(CustomerOrgRegister);
