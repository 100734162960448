import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import { storage } from "../../firebase-client/firebase-client";
import { defaultStyles, FileIcon } from "react-file-icon";
import mime from "mime-to-extensions";
import moment from "moment";
import { dateFormat } from "utilities/constants/date-format";
import Organization from "./Organization";
import downloadFile from "downloadjs";
import { CustomInput } from "reactstrap";

export default class Document extends PersistentBase {
  constructor(timeCreated, contentType, size, name, id, partnerName, fullPath) {
    super();
    this.name = name;
    this.timeCreated = timeCreated;
    this.contentType = contentType;
    this.size = size;
    this.id = id;
    this.partnerName = partnerName;
    this.fullPath = fullPath;
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}`;
  }

  metaData() {
    return {
      name: this.name,
      timeCreated: this.timeCreated,
      id: document.name,
      contentType: this.contentType,
      size: this.size,
      partnerName: this.partnerName,
      fullPath: this.fullPath,
    };
  }

  createWithObject(payload) {
    const document = payload || this;
    this.name = document.name;
    this.id = document.name;
    this.size = document.size;
    this.contentType = document.contentType;
    this.timeCreated = document.timeCreated;
    this.partnerName = document.partnerName;
    this.fullPath = document.fullPath;

    return this;
  }

  getAllPartnerDocs(partners) {
    return Object.values(partners || {}).reduce((mapArr, partner) => {
      if (partner?.formData) {
        mapArr.push(
          ...partner.formData.filePaths.concat(",", partner.formData.logoPaths)
            .split(",")
            .filter(str => str)
            .map((path) => {
              const ref = storage.ref(path);
              ref.partner = partner;
              return ref;
            })
        );
      }
      return mapArr;
    }, []);
  }

  async getAllEventDocs(event) {
    const orgId = await super.getUserOrgId();
    const partnerDocs = this.getAllPartnerDocs(event.partners)
    const storageRef = storage.ref(`documents/${orgId}/${event?.id}/`);
    let results = [];
    let list = await storageRef.listAll();
    let index = 0;
    for (const doc of list.items.concat(partnerDocs)) {
      const metaData = await doc.getMetadata();
      let partnerName;
      if (doc.partner) {
        const partnerOrg = await new Organization().getOrgById(doc.partner.orgId);
        partnerName = partnerOrg?.name;
      }
      const formattedData = new this.constructor()
        .createWithObject({
          ...metaData,
          fullPath: doc.fullPath,
          partnerName
        })
        .tableRowFormat(index++);
      results.push(formattedData);
    }

    return results;
  }

  async delete(ref, event) {
    const orgId = await super.getUserOrgId();
    const storageRef = await storage
      .ref(`documents/${orgId}/${event}/${ref}`)
      .delete();
  }

  async getBlob() {
    const url = await storage
      .ref(this.fullPath)
      .getDownloadURL();
    const response = await fetch(url);
    const blob = await response.blob();
    return { 
      blob,
      name: this.name || "Untitled",
      type: response.type
    };
  }

  async download() {
    const data = await this.getBlob();
    downloadFile(data.blob, data.name, data.type);
  }

  bytesToSize(bytes) {
    //convert bytes to other units
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  dateFormatter(date) {
    // format the date in MMMM Do YYYY
    return moment(date).format(dateFormat);
  }
  
  tableCsvData() {
    return {
      name: this.name || "-",
      createdat: this.dateFormatter(this.timeCreated) || "-",
      type: this.contentType || "-",
      size: this.bytesToSize(this.size || 0),
      partner: this.partnerName || "-",
    };
  }

  tableColumnsAndValues() {
    const em = "3em";
    this.isSelected = false;
    return {
      selection: {
        cellText: <div id={this.id} data-sort={this.isSelected}></div>,
        width: 100,
        filter : { isFilterable: false }
      },
      icon: (
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{width: em, height: em}}>
            <FileIcon
                radius={6}
                color="#1254F8"
                gradientColor="#00D2FF"
                gradientOpacity={1}
                fold={true}
                glyphColor="rgba(255,255,255,0.6)"
                extension={"" + mime.extension(this.contentType)}
                {...defaultStyles[mime.extension(this.contentType)]}
            />
          </div>
        </div>
      ),
      name: this.centerAlignDiv(this.name),
      createdat: this.centerAlignDiv(this.dateFormatter(this.timeCreated)),
      type: this.centerAlignDiv(this.contentType),
      size: this.centerAlignDiv(this.bytesToSize(this.size)),
      partner: this.centerAlignDiv(this.partnerName || "-"),
    };
  }
}
