import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import ModalContainer from "../../../../shared/ModalContainer";
import DocumentForm from "./DocumentForm";

function NewDocumentModal(props) {
  const showButton = true;

  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  //
  const OpenModalButton = () => {
    return showButton === false ? (
      ""
    ) : (
      <Button color="info" onClick={() => toggle()} disabled={!props.isEventEditable}>
        + Add Document
      </Button>
    );
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(true);
    // toggle()
  };

  const newDocumentFormSubmitCallback = (data, index) => {
    setIsSubmitReady(false);
    props.formSubmitCallBack(data, index);
    toggle();
  };
  return (
    <ModalContainer
      isAutoClose={true}
      buttonLabel="+ Add Document"
      modalTitle="Add New Document"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={() => modalContainerSubmitCallback()}
      button={<OpenModalButton />}
    >
      <DocumentForm
        isPopulatingForm={false}
        isSubmitReady={isSubmitReady}
        submitCallback={(data, index) =>
          newDocumentFormSubmitCallback(data, index)
        }
      />
    </ModalContainer>
  );
}

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(NewDocumentModal);
