
const arrayToDisplayString = (arr) => {
  if (!arr?.length) return;
  let displayStr = "";
  for (const entity of arr) {
    const name = entity.name || entity.itemClass?.name;
    if (!name) continue;
    displayStr = `${displayStr}<br>${name}`;
  }
  return displayStr;
}

export default arrayToDisplayString;
