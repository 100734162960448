import store from "redux-store/store";
import Adapter from "./Adapter";

export default class PersistentBase extends Adapter {

  getUserToken() {
    return this.getUser(async (user) => {
      const idToken = await user.getIdToken();
      return idToken;
    });
  }

  getUserUid() {
    return this.getUser((user) => {
      return user.uid;
    });
  }

  getUser(callback) {
    const { firebase } = store.getState();
    const curUser = firebase?.auth().currentUser;
    if (!curUser) {
      return new Promise((resolve, reject) => {
        // Return user once it has been set by firebase-client.js
        const checkStore = () => {
          let { user } = store.getState();
          if (user) {
            unsubscribe();
            resolve(callback(user));
          }
        };
        let unsubscribe = store.subscribe(checkStore);
      });
    }
    return callback(curUser);
  }

  async appendAuth(url) {
    const token = await this.getUserToken();

    const separator = url.includes("?") ? "&" : "?";
    return `${url}${separator}auth=${token}`;
  }

  /**
   * Maps an object to this class
   * @param {object} payload Object to map
   * @returns {class} PersistentBase
   */
  createWithObject(payload) {
      const data = payload || this;
      for (const [key, value] of Object.entries(data)) {
          this[key] = value;
      }
      return this;
  }

  /**
   * Removes any key/value pairs with nullish or 
   * empty string values from an object
   * @param {object} obj Object to clean
   * @returns {object} Cleaned object
   */
  cleanObject(obj) {
      for (const key in obj) {
          if (obj[key] == undefined || obj[key] === "") {
            delete obj[key];
          }
      }
      return obj;
  }
}
