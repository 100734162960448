import React, { Component } from "react";

import AccountSponsorship from "models/database/AccountSponsorship";
import AccountSponsorships from "models/database/AccountSponsorships";
import CrudActionsTable from "../../../shared/CrudActionsTable";
import EditSponsorshipsModal from "./EditSponsorshipsModal";


class SponsorshipsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : []
        };
    }
    getSponsorships = async () => {
        const sponsorships = await new AccountSponsorships().getAllEntities();
        this.setState({ data: sponsorships });
    }
    componentDidMount() {
        this.getSponsorships();
    }

    render() {
        const pageSize = 10;

        return (
            <>
                <CrudActionsTable
                    data={this.state.data}
                    dataModel={AccountSponsorship}
                    filterable={true}
                    newItem={this.props.newRow}
                    resizable={true}
                    defaultPageSize={pageSize}
                    modalName="sponsorship"
                    editModal={EditSponsorshipsModal}
                />
            </>
        );
    }
}

export default SponsorshipsTable;
