import getPartnerDeliverables from "./getPartnerDeliverables";

const filterByType = (partner, filterIds, filterType) => {
  if (!filterIds.length) return true;
  const filterTypeMap = {
    "All": () => !!validIds.length,
    "Incomplete": () => validIds.length < filterIds.length,
    "Completed": () => validIds.length === filterIds.length,
  };
  if (!filterTypeMap[filterType]) filterType = "All";
  const validIds = getPartnerDeliverables(partner, filterIds, {
    property: filterType === "All" ? null : "completed",
    value: true,
  });
  return filterTypeMap[filterType]();
};

const isPartnerEntitiesValid = (entities, filterIds) =>
  !filterIds.length || Object.keys(entities || {}).some((id) => filterIds.includes(id));

const filterContacts = (
  contacts,
  partnersData,
  sponsorshipIds,
  boothIds,
  deliverableIds,
  deliverableFilterType
) => {
  if (!sponsorshipIds.length && !boothIds.length && !deliverableIds.length)
    return contacts;
  const validContacts = {};
  for (const [email, contact] of Object.entries(contacts)) {
    const partner = partnersData[contact.orgId];
    if (!partner) continue;
    if (
      !isPartnerEntitiesValid(partner.sponsorships, sponsorshipIds) ||
      !isPartnerEntitiesValid(partner.booths, boothIds)
    )
      continue;
    const hasValidDeliverable = filterByType(partner, deliverableIds, deliverableFilterType);
    if (!hasValidDeliverable) continue;
    validContacts[email] = contact;
  }
  return validContacts;
};

export default filterContacts;
