import Contact from "./Contact";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "utilities/constants/urls";

export default class Contacts extends PersistentBase {
  constructor() {
    super();
    this.collectionPath = "contacts";
    this.dataModel = Contact;
    this.setRestUrl(); // eventFirebaseUrl doesn't seem to be loaded when declaring the normal way
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl;
    this.jsonUrl = customerOrgsFirebaseUrl;
  }
}
