import PersistentBase from "./PersistentBase";

import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";

export default class AccountDeliverable extends PersistentBase {
    constructor(id, name, description) {
        super();
        this.id = id;
        this.name = name;
        this.description = description;
        this.csvTitle = "Account Deliverable";
        this.setRestUrl();
    }

    async setRestUrl() {
        const userOrg = await super.getUserOrgId();
        this.url = `${customerOrgsFirebaseUrl}/${userOrg}/account-deliverables`;
        this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/account-deliverables.json`;
    }

    metaData() {
        return {
            name: this.name,
            description: this.description,
        };
    }

    async update(payload) {
        const updatedEntity = this.createWithObject(payload);
        if (!this.url) await this.setRestUrl();
        const url = await super.appendAuth(super.buildUrl(this.url, this.id));
        super.update(url, updatedEntity.metaData());
        return this;
    }

    tableColumnsAndValues() {
        return {
            deliverable: this.name || "",
            description: { cellText: this.description || " ", width: 1000 },
        };
    }
}
