import React, { useEffect, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import AccountDeliverables from "models/database/AccountDeliverables";
import { connect } from "react-redux";

const createSingleSelect = (value, label, id, itemClass) => {
  return {
    value,
    label,
    id,
    name: label,
    itemClass,
  };
};

const mapOptions = (deliverables) =>
  deliverables.map((value, index) =>
    createSingleSelect(
      index,
      value.deliverable || value.name,
      value.itemClass?.id || value.id,
      value.itemClass || value
    )
  );

function DeliverablesDropdown(props) {
  const [value, setValue] = useState(null);
  const [deliverableOptions, setDeliverableOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const handleChange = (val) => {
    props.callback({ ...val, index: props.index });
    setValue(val);
  };

  useEffect(() => {
    if (!props.options) return;
    setDeliverableOptions(mapOptions(props.options));
  }, [props.options]);

  useEffect(() => {
    (async function () {
      if (fetchedData || (!props.eventData && props.filterItems)) return;
      setFetchedData(true);
      if (props.options) return setDeliverableOptions(mapOptions(props.options));
      let deliverables = await new AccountDeliverables().getAllEntities();

      if (props.filterItems) {
        // Filter out deliverables that have already been added to the event
        const usedDeliverableIds = props.eventData.deliverables.map(
          (o) => o.id
        );
        deliverables = deliverables.filter(
          (d) =>
            !usedDeliverableIds.includes(d.itemClass.id) ||
            (props.defaultValue && d.itemClass.id === props.defaultValue.id)
        );
      }

      if (props.filterOfferingDeliverables) {
        // Filter out deliverables that have already been added in the table
        const usedDeliverableIds = props.filterOfferingDeliverablesData.map(
          (o) => o.id
        );
        deliverables = deliverables.filter(
          (d) =>
            !usedDeliverableIds.includes(d.itemClass.id) ||
            (props.defaultValue && d.itemClass.id === props.defaultValue.id)
        );
      }

      if (props.isEventLevel) {
        // Filter out deliverables that do not belong to the current event
        const eventDeliverables = Object.values(props.eventData.offerings || {})
          .flatMap((offering) => Object.values(offering).flatMap((o) => Object.values(o.deliverables || {})));
        deliverables = deliverables.filter(
          (d) =>
            (props.defaultValue && d.itemClass.id === props.defaultValue.id) ||
            eventDeliverables.find((deliverable) => deliverable.id === d.itemClass.id)
        );
      }

      setDeliverableOptions(mapOptions(deliverables));
    })();
  });

  return (
    <CustomSelect
      options={deliverableOptions}
      placeholder="Deliverable"
      value={value}
      defaultSelected={props.defaultValue}
      callback={handleChange}
      multiSelect={props.multiSelect}
      searchable={true}
      size="9"
      whiteSpace="normal"
      isNotPadded={props.isNotPadded}
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(DeliverablesDropdown);
