import { Button, UncontrolledTooltip } from "reactstrap";
import classNames from "classnames";
import React from "react";

const IconButton = (props) => {
  const icon = <i className={`tim-icons ${props.icon}`} />;
  return (
    <>
      <Button
        onClick={() => {
          props.onClick(props.indexKey);
        }}
        color={props.color}
        size="sm"
        className={classNames("btn-icon btn-link like", props.className)}
        id={`tooltip00000${props.tooltipId}`}
        style={props.style || {}}
        disabled={props.disabled}
      >
        {icon}
      </Button>

      <UncontrolledTooltip
        delay={0}
        target={`tooltip00000${props.tooltipId}`}
        placement="top"
      >
        {props.tooltip}
      </UncontrolledTooltip>
    </>
  );
};

export default IconButton;
