import classNames from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { toString as _toString } from "lodash";

const DraggableItem = (props) => {
  const [isDragging, setIsDragging] = useState(false);
  const handleDragStart = (e) => {
    setIsDragging(true);
    e.dataTransfer.setData("text/plain", _toString(props.dragData || props.textContent));
  };

  const handleDragEnd = (e) => {
    setIsDragging(false);
    props.onDragEnd?.(props.callbackData);
  };

  return (
    <div
      className={classNames(
        "arcat draggable-item",
        isDragging && "dragging",
        props.className && props.className
      )}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      draggable
    >
      {props.textContent}
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventContacts: state.eventContacts,
  user: state.user,
});

export default connect(mapStateToProps)(DraggableItem);
