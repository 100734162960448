import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import Events from "models/database/Events";

const createSingleSelect = (
  value,
  label,
  itemClass
) => {
  return {
    value,
    label,
    itemClass,
  };
};

const mapOptions = (events) =>
  events.map((value, index) =>
    createSingleSelect(
      index,
      value.name,
      value
    )
  );

function EventsDropdown(props) {
  const isMounted = useRef(false);
  const [value, setValue] = useState(null);
  const [eventOptions, setEventOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const handleChange = (val) => {
    props.callback(val);
    setValue(val);
  };

  const setOptions = (options) => {
    if (isMounted.current) {
      setEventOptions(mapOptions(options));
    }
  }

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    (async function () {
      if (fetchedData || eventOptions.length) return;
      setFetchedData(true);
      let events = props.options || await new Events().getAllEntities(false);
      setOptions(events);
    })();
  });

  return (
    <CustomSelect
      options={eventOptions}
      placeholder="Event"
      value={value}
      defaultSelected={props.defaultValue}
      callback={handleChange}
      multiSelect={props.multiSelect}
      searchable={true}
      size="9"
    />
  );
}

export default EventsDropdown;
