import Contact from "models/database/Contact";
import Event from "models/database/Event";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Collapse,
} from "reactstrap";
import { dateFormat } from "utilities/constants/date-format";
import NewContactModal from "../sections/contacts/newContactModal";
import StyledLink from "./StyledLink";

export default function Accordion({ contacts, orgData }) {
  const [contactsData, setContactsData] = useState(contacts);
  const [eventsData, setEventsData] = useState([]);
  const [contactCollapse, setContactsCollapse] = useState(false);
  const [eventsCollapse, seteventsCollapse] = useState(false);
  const [changeHistoryCollapse, setChangeHistoryCollapse] = useState(false);

  const getEvents = async (eventIds) => {
    const events = [];
    for (const eventId of eventIds) {
      const event = await new Event().getById(eventId);
      if (!event) continue;
      events.push(event);
    }
    setEventsData(events);
  };

  useEffect(() => {
    setContactsData(contacts);
  }, [contacts]);

  useEffect(() => {
    if (eventsData.length || !orgData?.eventIds) return;
    getEvents(Object.values(orgData.eventIds));
  }, [orgData]);

  const contactSubmitEventHandler = async (contactProps) => {
    const newContact = await new Contact().create(contactProps);
    newContact.orgName = orgData.name;
    const formattedContact = [newContact.orgId, newContact];
    setContactsData((prevState) => [...prevState, formattedContact]);
  };

  const populateContactsTable = (contact, index) => {
    return (
      !!contact && (
        <tr
          key={index}
          className="border border-top border-right-0 border-left-0 border-bottom-1"
        >
          <td>
            <StyledLink to={`/plan/contact/${contact.fullName}`}>
              {contact.firstName}
            </StyledLink>
          </td>
          <td>
            <StyledLink to={`/plan/contact/${contact.fullName}`}>{contact.lastName}</StyledLink>
          </td>
          <td>{contact.title}</td>
          <td>{contact.email || "-"}</td>
          <td>{contact.contactType}</td>
        </tr>
      )
    );
  };

  const populateEventsTable = (event, index) => {
    return (
      !!event?.code && (
        <tr
          key={index}
          className="border border-top border-right-0 border-left-0 border-bottom-1"
        >
          <td>
            <StyledLink to={`/plan/event/${event.code}`}>{event.name}</StyledLink>
          </td>
          <td>{moment(event.startDate).format(dateFormat)}</td>
          <td>{moment(event.endDate).format(dateFormat)}</td>
          <td>{`${event.city || ""} ${event.state || ""}`}</td>
          <td>{event.format || ""}</td>
        </tr>
      )
    );
  };

  const contactsRow = (
    <Card className="card-plain">
      <CardHeader role="tab">
        <a
          aria-expanded={contactCollapse}
          data-parent="#accordion"
          data-toggle="collapse"
          onClick={(e) => setContactsCollapse((prevState) => !prevState)}
        >
          Contacts <i className="tim-icons icon-minimal-down" />
        </a>
      </CardHeader>
      <Collapse role="tabpanel" isOpen={contactCollapse}>
        <CardBody>
          <Row style={{ marginLeft: "1%" }}>
            <Col md="12" className="text-right">
              <NewContactModal
                formSubmitCallBack={contactSubmitEventHandler}
                orgId={orgData?.id}
              />
            </Col>
            <Table borderless>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Title</th>
                  <th>Email</th>
                  <th>Contact Type</th>
                </tr>
              </thead>
              <tbody>
                {contactsData?.map(([key, value], index) =>
                  populateContactsTable(value, index)
                )}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );

  const eventsRow = (
    <Card className="card-plain">
      <CardHeader role="tab">
        <a
          aria-expanded={eventsCollapse}
          // href="#pablo"
          data-parent="#accordion"
          data-toggle="collapse"
          onClick={(e) => seteventsCollapse((prevState) => !prevState)}
        >
          Events
          <i className="tim-icons icon-minimal-down" />
        </a>
      </CardHeader>
      <Collapse role="tabpanel" isOpen={eventsCollapse}>
        <CardBody>
          <Row style={{ marginLeft: "1%" }}>
            <Table borderless>
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Location</th>
                  <th>Format</th>
                </tr>
              </thead>
              <tbody>
                {eventsData?.map((event, index) =>
                  populateEventsTable(event, index)
                )}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );

  return (
    <Row>
      <Card md={8}>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          {contacts && contactsRow}
          {eventsRow}
          {/* <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={changeHistoryCollapse}
                // href="#pablo"
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={(e) =>
                  setChangeHistoryCollapse((prevState) => !prevState)
                }
              >
                Change History <i className="tim-icons icon-minimal-down" />
              </a>
            </CardHeader>
            <Collapse role="tabpanel" isOpen={changeHistoryCollapse}>
              <CardBody>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably
                haven't heard of them accusamus labore sustainable VHS.
              </CardBody>
            </Collapse>
          </Card> */}
        </div>
      </Card>
    </Row>
  );
}
