import React from "react";

export const NumberToCurrency = (value) => {
  let currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  let formattedValue;
  if (!isNaN(Number(value)) && typeof currencyFormat?.format === "function") {
    formattedValue = currencyFormat.format(Number(value));
  }
  return formattedValue || value;
};

export const CurrencyToNumber = (value) => {
  if (typeof value == "string" && value.indexOf("$") > -1) {
    let num = Number(value.split("$")[1].split(",").join(""));
    return num;
  } else return value;
};

function CurrencyFormatter({ value }) {
  return <>{NumberToCurrency(value)}</>;
}

export default CurrencyFormatter;
