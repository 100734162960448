import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import ModalContainer from "../../../../shared/ModalContainer";
import NewEmailForm from "./NewEmailForm";
import { connect } from "react-redux";
import Organization from "models/database/Organization";
import { toString as _toString } from "lodash";
import userEmailVariables from "utilities/methods/userEmailVariables";
import formatEmailVariable from "utilities/methods/formatEmailVariable";
import arrayToDisplayString from "utilities/methods/arrayToDisplayString";
import MailgunEmail from "models/backend/MailgunEmail";
import Email from "models/database/Email";

const NewEmailModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(props.item ? true : false);
  const [emailFormData, setEmailFormData] = useState({});

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle?.();
    setEmailFormData({});
  };

  const OpenModalButton = (
    <Button color="info" size="md" onClick={toggle} disabled={!props.isEventEditable}>
      + New Email
    </Button>
  );

  const getVariableValue = (variable, userOrganization) => {
    let value = variable.value;
    if (variable.category === "Event") {
      if (variable.key === "sponsorships" ||
          variable.key === "deliverables") {
        value = arrayToDisplayString(props.eventData[variable.key]);
      } else {
        value = props.eventData[variable.key];
      }
    } else if (variable.category === "Organization") {
      value = userOrganization[variable.key];
    }
    return formatEmailVariable(value) || "<no-variable-data />";
  }

  const getSharedVariables = async () => {
    const { emailForm, usedVariables } = emailFormData;
    const sharedVariables = {};
    const hasOrgVariable = !!usedVariables?.some(variable => variable.category === "Organization");
    const userOrganization = hasOrgVariable ? await new Organization().getUserOrgData() : {};
    for (const variable of usedVariables) {
      const variableText = `${variable.category}_${variable.key}`;
      if (!sharedVariables[variable.key] &&
         (emailForm.subject.includes(variableText) ||
          emailForm.html.includes(variableText))) {
        sharedVariables[`${variable.category}_${variable.key}`] = 
          getVariableValue(variable, userOrganization);
      }
    }
    return sharedVariables;
  };

  const getEmailVariables = async () => {
    const { emailForm } = emailFormData;
    const allRecipients = emailForm.to.split(",");
    const sharedVariables = await getSharedVariables();
    const variables = await userEmailVariables(allRecipients, emailFormData, props.eventData);
    for (const email of allRecipients) {
      variables[email] = { ...sharedVariables, ...variables[email] };
    }
    return variables;
  };

  const emailVariableRegex = (textContent) => {
    return textContent?.replaceAll?.(/%([a-z,A-Z,0-9,_,-]+)%/g, "%recipient.$1%") || textContent;
  };

  const parseEmailVariables = (emailForm) => {
    const subject = emailVariableRegex(emailForm.subject);
    const html = emailVariableRegex(emailForm.html);
    return { ...emailForm, subject, html };
  };

  const handleEmailFormData = (data) => {
    setEmailFormData(data);
    setIsSubmitReady(isFormValid(data.emailForm));
  };

  const isFormValid = (data) => Boolean(data.html && data.subject && data.to);

  const submitCallback = async () => {
    const emailForm = parseEmailVariables({ ...emailFormData.emailForm });
    emailForm["recipient-variables"] = await getEmailVariables();
    if (props.submitCallback) {
      props.submitCallback({ ...emailFormData, emailForm });
    } else {
      await new MailgunEmail().createWithObject(emailForm).create();
    }
    toggle();
  };

  useEffect(() => {
    if (props.item?.data) {
      const parsedData = new Email().createWithObject(props.item.data).parseEmail();
      handleEmailFormData(parsedData);
    }
  }, []);

  return (
    <ModalContainer
      buttonLabel="+ Send Email"
      modalTitle="Send New Email"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      button={props.item ? null : OpenModalButton}
      isSubmitDisabled={!isSubmitReady}
      submitButtonText="Send"
    >
      <NewEmailForm data={emailFormData} setData={handleEmailFormData} />
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(NewEmailModal);
