import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import ModalContainer from "../../../../shared/ModalContainer";
import EventPartnerForm from "./EventPartnerForm";

const NewEventPartnerModal = (props) => {
  const showButton = true;

  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = () => {
    return showButton === false ? (
      ""
    ) : (
      <Button color="info" onClick={() => toggle()} disabled={!props.isEventEditable}>
        + Add Event Partner
      </Button>
    );
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(true);
  };

  const newPartnerFormSubmitCallback = (data, index) => {
    setIsSubmitReady(false);
    props.formSubmitCallBack(data, index);
    toggle();
  };

  return (
    <ModalContainer
      buttonLabel="+ Partner"
      modalTitle="Add New Partner"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={() => modalContainerSubmitCallback()}
      button={<OpenModalButton />}
    >
      <EventPartnerForm
        event={props?.event}
        isPopulatingForm={false}
        isSubmitReady={isSubmitReady}
        submitCallback={(data, index) =>
          newPartnerFormSubmitCallback(data, index)
        }
      />
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(NewEventPartnerModal);
