import React from 'react';
import { Card, CardHeader, CardTitle, CardBody, CardText } from "reactstrap";

const CustomCard = (props) => {
  return (
    <Card sm={props.sm} md={props.md} lg={props.lg} className={props.className}>
      <CardHeader>
        <CardTitle tag={props.titleTag || "h4"}>{props.title || ""}</CardTitle>
      </CardHeader>
      <CardBody>
        <CardText>{props.body || ""}</CardText>
      </CardBody>
    </Card>
  );
}

export default CustomCard;
