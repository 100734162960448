import { customerOrgsFirebaseUrl } from "utilities/constants/urls";
import PersistentBase from "./PersistentBase";
import AccountEmailTemplate from "./AccountEmailTemplate";

export default class AccountEmailTemplates extends PersistentBase {

    constructor() {
        super();
        this.collectionPath = "account-emails";
        this.dataModel = AccountEmailTemplate;
        this.setRestUrl();
    }

    setRestUrl() {
        this.url = customerOrgsFirebaseUrl;
        this.jsonUrl = customerOrgsFirebaseUrl;
    }

}
