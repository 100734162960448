import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import CurrencyFormatter from "layouts/Plan/shared/CurrencyFormatter";

export default class Booths extends PersistentBase {
  constructor(id, name, width, depth, quantity, rate, deliverables, reserved) {
    super();
    this.id = id;
    this.name = name;
    this.width = width;
    this.depth = depth;
    this.quantity = quantity;
    this.rate = rate;
    this.deliverables = deliverables;
    this.reserved = reserved;
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl;
    this.jsonUrl = customerOrgsFirebaseUrl;
  }

  metaData() {
    return {
      name: this.name,
      width: this.width,
      depth: this.depth,
      quantity: this.quantity,
      rate: this.rate,
      id: this.id,
      deliverables: this.deliverables,
      reserved: this.reserved,
    };
  }

  async getAll(boothsData, eventId) {
    const userOrgId = await super.getUserOrgId();
    const booths = [];
    let index = 0;
    for (const booth of boothsData) {
      this.jsonUrl = await super.appendAuth(
        `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/booths/${booth.id}.json`
      );
      const response = await super.get();
      const formattedBooth = new this.constructor()
        .createWithObject(response)
        .tableRowFormat(index++);
      Booths.push(formattedBooth);
    }
    return booths;
  }

  async updateSingleEntity(payload, eventId) {
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/booths/${payload?.id}.json`
    );
    const response = await super.update(url, payload);

    return response;
  }

  async update(payload, event, deleteItem, single = false, collection) {
    if (deleteItem?.id && !Object.keys(payload?.booths || {}).includes(deleteItem.id)) {
      await this.delete(deleteItem.id, event.id, collection);
    }
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event.id}/offerings/booths.json`
    );
    const response = await super.update(url, payload.booths);
    const booths = [];
    for (const boothData of Object.values(response)) {
      const booth = new this.constructor().createWithObject(boothData);
      if (single) {
        return booth;
      }
      booths.push(booth);
    }
    return booths;
  }

  // async update(payload, event, deleteItem, single = false) {
  //   if (deleteItem && deleteItem.id) {
  //     await this.delete(deleteItem.id, event.id);
  //   }
  //   const userOrgId = await super.getUserOrgId();
  //   const url = await super.appendAuth(
  //     `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event.id}/offerings/booths.json`
  //   );
  //   const response = await super.update(url, payload.booths);

  //   const booths = [];
  //   for (const boothData of Object.values(response)) {
  //     const booth = new this.constructor().createWithObject(boothData);
  //     if (single) {
  //       return booth;
  //     }
  //     booths.push(booth);
  //   }
  //   return booths;
  // }

  async delete(boothID, eventId) {
    const userOrgId = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/booths/`;
    const response = await super.delete(boothID);
    return response;
  }

  tableColumnsAndValues() {
    return {
      booth: this.name,
      quantity: { cellText: this.centerAlignDiv(this.quantity || "0"), width: 100 },
      rate: {
        cellText: this.centerAlignDiv(
          <CurrencyFormatter value={this.rate || "0"} />
        ),
        width: 110,
      },
      reserved: { cellText: this.centerAlignDiv(this.reserved || "0"), width: 100 },
    };
  }
}
