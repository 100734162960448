import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CustomerOrgUsers from "../../../models/database/CustomerOrgUsers";
import Contacts from "models/database/Contacts";
import User from "../../../models/database/User";
import CustomSelect from "creative-tim/components/CustomSelect";
import formatUserName from "utilities/methods/formatUserName";

const createSingleSelect = (value, label, itemClass) => {
  return {
    value,
    label,
    itemClass,
  };
};

const mapOptions = (arr) => arr.map((user) => 
  createSingleSelect(user?.uid, formatUserName(user), user)
);

function UsersDropdown(props) {
  const isMounted = useRef(false);
  const [usersData, setUsersData] = useState([]);
  const [value, setValue] = useState(props.fixedOptions || []);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const handleChange = (newValue = []) => {
    props.onChange(newValue);
    setValue(newValue);
  };

  async function getUsers() {
    const orgUsers = await new CustomerOrgUsers().getAllEntities(false);
    const users = [];
    for (const orgUser of orgUsers) {
      const user = await new User().get(orgUser.usersId);
      if (user) users.push(user);
    }
    setUsersData(mapOptions(users));
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <CustomSelect
      value={value}
      options={usersData}
      defaultSelected={props.defaultSelected}
      placeholder={props.placeholder || "Users"}
      callback={handleChange}
      multiSelect={props.multiSelect}
      isClearable
      searchable
      isNotPadded
      size="9"
      whiteSpace="normal"
    />
  );
}

const mapStateToProps = (state) => ({
  orgUsers: state.orgUsers,
  eventData: state.eventData,
});

export default connect(mapStateToProps)(React.memo(UsersDropdown));
