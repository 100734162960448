import CustomerOrgUser from "./CustomerOrgUser";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "utilities/constants/urls";

export default class Organizations extends PersistentBase {
  constructor() {
    super();
    this.collectionPath = "users";
    this.dataModel = CustomerOrgUser;
    this.setRestUrl(); // eventFirebaseUrl doesn't seem to be loaded when declaring the normal way
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl;
    this.jsonUrl = customerOrgsFirebaseUrl; // should this be customerOrgsFirebaseJsonUrl ?
  }
}
