import React, { useState } from "react";

import ModalContainer from "../../shared/ModalContainer";
import NewEventForm from "./NewEventForm";

/**
 *  =======
 * = USAGE =
 *  =======
 *
 * <EditEventModal
 *  formSubmitCallBack={(data, index) => this.submitEventHandler(data, index)}
 *  event={eventEditObj}
 *  toggle={() => {this.toggleModifyEventModal()}}
 * />
 *
 * =======
 * = API =
 * =======
 * event (required) (Object)
 * - used to pre-populate the form with current properties
 * formSubmitCallBack (optional) (Function)
 * - callback function to invoke submit method in parent component
 *  toggle (required) (Function)
 *  - callback function to flip state if modal should be visible or hidden form true to false, or false to true
 */

const NewEventModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(true);
  };

  const editEventFormSubmitCallback = (data, index) => {
    setIsSubmitReady(false);
    props.formSubmitCallBack(data, index);
    props.toggle();
  };

  return (
    <ModalContainer
      modalTitle="Edit Event"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={() => modalContainerSubmitCallback()}
    >
      <NewEventForm
        isPopulatingForm={true}
        isSubmitReady={isSubmitReady}
        setIsSubmitReady={setIsSubmitReady}
        event={props.item}
        submitCallback={(data, index) =>
          editEventFormSubmitCallback(data, index)
        }
      />
    </ModalContainer>
  );
};

export default NewEventModal;
