import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import StyledLink from "../../layouts/Plan/shared/StyledLink";
import { contactTypes } from "utilities/constants/event-terms";
import Organizations from "./Organizations";
import firebaseUid from "utilities/methods/firebaseUid";

export default class Contact extends PersistentBase {
  constructor(
    id,
    firstName,
    lastName,
    title,
    contactType,
    phone,
    email,
    address1,
    address2,
    zip,
    country,
    state,
    city,
    orgId,
    orgName,
    orgModel,
  ) {
    super();
    this.firstName = firstName;
    this.lastName = lastName;
    this.title = title;
    this.contactType = contactType;
    this.phone = phone;
    this.email = email;
    this.address1 = address1;
    this.address2 = address2;
    this.zip = zip;
    this.country = country;
    this.state = state;
    this.city = city;
    this.orgId = orgId;
    this.id = id;
    this.fullName = "";
    this.orgName = orgName;
    // added orgModel so that you can click through to the Org page (which needs the orgId)
    this.orgModel = orgModel;
    this.csvTitle = "Contact";
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/contacts`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/contacts.json`;
  }

  metaData() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      contactType: this.contactType,
      phone: this.phone,
      email: this.email,
      address1: this.address1,
      address2: this.address2,
      zip: this.zip,
      country: this.country,
      state: this.state,
      city: this.city,
      orgId: this.orgId,
      fullName: this.fullName,
      orgName: this.orgName,
      orgData: this.orgData,
    };
  }

  emailVariables() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      contactType: this.contactType,
      phone: this.phone,
      email: this.email,
      address1: this.address1,
      address2: this.address2,
      zip: this.zip,
      country: this.country,
      state: this.state,
      city: this.city,
      orgName: this.orgName,
    };
  }

  createWithObject(payload) {
    const contact = payload || this;
    this.id = contact.id;
    this.firstName = contact.firstName;
    this.lastName = contact.lastName;
    this.title = contact.title;
    this.contactType = contact.contactType;
    this.phone = contact.phone;
    this.email = contact.email;
    this.address1 = contact.address1;
    this.address2 = contact.address2;
    this.zip = contact.zip;
    this.country = contact.country;
    this.state = contact.state;
    this.city = contact.city;
    this.orgId = contact.orgId;
    this.orgName = contact.orgName;
    this.fullName = contact.fullName;
    this.orgData = contact.orgData;
    return this;
  }

  async create(payload, orgData) {
    if (payload) {
      this.createWithObject({ id: "", ...payload });
    }
    delete payload["id"];
    delete payload["jsonUrl"];
    delete payload["url"];

    const user = await this.getUserUid();
    const email = localStorage.getItem("userEmail");
    payload.users = { [user]: { email: email } };

    // if setRestUrl is async, make sure url is ready
    if (!this.jsonUrl) await this.setRestUrl();
    this.jsonUrl = await this.appendAuth(this.jsonUrl);
    const response = await super.create(payload);
    if (orgData) {
      let org = orgData.find(({ itemClass }) => itemClass?.id == payload.orgId);
      response.orgName = org?.itemClass?.name || " ";
      this.orgData = org.itemClass
    }
    this.id = response.name; // firebase response is { name: uniqueID }
    return this;
  }

  async getAllEntities(orgData) {
    const userOrgId = await super.getUserOrgId();
    this.jsonUrl = await this.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/contacts.json`
    );
    const entities = await this.get();
    if (!entities) return [];

    let index = 0;
    const arrayOfEntities = [];
    for (const [entityID, entityData] of Object.entries(entities)) {
      if (entityID == undefined) continue;
      let orgsArr = orgData.find((x) => entityData.orgId == x.itemClass.id);
      const entityObj = {
        id: entityID,
        index: index,
        orgName: orgsArr?.itemClass?.name,
        orgData: orgsArr?.itemClass,
        ...entityData,
      };
      let entity = new this.constructor()
        .createWithObject(entityObj)
        .tableRowFormat(index++);
      arrayOfEntities.push(entity);
    }
    return arrayOfEntities;
  }

  async getContacts(orgId, contactId) {
    const userOrgId = await this.getUserOrgId();
    this.jsonUrl = await this.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/contacts.json`
    );
    const entity = await this.get();

    return entity;
  }

  async getOrgContactFromFullName(fullName) {
    const userOrgId = await this.getUserOrgId();
    this.jsonUrl = await this.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/contacts.json?`
    );
    const contacts = await this.get();

    const contactKv = Object.entries(contacts).find(
      ([key, value]) => {
        if (value["fullName"]) {
          return value["fullName"] === fullName
        } else {
          const [first, last] = fullName.split(" ");
          return first ===  value['firstName'] && last === value['lastName']
        }
      }
    );

    return contactKv || [];
  }

  async update(contact, eventData, deleteItem, bool, parent, orgData) {
    if (!this.url) await this.setRestUrl();

    const updatedContact = await this.createWithObject({
      id: this.id,
      ...contact,
    });

    const url = await super.appendAuth(super.buildUrl(this.url, this.id));
    super.update(url, updatedContact.metaData());

    if (orgData) {
      let org = orgData.find(({ itemClass }) => itemClass?.id == contact.orgId);
      this.orgName = org?.itemClass?.name || " ";
    }

    return this;
  }

  csvKeyMap() {
    return {
      "first name": {
        keyName: "firstName",
        templateData: "John"
      },
      "last name": {
        keyName: "lastName",
        templateData: "Doe"
      },
      "fullName": {
        keyName: "fullName",
        isInternal: true,
      },
      "organization name": {
        keyName: "orgName",
        templateData: "ArcatXP"
      },
      "orgId": {
        keyName: "orgId",
        isInternal: true,
      },
      "orgName": {
        keyName: "orgName",
        isInternal: true,
      },
      "orgData": {
        keyName: "orgData",
        isInternal: true,
      },
      "title": {
        keyName: "title",
        templateData: "CTO"
      },
      "contact type": {
        keyName: "contactType",
        templateData: contactTypes.join("/"),
      },
      "email": {
        keyName: "email",
        templateData: "johndoe@example.com"
      },
      "phone": {
        keyName: "phone",
        templateData: "+1(555)555-5555"
      },
      "address 1": {
        keyName: "address1",
        templateData: "1 Main Street",
      },
      "address 2": {
        keyName: "address2",
        templateData: "Unit B",
      },
      "city": {
        keyName: "city",
        templateData: "San Francisco",
      },
      "state": {
        keyName: "state",
        templateData: "California",
      },
      "zip code": {
        keyName: "zip",
        templateData: "12345",
      },
      "country": {
        keyName: "country",
        templateData: "United States",
      },
    };
  }

  async uploadCsvData(items, tableIndex = null) {
    const organizations = await new Organizations().getAllEntities(false);
    const contacts = items.map((contact) => {
      const contactOrgName = contact["organization name"]?.toLowerCase?.().trim() || null;
      const org = organizations.find((org) => org.name?.toLowerCase().trim() === contactOrgName);
      delete contact["organization name"];
      return {
        ...contact,
        fullName: `${contact["first name"] || ""} ${contact["last name"] || ""}`,
        orgId: org?.id || "",
        orgName: org?.name || "",
        orgData: org || {},
      };
    });
    const response = await super.uploadCsvData(contacts, tableIndex);
    return response;
  }

  tableColumnsAndValues() {
    this.fullName = `${this.firstName} ${this.lastName}`;
    const LinkTo = {
      pathname: `/plan/contact/${this.fullName}`,
      state: { contact: this },
    };

    const  orgLinkTo = {
        pathname: `organization/${this.orgName}`,
        state: { organization: this.orgData}
    };

    return {
      "first name": <StyledLink to={LinkTo}>{this.firstName}</StyledLink>,
      "last name": <StyledLink to={LinkTo}>{this.lastName}</StyledLink>,
      "organization name": <StyledLink to={orgLinkTo}>{this.orgName}</StyledLink>,
      title: this.title,
      "contact type": this.contactType,
      state: this.state,
      country: this.country,
    };
  }
}
