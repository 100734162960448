import EventPartner from "models/database/EventsPartner";
import Organization from "models/database/Organization";
import User from "models/database/User";
import arrayToDisplayString from "./arrayToDisplayString";
import formatEmailVariable from "./formatEmailVariable";

const userEmailVariables = async (allRecipients, emailFormData, eventData) => {
  const { contactsData, usedVariables } = emailFormData;
  const categories = usedVariables.reduce(
    (mapObj, variable) => {
      mapObj[variable.category]?.push?.(variable);
      return mapObj;
    },
    {
      Contact: [],
      Partner: [],
      Booth: [],
      Deliverable: [],
    }
  );
  const partnerDeliverables =
    categories.Deliverable.length || categories.Booth.length
      ? await new EventPartner().getEventDeliverables(eventData.id, false)
      : {};
  const contactOrgs = {};
  const variables = {};
  const nullKeyValues = {
    rate: "0",
    quantity: "0",
    reserved: "0",
    completed: "false",
    deadline: "N/A",
  };
  for (const email of allRecipients) {
    variables[email] = {};
    for (const variable of categories["Contact"]) {
      if (variable.key === "orgName" && contactsData[email].orgId) {
        if (!contactOrgs[contactsData[email].orgId]) {
          contactOrgs[contactsData[email].orgId] =
            await new Organization().getOrgById(contactsData[email].orgId);
        }
        variables[email]["Contact_" + variable.key] =
          formatEmailVariable(contactOrgs[contactsData[email].orgId].name) ||
          "<no-contact>";
        continue;
      }
      variables[email]["Contact_" + variable.key] =
        formatEmailVariable(contactsData[email][variable.key]) || "<no-contact>";
    }
    for (const variable of categories["Partner"]) {
      if (contactsData[email]) {
        const [parentOrgId, parentOrg] =
          Object.entries(eventData.partners || {}).find(
            ([partnerId, partner]) =>
              partner.orgId === contactsData[email].orgId
          ) || [];
        let entityValue = parentOrg?.[variable.key];
        if (variable.key === "booth") {
          entityValue = Object.values(parentOrg?.booths || {})[0].itemClass
            ?.name;
        } else if (variable.key === "sponsorLevel") {
          entityValue = Object.values(parentOrg?.sponsorships || {}).find(
            (sponsor) => !sponsor.isCarteSponsor
          )?.label;
        } else if (variable.key === "carteSponsorships") {
          entityValue = Object.values(parentOrg?.sponsorships || {}).filter(
            (sponsor) =>
              sponsor.isCarteSponsor || sponsor.itemClass?.type === "A La Carte"
          );
        } else if (variable.key === "allSponsorships") {
          const sponsorships = Object.values(parentOrg?.sponsorships || {})
            .reduce((mapArr, sponsorship) => {
            const name = sponsorship?.name || sponsorship?.label;
            if (!name) return mapArr;
            const deliverables = Object.values(sponsorship?.deliverables || {}).map((deliverable) => {
              const name = deliverable?.name || deliverable?.label;
              const deadline = `Due Date: ${formatEmailVariable(deliverable?.deadline || "N/A")}`;
              return { name: `<li>${name}<br>${deadline}</li>` };
            });
            const deliverablesString = arrayToDisplayString(deliverables);
            mapArr.push(`${name}<br>Deliverables:<ul>${deliverablesString}</ul>`);
            return mapArr;
          }, []);
          entityValue = `Sponsorships:<br><ul>${sponsorships.join("<br>") || "N/A"}</ul>`;
        } else if (variable.key === "organization") {
          if (!contactOrgs[contactsData[email].orgId]) {
            contactOrgs[contactsData[email].orgId] =
              await new Organization().getOrgById(contactsData[email].orgId);
          }
          entityValue = contactOrgs[contactsData[email].orgId]?.name;
        } else if (variable.key === "formUrl") {
          const userOrgId = await new User().getUserOrgId();
          const eventId = eventData.id;
          entityValue = `${window.location.origin}/auth/partner/${userOrgId}/${eventId}/${parentOrgId}`;
        }
        if (!entityValue && nullKeyValues[variable.key]) {
          entityValue = nullKeyValues[variable.key];
        }
        variables[email][`${variable.category}_${variable.key}`] =
          formatEmailVariable(entityValue) || "<no-entity-key-value>";
      } else {
        variables[email][variable.key] = "<no-contact>";
      }
    }
    for (const variable of categories["Booth"]) {
      if (contactsData[email]) {
        const parentOrg =
          Object.values(eventData.partners || {}).find(
            (partner) => partner.orgId === contactsData[email].orgId
          ) || {};
        const boothCollection = Object.values(parentOrg["booths"] || {});
        const boothId = boothCollection?.[0]?.accountboothId;
        const entity = eventData["booths"]?.find?.(
          (o) => o.id === boothId
        );
        let entityValue = entity?.[variable.key];
        if (variable.key === "deliverables") {
          entityValue = partnerDeliverables.reduce((mapArr, deliv) => {
            if (
              deliv.partnerCollection === "booths" &&
              deliv.entityId === boothId
            ) {
              mapArr.push({
                name: `${deliv.name}<br>Quantity: ${
                  deliv.quantity || 0
                }<br>Rate: ${deliv.rate || 0}<br>`,
              });
            }
            return mapArr;
          }, []);
        }
        if (!entityValue && nullKeyValues[variable.key]) {
          entityValue = nullKeyValues[variable.key];
        }
        variables[email][`${variable.category}_${variable.key}`] =
          formatEmailVariable(entityValue) || "<no-entity-key-value>";
      } else {
        variables[email][variable.key] = "<no-contact>";
      }
    }
    for (const variable of categories["Deliverable"]) {
      if (contactsData[email]) {
        const deliverableKey = variable.key.split("-", 2)[0];
        const deliverableId = variable.key.slice(variable.key.indexOf("-"));
        const partnerDeliverable = partnerDeliverables.find(
          (d) => d.id === deliverableId
        );
        let entityValue = partnerDeliverable?.[deliverableKey] || variable.value;
        if (!entityValue && nullKeyValues[deliverableKey]) {
          entityValue = nullKeyValues[deliverableKey];
        }
        variables[email][`${variable.category}_${variable.key}`] =
          formatEmailVariable(entityValue) || "<no-entity-key-value>";
      } else {
        variables[email][variable.key] = "<no-contact>";
      }
    }
  }
  return variables;
};

export default userEmailVariables;
