import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Container,
  Row,
  Col,
  Input,
  Label,
  Button,
  CardSubtitle,
} from "reactstrap";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { storage } from "firebase-client/firebase-client";
import EventsPartner from "../../models/database/EventsPartner";
import Event from "models/database/Event";
import { connect } from "react-redux";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const PartnerFormPage = (props) => {
  const [filePath, setFilePath] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [logos, setLogos] = useState([]);
  const [files, setFiles] = useState([]);
  const [logoPaths, setLogoPaths] = useState([]);
  const [filePaths, setFilePaths] = useState([]);
  const [logosProgress, setLogosProgress] = useState(1);
  const [filesProgress, setFilesProgress] = useState(1);
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [eventName, setEventName] = useState("");

  useEffect(() => {
    setFilePath(`partners/${props.match.params.partnerId}/`);
    (async function() {
      if (!props.user && !localStorage.getItem("userOrg")) {
        await props.firebase.auth().signInAnonymously();
      }
      const { orgId, eventId, partnerId } = props.match.params;
      const eventData = await new Event().getById(eventId);
      setEventName(eventData?.name || "");
      const formData = await new EventsPartner().getFormData(orgId, eventId, partnerId);
      if (!formData) return;
      const logosData = (formData.logoPaths || "").split(",").filter((x) => x);
      const filesData = (formData.filePaths || "").split(",").filter((x) => x);
      setCompanyDescription(formData.companyDescription);
      setLogos(mapPathsToObjects(logosData));
      setLogoPaths(logosData);
      setFiles(mapPathsToObjects(filesData));
      setFilePaths(filesData);
    })();
  }, []);

  useEffect(() => {
    setIsSubmitReady((logosProgress === 1 && filesProgress === 1));
  }, [logosProgress, filesProgress, companyDescription]);

  const mapPathsToObjects = (paths) => {
    return paths.map((path) => ({
      source: path,
      options: {
        type: "local",
      },
    }))
  }

  const getMetadata = async (path) => {
    const metadata = await storage
      .ref(path)
      .getMetadata();
    return metadata;
  }

  const getDownloadUrl = async (path, asBlob) => {
    const url = await storage
      .ref(path)
      .getDownloadURL();
    if (!asBlob) return url;
    const response = await fetch(url);
    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);
    return objectUrl;
  }

  const downloadFile = async (path) => {
    const url = await getDownloadUrl(path, true);
    const metadata = await getMetadata(path);
    const linkElement = document.createElement("a");
    linkElement.style.display = "none";
    linkElement.href = url;
    linkElement.download = metadata.name || "Untitled";
    document.body.appendChild(linkElement);
    linkElement.click();
    window.URL.revokeObjectURL(url);
  }

  const handleUploadProgress = (file, progress, setProgress, setPaths) => {
    setProgress(progress);
    if (progress !== 1) return;
    setPaths((prevState) => [...prevState, filePath + file.filename]);
  };

  const handleDownload = (paths) => {
    for (const path of paths) {
      downloadFile(path);
    }
  }

  const handleSubmitForm = async () => {
    if (!isSubmitReady) return;
    const payload = {
      formData: {
        companyDescription, 
        logoPaths: logoPaths.join(),
        filePaths: filePaths.join(),
      }
    };
    const { orgId, eventId, partnerId } = props.match.params;
    const response = await new EventsPartner()
      .updateFormData(payload, orgId, eventId, partnerId);
    if (response) setIsSubmitReady(false);
  }

  const server = new EventsPartner().storageServer(filePath);

  return (
    <div id="partner-form-page" className="content">
      <img className="auth-logo-top" src={require("assets/img/arcat-logo-full.png").default} />
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="card-register card-white">
              <CardHeader>
                <CardTitle
                  className="arcat text-primary pt-4 pl-3"
                  tag="h1"
                >
                  Partner form
                </CardTitle>
                <CardSubtitle
                  className="arcat text-primary pt-3 pl-3"
                  tag="h3"
                >
                  {eventName}
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmitForm}>
                  <Label for="companyDescription">Company Description</Label>
                  <Input
                    id="companyDescription"
                    className="arcat textarea mb-3"
                    placeholder="Description of the company"
                    rows="5"
                    type="textarea"
                    value={companyDescription}
                    onChange={(e) => setCompanyDescription(e.target.value)}
                  />
                  <Label for="companyLogo">
                    Company Logo
                    <Button 
                      disabled={!logoPaths.length} 
                      className="ml-2" 
                      size="sm" 
                      color="info" 
                      title="Download"
                      onClick={() => handleDownload(logoPaths)}
                    >
                      <i className="tim-icons icon-cloud-download-93" />
                    </Button>
                  </Label>
                  <FilePond
                    id="companyLogo"
                    files={logos}
                    onupdatefiles={setLogos}
                    allowMultiple={true}
                    maxFiles={3}
                    server={server}
                    onprocessfileprogress={(file, progress) => handleUploadProgress(file, progress, setLogosProgress, setLogoPaths)}
                    name="files"
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
                  <Label for="otherFiles">
                    Other Documents
                    <Button 
                      disabled={!filePaths.length} 
                      className="ml-2" 
                      size="sm" 
                      color="info" 
                      title="Download"
                      onClick={() => handleDownload(filePaths)}
                    >
                      <i className="tim-icons icon-cloud-download-93" />
                    </Button>
                  </Label>
                  <FilePond
                    id="otherFiles"
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={10}
                    server={server}
                    onprocessfileprogress={(file, progress) => handleUploadProgress(file, progress, setFilesProgress, setFilePaths)}
                    name="files"
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
                  <Button 
                    disabled={!isSubmitReady} 
                    size="lg" 
                    color="primary" 
                    onClick={handleSubmitForm}
                  >
                    Save
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  firebase: state.firebase,
});

export default connect(mapStateToProps)(PartnerFormPage);
