
const initialState = {
  user: null,
  userOrg: null,
  firebase: null,
  eventData: null,
  orgData: null,
  orgUsers: null,
  isEventEditable: false,
};

export default initialState;
