import { TableColumn } from "./TableColumn";
import { TableRow } from "./TableRow";

export class TableFormatter {
  constructor() {
    this.columnHeadings = [];
    this.rows = {};
  }

  init(columnsAndValues, filterMethod) {
    for (const key in columnsAndValues) {
      const value = columnsAndValues[key];

      const text = (value && value.cellText) || value;

      this.rows = Object.assign(this.rows, new TableRow(key, text).metaData());

      const width = (value && value.width) || undefined;
      const filter = (value && value.filter) || { type: 'default', isFilterable: true };

      this.columnHeadings.push(new TableColumn(key, width, true, filter, filterMethod).metaData());
    }
    return this;
  }

  tableColumnHeadings(hasNoActions = false) {
    if (hasNoActions) return this.columnHeadings;
    const actionsHeader = new TableColumn(
      "Actions",
      120,
      false,
      { isFilterable: false}
    ).metaData();
    return [...this.columnHeadings, actionsHeader];
  }

  tableRowFormat(index, model) {
    const row = {
      index: index,
      ...this.rows,
      itemClass: model,
    };
    return row;
  }
}
