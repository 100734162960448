import JSZip from "jszip";
import downloadFile from "downloadjs";

const createZip = async (files, name = "Untitled.zip") => {
  const zip = new JSZip();
  for (const file of files) {
    zip.file(file.name || "Untitled", file.blob);
  }
  const data = await zip.generateAsync({ type: "blob" });
  return {
    data,
    download: () => downloadFile(data, name, "application/zip")
  };
}

export default createZip;
