import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { tz } from "moment-timezone";
import ContactsDropdown from "layouts/Plan/shared/ContactsDropdown";
import formatContactLabel from "utilities/methods/formatContactLabel";
import TimezoneSelect from "react-timezone-select";

const AgendaForm = (props) => {
  const [isFormPopulated, setIsFormPoplated] = useState(false);
  const [timezone, setTimezone] = useState(tz.guess());
  const [agenda, setAgenda] = useState({ 
    startTime: "", 
    endTime: "",
    activity: "",
    location: "",
    speaker: undefined,
   });

  useEffect(() => {
    if (isFormPopulated || !props.agenda?.data) return;
    const speaker = props.agenda.data.speaker || {};
    setAgenda({
      ...props.agenda.data,
      startTime: moment(props.agenda.data.startTime),
      endTime: moment(props.agenda.data.endTime),
      speaker: {
        ...speaker,
        label: formatContactLabel(speaker),
      }
    });
    setIsFormPoplated(true);
  }, [props.agenda]);

  useEffect(() => {
    props.setAgenda(agenda);
    props.setIsSubmitReady(formIsValid());
  }, [agenda]);

  const formIsValid = () => (
    agenda.startTime?.isValid?.() &&
    agenda.endTime?.isValid?.()
  );

  const agendaChangeHandler = (value, key) => {
    setAgenda((prevState) => ({
      ...prevState,
      [key]: value
    }));
  }

  const convertTimezone = (offset, key) => {
    if (!agenda[key]?.isValid?.()) return;
    agendaChangeHandler(moment(agenda[key]).utcOffset(offset), key);
  }

  const timezoneChangeHandler = (time) => {
    const value = time?.value || tz.guess();
    const offset = tz(value).utcOffset();
    convertTimezone(offset, "startTime");
    convertTimezone(offset, "endTime");
    setTimezone(value);
  }

  return (
    <Fragment>
      <Label>Agenda</Label>
      <Row>
        <Label md="2">Start Time</Label>
        <Col md="3">
          <FormGroup>
            <ReactDatetime
              inputProps={{ className: "form-control", placeholder: "Start Time" }}
              value={agenda.startTime || ""}
              onChange={(date) => agendaChangeHandler(date, "startTime")}
              dateFormat={false}
              viewMode="time"
              closeOnSelect
            />
          </FormGroup>
        </Col>
        <Label md="2" className="pl-0">End Time</Label>
        <Col md="3">
          <FormGroup>
            <ReactDatetime
              inputProps={{ className: "form-control", placeholder: "End Time" }}
              value={agenda.endTime || ""}
              onChange={(date) => agendaChangeHandler(date, "endTime")}
              dateFormat={false}
              viewMode="time"
              closeOnSelect
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label md="2">Timezone</Label>
        <Col md="9">
          <FormGroup>
            <TimezoneSelect
              value={timezone}
              onChange={timezoneChangeHandler}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label md="2">Activity</Label>
        <Col md="9">
          <FormGroup>
            <Input
              type="text"
              value={agenda.activity || ""}
              onChange={(e) => agendaChangeHandler(e.target.value, "activity")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label md="2">Location</Label>
        <Col md="9">
          <FormGroup>
            <Input
              type="text"
              value={agenda.location || ""}
              onChange={(e) => agendaChangeHandler(e.target.value, "location")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label md="2">Speaker</Label>
        <Col md="9">
          <FormGroup>
            <ContactsDropdown 
              callback={(e) => agendaChangeHandler(e.itemClass, "speaker")}
              defaultValue={agenda.speaker}
              filterByType="Speaker"
              isNotPadded
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(AgendaForm);
