import React, { useState, useEffect } from "react";
import { Button, Label } from "reactstrap";

import ModalContainer from "../../shared/ModalContainer";
import NewEmailTemplateForm from "./NewEmailTemplateForm";

const NewEmailTemplateModal = (props) => {

    const [isSubmitReady, setIsSubmitReady] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailTemplateFormData, setEmailTemplateFormData] = useState({});

    const toggle = () => {
        setIsModalOpen(!isModalOpen);
    }

    const OpenModalButton = (
        <Button color="info" size="md" onClick={toggle}>
            + New Email Template
        </Button>
    );

    const handleEmailTemplateFormData = data => {
        setEmailTemplateFormData(data);
        setIsSubmitReady(isFormValid(data));
    }

    const isFormValid = data => {
        const isValid = Boolean(
            data.html && data.subject && data.name
        );
        return isValid;
    }

    const submitCallback = () => {
        const data = {
            emailTemplate: emailTemplateFormData
        };
        props.submitCallback(data);
        toggle();
    };

    return (
        <ModalContainer
            buttonLabel="+ Add Email Template"
            modalTitle="Add New Email Template"
            modalClassName="modal-long"
            isModalOpen={isModalOpen}
            toggleCallBack={toggle}
            submitCallback={submitCallback}
            button={OpenModalButton}
            isSubmitDisabled={!isSubmitReady}
        >
            <NewEmailTemplateForm 
                setData={handleEmailTemplateFormData} 
            />
        </ModalContainer>
    );
};

export default NewEmailTemplateModal;
