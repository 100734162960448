/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import SectionContainer from "../../../SectionContainer";
import NewEventPartnerModal from "./NewEventPartnerModal";
import EventPartnerTable from "./EventPartnerTable";
import EventPartner from "../../../../../../models/database/EventsPartner";
import Sponsorship from "models/database/Sponsorship";
import Booth from "models/database/Booth";
import { connect } from "react-redux";
import { isNull, isNumber, isUndefined } from "lodash";
import Organization from "models/database/Organization";
import updateReduxData from "utilities/methods/updateReduxData";
import Contact from "models/database/Contact";
import Email from "models/database/Email";
import MailgunEmail from "models/backend/MailgunEmail";
import userEmailVariables from "utilities/methods/userEmailVariables";

class EventPartnerSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue", //"#005BAA",
      sidebarMini: true,
      newEventPartner: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async handleEntityCreate(data, entityClass, collection) {
    await new entityClass().update(
      data,
      this.props.event,
      null,
      null,
      collection
    );
  }

  findItem(arr, id) {
    return arr.find((val) => val.id == id);
  }

  incrementReservedCount(obj) {
    if (obj) {
      let { reserved } = obj;
      let hasReserved = this.validateItem(reserved);
      if (hasReserved >= 0) {
        obj["reserved"] = hasReserved + 1;
      }
      return obj;
    }
  }

  validateItem(item) {
    if (isUndefined(item) || !isNumber(item) || isNaN(item) || isNull(item))
      return 0;
    return item;
  }

  async submitNewEventPartnerHandler(eventPartnerProps) {
    let event = await this.props.event;
    let {
      orgId,
      boothNumber,
      totalSpend,
      booths,
      salesPerson,
      contractReceived,
      notes,
      eventContacts,
      regCode,
      sponsorships,
      carteSponsorships,
    } = eventPartnerProps;

    await new EventPartner().createNew(event, {
      orgId,
      boothNumber,
      totalSpend,
      salesPerson,
      contractReceived,
      notes,
      eventContacts,
      regCode,
      booths,
      sponsorships,
    });

    const orgData = await new Organization().getOrgById(orgId);
    const eventEmailIds = Object.keys(this.props.eventData.emails || {});
    const emails = await new Email().getAll(eventEmailIds, this.props.eventData.id);
    const autoSendEmails = emails.filter((email) => email?.itemClass?.autoSend);
    const contacts = await new Contact().getAllEntities(this.props.orgData);
    const orgContacts = contacts.filter((contact) => contact?.itemClass?.orgId === orgId);
    const contactEmails = orgContacts.map((contact) => contact.itemClass.email || "");
    if (contactEmails.length) {
      const contactsData = orgContacts.reduce((mapObj, contact) => {
        if (contact?.itemClass?.email) {
          mapObj[contact.itemClass.email] = contact.itemClass;
        }
        return mapObj;
      }, {});
      for (const email of autoSendEmails) {
        const emailFormData = {
          contactsData,
          usedVariables: email.itemClass?.usedVariables,
        };
        const userVariables = await userEmailVariables(contactEmails, emailFormData, this.props.eventData);
        const sharedVariables = Object.values(email.itemClass?.emailForm?.["recipient-variables"] || {})[0] || [];
        const variables = orgContacts.reduce((mapObj, contact) => {
          if (contact.itemClass.email) {
            mapObj[contact.itemClass.email] = { 
              ...sharedVariables,
              ...userVariables[contact.itemClass.email] 
            };
          }
          return mapObj;
        }, {});
        const emailForm = {
          ...email.itemClass?.emailForm,
          cc: "",
          bcc: "",
          to: contactEmails.join(),
          "recipient-variables": variables
        };
        await new MailgunEmail().createWithObject(emailForm).create();
      }
    }
    const orgEventIds = orgData?.eventIds || {};
    const eventIds = { ...orgEventIds, [event.id]: event.id };
    await new Organization(orgId).update({ eventIds });

    const eventBooths = this.props.eventData?.booths;
    const eventSponsors = this.props.eventData?.sponsorships;
    const partnerBoothIds = Object.keys(booths);
    const partnerSponsorIds = Object.keys(sponsorships);

    if (this.mounted) {
      this.setState({ newEventPartner: !this.state.newEventPartner });
    }

    if (partnerBoothIds.length) {
      let incrementedBooth = this.incrementReservedCount(
        this.findItem(eventBooths, partnerBoothIds[0])
      );
      await new Booth().updateSingleEntity(incrementedBooth, event.id);
    }
    
    for (const sponsorId of partnerSponsorIds) {
      let incrementedSponsor = this.incrementReservedCount(
        this.findItem(eventSponsors, sponsorId)
      );
      await new Sponsorship().updateSingleEntity(incrementedSponsor, event.id);
    }
    const eventData = await updateReduxData.event(event.code);
    await updateReduxData.eventContacts(eventData);
  }

  tableHeader = () => (
    <CardHeader>
      <Col className="d-flex justify-content-end">
        <NewEventPartnerModal
          event={this.props?.event}
          formSubmitCallBack={(data) => this.submitNewEventPartnerHandler(data)}
        />
      </Col>
    </CardHeader>
  );

  renderTable = () => (
    <Row>
      <Col md={12} className="mx-0 px-0">
        <SectionContainer>
          <div>
            {this.tableHeader()}
            <CardBody>
              <EventPartnerTable
                newRow={this.state.newEventPartner}
                event={this.props.event}
              />
            </CardBody>
          </div>
        </SectionContainer>
      </Col>
    </Row>
  );

  render() {
    return this.renderTable();
  }
}
const mapStateToProps = (state) => ({
  eventData: state.eventData,
  orgData: state.orgData,
  orgUsers: state.orgUsers,
});

export default connect(mapStateToProps)(EventPartnerSection);
