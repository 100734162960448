import Contacts from "models/database/Contacts";
import CustomerOrgUsers from "models/database/CustomerOrgUsers";
import Event from "models/database/Event";
import Organizations from "models/database/Organizations";
import User from "models/database/User";
import store from "redux-store/store";

const updateReduxData = {
  async event(eventCode) {
    const eventData = await new Event().getSingleEvent(eventCode);
    store.dispatch({ type: "setEventData", data: eventData });
    return eventData;
  },
  async eventContacts(eventData) {
    let contacts = [];
    if (eventData.partners) {
      contacts = await new Contacts().getAllEntities(false);
      const eventPartnerIds = Object.values(eventData.partners).map(
        (partner) => partner.orgId
      );
      contacts = contacts.filter(
        (contact) => contact.orgId && eventPartnerIds.includes(contact.orgId)
      );
    }
    store.dispatch({ type: "setEventContacts", data: contacts });
    return contacts;
  },
  async orgUsers() {
    const users = await new CustomerOrgUsers().getAllEntities(false);
    const usersData = await users.map(async ({ usersId }) => {
      if (!usersId) return null;
      const userObj = await new User().get(usersId);
      return userObj;
    }).filter((x) => x);
    store.dispatch({ type: "setOrgUsers", data: usersData });
    return usersData;
  },
  async orgData() {
    const orgData = await new Organizations().getAllEntities(false);
    store.dispatch({ type: "setOrgData", data: orgData });
    return orgData;
  }
};

export default updateReduxData;
