import CustomLabel from "layouts/Plan/shared/CustomLabel";
import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Input, Form, Label } from "reactstrap";

import ModalContainer from "../../../../shared/ModalContainer";
import SponsorshipsDropdown from "../../../../shared/SponsorshipsDropdown";

const SponsorshipsModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sponsorships, setSponsorships] = useState([{ index: 0 }]);

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  const toggle = () => {
    if (isModalOpen) {
      setSponsorships([{ index: 0 }]);
    }
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = (
    <Button color="info" size="md" onClick={toggle} disabled={!props.isEventEditable}>
      + Add Sponsorship
    </Button>
  );

  const sponsorshipChangeHandler = (sponsorship, value, type) => {
    setSponsorships((prevState) => {
      sponsorship[type] = parseInt(value);
      const newState = [...prevState];
      newState[sponsorship.index] = sponsorship;
      return newState;
    });
  };

  const addSponsorship = () => {
    setSponsorships([...sponsorships, { index: sponsorships.length }]);
  };

  const sponsorshipsDropdownCallback = (dropdownData) => {
    setSponsorships((prevState) => {
      const newState = [...prevState];
      newState[dropdownData.index] = {
        ...prevState[dropdownData.index],
        ...dropdownData,
      };
      return newState;
    });
  };

  const formIsValid = () => {
    const isValid = !!sponsorships.find(
      (sponsorship) =>
        sponsorship.id &&
        Number.isInteger(sponsorship.quantity) &&
        Number.isInteger(sponsorship.rate)
    );
    return isValid;
  };

  const arrayToObject = (arr) => {
    return arr.reduce((map, obj) => {
      if (obj.id) {
        if (Object.values(map).find((o) => o.id === obj.id)) return map;
        if (obj.label) {
          obj.name = `${obj.label}`;
          obj.reserved = 0;
          delete obj.label;
        }
        map[obj.id] = obj;
      }
      return map;
    }, {});
  };

  const submitCallback = () => {
    if (!formIsValid()) return;
    const data = {
      sponsorships: arrayToObject(sponsorships),
    };
    props.submitCallback(data);
    toggle();
  };

  const sponsorshipDropdowns = sponsorships.map((sponsorship, index) => {
    return (
      <Fragment key={index}>
        <Row className="mb-2">
          <SponsorshipsDropdown
            callback={sponsorshipsDropdownCallback}
            index={index}
          />
          {sponsorship.label && (
            <Row className="pl-3 mt-2 pr-3">
              <Col md="6" className="m-0 p-0">
                <Input
                  type="number"
                  step="1"
                  placeholder="Quantity *"
                  onChange={(e) =>
                    sponsorshipChangeHandler(
                      sponsorship,
                      e.target.value,
                      "quantity"
                    )
                  }
                />
              </Col>
              <Col md="6" className="m-0 pl-2">
                <Input
                  type="number"
                  step="1"
                  placeholder="Rate"
                  onChange={(e) =>
                    sponsorshipChangeHandler(
                      sponsorship,
                      e.target.value,
                      "rate"
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Row>
        {sponsorship.label && index + 1 === sponsorships.length && (
          <Button
            className="mt-3"
            size="md"
            onClick={addSponsorship}
            title="Add Sponsorship"
          >
            + Add Sponsorship
          </Button>
        )}
      </Fragment>
    );
  });

  const requiredFieldsRow = (
    <Row className="pt-3">
      <Col>
        <CustomLabel label="Required Fields" required isInverted />
      </Col>
    </Row>
  );

  return (
    <ModalContainer
      buttonLabel="+ Add Sponsorships"
      modalTitle="Add New Sponsorships"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      button={OpenModalButton}
      isSubmitDisabled={!isSubmitReady}
    >
      <Label className="ml-1 mb-0 mt-0">Sponsorships</Label>
      {sponsorshipDropdowns}
      {requiredFieldsRow}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(SponsorshipsModal);
