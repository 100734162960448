import AccountSponsorship from "./AccountSponsorship";

import { customerOrgsFirebaseUrl } from "utilities/constants/urls";
import PersistentBase from "./PersistentBase";

export default class AccountSponsorships extends PersistentBase {

    constructor(id, name, description, type, deliverables) {
        super();
        this.id             = id;
        this.name           = name;
        this.description    = description;
        this.deliverables   = deliverables;
        this.type           = type;
        this.collectionPath = "account-sponsorships";
        this.dataModel      = AccountSponsorship;
        this.setRestUrl();
    }

    setRestUrl() {
        this.url = customerOrgsFirebaseUrl;
        this.jsonUrl = customerOrgsFirebaseUrl;
    }

}
