import React, { useEffect, useState } from "react";
import IconButton from "./IconButton";
import classNames from "classnames";
import CrudActionsTable from "./CrudActionsTable";
import EditDeliverablesModal from "../sections/events/eventPage/deliverables/EditDeliverablesModal";
import { connect } from "react-redux";

const defaultRows = 5;

const ExpandableRows = (props) => {
  const [isExpanded, setIsExpanded] = useState(!!props.startExpanded);
  const [data, setData] = useState([]);
  const [padding, setPadding] = useState(0);
  
  const rowHeight = (props.hasNoActions || !props.isEventEditable) ? 47 : 64;

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setPadding(data.length > defaultRows ? 200 : 134);
  }, [data]);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className={classNames("arcat", "expandable-rows", isExpanded && "expanded")}>
      {!!data.length && (
        <IconButton
          className="expand-icon-button"
          onClick={toggle}
          color="primary"
          icon="icon-minimal-right"
          tooltip={props.tooltip || "Expand"}
        />
      )}
      <span className="table-name" onClick={toggle}>
        {props.name || "-"}
      </span>
      <div
        className="table-container" 
        style={{ maxHeight: isExpanded ? `${data.length * rowHeight + padding}px` : "0px" }}
      >
        <CrudActionsTable
          data={data}
          dataModel={props.dataModel}
          defaultPageSize={defaultRows}
          modalName="deliverable"
          editModal={EditDeliverablesModal}
          hasNoActions={props.hasNoActions || !props.isEventEditable}
        />
      </div>
      
    </div>
  );
}

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(ExpandableRows);
