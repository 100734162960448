import React, { useState, useEffect } from "react";
import { Row, Col, Input, Label } from "reactstrap";
import ModalContainer from "layouts/Plan/shared/ModalContainer";
import AddressInputForm from "layouts/Plan/shared/forms/AddressInputForm";
import { defaultContractualUsers } from "utilities/constants/contractual-users";
import validateUrl from "utilities/methods/validateUrl";
// import PhoneInput from "react-phone-input-2";

const EditCustomerOrgModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [URL, setURL] = useState("");
  const [contractualUsers, setContractualUsers] = useState(defaultContractualUsers);
  const [address, setAddress] = useState({});
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  }, [URL, address, name, phone]);

  useEffect(() => {
    const {
      URL, 
      contractualUsers,
      name, 
      phone, 
      address1, 
      address2, 
      city, 
      state, 
      zip, 
      country
    } = props.item?.data?.customerMetaData;
    setURL(validateUrl(URL));
    setContractualUsers(contractualUsers || defaultContractualUsers)
    setName(name);
    setPhone(phone);
    setAddress({
      address1, 
      address2, 
      city, 
      state,
      zip, 
      country
    });
  }, [props.item]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };
  
  const formIsValid = () => !!(
    URL && contractualUsers && name && address.address1
  );
  
  const submitCallback = () => {
    if (!formIsValid()) return;
    toggle();
    const payload = {
      customerMetaData: {
        name,
        URL,
        contractualUsers,
        ...address
      },
      orgId: props.item?.data?.id
    };
    props.formSubmitCallBack(payload, props.item.index);
  };

  const orgInputs = (
    <Row className="pl-3 pt-2 pb-2 pr-3">
      <Col md="6" className="p-0 pl-3 pr-3">
        <Label>Organization Name</Label>
        <Input
          type="text"
          placeholder="Name"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
      <Col md="6" className="p-0 pl-3 pr-3">
        <Label>Company URL</Label>
        <Input
          type="url"
          placeholder="URL"
          value={URL || ""}
          onChange={(e) => setURL(e.target.value)}
          onBlur={(e) => setURL(validateUrl(e.target?.value))}
        />
      </Col>
      <Col md="12" className="p-0 pl-3 pr-3 mt-2">
        <Label>Contractual Users</Label>
        <Input
          type="number"
          placeholder="Contractual Users"
          value={contractualUsers || ""}
          onChange={(e) => setContractualUsers(e.target.value)}
          required
        />
      </Col>
      <Col md="12" className="mt-3">
        <Label>Address</Label>
        <AddressInputForm
          withLabel={false}
          colSize={12}
          labelSize={2}
          getAddressFormValue={setAddress}
          sameAddress={address}
          isSameAddress
          blurOnUpdate={false}
        />
      </Col>
      {/* <Col md="12" className="p-0 mt-3 pl-3 pr-3">
        <Label>Phone</Label>
        <PhoneInput
          inputStyle={{
            borderColor: "#e0e1e4",
            borderRadius: 8,
            height: "5.5vh",
            width: "100%",
            paddingLeft: 52,
          }}
          country={"us"}
          value={phone}
          onChange={setPhone}
        />
      </Col> */}
    </Row>
  );

  return (
    <ModalContainer
      buttonLabel="+ Edit Organization"
      modalTitle="Edit Organization"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      isSubmitDisabled={!isSubmitReady}
    >
      <Label className="ml-1 mb-0 mt-0">Organization</Label>
      {orgInputs}
    </ModalContainer>
  );
};

export default EditCustomerOrgModal;
