import React, { Fragment, useState, useEffect } from "react";
import { Input, Label, Row, Col, FormGroup } from "reactstrap";
import AddressInputForm from "./forms/AddressInputForm";
import ContactInfoForm from "./forms/ContactInfoForm";

function OrganizationContacts({ getOrgContactForm, addressForm }) {
  const [contactAddressCheckbox, setContactAddressCheckbox] = useState(null);
  const [contactFormValues, setContactFormValues] = useState({});
  const [addressFormValues, setAddressFormValues] = useState();
  const [updateAddress, setUpdateAddress] = useState(false);

  useEffect(() => {
    setUpdateAddress(!updateAddress);
  }, [addressForm])

  useEffect(() => {
    let contactFormValue = {
      ...addressFormValues,
      ...contactFormValues,
      contactAddressCheckbox,
    };
    getOrgContactForm &&
      getOrgContactForm({
        contactFormValue,
      }); // to send all the input fields value to the parent component
  }, [addressFormValues, contactFormValues]);

  const sameAddressCheckbox = () => (
    <FormGroup row>
      <Col md={12}>
        <Input
          type="checkbox"
          onClick={() => setContactAddressCheckbox(!contactAddressCheckbox)}
        />
        <Label>Contact Address Same as Organization Address checkbox</Label>
      </Col>
    </FormGroup>
  );

  const composeContactForm = (values) => {
    const modifiedValues = {...values, fullName: `${values.firstName} ${values.lastName}`}
    setContactFormValues(modifiedValues)
  }

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <ContactInfoForm
            getContactFormValue={(val) => composeContactForm(val)}
            isOrgName={false}
          />
          {sameAddressCheckbox()}
          <AddressInputForm
            getAddressFormValue={(val) => {
              setAddressFormValues(val);
            }}
            sameAddress={addressForm}
            isSameAddress={contactAddressCheckbox}
            updateAddress={updateAddress}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
export default React.memo(OrganizationContacts);
