import React from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Card,
    CardBody,
    Form,
} from "reactstrap";

const ModalContainer = (props) => {
    const handleSubmit = (ev) => {
        ev.preventDefault();
        props.submitCallback();
    };

    return (
        <div>
            {props.button}
            <Modal
                isOpen={props.isModalOpen}
                toggle={props.toggleCallBack}
                className={props.className}
                modalClassName={props.modalClassName}
                size="lg"
            >
                <ModalHeader toggle={props.toggleCallBack}>
                    {props.modalTitle}
                </ModalHeader>
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                    <ModalBody>
                        <Col md="12">
                            <Card>
                                <CardBody>{props.children}</CardBody>
                            </Card>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        {/* for download we need auto close modal so here is props */}
                        {!props.isAutoClose && (
                            <>
                                <Button
                                    color="secondary"
                                    onClick={props.toggleCallBack}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btn-fill"
                                    color="info"
                                    type="submit"
                                    disabled={props.isSubmitDisabled}
                                >
                                    {props.submitButtonText || "Save"}
                                </Button>
                            </>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default ModalContainer;
