import React from "react";
import BaseFeature from "./BaseFeature";

/**
 * @description ReactTable columns prop creator
 */
export class TableColumn extends BaseFeature {
  constructor(name, width, sortable, filterConfig, filterMethod) {
    super();
    this.Header = () => <div style={{ textAlign: "center" }}>{name}</div>;
    this.width = width;
    this.accessor = name.toLowerCase();
    this.sortable = sortable == undefined ? true : sortable;
    this.filterable = filterConfig.isFilterable;
    this.Filter = ({ column: {filterValue, preFilteredRows, setFilter}}) => {
      if (filterConfig.type === 'dropdown') {
        return this.filterDropDown(filterValue, filterMethod, name, filterConfig)
      } else {
        return this.defaultFilterInput(name, filterValue, filterMethod)
      }
    }
  }

  filterDropDown = (filterValue, filterMethod, name, filterConfig) => {
    const options = () => {
      const options = new Set();
      filterConfig.options.forEach(row => {
        options.add(row)
      });
      return [...options.values()]
    };

    // Render a multi-select box
    return (
        <select
            style={{width: '99%'}}
            value={filterValue}
            onChange={e => {
              filterMethod(e.target.value || undefined, name)
            }}
        >
          <option value="">All</option>
          {options().map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
          ))}
        </select>
    )

  };

  defaultFilterInput(name, filterValue, filterMethod) {
    return (
        <input
            value={filterValue}
            style={{width: '99%'}}
            placeholder={`Search ${name}`}
            onChange={ (e) => {
              filterMethod(e.target.value, name)
            }}
        />
    )
  }

  setWidth(width) {
    this.width = width;
    return this;
  }

  metaData() {
    return super.createWithObject();
  }
}
