import React from "react";
import ExpandableRows from "layouts/Plan/shared/ExpandableRows";
import PersistentBase from "./PersistentBase";
import Deliverable from "./Deliverable";
import { dateFormat } from "utilities/constants/date-format";
import moment from "moment";
import _ from "lodash";

/**
 * @description collection of event deliverables
 */
export default class DeliverableCollection extends PersistentBase {
  constructor(deliverables, name, description, deadline) {
    super();
    this.deliverables = deliverables;
    this.name = name;
    this.description = description;
    this.deadline = deadline;
    this.csvTitle = "Deliverable";
  }

  fromArray(arr) {
    const deliverablesObj = arr.reduce((mapObj, deliverable) => {
      if (!(deliverable.itemClass.id in mapObj)) {
        mapObj[deliverable.itemClass.id] = [];
      }
      mapObj[deliverable.itemClass.id].push(deliverable)
      return mapObj;
    }, {});
    return Object.values(deliverablesObj).map((deliverables, index) => {
      const path = "itemClass.itemClass.description";
      return new this.constructor().createWithObject({ 
        deliverables, 
        name: deliverables[0].itemClass.name,
        description: _.get(deliverables?.find((d) => _.get(d, path)), path, ""),
        deadline: _.get(deliverables?.find((d) => _.get(d, "itemClass.deadline")), "itemClass.deadline", ""),
      }).tableRowFormat(index);
    });
  }

  formatName() {
    const deadline = this.deadline ? `(${moment(this.deadline).format(dateFormat)})` : "";
    const separator = this.description || deadline ? " - " : "";
    return `${this.name}${separator}${this.description} ${deadline}`;
  }

  tableCsvData() {
    return {
      deliverable: this.name,
      quantity: this.deliverables?.length,
    };
  }

  tableColumnsAndValues() {
    return {
      deliverable: (
        <ExpandableRows 
          name={this.formatName()} 
          tooltip="Expand Deliverables"
          data={Object.values(this.deliverables || {})}
          dataModel={Deliverable}
        />
      ),
      quantity: {
        cellText: this.centerAlignDiv(this.deliverables?.length || "0"),
        width: 100,
      },
    };
  }
}
