import CustomerOrg from "./CustomerOrg";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl, customerOrgsFirebaseJsonUrl } from "../../utilities/constants/urls";

export default class CustomerOrgs extends PersistentBase {
    constructor() {
        super();
        this.dataModel = CustomerOrg;
        this.collectionPath = 'super';
        this.setRestUrl()
    }

    setRestUrl() {
        this.url = customerOrgsFirebaseUrl;
        this.jsonUrl = customerOrgsFirebaseJsonUrl;
    }
}
