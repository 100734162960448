import React, { Fragment } from "react";
import IconButton from "./IconButton";
import IconPencilButton from "./buttons/IconPencilButton";
import PropTypes from "prop-types";

const ActionButtonsColumn = (props) => {
  //@TODO add "favorite" backend functionality
  const heartButton = (key, id) => {
    return (
      <IconButton
        id={id}
        indexKey={key}
        onClick={() => {
          let obj = this.state.data.find((o) => o.id === key);
          alert(
            "You've clicked LIKE button on \n{ \nName: " +
              obj.name +
              ", \nposition: " +
              obj.position +
              ", \noffice: " +
              obj.office +
              ", \nage: " +
              obj.age +
              "\n}."
          );
        }}
        color="warning"
        icon="icon-heart-2"
        tooltip="Favorite"
      />
    );
  };
  const key = props.itemIndex
  return (
    <div className="actions-right">
      {/*{this.heartButton(key, 1)}{" "}*/}
      {props.hasDownloadAction && (
        <IconButton
          tooltipId={`${2}${key}`}
          indexKey={key}
          onClick={(itemIndex) => props.downloadHandler(itemIndex)}
          icon="icon-cloud-download-93"
          color="success"
          tooltip="Download"
        />
      )}
      {props.hasDuplicateAction && (
        <IconButton
          tooltipId={`${2}${key}`}
          indexKey={key}
          onClick={(itemIndex) => props.duplicateHandler(itemIndex)}
          icon="icon-spaceship"
          color="success"
          tooltip="Duplicate"
        />
      )}
      {props.hasEditAction && 
        <IconButton
          tooltipId={`${3}${key}`}
          indexKey={key}
          onClick={(itemIndex) => props.editHandler(itemIndex)}
          color="info"
          icon="icon-pencil"
          tooltip="Edit"
        />
      }
      <IconButton
        tooltipId={`${4}${key}`}
        indexKey={key}
        onClick={(itemIndex) => props.deleteHandler(itemIndex)}
        icon="icon-trash-simple"
        color="danger"
        tooltip="Delete"
      />{" "}
    </div>
  );
};
ActionButtonsColumn.prototype = {
  hasEditAction: PropTypes.bool,
  hasDuplicateAction: PropTypes.bool,
  hasDownloadAction: PropTypes.bool,
};

ActionButtonsColumn.defaultProps = {
  hasEditAction: true,
  hasDuplicateAction: true,
  hasDownloadAction: false,
};
export default ActionButtonsColumn;
