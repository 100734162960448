import EmailFormVariables from "layouts/Plan/shared/EmailFormVariables";
import RichTextEditor from "layouts/Plan/shared/RichTextEditor";
import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label } from "reactstrap";
import ModalContainer from "../../shared/ModalContainer";

const EditEmailTemplateModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [usedVariables, setUsedVariables] = useState([]);
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState(" ");
  const [htmlEditorValue, setHtmlEditorValue] = useState("");

  const changeHandler = (value, setValue) => {
    setValue(value);
  };

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  useEffect(() => {
    const { name, subject, html, usedVariables } = props.item.data;
    // console.log("usedVariables", usedVariables);
    setUsedVariables(usedVariables);
    setName(name);
    setSubject(subject);
    setHtml(html || "<p></p>");
    setHtmlEditorValue(html || "<p></p>");
  }, [props.item]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const formIsValid = () => {
    const isValid = Boolean(html && subject && name);
    return isValid;
  };

  const submitCallback = async () => {
    if (!formIsValid()) return;
    toggle();
    const payload = {
      name,
      subject,
      html,
      usedVariables,
    };
    props.formSubmitCallBack(payload, props.item.index);
  };

  const nameRow = (
    <Row className="mt-2 d-flex align-items-center">
      <Col md="1">
        <Label>Name</Label>
      </Col>
      <Col md="6">
        <Input
          type="text"
          value={name}
          onChange={(e) => changeHandler(e.target.value, setName)}
          required
        />
      </Col>
    </Row>
  );

  const subjectRow = (
    <Row className="mt-2 d-flex align-items-center">
      <Col md="1">
        <Label for="emailSubject">Subject</Label>
      </Col>
      <Col md="6">
        <Input
          id="emailSubject"
          type="text"
          value={subject}
          onChange={(e) => changeHandler(e.target.value, setSubject)}
          required
        />
      </Col>
    </Row>
  );

  const variablesRow = (
    <EmailFormVariables
      subject={subject}
      html={html}
      setUsedVariables={setUsedVariables}
    />
  );

  const bodyRow = (
    <Row className="mt-3">
      <Col md="12">
        <RichTextEditor
          value={htmlEditorValue}
          onBlur={(value) => changeHandler(value, setHtml)}
          initialValue={html}
          required
        />
      </Col>
    </Row>
  );

  return (
    <ModalContainer
      buttonLabel="+ Edit Email Template"
      modalTitle="Edit Email Template"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      isSubmitDisabled={!isSubmitReady}
    >
      {nameRow}
      {subjectRow}
      {variablesRow}
      {bodyRow}
    </ModalContainer>
  );
};

export default EditEmailTemplateModal;
