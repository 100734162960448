/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { connect } from "react-redux";
// react component for creating dynamic tables
import { CardBody, CardHeader, CardTitle } from "reactstrap";

import SectionContainer from "../SectionContainer";

//model that holds the methods DB interaction.
import Contact from "../../../../models/database/Contact";
import NewContactModal from "./newContactModal";
import ContactsTable from "./contactsTable";
import Organizations from "models/database/Organizations";

class ContactsSectionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue", //"#005BAA",
      sidebarMini: true,
      newContact: "",
    };
  }

  async componentDidMount() {
    if (!this.props.orgData) {
      const res = await new Organizations().getAllEntities();
      this.props.dispatch({ type: "setOrgData", data: res });
    }
  }

  async submitEventHandler(contactProps) {
    const contact = await new Contact().create(
      contactProps,
      this.props.orgData
    );
    this.setState((previousState) => {
      return { newContact: contact };
    });
  }

  tableHeader = () => (
    <CardHeader>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CardTitle tag="h4">Contacts</CardTitle>
        <NewContactModal
          formSubmitCallBack={(data) => this.submitEventHandler(data)}
        />
      </div>
    </CardHeader>
  );

  render() {
    return (
      <>
        <SectionContainer>
          <div>
            {this.tableHeader()}
            <CardBody>
              <ContactsTable newRow={this.state.newContact} />
            </CardBody>
          </div>
        </SectionContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orgData: state.orgData,
});

export default connect(mapStateToProps)(ContactsSectionContainer);
