import Booth from "models/database/Booth";
import Contact from "models/database/Contact";
import Deliverable from "models/database/Deliverable";
import Event from "models/database/Event";
import Organization from "models/database/Organization";
import Sponsorship from "models/database/Sponsorship";
import EventsPartner from "../../models/database/EventsPartner";

export const variableCategories = {
  Event: { ...new Event().emailVariables() },
  Partner: { ...new EventsPartner().emailVariables() },
  Contact: { ...new Contact().emailVariables() },
  Organization: { ...new Organization().emailVariables() },
  Sponsorship: { ...new Sponsorship().emailVariables(), hasSearchBar: true },
  Booth: { ...new Booth().emailVariables() },
  Deliverable: { ...new Deliverable().emailVariables(), hasSearchBar: true },
};
