import React from "react";
import PersistentBase from "./PersistentBase";

import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import StyledLink from "../../layouts/Plan/shared/StyledLink";

export default class AccountSponsorship extends PersistentBase {
  constructor(id, name, description, type, deliverables) {
    super();
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
    this.deliverables = deliverables;
    this.csvTitle = "Account Sponsorship";
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/account-sponsorships`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/account-sponsorships.json`;
  }

  metaData() {
    return {
      name: this.name,
      type: this.type,
      description: this.description,
      deliverables: this.deliverables,
    };
  }

  async update(payload) {
    const updatedEntity = this.createWithObject(payload);
    if (!this.url) await this.setRestUrl();
    const url = await super.appendAuth(super.buildUrl(this.url, this.id));
    super.update(url, updatedEntity.metaData());
    return this;
  }

  async updateDeliverables(payload, sponsorshipId) {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/account-sponsorships/${sponsorshipId}/deliverables`;
    const url = await super.appendAuth(super.buildUrl(this.url, this.id));
    let res = super.update(url, payload);
    return res;
  }

  tableColumnsAndValues() {
    const LinkTo = {
      pathname: `/plan/offerings/sponsorship/${this.name}`,
      state: { sponsorship: this },
    };
    return {
      sponsorship: <StyledLink to={LinkTo}>{this.name}</StyledLink>,
      description: { cellText: this.description || " ", width: 100 },
      type: { cellText: this.centerAlignDiv(this.type) || " ", width: 100 },
    };
  }
}
