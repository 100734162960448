import React from "react";
import { CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import SectionContainer from "../../../SectionContainer";
import DocumentsTable from "./DocumentsTable";
import NewDocumentModal from "./NewDocumentModal";

class DocumentsSectionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue", //"#005BAA",
      sidebarMini: true,
      newDocument: "",
    };
  }

  submitEventHandler(document) {
    this.setState((previousState) => {
      return { newDocument: document };
    });
  }

  tableHeader = () => (
    <CardHeader>
      <Col className="d-flex justify-content-end">
        <NewDocumentModal
          formSubmitCallBack={(data) => this.submitEventHandler(data)}
        />
      </Col>
    </CardHeader>
  );

  render() {
    return (
      <Row>
        <Col className="px-0">
          <SectionContainer>
            <div>
              {this.tableHeader()}
              <CardBody>
                <DocumentsTable
                  event={this?.props?.event}
                  newRow={this.state.newDocument}
                />
              </CardBody>
            </div>
          </SectionContainer>
        </Col>
      </Row>
    );
  }
}

export default DocumentsSectionContainer;
