
export const registerMarketingBullets = [
  {
    title: 'Ditch. Those. Spreadsheets.',
    description: 'Leverage custom workflows to manage your sponsor deliverables more efficiently. ArcatXP frees up your team to create strategic value and focus on profitability.',
    icon: 'cloud-upload-94',
    iconColor: 'warning'
  },
  {
    title: 'Communicate with Confidence',
    description: 'Our email templates make keeping in touch with your clients a breeze. Automate and personalize reminders to sponsors in seconds so they never miss a deadline.',
    icon: 'check-2',
    iconColor: 'primary'
  },
  {
    title: 'Scale Seamlessly',
    description: 'Whether it’s one more sponsor, or a whole new show, ArcatXP is built to handle volume and complexity.  Now you can increase revenue instead of headcount.',
    icon: 'bulb-63',
    iconColor: 'info'
  }
];
