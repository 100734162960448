import Event from "./Event";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "utilities/constants/urls";

export default class Events extends PersistentBase {
  constructor() {
    super();
    this.collectionPath = "events";
    this.dataModel = Event;
    this.setRestUrl(); // eventFirebaseUrl doesn't seem to be loaded when declaring the normal way
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl;
    this.jsonUrl = customerOrgsFirebaseUrl;
  }
}
