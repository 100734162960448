import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";

const defaultConfig = {
  buttons: [
    "source",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "superscript",
    "subscript",
    "ul",
    "ol",
    "indent",
    "outdent",
    "left",
    "font",
    "fontsize",
    "paragraph",
    "brush",
    "image",
    "video",
    "file",
    "cut",
    "copy",
    "paste",
    "selectall",
    "hr",
    "table",
    "link",
    "symbol",
    "undo",
    "redo",
    "find",
    "preview",
    "print",
  ],
  toolbarAdaptive: false,
  readonly: false,
  minHeight: 400,
};

/**
 * Renders a RichTextEditor component
 * @param props
 * @param {function} props.onBlur Called on loss of focus, passes editor content
 * @param {function} [props.onChange] Called onChange, passes editor content.
 * For performance reasons, this should be used carefully (or not at all).
 * @param {string} [props.initialValue] Initial editor value
 * @param {object} [props.config] Editor config [{@link https://xdsoft.net/jodit/doc/options/|Docs}]
 * @param {boolean} [props.required] Require non-empty editor value for parent form submit
 * @param {number} [props.tabIndex] Tab index of the editor element
 */
const RichTextEditor = (props) => {
  const [value, setValue] = useState(props.value);
  const editor = useRef(null);

  const handleBlur = (html) => {
    props.onBlur(html);
    setValue(html);
  };

  useEffect(() => {
    if (props.required) {
      editor.current.required = true;
    }
    editor.current.classList.add("jodit-input-elem");
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <>
      <JoditEditor
        ref={editor}
        value={value}
        config={defaultConfig}
        tabIndex={props.tabIndex || 1}
        onBlur={props.onBlur && handleBlur}
        onChange={props.onChange && props.onChange}
      />
    </>
  );
};

export default RichTextEditor;
