import React, { useState, Fragment } from "react";
import { Button } from "reactstrap";

import ModalContainer from "../../shared/ModalContainer";
import ContactForm from "../../shared/forms/ContactForm";

/**
 *  =======
 * = USAGE =
 *  =======
 *
 * <NewOrganizationModal
 *   callBack={(data) => this.submitEventHandler(data)}
 * />
 *
 * =======
 * = API =
 * =======
 * formSubmitCallBack (optional) (Function)
 *  - callback function to invoke submit method in parent component
 */
const NewContactModal = (props) => {
  const showButton = true;

  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = () => {
    return showButton === false ? (
      ""
    ) : (
      <Button color="info" onClick={() => toggle()}>
        + Add Contact
      </Button>
    );
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(true);
  };

  const newContactFormSubmitCallback = (data, index) => {
    setIsSubmitReady(false);
    props.formSubmitCallBack(data, index);
    toggle();
  };
  return (
    <ModalContainer
      buttonLabel="+ Add Contact"
      modalTitle="Add New Contact"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={modalContainerSubmitCallback}
      button={<OpenModalButton />}
    >
      <ContactForm
        isPopulatingForm={false}
        isSubmitReady={isSubmitReady}
        submitForm={setIsSubmitReady}
        submitCallback={(data, index) =>
          newContactFormSubmitCallback(data, index)
        }
        orgId={props.orgId}
      />
    </ModalContainer>
  );
};

export default NewContactModal;
