import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import firebaseConfig from "./firebase-config";
import User from "models/database/User";
import store from "redux-store/store";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  store.dispatch({ type: "firebaseInit", firebase });
  firebase.auth().onAuthStateChanged(async (userAuth) => {
    if (userAuth) {
      store.dispatch({ type: "authUpdate", user: userAuth });
      if (process.env.NODE_ENV !== "production") {
        console.log("User auth state changed:", userAuth);
      }
      const userData = await new User().get(userAuth.uid);
      const userOrg = userData?.organization || "";
      store.dispatch({ type: "orgUpdate", userOrg });
      let orgId = localStorage.getItem("userOrg");
      if (!orgId || orgId !== userOrg) localStorage.setItem("userOrg", userOrg);
    }
  });
}

const storage = firebase.storage();
export { firebase as default, storage };
