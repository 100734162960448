import React, { Component } from "react";
// react component for creating dynamic tables
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle, Col,
} from "reactstrap";

import AccountSponsorship from "models/database/AccountSponsorship";
import SponsorshipsTable from "./SponsorshipsTable";
import NewSponsorshipModal from "./NewSponsorshipModal";

class SponsorshipsTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor : "blue", //"#005BAA",
            sidebarMini : true,
            newSponsorship    : '',
        };
    }

    async submitSponsorshipHandler(sponsorshipProps) {
        const sponsorship = await new AccountSponsorship().create(sponsorshipProps);

        this.setState((previousState) => {
            return { newSponsorship : sponsorship }
        });
    }

    tableHeader = () => (
        <CardHeader>
            <div style={{display: 'flex', justifyContent: "space-between"}}>
                <CardTitle tag="h4">Sponsorships</CardTitle>
                <NewSponsorshipModal
                    formSubmitCallBack={(data) => this.submitSponsorshipHandler(data)}
                />
            </div>
        </CardHeader>
    );

    render() {
        return (
            <>
                <Col xs={6} md={6}>
                    <Card>
                        { this.tableHeader() }
                        <CardBody>
                            <SponsorshipsTable newRow={this.state.newSponsorship}/>
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    }
}

export default SponsorshipsTableContainer;
