import { mailgunEmailsBackendUrl } from "../../utilities/constants/urls";
import PersistentBase from "./PersistentBase";

export default class Email extends PersistentBase {
  constructor(name, from, to, cc, bcc, subject, html, template, replyTo) {
    super();
    this.name = name;
    this.from = from;
    this.to = to;
    this.cc = cc;
    this.bcc = bcc;
    this.subject = subject;
    this.html = html;
    this.template = template;
    this.replyTo = replyTo;
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = mailgunEmailsBackendUrl;
  }

  metaData() {
    return super.cleanObject({
      name: this.name,
      from: this.from,
      to: this.to,
      cc: this.cc,
      bcc: this.bcc,
      subject: this.subject,
      html: this.html,
      template: this.template,
      replyTo: this.replyTo,
      "recipient-variables": this["recipient-variables"],
    });
  }

  async create() {
    const payload = this.metaData();
    this.url = `${mailgunEmailsBackendUrl}/create`;
    const response = await super.post(payload);
    return response;
  }
}
