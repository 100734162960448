
export const awardsDinner = "Awards Dinner";
export const conference   = "Conference";
export const expo         = "Expo";
export const gala         = "Gala";
export const reception    = "Reception";
export const roundtable   = "Roundtable";
export const summit       = "Summit";
export const symposium    = "Symposium";
export const tradeshow    = "Tradeshow";
export const webinar      = "Webinar";
export const workshop     = "Workshop";

export const eventTypes   = [ conference, tradeshow, expo, reception, gala, roundtable, workshop, awardsDinner, symposium, webinar ];

export const live         = "Live";
export const hybrid       = "Hybrid";
export const virtual      = "Virtual";
export const eventFormats = [ live, virtual, hybrid ];

export const main         = "Main";
export const financial    = "Financial";
export const contract     = "Contract";
export const speaker      = "Speaker";
export const contactTypes = [ main, financial, contract, speaker ];
