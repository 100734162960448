import React, { Component } from "react";

// react component for creating dynamic tables
import EventsPartner from "../../../../../../models/database/EventsPartner";

import CrudActionsTable from "../../../../shared/CrudActionsTable";

import EditEventPartnerModal from "./EditEventPartnerModal";

import { connect } from "react-redux";
import { isUndefined, isNumber, isNull } from "lodash";
import Booth from "models/database/Booth";
import Sponsorship from "models/database/Sponsorship";
import updateReduxData from "utilities/methods/updateReduxData";

class EventPartnersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isUpdate: true,
    };
  }

  async fetchData(event, orgData, orgUsers) {
    const eventPartners = await new EventsPartner().getAll(
      event,
      orgData,
      orgUsers
    );
    if (this.mounted) {
      this.setState({ data: eventPartners });
    }
    return eventPartners;
  }

  async componentDidUpdate(prevProps) {
    let { event, orgData, newRow, orgUsers } = await this.props;
    if (
      prevProps.event != this.props.event ||
      prevProps.orgData != orgData ||
      prevProps.newRow != newRow ||
      prevProps.orgUsers != orgUsers
    ) {
      this.fetchData(event, orgData, orgUsers);
    }
  }

  async componentDidMount() {
    this.mounted = true;
    let { event, orgData, orgUsers } = await this.props;
    this.fetchData(event, orgData, orgUsers);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  findItem(arr, id) {
    return arr.find((val) => val.id == id);
  }

  modifyReservedCount(obj, action) {
    if (obj) {
      let { reserved } = obj;
      let hasReserved = this.validateItem(reserved);
      if (hasReserved >= 0) {
        if (action === "subtract") {
          obj["reserved"] = hasReserved <= 0 ? 0 : hasReserved - 1;
        } else if (action === "add") {
          obj["reserved"] = hasReserved + 1;
        }
      }
      return obj;
    }
  }

  validateItem(item) {
    if (isUndefined(item) || !isNumber(item) || isNaN(item) || isNull(item))
      return 0;
    return item;
  }

  handleDeleteCallback = async (item) => {
    let { boothId, sponsorId, carteSponsorships } = await item;
    let { booths, sponsorships, id } = await this.props.eventData;
    if (boothId) {
      let decrementedBooth = this.modifyReservedCount(
        this.findItem(booths, boothId),
        "subtract"
      );
      if (decrementedBooth) {
        await new Booth().updateSingleEntity(decrementedBooth, id);
      }
    }
    if (sponsorId) {
      let decrementedSponsor = this.modifyReservedCount(
        this.findItem(sponsorships, sponsorId),
        "subtract"
      );
      if (decrementedSponsor) {
        await new Sponsorship().updateSingleEntity(decrementedSponsor, id);
      }
    }
    for (let sponsor of carteSponsorships) {
      let decrementedSponsor = this.modifyReservedCount(
        this.findItem(sponsorships, sponsor.accountsponsorId),
        "subtract"
      );
      if (decrementedSponsor) {
        await new Sponsorship().updateSingleEntity(decrementedSponsor, id);
      }
    }
  };

  handleUpdateCallback = async (tableData, item, deleteItem, prevData) => {
    let { id, booths, sponsorships, code } = await this.props.eventData;
    let newData = [...tableData];
    let tableRow = await newData[prevData.index]?.itemClass;
    let newBoothId = tableRow?.boothId;
    let newSponsorId = tableRow?.sponsorId;
    let { boothId, sponsorId } = await prevData.itemClass;

    if (newBoothId != boothId) {
      let decrementedBooth = this.modifyReservedCount(
        this.findItem(booths, boothId),
        "subtract"
      );

      await new Booth().updateSingleEntity(decrementedBooth, id);

      if (newBoothId) {
        let incrementedBooth = this.modifyReservedCount(
          this.findItem(booths, newBoothId),
          "add"
        );
        await new Booth().updateSingleEntity(incrementedBooth, id);
      }
    }

    if (newSponsorId != sponsorId) {
      let decrementedSponsor = this.modifyReservedCount(
        this.findItem(sponsorships, sponsorId),
        "subtract"
      );
      await new Sponsorship().updateSingleEntity(decrementedSponsor, id);

      if (newSponsorId) {
        let incrementedSponsor = this.modifyReservedCount(
          this.findItem(sponsorships, newSponsorId),
          "add"
        );
        await new Sponsorship().updateSingleEntity(incrementedSponsor, id);
      }
    }
    const eventData = await updateReduxData.event(code);
    await updateReduxData.eventContacts(eventData);
  };

  render() {
    const pageSize = 10;
    return (
      <>
        <CrudActionsTable
          data={this.state.data}
          dataModel={EventsPartner}
          defaultPageSize={pageSize}
          modalName="Event Partner"
          editModal={EditEventPartnerModal}
          deleteCallback={this.handleDeleteCallback}
          editCallback={(tableData, item, deleteItem, prevData) =>
            this.handleUpdateCallback(tableData, item, deleteItem, prevData)
          }
          hasNoActions={!this.props.isEventEditable}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  orgData: state.orgData,
  orgUsers: state.orgUsers,
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(EventPartnersTable);
