import fetcher from '../../utilities/fetcher';

/**
 * @description HTTP verb methods wrapper for fetcher utility
 */
export default class Adapter {

    setRestUrl(url) {
        this.url = url;
    }

    create(payload) {
        const resp = fetcher(this.jsonUrl,'POST', payload);
        return resp;
    }

    async get() {
        const resp = await fetcher(this.jsonUrl,'GET');
        return resp;
    }

    async delete(url) {
        const resp = await fetcher(url,'DELETE');
        return resp;
    }

    async update(url, payload) {
        const resp = await fetcher(url,'PATCH', payload);
        return resp;
    }

    async put(url, payload) {
        return await fetcher(url, "PUT", payload);
    }

    buildUrl(url, id) {
        const idUrl = `${url}/${id}.json`;
        return idUrl;
    }
}
