import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import StyledLink from "../../layouts/Plan/shared/StyledLink"
export default class Organization extends PersistentBase {
  constructor(
    id,
    name,
    address1,
    address2,
    city,
    state,
    country,
    zip,
    phone,
    URL,
    contactId,
    eventIds,
    orgLogo
  ) {
    super();
    this.id = id;
    this.name = name;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.zip = zip;
    this.phone = phone;
    this.URL = URL;
    this.contactId = contactId;
    this.eventIds = eventIds;
    this.orgLogo = orgLogo;
    this.csvTitle = "Organization";
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/organizations`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/organizations.json`;
  }
  async addEvent(eventId) {
    this.firebaseId = super.getUserOrgId();
    const url = await this.appendAuth(
      this.buildUrl(this.url, `${this.firebaseId}/events/${eventId}`)
    );
    return super.put(url, eventId);
  }

  metaData() {
    return {
      name: this.name,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.zip,
      phone: this.phone,
      URL: this.URL,
      contactId: this.contactId,
      eventIds: this.eventIds,
      orgLogo: this.orgLogo,
    };
  }

  emailVariables() {
    return {
      name: this.name,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.zip,
      phone: this.phone,
      URL: {
        value: this.URL,
        draggableName: "URL"
      },
    };
  }

  createWithObject(payload) {
    const organization = payload || this;

    this.id = organization.id;
    this.name = organization.name;
    this.address1 = organization.address1;
    this.address2 = organization.address2;
    this.city = organization.city;
    this.city = organization.city;
    this.state = organization.state;
    this.country = organization.country;
    this.zip = organization.zip;
    this.phone = organization.phone;
    this.URL = organization.URL;
    this.contactId = organization.contactId;
    this.eventIds = organization.eventIds;
    this.orgLogo = organization.orgLogo;

    return this;
  }

  async getUserOrgData() {
    const userOrg = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrg}.json`
    );
    const response = await super.get();
    return response.customerMetaData || {};
  }

  async getOrgById(orgId) {
    const userOrg = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrg}/organizations/${orgId}.json`
    );
    const response = await super.get();
    return response;
  }

  async update(organization) {
    if (!this.url) await this.setRestUrl();
    const updatedOrganization = this.createWithObject({
      id: this.id,
      ...organization,
    });
    const url = await super.appendAuth(super.buildUrl(this.url, this.id));

    super.update(url, updatedOrganization.metaData());
    return this;
  }

  csvKeyMap() {
    return {
      "organization": {
        keyName: "name",
        templateData: "ArcatXP"
      },
      "address 1": {
        keyName: "address1",
        templateData: "1 Main Street"
      },
      "address 2": {
        keyName: "address2",
        templateData: "Unit C"
      },
      "city": {
        keyName: "city",
        templateData: "San Francisco"
      },
      "state": {
        keyName: "state",
        templateData: "California",
      },
      "zip code": {
        keyName: "zip",
        templateData: "12345",
      },
      "country": {
        keyName: "country",
        templateData: "United States",
      },
      "url": {
        keyName: "URL",
        templateData: "https://app.arcatxp.com/",
      },
      "phone": {
        keyName: "phone",
        templateData: "+1(555)555-5555"
      },
    };
  }

  tableColumnsAndValues() {
    const LinkTo = {
      pathname: `/plan/organization/${this.name}`,
      state: { organization: this },
    };

    return {
      organization: <StyledLink to={LinkTo}>{this.name}</StyledLink>,
      address1: this.address1,
      address2: this.address2,
      city: this.centerAlignDiv(this.city),
      state: this.centerAlignDiv(this.state),
      country: this.centerAlignDiv(this.country),
      url: this.URL,
    };
  }
}
