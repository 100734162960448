import Select from "react-select";
import { Col } from "reactstrap";
import React, { useState } from "react";
import classNames from "classnames";

const CustomSelect = (props) => {
  const {
    options,
    placeholder,
    callback,
    defaultSelected,
    multiSelect,
    onAuthPage,
    whiteSpace,
  } = props;

  const [singleSelect, setSingleSelect] = useState("");
  const [value, setvalue] = useState(null);

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: whiteSpace || "nowrap",
      overflow: "hidden",
      display: "initial",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 12,
    }),
    control: (provided) => {
      return onAuthPage
        ? {
            ...provided,
            height: 48,
          }
        : {
            ...provided,
          };
    },
  };

  const multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
    const val = `${label}${labelSuffix}`;
    return val;
  }; //added because ui breaks when added more than one in list.

  return (
    <Col
      className={classNames(props.isNotPadded ? "pt-0 pl-0 pr-0 pb-0" : "pt-2")}
    >
      <Select
        styles={customStyles}
        className="info"
        classNamePrefix="react-select"
        components={{
          MultiValueContainer: multiValueContainer,
          // Option: CustomOption,
        }}
        // name="singleSelect"
        value={value ? value : defaultSelected}
        onChange={(value) => {
          setvalue(value);
          setSingleSelect(value);
          callback(value);
        }}
        options={options}
        placeholder={placeholder}
        isMulti={multiSelect}
        aria-label={placeholder}
      />
    </Col>
  );
};

export default React.memo(CustomSelect);
