import React from "react";
import PersistentBase from "./PersistentBase";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import CurrencyFormatter, { NumberToCurrency } from "../../layouts/Plan/shared/CurrencyFormatter";
import Deliverable from "./Deliverable";
import Organization from "./Organization";
import { storage } from "firebase-client/firebase-client";
import StyledLink from "layouts/Plan/shared/StyledLink";
import store from "redux-store/store";
import truncateString from "utilities/methods/truncateString";

export default class EventPartner extends PersistentBase {
  constructor(
    id,
    company,
    sponsorLevel,
    boothNumber,
    booth,
    totalSpend,
    orgId,
    boothId,
    sponsorId,
    salesContact,
    regCode,
    salesPerson,
    contractReceived,
    notes,
    eventContacts,
    boothsObj,
    booths,
    sponsorshipsObj,
    sponsorships,
    carteSponsorships,
    salesContactNames,
    formData,
  ) {
    super();
    this.company = company;
    this.sponsorLevel = sponsorLevel;
    this.boothNumber = boothNumber;
    this.booth = booth;
    this.totalSpend = totalSpend;
    this.id = id;
    this.orgId = orgId;
    this.boothId = boothId;
    this.sponsorId = sponsorId;
    this.salesContact = salesContact;
    this.regCode = regCode;
    this.salesPerson = salesPerson;
    this.contractReceived = contractReceived;
    this.notes = notes;
    this.eventContacts = eventContacts;
    this.boothsObj = boothsObj;
    this.booths = booths;
    this.sponsorshipsObj = sponsorshipsObj;
    this.carteSponsorships = carteSponsorships;
    this.sponsorships = sponsorships;
    this.salesContactNames = salesContactNames;
    this.formData = formData;
    this.csvTitle = "Event Partner";
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/events/${this.id}/partners/`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/events/${this.id}/partners.json`;
  }

  metaData() {
    return {
      company: this.company,
      sponsorLevel: this.sponsorLevel,
      boothNumber: this.boothNumber,
      booth: this.booth,
      totalSpend: this.totalSpend,
      orgId: this.orgId,
      boothId: this.boothId,
      sponsorId: this.sponsorId,
      id: this.id,
      boothsObj: this.boothsObj,
      booths: this.booths,
      sponsorshipsObj: this.sponsorshipsObj,
      sponsorships: this.sponsorships,
      salesContact: this.salesContact,
      regCode: this.regCode,
      salesPerson: this.salesPerson,
      contractReceived: this.contractReceived,
      notes: this.notes,
      eventContacts: this.eventContacts,
      carteSponsorships: this.carteSponsorships,
      formData: this.formData,
    };
  }

  mapEventDeliverables(
    entity,
    entityId,
    partnerId,
    partnerCollection,
    partnerOrg,
    parentName,
    isTableFormat = true
  ) {
    const results = [];
    let n = 0;
    results.push(
      ...Object.values(entity.deliverables || {}).map((deliverable, index) => {
        const deliverableObj = new Deliverable()
          .createWithObject({
            ...deliverable,
            partnerOrg,
            partnerId,
            partnerCollection,
            parentName,
            entityId,
          });
        if (!isTableFormat) {
          return deliverableObj;
        }
        return deliverableObj.tableRowFormat(n++);
      }
      )
    );
    return results;
  }

  async getEventDeliverables(eventId, isTableFormat = true) {
    const userOrgId = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/partners.json`
    );
    const response = await super.get();
    const results = [];
    if (!response) return results;
    for (const [partnerId, partner] of Object.entries(response)) {
      if (!partner?.orgId) continue;
      const partnerOrgData = await new Organization().getOrgById(partner.orgId);
      for (const [entityId, booth] of Object.entries(partner.booths || {})) {
        results.push(
          ...this.mapEventDeliverables(
            booth,
            entityId,
            partnerId,
            "booths",
            partnerOrgData,
            booth.label,
            isTableFormat
          )
        );
      }
      for (const [entityId, sponsorship] of Object.entries(
        partner.sponsorships || {}
      )) {
        results.push(
          ...this.mapEventDeliverables(
            sponsorship,
            entityId,
            partnerId,
            "sponsorships",
            partnerOrgData,
            sponsorship.label,
            isTableFormat
          )
        );
      }
    }
    return results;
  }

  async getAll(event, orgs = []) {
    if (!event) return [];
    const userOrgId = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event.id}/partners.json`
    );
    const response = await super.get();
    const results = Object.entries(response || {}).reduce((mapArr, [partnerId, partner], index) => {
      const boothId = Object.keys(partner.booths || {})[0];
      const sponsorId = Object.entries(partner.sponsorships || {})
        .find((entry) => entry[1] && !entry[1].isCarteSponsor)?.[0];
      const booth = event.booths.find(({ id }) => id === boothId);
      const sponsor = event.sponsorships.find(({ id }) => id === sponsorId);
      const org = orgs?.find?.(({ itemClass }) => itemClass.id == partner.orgId);
      const carteSponsorships = Object.values(partner.sponsorships || {})?.filter((s) => s.isCarteSponsor);
      const data = {
        ...partner,
        id: partnerId,
        booth: booth?.name,
        sponsorLevel: sponsor?.name,
        company: org?.itemClass?.name,
        boothId,
        sponsorId,
        carteSponsorships,
      };
      const formattedData = new this.constructor().createWithObject(data).tableRowFormat(index);
      mapArr.push(formattedData);
      return mapArr;
    }, []);
    return results;
  }

  async getPartnerById(partnerId, eventId) {
    const userOrg = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrg}/events/${eventId}/partners/${partnerId}.json`
    );
    const response = await super.get();
    return response;
  }

  async update(
    payload,
    event,
    deleteID,
    single = false,
    parent,
    orgData,
    orgUsers
  ) {
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event?.id}/partners/${payload?.id}.json`
    );
    const response = await super.update(url, payload);
    const res = await this.getAll(event, orgData, orgUsers);
    let partner =
      res.length && res.find(({ itemClass }) => itemClass?.id == payload?.id);
    return partner?.itemClass;
  }

  async delete(partnerId, eventId) {
    const partnerData = await this.getPartnerById(partnerId, eventId);
    const partnerOrgData = await new Organization().getOrgById(partnerData.orgId);
    const orgEventIds = partnerOrgData.eventIds || {};
    const eventIds = { ...orgEventIds };
    delete eventIds[eventId];
    await new Organization(partnerData.orgId).update({ eventIds });
    const userOrgId = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/partners/`;
    const response = await super.delete(partnerId);
    return response;
  }

  async createNew(event, payload) {
    const userOrg = await super.getUserOrgId();
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/events/${event.id}/partners.json`;
    const response = await super.asyncCreate(payload);
    return response;
  }

  storageServer(filePath) {
    const server = {
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        const task = storage
          .ref(`${filePath}/${file.name}`)
          .put(file, {
            contentType: file.type,
          });
        task.on(
          "state_changed",
          (snap) => {
            progress(true, snap.bytesTransferred, snap.totalBytes);
          },
          (err) => {
            error(err.message);
          },
          () => {
            task.snapshot.ref.getMetadata().then(async (metaData) => {
              load(`${filePath}/${file.name}`);
              return metaData;
            });
          }
        );
      },

      load: (source, load, error, progress, abort) => {
        storage
          .ref(source)
          .getDownloadURL().then((url) => {
            fetch(url).then((res) => {
              res.blob().then((blob) => {
                load(blob);
              });
            });
          })
        
      },
    };

    return server;
  }

  async updateFormData(payload, orgId, eventId, partnerId) {
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${orgId}/events/${eventId}/partners/${partnerId}.json`
    );
    const response = await super.update(url, payload);
    return response;
  }

  async getFormData(orgId, eventId, partnerId) {
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${orgId}/events/${eventId}/partners/${partnerId}/formData.json`
    );
    const response = await super.get();
    return response || {};
  }

  isFormCompleted() {
    return Object.values(this.formData || {}).some(val => val); 
  }

  tableCsvData() {
    return {
      company: this.company || "-",
      "partner form completed": this.isFormCompleted() ? "Yes" : "No",
      "booth number": this.boothNumber || "-",
      booth: this.booth || "-",
      "sponsor level": this.sponsorLevel || "-",
      "a-la-carte sponsors": this.carteSponsorships?.map((s) => s?.itemClass?.name || "-").join() || "-",
      "total spend": NumberToCurrency(this.totalSpend || 0),
      "reg code": this.regCode || "-",
      description: this.formData?.companyDescription || "-",
    };
  }

  tableColumnsAndValues() {
    const orgId = super.getUserOrgId() || "";
    const eventId = store.getState().eventData?.id || "";
    const linkTo = { pathname: `/auth/partner/${orgId}/${eventId}/${this.id}` };
    return {
      company: <StyledLink to={linkTo}>{this.company}</StyledLink>,
      "partner form completed": this.centerAlignDiv(this.isFormCompleted() ? "Yes" : "No"),
      "booth number": this.centerAlignDiv(this.boothNumber),
      booth: this.centerAlignDiv(this.booth),
      "sponsor level": this.centerAlignDiv(this.sponsorLevel),
      "total spend": this.centerAlignDiv(
        <CurrencyFormatter value={this.totalSpend || "0"} />
      ),
      description: {
        width: 260,
        cellText: this.centerAlignDiv(truncateString(this.formData?.companyDescription || "", 36))
      },
    };
  }

  emailVariables() {
    return {
      organization: this.centerAlignDiv(this.company),
      boothNumber: this.centerAlignDiv(this.boothNumber),
      booth: this.centerAlignDiv(this.booth),
      sponsorLevel: this.centerAlignDiv(this.sponsorLevel),
      carteSponsorships: {
        cellText: this.centerAlignDiv(this.carteSponsorships),
        draggableName: "A-La-Carte"
      },
      totalSpend: this.centerAlignDiv(
          <CurrencyFormatter value={this.totalSpend || "0"} />
      ),
      regCode: this.centerAlignDiv(this.regCode),
      salesPerson: this.centerAlignDiv(this.salesPerson),
      eventContacts: this.centerAlignDiv(this.eventContacts),
      contractReceived: this.centerAlignDiv(this.contractReceived),
      notes: this.centerAlignDiv(this.notes),
      formUrl: {
        draggableName: "Form URL"
      },
      allSponsorships: {
        cellText: "",
        draggableName: "All Sponsorships"
      },
    };
  }
}
