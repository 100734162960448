import React from "react";
import Adapter from "./Adapter";
import { TableFormatter } from "../features/TableFormatter";
import store from "redux-store/store";
import { customerOrgsFirebaseUrl } from "utilities/constants/urls";
import firebaseUid from "utilities/methods/firebaseUid";
import { assignWith } from "lodash";

// Base class for all persistant data models to extend
export default class PersistentBase extends Adapter {
  getUserToken() {
    return this.getUser(async (user) => {
      const idToken = await user.getIdToken();
      return idToken;
    });
  }

  getUserUid() {
    return this.getUser((user) => {
      return user.uid;
    });
  }

  getUserOrgId() {
    let userOrg = store.getState().userOrg || localStorage.getItem("userOrg");
    return (
      userOrg ||
      new Promise((resolve, reject) => {
        // Return userOrg once it has been set by firebase-client.js
        const checkStore = () => {
          let { userOrg } = store.getState();
          if (userOrg) {
            resolve(userOrg);
            unsubscribe();
          }
        };
        let unsubscribe = store.subscribe(checkStore);
      })
    );
  }

  getUser(callback) {
    const { firebase } = store.getState();
    const curUser = firebase?.auth().currentUser;
    if (!curUser) {
      return new Promise((resolve, reject) => {
        // Return user once it has been set by firebase-client.js
        const checkStore = () => {
          let { user } = store.getState();
          if (user) {
            unsubscribe();
            resolve(callback(user));
          }
        };
        let unsubscribe = store.subscribe(checkStore);
      });
    }
    return callback(curUser);
  }

  async getEntitiesById(ids, collectionPath = this.collectionPath) {
    const userOrgId = await this.getUserOrgId();
    const entities = {};
    for (const id of ids) {
      if (!id) continue;
      this.jsonUrl = await this.appendAuth(
        `${customerOrgsFirebaseUrl}/${userOrgId}/${collectionPath}/${id}.json`
      );
      const response = await super.get();
      if (!response) continue;
      entities[id] = response;
    }
    return entities;
  }

  async getAllEntities(withTableFormat = true) {
    if (!this.collectionPath || !this.dataModel) return [];
    const userOrgId = await this.getUserOrgId();

    // this is for Super Admin to get ALL entities in the db
    if (this.collectionPath === 'super') {
      this.jsonUrl = await this.appendAuth(this.jsonUrl);
    } else {
      this.jsonUrl = await this.appendAuth(
          `${this.url}/${userOrgId}/${this.collectionPath}.json`
      );
    }
    const entities = await this.get();
    if (!entities) return [];
    let index = 0;
    const arrayOfEntities = [];
    for (const [entityID, entityData] of Object.entries(entities)) {
      if (entityID == undefined || entityID === "undefined") continue;
      const entityObj = { id: entityID, index: index, ...entityData };
      let entity = new this.dataModel().createWithObject(entityObj);

      if (withTableFormat) {
        entity = entity.tableRowFormat(index++);
      }
      arrayOfEntities.push(entity);
    }
    return arrayOfEntities;
  }
  /**
   * @description generic way to iterate over an object and set data attributes to the class
   * @param payload
   * @returns {{}}
   */
  createWithObject(payload) {
    const data = payload || this;

    const keys = Object.keys(data);
    const values = Object.values(data);

    const obj = {};
    keys.forEach((key, index) => {
      if (key !== "url" && key !== "jsonUrl") {
        this[key] = values[index];
        obj[key] = values[index];
      }
    });
    return this;
  }

  metaData(payload) {
    return this.createWithObject(payload);
  }

  /**
   * @description
   * @param payload
   * @returns {Promise<PersistentBase>}
   */
  async asyncCreate(payload) {
    if (payload) {
      this.createWithObject({ id: "", ...payload });
    }
    delete payload["id"];
    delete payload["jsonUrl"];
    delete payload["url"];

    const user = await this.getUserUid();
    const email = localStorage.getItem("userEmail");
    payload.users = { [user]: { email: email } };

    // if setRestUrl is async, make sure url is ready
    if (!this.jsonUrl) await this.setRestUrl();

    this.jsonUrl = await this.appendAuth(this.jsonUrl);
    const response = await super.create(payload);
    this.id = response.name; // firebase response is { name: uniqueID }
    return this;
  }

  /**
   * @description async/await was messing up adding new items to the table right away, this solves for it without
   * needing a create method in each child class
   * @param payload
   * @returns {PersistentBase}
   */
  create(payload = this.metaData()) {
    this.asyncCreate(payload);
    return this;
  }

  async appendFirebaseIdAndAuth(url) {
    const authUrl = await this.appendAuth(`${url}/${this.firebaseId}.json`);
    return authUrl;
  }

  async appendAuth(url) {
    const token = await this.getUserToken();

    const separator = url.includes("?") ? "&" : "?";
    return `${url}${separator}auth=${token}`;
  }

  async put(url, payload) {
    const putUrl = await this.appendAuth(url);
    return super.put(putUrl, payload);
  }

  async getAll() {
    const response = await super.get();
    const arrayOfModels = [];
    let index = 0;
    for (const key in response) {
      const value = response[key];
      const item = { id: key, ...value };
      arrayOfModels.push({ item, index });
      index++;
    }
    index = 0;
    return arrayOfModels;
  }

  async delete(id = this.id) {
    const url = await this.appendAuth(super.buildUrl(this.url, id));
    super.delete(url);
  }

  isDateValid(date) {
    //true
    //!Number.isNaN(new Date().getTime())
    //!Number.isNaN(new Date(2021-02-02T05:00:00.000Z).getTime())

    //false
    //!Number.isNaN(new Date(undefined).getTime())
    return !Number.isNaN(new Date(date).getTime());
  }

  divWrapper(display, alignment) {
    return <div style={{ textAlign: alignment }}>{display}</div>;
  }

  centerAlignDiv(display) {
    return this.divWrapper(display, "center");
  }

  leftAlign(display) {
    return this.divWrapper(display, "left");
  }

  async uploadCsvData(items, tableIndex = null) {
    const payload = items.reduce((mapObj, item) => {
      const data = this.createFromCsv(item).metaData();
      const obj = assignWith({}, data, (_, value) => value == undefined ? "" : value);
      mapObj[firebaseUid()] = obj;
      return mapObj;
    }, {});
    if (!this.jsonUrl) await this.setRestUrl();
    const url = await this.appendAuth(this.jsonUrl);
    const response = await super.update(url, payload);
    if (tableIndex == null) return response;
    return Object.entries(response || {}).map(([id, item], index) => {
      return this.createWithObject({ ...item, id }).tableRowFormat(index + tableIndex);
    });
  }

  createFromCsv(obj) {
    return this.createWithObject(
      Object.entries(this.csvKeyMap?.() || {}).reduce((mapObj, [key, value]) => {
        mapObj[value.keyName] = obj[key] || "";
        return mapObj;
      }, {})
    );
  }

  tableCsvData() {
    return Object.entries(this.csvKeyMap?.() || {}).reduce((mapObj, [key, value]) => {
      if (!value.isInternal) mapObj[key] = this[value.keyName];
      return mapObj;
    }, {});
  }

  csvImportTemplate() {
    return {
      data: [
        Object.entries(this.csvKeyMap?.() || {}).reduce((mapObj, [key, value]) => {
          if (!value.isInternal) mapObj[key] = value.templateData;
          return mapObj;
        }, {}),
      ],
      title: `${this.csvTitle || this.constructor.name} Import Template`
    }
  }

  tableColumnsAndValues() {
    const generic = this.createWithObject();
    return generic;
  }

  /**
   * @description async/await was messing up adding new items to the table right away, this solves for it without
   * needing a create method in each child class
   * @param payload
   * @returns {PersistentBase}
   */
  //   create(payload = this.metaData()) {
  //     this.asyncCreate(payload);
  //     return this;
  //   }

  tableRowFormat(index) {
    return new TableFormatter()
      .init(this.tableColumnsAndValues())
      .tableRowFormat(index, this);
  }

  tableColumnHeadings(hasNoActions = false, filterMethod) {
    return new TableFormatter()
      .init(this.tableColumnsAndValues(), filterMethod)
      .tableColumnHeadings(hasNoActions);
  }
}
