import PersistentBase from "./PersistentBase";
import React from "react";
import { customerOrgsFirebaseJsonUrl, customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import moment from "moment";
import { timeFormat } from "utilities/constants/date-format";

export default class Agenda extends PersistentBase {
  constructor(id, startTime, endTime, activity, location, speaker) {
    super();
    this.id = id;
    this.startTime = startTime;
    this.endTime = endTime;
    this.activity = activity;
    this.location = location;
    this.speaker = speaker;
    this.csvTitle = "Agenda Item";
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl
    this.jsonUrl = customerOrgsFirebaseUrl;
  }

  metaData() {
    return {
      startTime: this.startTime,
      endTime: this.endTime,
      activity: this.activity,
      location: this.location,
      speaker: this.speaker,
    };
  }

  async createNew(payload, eventId) {
    const userOrgId = await super.getUserOrgId();
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/agenda.json`;
    const response = await super.asyncCreate(payload);
    return response;
  }

  create(payload, event) {
    this.createNew(payload, event.id);
    return this.createWithObject(payload);
  }

  async delete(agendaId, eventId) {
    const userOrgId = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/agenda/`;
    const response = await super.delete(agendaId);
    return response;
  }

  async getEventAgenda(eventId) {
    const userOrgId = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/agenda.json`
    );
    const response = await super.get();
    if (!response) return [];
    return Object.entries(response).map(([key, value], index) => 
      new this.constructor().createWithObject({ ...value, id: key }).tableRowFormat(index)
    );
  }

  async update(payload, event) {
    const userOrgId = await super.getUserOrgId();
    const updatedEntity = this.createWithObject(payload);
    const url = await super.appendAuth(
      super.buildUrl(`${customerOrgsFirebaseUrl}/${userOrgId}/events/${event.id}/agenda`, this.id)
    );
    super.update(url, updatedEntity.metaData());
    return this;
  }

  timeToString(date, format = timeFormat) {
    if (!this.isDateValid(date)) return "-";
    return moment(date).format(format);
  }

  tableCsvData() {
    const speakerName = `${this.speaker?.firstName || ""} ${this.speaker?.lastName || ""}`.trim();
    return {
      "start time": this.timeToString(this.startTime),
      "end time": this.timeToString(this.endTime),
      activity: this.activity || "-",
      location: this.location || "-",
      speaker: speakerName || "-",
    };
  }

  tableColumnsAndValues() {
    const speakerName = `${this.speaker?.firstName || ""} ${this.speaker?.lastName || ""}`.trim();
    return {
      "start time": { cellText: this.timeToString(this.startTime), width: 150 },
      "end time": { cellText: this.timeToString(this.endTime), width: 150 },
      activity: this.activity || "-",
      location: this.location || "-",
      speaker: speakerName || "-",
    };
  }
}
