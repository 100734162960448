import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  eventFormats,
  eventTypes,
} from "../../../../utilities/constants/event-terms";
import CustomSelect from "../../../../creative-tim/components/CustomSelect";
import AddressInputForm from "layouts/Plan/shared/forms/AddressInputForm";
import InputText from "../../shared/inputs/InputText";
import Event from "models/database/Event";
import CustomLabel from "layouts/Plan/shared/CustomLabel";
import IconTooltip from "layouts/Plan/shared/IconTooltip";
import { NumberToCurrency } from "layouts/Plan/shared/CurrencyFormatter";
import validateUrl from "utilities/methods/validateUrl";
import PhoneInput from "react-phone-input-2";
import { CurrencyToNumber } from "layouts/Plan/shared/CurrencyFormatter";
import { ColorPicker } from "layouts/Plan/shared/inputs/ColorPicker";
import UsersDropDownList from "layouts/Plan/shared/UsersDropDownList";
import UsersDropdown from "layouts/Plan/shared/UsersDropdown";
import formatUserName from "utilities/methods/formatUserName";

const NewEventForm = (props) => {
  const createInitialState = (key, data) => {
    return (data.event && data.event.data && data.event.data[key]) || "";
  };

  const [name, setEventName] = useState("");
  const [code, setEventCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventState, setEventState] = useState("");

  //update form to pre-populate values
  const initialFormat = createInitialState("format", props);
  const [format, setEventFormat] = useState(
    createInitialState("format", props)
  );
  const [type, setEventType] = useState(createInitialState("type", props));
  const [editingIndex, setEditingIndex] = useState("");
  const [address, setAddress] = useState({});
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [venue, setVenue] = useState("");
  const [eventUrl, setEventUrl] = useState("");
  const [eventColor, setEventColor] = useState("");
  const [revenueGoal, setRevenueGoal] = useState(0);
  const [hotelRate, setHotelRate] = useState(0);
  const [hotelDeadline, setHotelDeadline] = useState("");
  const [reservationUrl, setReservationUrl] = useState("");
  const [reservationPhone, setReservationPhone] = useState("");
  const [eventManagers, setEventManagers] = useState({});
  const [defaultEventManagers, setDefaultEventManagers] = useState(undefined);
  const [isPopulatingForm, setIsPopulatingForm] = useState(
    props.isPopulatingForm
  );
  const [hasEventCode, setHasEventCode] = useState(false);

  const clearHooks = () => {
    setEventName("");
    setEventCode("");
    setEventType("");
    setEventFormat("");
    setStartDate("");
    setEndDate("");
    setCity("");
    setEventState("");
    setHasEventCode(false);
  };

  const submitEvent = async () => {
    const event = {
      name,
      code,
      type,
      format,
      startDate,
      endDate,
      city,
      state: eventState,
      venue,
      eventUrl,
      eventColor,
      revenueGoal,
      hotelRate,
      hotelDeadline,
      reservationUrl,
      reservationPhone,
      eventManagers,
      ...address
    };

    if (!isPopulatingForm || (isPopulatingForm && code !== props.event.data.code)) {
      const hasEventWithCode = await new Event().getSingleEvent(code, true);
      if (hasEventWithCode) {
        props.setIsSubmitReady?.(false);
        setHasEventCode(true);
        return;
      }
    }
    props.submitCallback(event, editingIndex);
  };

  useEffect(() => {
    if (props.isSubmitReady) {
      submitEvent();
    }
  });

  useEffect(() => {
    if (isPopulatingForm) {
      const { 
        name, 
        code, 
        type, 
        format, 
        startDate, 
        endDate, 
        address1, 
        address2, 
        city, 
        state, 
        country, 
        zip, 
        venue,
        eventUrl,
        eventColor,
        revenueGoal,
        hotelRate,
        hotelDeadline,
        reservationUrl,
        reservationPhone,
        eventManagers,
      } = props.event.data;

      const defaultManagers = Object.entries(eventManagers || {}).map(([id, user]) => ({
        value: user?.uid,
        label: formatUserName(user),
        itemClass: user,
      }));

      setEditingIndex(props.event.index);
      setEventName(name);
      setEventCode(code);
      setEventType(type);
      setEventFormat(format);
      setStartDate(startDate);
      setEndDate(endDate);
      setCity(city);
      setEventState(state);
      setVenue(venue);
      setEventUrl(eventUrl);
      setEventColor(eventColor);
      setRevenueGoal(revenueGoal);
      setHotelRate(hotelRate);
      setHotelDeadline(hotelDeadline);
      setReservationUrl(reservationUrl);
      setReservationPhone(reservationPhone);
      setEventManagers(eventManagers || {});
      setDefaultEventManagers(defaultManagers);

      updateAddress({
        address1,
        address2,
        city,
        state,
        country,
        zip,
      });
    }
  }, [props.event]);

  const onChangeHandler = (event, setState) => {
    const { value } = event.target;
    setState(value);
  };

  const updateAddress = (address) => {
    if (!address) return;
    setAddress(address)
  };

  const updateEventManagers = (value) => {
    const managers = (value || []).reduce((mapObj, value) => {
      if (value?.itemClass) {
        mapObj[value.itemClass.uid] = value.itemClass;
      }
      return mapObj;
    }, {});
    setEventManagers(managers);
  }

  const nameRow = (
    <Row>
      <Col xs="12" md="2" className="text-right pr-0">
        <Label for="eventName">Event Name</Label>
      </Col>
      <Col md="4">
        <FormGroup>
          <Input
            id="eventName"
            type="text"
            value={name || ""}
            required
            onChange={(event) => onChangeHandler(event, setEventName)}
          />
        </FormGroup>
      </Col>
      <Col xs="12" md="2" className="text-right pr-0">
        <CustomLabel label="Event Code" for="eventCode" required />
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="Each event code must be unique. We suggest adding the year or location to ensure no Event Code is duplicated"
        />
      </Col>
      <Col md="4">
        <FormGroup>
          <Input
            id="eventCode"
            className="mb-0"
            type="text"
            value={code || ""}
            role="textbox"
            required
            onChange={(event) => onChangeHandler(event, setEventCode)}
          />
          {hasEventCode && (
            <Label className="text-danger mb-0">Code Already Exists</Label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );

  const createDatePicker = (label, value, setState, placeholder) => {
    return (
      <Fragment>
        <Col xs="12" md="2" className="text-right pr-0">
          <Label>{label}</Label>
        </Col>
        <Col md="4">
          <FormGroup className="mb-1">
            <ReactDatetime
              value={value || ""}
              onChange={(date) => setState(new Date(date))}
              inputProps={{
                className: "form-control",
                placeholder: placeholder,
              }}
              closeOnSelect={true}
            />
          </FormGroup>
        </Col>
      </Fragment>
    );
  };

  const startDatePicker = () => {
    return createDatePicker(
      "Start Date",
      startDate,
      setStartDate,
      "Start Date"
    );
  };

  const endDatePicker = () => {
    return createDatePicker("End Date", endDate, setEndDate, "End Date");
  };

  const dateInputsRow = (
    <Row>
      {startDatePicker()}
      {endDatePicker()}
    </Row>
  );

  const createSingleSelect = (value, label) => {
    return {
      value,
      label,
    };
  };

  const eventTypeDropDown = () => {
    const eventTypeOptions = eventTypes.map((type, index) =>
      createSingleSelect(index, type)
    );
    let defaultSelected;

    let placeholder = "Event Type";
    let index;
    if (type) {
      index = eventTypes.indexOf(type);
      defaultSelected = eventTypeOptions[index];
    }
    return (
      <CustomSelect
        options={eventTypeOptions}
        placeholder={placeholder}
        defaultSelected={defaultSelected}
        value={type || ""}
        callback={(value) => setEventType(value.label)}
      />
    );
  };

  const eventFormatDropDown = () => {
    const eventFormatOptions = eventFormats.map((format, index) =>
      createSingleSelect(index, format)
    );
    let defaultSelected;
    if (format) {
      const index = eventFormats.indexOf(format);
      defaultSelected = eventFormatOptions[index];
    }
    return (
      <CustomSelect
        options={eventFormatOptions}
        placeholder="Format"
        defaultSelected={defaultSelected}
        value={format || ""}
        callback={(value) => setEventFormat(value.label)}
      />
    );
  };

  const typeAndFormatRow = (
    <Row className="mb-1 mt-0 pt-0">
      <Label md="2"> Type </Label>
      {eventTypeDropDown()}

      <Label md="3"> Format </Label>
      {eventFormatDropDown()}
    </Row>
  );

  const managersRow = (
    <Row className="mt-2">
      <Label md="2">Event Managers</Label>
      <Col md="10">
        <UsersDropdown
          multiSelect
          onChange={updateEventManagers}
          defaultSelected={defaultEventManagers}
        />
      </Col>
    </Row>
  );

  const urlRow = (
    <Row className="mb-1 mt-0 pt-2">
      <Col md="2" className="text-right pr-0">
        <Label for="eventURL">Event URL</Label>
      </Col>
      <Col md="4" className="text-left">
        <Input
          id="eventURL"
          className="mb-0"
          type="url"
          value={eventUrl || ""}
          onChange={(e) => onChangeHandler(e, setEventUrl)}
          onBlur={(e) => setEventUrl(validateUrl(e.target?.value))}
        />
      </Col>
    </Row>
  );
  
  const colorRow = (
    <Row className="mb-1 mt-0 pt-2">
      <Col md="2" className="text-right pr-0">
        <Label>Event Color</Label>
      </Col>
      <Col md="4" className="text-left">
        <ColorPicker color={eventColor} onChange={setEventColor} />
      </Col>
    </Row>
  );

  // @TODO update so Venue gets populated on Edit screen
  const locationRow = (
    <>
      <InputText
        label="Venue"
        colSize={6}
        labelSize={2}
        value={venue || ""}
        type="text"
        inputHandler={setVenue}
      />
      <AddressInputForm
        colSize={6}
        labelSize={2}
        isSameAddress={!!props.event}
        sameAddress={
          !!props.event && {
            address1: props.event.data?.address1,
            address2: props.event.data?.address2,
            city: props.event.data?.city,
            state: props.event.data?.state,
            country: props.event.data?.country,
            zip: props.event.data?.zip,
          }
        }
        getAddressFormValue={updateAddress}
      />
    </>
  );

  const revenueRow = (
    <Row className="mb-1 mt-0 pt-1">
      <Col md="2" className="text-right pr-0">
        <Label for="eventRevenueGoal">Partner Revenue Goal</Label>
      </Col>
      <Col md="4" className="text-left">
        <Input
          id="eventRevenueGoal"
          className="mb-0"
          type="text"
          value={NumberToCurrency(revenueGoal) || ""}
          onChange={(e) => setRevenueGoal(CurrencyToNumber(e.target.value))}
        />
      </Col>
    </Row>
  );

  const hotelRow = (
    <Row className="mb-0 mt-0 pt-2">
      <Col md="2" className="text-right pr-0">
        <Label for="hotelRate">Hotel Rate</Label>
      </Col>
      <Col md="4" className="text-left">
        <Input
          id="hotelRate"
          className="mb-0"
          type="text"
          value={NumberToCurrency(hotelRate) || ""}
          onChange={(e) => setHotelRate(CurrencyToNumber(e.target.value))}
        />
      </Col>
      {createDatePicker(
          "Hotel Deadline",
          hotelDeadline,
          setHotelDeadline,
          "Hotel Deadline"
        )}
    </Row>
  );

  const reservationRow = (
    <Row className="mb-1 mt-0 pt-2">
      <Col md="2" className="text-right pr-0">
        <Label for="reservationUrl">Reservation Link</Label>
      </Col>
      <Col md="4" className="text-left">
        <Input
          id="reservationUrl"
          className="mb-0"
          type="url"
          value={reservationUrl || ""}
          onChange={(e) => onChangeHandler(e, setReservationUrl)}
          onBlur={(e) => setReservationUrl(validateUrl(e.target?.value))}
        />
      </Col>
      <Col md="2" className="text-right pr-0">
        <Label for="reservationPhone">Reservation Phone</Label>
      </Col>
      <Col md="4" className="text-left">
        <PhoneInput
          id="reservationPhone"
          inputStyle={{
            borderColor: "#e0e1e4",
            borderRadius: 8,
            height: "2.5rem",
            width: "100%",
            paddingLeft: 52,
          }}
          country={"us"}
          value={reservationPhone || ""}
          onChange={(phone) => setReservationPhone(phone)}
        />
      </Col>
    </Row>
  );

  const requiredFieldsRow = (
    <Row className="pt-3">
      <Col>
        <CustomLabel label="Required Fields" required isInverted />
      </Col>
    </Row>
  );

  const form = () => {
    return (
      <Fragment>
        {nameRow}
        {dateInputsRow}
        {typeAndFormatRow}
        {managersRow}
        {urlRow}
        {colorRow}
        {locationRow}
        {revenueRow}
        {hotelRow}
        {reservationRow}
        {requiredFieldsRow}
      </Fragment>
    );
  };

  return form();
};

export default NewEventForm;
