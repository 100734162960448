import React, { useEffect, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

const createSingleSelect = (value, label, deliverables, itemClass) => {
  return {
    value,
    label,
    deliverables,
    itemClass,
  };
};

const mapOptions = (booths) =>
  booths.map((value, index) => {
    return createSingleSelect(
      value?.id,
      value?.name,
      value?.deliverables,
      value
    );
  });

function OfferingBoothsDropdown(props) {
  const [value, setValue] = useState(null);
  const [boothsOptions, setBoothsOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const handleChange = (val) => {
    props.callback({ ...val });
    setValue(val);
  };

  useEffect(() => {
    (async function () {
      if (!props.eventData || boothsOptions.length || fetchedData) return;
      setFetchedData(true);
      const booths = props.cloneData
        ? cloneDeep(props.eventData.booths)
        : props.eventData.booths;
      setBoothsOptions(mapOptions(booths));
    })();
  });
  return (
    <CustomSelect
      options={boothsOptions}
      placeholder="Booth"
      value={value}
      defaultSelected={props.defaultSelected}
      callback={handleChange}
      multiSelect={false}
      searchable={true}
      size="9"
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(OfferingBoothsDropdown);
