import React, {useState} from 'react';

import ModalContainer from "../../../shared/ModalContainer";
import NewBoothForm from "./NewBoothForm";

const EditBoothsModal = (props) => {

    const [isSubmitReady, setIsSubmitReady] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const toggle = () => {
        setIsModalOpen(!isModalOpen);
       props.toggle();
    };

    const modalContainerSubmitCallback = () => {
        setIsSubmitReady(true)
    };

    const editBoothFormSubmitCallback = (data, index) => {
        setIsSubmitReady(false);
        props.formSubmitCallBack(data, index);
        props.toggle();
    };

    return (
        <ModalContainer
            modalTitle="Edit Booth"
            isModalOpen={isModalOpen}
            toggleCallBack={() => toggle()}
            submitCallback={() => modalContainerSubmitCallback()}
        >
            <NewBoothForm
                isPopulatingForm={true}
                isSubmitReady={isSubmitReady}
                booth={props.item}
                submitCallback={ (data, index) => editBoothFormSubmitCallback(data, index)}
            />
        </ModalContainer>
    )

};

export default EditBoothsModal
