import React, { useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import classnames from "classnames";

function InputText({
  label,
  className,
  inputHandler,
  value,
  colSize,
  labelSize,
  type,
  handleBlur,
  // Destructuring registration input field props.
  withLabel = true,
  icon,
  focus,
  handleFocus,
  isPasswordInput,
  placeholder,
  invalid,
}) {
  const labelRow = () => (
    <Col xs="12" sm={labelSize} className="text-sm-right pr-sm-0">
      <Label>{label && label}</Label>
    </Col>
  );

  return (
    <Row className="pt-2 pb-1">
      {withLabel && labelRow()}
      <Col sm={colSize}>
        {/* Prop "withLabel is checked to make this component more reusable. By this check the component will also be used
        in registration form." */}
        {withLabel ? (
          <Input
            className={className || ""}
            type={type}
            value={value ? value : ""}
            onChange={(e) => inputHandler && inputHandler(e.target.value)}
            onBlur={(e) => handleBlur && handleBlur(e.target.value)}
          />
        ) : (
          <IconWrapper
            icon={icon}
            focus={focus}
            type={type}
            value={value ? value : ""}
            getChangeValue={(e) => inputHandler && inputHandler(e.target.value)}
            getBlurValue={(e) => handleBlur && handleBlur(e.target.value)}
            getFocusValue={(e) => handleFocus && handleFocus(e.target.value)}
            // onFocus={(e) => handleFocus && handleFocus(e.target.value)}
            placeholder={placeholder}
            isPasswordInput={isPasswordInput}
            invalid={invalid}
          />
        )}
      </Col>
    </Row>
  );
}

// Component to wrap Icon container over the input
const IconWrapper = ({
  getChangeValue,
  getBlurValue,
  getFocusValue,
  placeholder,
  value,
  type,
  icon = "tim-icons icon-single-02",
  isPasswordInput = false,
  invalid,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  // return right aligned icon container to toggle input type.
  const ShowPassword = () => (
    <InputGroupAddon addonType="append">
      <InputGroupText
        style={isFocus ? {} : { borderColor: "#D1D3D7" }}
        onClick={() => setShowPassword((prevState) => !prevState)}
      >
        {showPassword ? (
          <span
            size="sm"
            color="link"
            style={{ cursor: "pointer", color: "black" }}
            className="fas fa-eye m-0 p-0 border-0"
          ></span>
        ) : (
          <span
            color="link"
            style={{ cursor: "pointer", color: "black" }}
            size="sm"
            className="far fa-eye-slash m-0 p-0 border-0"
          ></span>
        )}
      </InputGroupText>
    </InputGroupAddon>
  );

  const handleFocus = (e) => {
    setIsFocus(true);
    getFocusValue && getFocusValue(e);
  };
  const handleBlur = (e) => {
    setIsFocus(false);
    getBlurValue && getBlurValue(e);
  };
  return (
    <InputGroup
      className={classnames({
        "input-group-focus": isFocus,
      })}
      size="lg"
    >
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className={icon} />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        type={isPasswordInput ? (showPassword ? "text" : "password") : type}
        value={value}
        onFocus={(e) => handleFocus(e)}
        onBlur={(e) => handleBlur(e)}
        onChange={(e) => getChangeValue && getChangeValue(e)}
        placeholder={placeholder}
        invalid={invalid}
      />

      {isPasswordInput && <ShowPassword />}
    </InputGroup>
  );
};
export default React.memo(InputText); // use memo to skip re-render it will always render when props coming to this component will change.
