import ModalContainer from "layouts/Plan/shared/ModalContainer";
import React, { useState, Fragment } from "react";
import { Button } from "reactstrap";
import AgendaForm from "./AgendaForm";


const NewAgendaModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agenda, setAgenda] = useState({});

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = () => {
    return (
      <Button color="info" onClick={toggle}>
        + Add Activity
      </Button>
    );
  }

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(false);
    props.submitCallback(agenda);
    toggle();
  };

  return (
    <ModalContainer
      buttonLabel="+ Add Activity"
      modalTitle="Add New Activity"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={modalContainerSubmitCallback}
      button={<OpenModalButton />}
      isSubmitDisabled={!isSubmitReady}
    >
      <AgendaForm
        isPopulatingForm={false}
        setIsSubmitReady={setIsSubmitReady}
        setAgenda={setAgenda}
      />
    </ModalContainer>
  );
};

export default NewAgendaModal;
