const arrayToObject = (arr) => {
  if (Array.isArray(arr)) {
    return arr.reduce((map, obj) => {
      if (obj?.id) {
        map[obj.id] = obj;
      }
      return map;
    }, {});
  }
};

export default arrayToObject;
