import moment from "moment";
import PersistentBase from "./PersistentBase";
import StyledLink from "../../layouts/Plan/shared/StyledLink";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import React from "react";
import store from "redux-store/store";
import { dateFormat } from "utilities/constants/date-format";
import { eventFormats } from "../../utilities/constants/event-terms";
import { NumberToCurrency } from "layouts/Plan/shared/CurrencyFormatter";

export default class Event extends PersistentBase {
  constructor(
    id,
    name,
    type,
    format,
    venue,
    startDate,
    endDate,
    address1,
    address2,
    city,
    state,
    country,
    zip,
    code,
    eventUrl,
    eventColor,
    revenueGoal,
    hotelRate,
    hotelDeadline,
    reservationUrl,
    reservationPhone,
    offerings,
    emails,
    partners,
    eventManagers
  ) {
    super();
    this.id = id;
    this.name = name;
    this.type = type;
    this.format = format;
    this.venue = venue;
    this.startDate = startDate;
    this.endDate = endDate;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.zip = zip;
    this.code = code;
    this.eventUrl = eventUrl;
    this.eventColor = eventColor;
    this.revenueGoal = revenueGoal;
    this.hotelRate = hotelRate;
    this.hotelDeadline = hotelDeadline;
    this.reservationUrl = reservationUrl;
    this.reservationPhone = reservationPhone;
    this.offerings = offerings;
    this.emails = emails;
    this.partners = partners;
    this.eventManagers = eventManagers;
    this.csvTitle = "Event";
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/events`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/events.json`;
  }

  metaData() {
    return {
      name: this.name,
      type: this.type,
      format: this.format,
      venue: this.venue,
      startDate: this.startDate,
      endDate: this.endDate,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.zip,
      code: this.code,
      eventUrl: this.eventUrl,
      eventColor: this.eventColor,
      revenueGoal: this.revenueGoal,
      hotelRate: this.hotelRate,
      hotelDeadline: this.hotelDeadline,
      reservationUrl: this.reservationUrl,
      reservationPhone: this.reservationPhone,
      sponsorships: this.sponsorships,
      deliverables: this.deliverables,
      emails: this.emails,
      partners: this.partners,
      eventManagers: this.eventManagers,
    };
  }

  duplicationData() {
    const data = {
      name: this.name,
      type: this.type,
      format: this.format,
      venue: this.venue,
      startDate: this.startDate,
      endDate: this.endDate,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.zip,
      code: "",
      eventUrl: this.eventUrl,
      eventColor: this.eventColor,
      revenueGoal: this.revenueGoal,
      hotelRate: this.hotelRate,
      hotelDeadline: this.hotelDeadline,
      reservationUrl: this.reservationUrl,
      reservationPhone: this.reservationPhone,
      emails: this.emails,
      partners: this.partners,
      offerings: this.offerings,
      eventManagers: this.eventManagers,
    };
    for (const partner of Object.values(data.partners || {})) {
      const partnerBooths = Object.values(partner.booths || {});
      const partnerSponsorships = Object.values(partner.sponsorships || {});
      for (const offering of partnerBooths.concat(partnerSponsorships)) {
        for (const deliverable of Object.values(offering.deliverables || {})) {
          deliverable.completed = false;
        }
      }
    }
    return data;
  }

  async getSingleEvent(eventCode, returnBool = false) {
    const userOrg = await super.getUserOrgId();
    const query = `orderBy=\"code\"&equalTo=\"${eventCode}\"`;
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrg}/events.json?${query}`
    );
    const response = await super.get();
    if (returnBool) {
      const hasEvent = Boolean(Object.keys(response).length);
      return hasEvent;
    }
    const [eventID, eventData] = Object.entries(response)[0];
    const event = { ...eventData, id: eventID };
    return this.createWithObject(event);
  }

  async getById(eventId) {
    const userOrg = await super.getUserOrgId();
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrg}/events/${eventId}.json`
    );
    const response = await super.get();
    const event = { ...response, id: eventId };
    return this.createWithObject(event);
  }

  createWithObject(payload) {
    const event = payload || this;
    const { sponsorships, deliverables, booths } = this.parseOfferings(
      event.offerings
    );

    this.id = event.id;
    this.name = event.name;
    this.type = event.type;
    this.format = event.format;
    this.venue = event.venue;
    this.startDate = this.dateTransformer(event.startDate);
    this.endDate = this.dateTransformer(event.endDate);
    this.address1 = event.address1;
    this.address2 = event.address2;
    this.city = event.city;
    this.state = event.state;
    this.country = event.country;
    this.zip = event.zip;
    this.code = event.code;
    this.eventUrl = event.eventUrl;
    this.eventColor = event.eventColor;
    this.revenueGoal = event.revenueGoal;
    this.hotelRate = event.hotelRate;
    this.hotelDeadline = this.dateTransformer(event.hotelDeadline);
    this.reservationUrl = event.reservationUrl;
    this.reservationPhone = event.reservationPhone;
    this.offerings = event.offerings;
    this.emails = event.emails;
    this.sponsorships = sponsorships;
    this.deliverables = deliverables;
    this.booths = booths;
    this.partners = event.partners;
    this.eventManagers = event.eventManagers;

    return this;
  }

  // @TODO update to allow venue info/address to save
  async update(event) {
    await this.setRestUrl();
    const updatedEvent = super.createWithObject({ id: this.id, ...event });
    if (!this.url) {
      await this.setRestUrl();
    }
    const url = await super.appendAuth(super.buildUrl(this.url, this.id));
    await super.update(url, updatedEvent);
    return this;
  }

  parseOfferings(offerings) {
    const partnerData = {
      sponsorships: [],
      deliverables: [],
      booths: [],
    };
    if (!offerings) return partnerData;
    for (const [offeringType, offering] of Object.entries(offerings)) {
      partnerData[offeringType].push(...Object.values(offering));
    }
    return partnerData;
  }

  dateTransformer(propDate) {
    const date = new Date(propDate);
    return this.isDateValid(date) ? date : undefined;
  }

  dateToString(date, format = dateFormat) {
    if (!this.isDateValid(date)) return "-";
    return moment(date).format(format);
  }

  createDateRange(startDate, endDate) {
    if (!this.isDateValid(startDate) && !this.isDateValid(endDate)) {
      return "-";
    }
    const start = this.isDateValid(startDate) ? this.dateToString(startDate) : "";
    let end = this.isDateValid(endDate) ? this.dateToString(endDate) : "";
    const sep = start && end ? " - " : "";
    if (start === end) return start;
    return `${start}${sep}${end}`;
  }

  displayFormat() {
    const dateRange = this.createDateRange(this.startDate, this.endDate);
    const location = this.cityAndState(this.city, this.state);

    return {
      name: this.name || "-",
      dateRange: dateRange,
      location: location || "-",
      format: this.format || "-",
    };
  }

  cityAndState(city, state) {
    if (!city && !state) {
      return "";
    }
    const seperator = (city && state) ? ", " : "";
    return `${city}${seperator}${state}`;
  }

  tableCsvData() {
    return {
      name: this.name || "",
      type: this.type || "",
      format: this.format || "",
      venue: this.venue || "-",
      startDate: this.startDate || "",
      endDate: this.endDate || "",
      "address 1": this.address1 || "-",
      "address 2": this.address2 || "-",
      city: this.city || "",
      state: this.state || "",
      country: this.country || "-",
      zip: this.zip || "-",
      code: this.code,
      "event url": this.eventUrl || "-",
      revenueGoal: this.revenueGoal ? NumberToCurrency(this.revenueGoal) : "-",
      hotelRate: this.hotelRate ? NumberToCurrency(this.hotelRate) : "-",
      hotelDeadline: this.hotelDeadline || "-",
      reservationUrl: this.reservationUrl || "-",
      reservationPhone: this.reservationPhone || "-",
    };
  }

  tableColumnsAndValues() {
    const setEvent = () => {
      store.dispatch({ type: "setEventData", data: this });
    };
    return {
      event: (
        <StyledLink to={`/plan/event/${this.code}`} onClick={setEvent}>
          {this.name}
        </StyledLink>
      ),
      code: { cellText: this.centerAlignDiv(this.code), width: 100 },
      "start date": this.centerAlignDiv(this.dateToString(this.startDate) || "-"),
      "end date": this.centerAlignDiv(this.dateToString(this.endDate)|| "-"),
      city: this.centerAlignDiv(this.city || "-"),
      state: this.centerAlignDiv(this.state || "-"),
      format: {
        cellText: this.centerAlignDiv(this.format || "-"),
        width: 200,
        filter: { type: 'dropdown', options: eventFormats, isFilterable: true },
      }
    };
  }

  emailVariables() {
    return {
      name: this.name,
      type: this.type,
      format: this.format,
      venue: this.venue,
      startDate: this.startDate,
      endDate: this.endDate,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.zip,
      code: this.code,
      eventUrl: this.eventUrl,
      revenueGoal: this.revenueGoal,
      hotelRate: this.hotelRate,
      hotelDeadline: this.hotelDeadline,
      reservationUrl: this.reservationUrl,
      reservationPhone: this.reservationPhone,
    }
  }
}
