import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Deliverable from "models/database/Deliverable";
import CrudActionsTable from "../../../../shared/CrudActionsTable";
import EventPartner from "models/database/EventsPartner";
import DeliverableCollection from "../../../../../../models/database/DeliverableCollection";

function DeliverablesTable(props) {
  const [fetchedData, setFetchedData] = useState(false);

  const getData = async () => {
    const eventDeliverables = props.eventData.deliverables.filter((d) => {
      return !d.parentId && !d.partner
      }
    );
    const deliverables = await new Deliverable().getAll(
      eventDeliverables,
      props.eventData.id
    );
    const partnerDeliverables = await new EventPartner().getEventDeliverables(
      props.eventData.id
    );
    const deliverableCollection = new DeliverableCollection().fromArray([...deliverables, ...partnerDeliverables]);

    props.setData(deliverableCollection);
  };

  useEffect(() => {
    if (fetchedData || !props.eventData || props.data.length) return;
    setFetchedData(true);
    getData();
  });

  const pageSize = props.data.length + 5;

  return (
    <>
      <CrudActionsTable
        data={props.data}
        dataModel={DeliverableCollection}
        filterable={true}
        newItem={props.newRow}
        resizable={true}
        defaultPageSize={pageSize}
        modalName="deliverable"
        hasNoActions
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(DeliverablesTable);
