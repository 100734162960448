import React from "react";
import PersistentBase from "./PersistentBase";
import CurrencyFormatter, { CurrencyToNumber, NumberToCurrency } from "../../layouts/Plan/shared/CurrencyFormatter";
import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import ExpandableRows from "layouts/Plan/shared/ExpandableRows";
import MinifiedDeliverable from "./MinifiedDeliverable";

export default class Sponsorship extends PersistentBase {
  constructor(
    id,
    name,
    description,
    deliverables,
    type,
    quantity,
    rate,
    reserved,
    orgId
  ) {
    super();
    this.id = id;
    this.name = name;
    this.description = description;
    this.deliverables = deliverables;
    this.type = type;
    this.quantity = quantity;
    this.rate = rate;
    this.reserved = reserved;
    this.orgId = orgId;
    this.csvTitle = "Sponsorship";
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl;
    this.jsonUrl = customerOrgsFirebaseUrl;
  }

  metaData() {
    return {
      name: this.name,
      type: this.type,
      description: this.description || this.itemClass?.description,
      deliverables: this.deliverables,
      quantity: this.quantity,
      rate: this.rate,
      id: this.id,
      reserved: this.reserved,
      orgId: this.orgId,
      itemClass: this.itemClass,
    };
  }

  emailVariables() {
    return {
      name: this.name,
      type: this.type,
      description: this.description,
      // deliverables: this.deliverables,
      quantity: this.quantity,
      rate: this.rate,
      reserved: this.reserved,
    };
  }

  async getAll(sponsorshipsData, eventId) {
    const userOrgId = await super.getUserOrgId();
    const sponsorships = [];
    let index = 0;
    for (const sponsorship of sponsorshipsData) {
      this.jsonUrl = await super.appendAuth(
        `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/sponsorships/${sponsorship.id}.json`
      );
      const response = await super.get();
      const formattedSponsorship = new this.constructor()
        .createWithObject(response)
        .tableRowFormat(index++);
      sponsorships.push(formattedSponsorship);
    }
    return sponsorships;
  }

  async updateSingleEntity(payload, eventId) {
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/sponsorships/${payload?.id}.json`
    );
    const response = await super.update(url, payload);
    return response;
  }

  async update(payload, event, deleteItem, single = false) {
    if (deleteItem?.id && !Object.keys(payload?.sponsorships || {}).includes(deleteItem.id)) {
      await this.delete(deleteItem.id, event.id);
    }
    const sponsorshipId = single ? `/${Object.keys(payload.sponsorships)[0]}` : "";
    const sponsorshipPayload = single ? Object.values(payload.sponsorships)[0] : payload.sponsorships;
    const userOrgId = await super.getUserOrgId();
    const url = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${userOrgId}/events/${event.id}/offerings/sponsorships${sponsorshipId}.json`
    );
    const response = await super.update(url, sponsorshipPayload);
    if (single) {
      return new this.constructor().createWithObject(response);
    }
    const sponsorships = [];
    for (const sponsorshipData of Object.values(response)) {
      const sponsorship = new this.constructor().createWithObject(
        sponsorshipData
      );
      sponsorships.push(sponsorship);
    }
    return sponsorships;
  }

  async delete(sponsorshipID, eventId) {
    const userOrgId = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/offerings/sponsorships/`;
    const response = await super.delete(sponsorshipID);
    return response;
  }

  tableCsvData() {
    return {
      sponsorship: this.name || "-",
      quantity: this.quantity || "0",
      reserved: this.reserved || "0",
      rate: NumberToCurrency(this.rate || 0),
      type: this.type || "-",
    };
  }

  tableColumnsAndValues() {
    return {
      sponsorship: (
        <ExpandableRows 
          name={this.name} 
          tooltip="Expand Deliverables"
          data={Object.values(this.deliverables || {}).map((deliverable, index) => 
            new MinifiedDeliverable().createWithObject(deliverable).tableRowFormat(index))}
          hasNoActions
          dataModel={MinifiedDeliverable}
        />
      ),
      quantity: {
        cellText: this.centerAlignDiv(this.quantity || "0"),
        width: 110,
      },
      reserved: { cellText: this.centerAlignDiv(this.reserved || "0"), width: 100 },
      rate: {
        cellText: this.centerAlignDiv(
          <CurrencyFormatter value={this.rate || "0"} data-sort={CurrencyToNumber(this.rate || 0)} />
        ),
        width: 100,
      },
      type: { cellText: this.centerAlignDiv(this.type || ""), width: 100 },
    };
  }
}
