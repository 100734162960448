import React, { useState } from "react";
import { connect} from "react-redux";
import {
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";
import UsersTable from "./accountSettingsPage/UsersTable";
import UsersModal from "./accountSettingsPage/UsersModal";
import MailgunEmail from "models/backend/MailgunEmail";
import SuperAdminTable from "./accountSettingsPage/superAdmin/SuperAdminTable";
import { adminEmails } from "utilities/constants/arcat-admin-emails";

const AccountSettings = (props) => {
  const [usersTableData, setUsersTableData] = useState([]);
  const [superAdminTableData, setsuperAdminTableData] = useState([]);

  const submitCallback = async (emailForm) => {
    const mailgunResponse = await new MailgunEmail()
      .createWithObject(emailForm)
      .create();
    const error =
      mailgunResponse.error || mailgunResponse.find((res) => res.error);
    if (error) {
    }
  }

  const superAdminTable = () => {
    if (process.env.NODE_ENV === "production" && !adminEmails.includes(props.userData.email)) return;
    return (
        <Card>
          <CardHeader>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <CardTitle tag="h4">Super Admin</CardTitle>
            </div>
          </CardHeader>
          <CardBody>
            <SuperAdminTable data={superAdminTableData} setData={setsuperAdminTableData} />
          </CardBody>
        </Card>
    )
  }

  return (
    <>
      <div id="account-settings-page" className="content">
        <Col>
          <Card>
            <CardHeader>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <CardTitle tag="h4">Users</CardTitle>
                <UsersModal submitCallback={submitCallback} />
              </div>
            </CardHeader>
            <CardBody>
              <UsersTable data={usersTableData} setData={setUsersTableData} />
            </CardBody>
          </Card>
          { superAdminTable() }
        </Col>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    userData: state.user
  }
};

export default connect(mapStateToProps)(AccountSettings);
