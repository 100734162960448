import React, { useState } from "react";

const HelpCenter = (props) => {
    const iframe = '<iframe src=" arcatxp.zendesk.com" width="540" height="450"></iframe>';

    function createMarkup() {
        return {__html: '<iframe src="https://www.arcatxp.zendesk.com" width="540" height="450"></iframe>'};
    }
    return (
        <div>
            <div dangerouslySetInnerHTML={ createMarkup() } />
            {/*<iframe src={"https://www.arcatxp.zendesk.com"} width="540" height="450" loading="lazy" />*/}
        </div>
    )
}

export default HelpCenter;
