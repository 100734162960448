import React, { useState, useEffect } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import IconTooltip from "../IconTooltip";
function CountryDropDown({
  getCountry,
  getRegion,
  getCountryAndState,
  countryValue,
  stateValue,
  withLabel = true,
}) {
  const [country, setcountry] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    getCountryAndState && getCountryAndState({ country, state });
  }, [country, state]);

  return (
    <>
      <Row className="pt-2 pb-2">
        {withLabel && (
          <Col xs="12" sm="2" className="text-sm-right pr-sm-0">
            <Label>Country</Label>
            <IconTooltip 
              iconClass="fas fa-info-circle pl-1 arcat icon-md"
              text="You must select a country before states or territories will appear."
            />
          </Col>
        )}
        <Col sm={withLabel ? "4" : "6"}>
          <CountryDropdown
            priorityOptions={["US"]}
            style={borderStyle}
            value={country || countryValue}
            onChange={(val) => {
              setcountry(val);
              getCountry && getCountry(val);
            }}
            // onBlur={(val) => handleBlurCountry(val)}
          />
        </Col>
        {withLabel && (
          <Col xs="12" sm="2" className="text-sm-right pr-sm-0">
            <Label>State</Label>
            <IconTooltip 
              iconClass="fas fa-info-circle pl-1 arcat icon-md"
              text="If this field is blank make sure you have selected the country for the country field."
            />
          </Col>
        )}
        <Col sm={withLabel ? "4" : "6"}>
          <RegionDropdown
            style={borderStyle}
            country={country || countryValue}
            value={state || stateValue}
            onChange={(val) => {
              setState(val);
              getRegion && getRegion(val);
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default React.memo(CountryDropDown);

export const borderStyle = {
  borderColor: "#1d253b78",
  borderRadius: 8,
  height: "5.5vh",
  width: "100%",
  backgroundColor:"white"
};
