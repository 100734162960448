/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
// reactstrap layout-components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

class Login extends React.Component {
  state = {};
  componentDidMount() {
    document.body.classList.toggle("login-page");
    this.errorMap = {
      "auth/invalid-email": {
        status: "The email address you entered is invalid.",
        call: () => this.setState({ emailInvalid: true }),
      },
      "auth/user-not-found": {
        status: "An account with this email does not exist.",
        call: () => this.setState({ emailInvalid: true }),
      },
      "auth/wrong-password": {
        status: "The password you entered is invalid.",
        call: () => this.setState({ passInvalid: true }),
      },
      "auth/user-disabled": {
        status: "Unauthorized - please contact your Arcat admin.",
        call: () => this.setState({ emailInvalid: true }),
      }
    };
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
  resetStatus = () => {
    this.setState({
      passInvalid: false,
      emailInvalid: false,
      status: "",
      statusColor: "text-danger",
    });
  };
  getLoginStatus = (errorCode = null) => {
    const { emailValue, passValue } = this.state;
    if (errorCode && this.errorMap[errorCode]) {
      this.errorMap[errorCode].call();
      return this.errorMap[errorCode].status;
    } else if (!emailValue) {
      return "Please enter your email";
    } else if (!passValue) {
      return "Please enter your password";
    } else {
      return "An error has occurred. Please try again later.";
    }
  };
  loginUser = () => {
    if (!this.state.emailValue || !this.state.passValue) {
      this.setState({
        passInvalid: !this.state.passValue,
        emailInvalid: !this.state.emailValue,
        status: this.getLoginStatus(),
        statusColor: "text-danger",
      });
      return;
    }
    this.resetStatus();
    this.props.firebase
      .auth()
      .signInWithEmailAndPassword(this.state.emailValue, this.state.passValue)
      .then((user) => {
        // Redirect to dashboard
        this.props.history.push("/plan/events");
      })
      .catch((error) => {
        console.warn("error", error.message);
        this.setState({
          status: this.getLoginStatus(error.code),
        });
      });
  };
  handleKeyPress = (e) => {
    if (e && e.keyCode === 13) {
      // 13 == "Enter"
      this.loginUser();
    }
  };
  resetPassword = (e) => {
    e.preventDefault();
    if (!this.state.emailValue) {
      this.setState({
        passInvalid: false,
        emailInvalid: true,
        status: "Please enter your email",
        statusColor: "text-danger",
      });
      return;
    }
    this.resetStatus();
    this.props.firebase
      .auth()
      .sendPasswordResetEmail(this.state.emailValue)
      .then(() => {
        this.setState({
          status:
            "We've sent an email with instructions to reset your password",
          statusColor: "text-info",
        });
      })
      .catch((error) => {
        console.warn(error);
        this.setState({
          status: this.getLoginStatus(error.code),
        });
      });
  };

  render() {
    return (
      <>
        <div id="login-page" className="content">
          <a className="auth-logo-top" href="/">
            <img src={require("assets/img/arcat-logo-full.png").default} />
          </a>
          <Container>
            <Col className="ml-auto mr-auto" lg="5" md="6">
              <Form className="form">
                <Card className="card-login card-white">
                  <CardHeader className="pt-5 pb-5">
                    <CardTitle className="arcat text-primary pt-4" tag="h1">
                      log in.
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="pt-4">
                    <InputGroup
                      className={classnames({
                        "input-group-focus": this.state.emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className=""
                        placeholder="Email"
                        type="text"
                        onFocus={(e) => this.setState({ emailFocus: true })}
                        onBlur={(e) => this.setState({ emailFocus: false })}
                        onInput={(e) =>
                          this.setState({ emailValue: e.target.value })
                        }
                        onKeyDown={this.handleKeyPress}
                        invalid={this.state.emailInvalid}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": this.state.passFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={(e) => this.setState({ passFocus: true })}
                        onBlur={(e) => this.setState({ passFocus: false })}
                        onInput={(e) =>
                          this.setState({ passValue: e.target.value })
                        }
                        onKeyDown={this.handleKeyPress}
                        invalid={this.state.passInvalid}
                      />
                    </InputGroup>
                    <h5
                      className="arcat text-primary cursor-pointer"
                      onClick={this.resetPassword}
                    >
                      forget password?
                    </h5>
                    <h5
                      className={classnames(
                        this.state.statusColor,
                        "text-center"
                      )}
                    >
                      {this.state.status}
                    </h5>
                  </CardBody>
                  <CardFooter className="pb-5 text-center">
                    <Button
                      className="arcat button-tertiary"
                      color="secondary"
                      onClick={this.loginUser}
                      size="lg"
                    >
                      LOG IN
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  firebase: state.firebase,
});

export default connect(mapStateToProps)(Login);
