import isEventEditable from "utilities/methods/isEventEditable";

const handlers = {
    authUpdate: (state, event) => ({ ...state, user: event.user }),
    orgUpdate: (state, event) => ({ ...state, userOrg: event.userOrg }),
    logOut: (state, event) => ({ ...state, userOrg: null, user: null }),
    setEventData: (state, event) => {
      state.eventData = event.data;
      state.isEventEditable = isEventEditable(state);
      return state;
    },
    setOrgData: (state, org) => ({ ...state, orgData: org.data }),
    addOrgData:(state,{data}) =>({ ...state, orgData: [...state.orgData,data] }),
    setContactsData:(state, contacts)=>({ ...state, contactsData: contacts.data }),
    firebaseInit:(state, event) => ({ ...state, firebase: event.firebase }),
    setOrgUsers: (state, users) => ({
        ...state,
        orgUsers: users?.data.filter((val) => Boolean(val)),
    }),
    setEventContacts: (state, event) => ({
        ...state,
        eventContacts: event.data,
    }),

    filterEventData: (state, event) => {
        if (!state.eventData || !event.id || !state.eventData[event.collection])
            return state;
        state.eventData[event.collection] = state.eventData[
            event.collection
        ].filter(
            (obj) =>
                obj.id !== event.id ||
                (event.parentId && obj.parentId !== event.parentId)
        );
        return state;
    },
    addEventData: (state, event) => {
        if (
            !state.eventData ||
            !event.data ||
            !state.eventData[event.collection]
        )
            return state;
        state.eventData[event.collection].push(...event.data);
        return state;
    },
    editEventData: (state, event) => {
        if (
            !state.eventData ||
            !event.newData ||
            !state.eventData[event.collection]
        )
            return state;
        const filterIds = [event.newData.id, event.deleteId];
        state.eventData[event.collection] = state.eventData[
            event.collection
        ].filter((obj) => !filterIds.includes(obj.id));
        state.eventData[event.collection].push(event.newData);
        return state;
    },
};

export default handlers;
