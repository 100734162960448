import React, { useState, useEffect } from "react";
// reactstrap layout-components
import { Row, Col } from "reactstrap";
import { roleTypes } from "utilities/constants/user-terms";
import DropDownList from "./DropDownList";

const createSingleSelect = (value, label) => {
  return {
    value,
    label,
  };
};

export default function RoleTypeDropDownList(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(roleTypes.map((type, index) =>
      createSingleSelect(index, type)
    ));
  }, []);

  return (
    <Row className="my-0 py-0 mb-1 pb-1">
      <Col md={12} className="mx-0 px-0">
        <DropDownList
          options={options}
          placeholder="Role Type"
          defaultSelected={props.defaultSelected}
          callback={props.onSelectRoleType}
          multiSelect={false}
          searchable={true}
          onAuthPage={props.onAuthPage}
        />
      </Col>
    </Row>
  );
}
