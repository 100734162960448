/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";

// react component for creating dynamic tables
import Event from "../../../../models/database/Event";
import Events from "../../../../models/database/Events";

import CrudActionsTable from "../../shared/CrudActionsTable";
import EditEventModal from "../events/EditEventModal";

const EventsTable = (props) => {
  const isMounted = useRef(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    isMounted.current = true;
    (async function() {
      const events = await new Events().getAllEntities();
      if (isMounted.current) {
        setData(events);
      }
    })();
    return () => { isMounted.current = false };
  }, []);
  const pageSize = 10;

  return (
    <>
      <CrudActionsTable
        data={data}
        dataModel={Event}
        filterable={true}
        newItem={props.newRow}
        resizable={true}
        defaultPageSize={pageSize}
        modalName="event"
        editModal={EditEventModal}
      />
    </>
  );
}

export default EventsTable;
