import React, { useEffect, useState } from "react";
import CrudActionsTable from "layouts/Plan/shared/CrudActionsTable";
import { connect } from "react-redux";
import CustomerOrgUsers from "models/database/CustomerOrgUsers";
import User from "models/database/User";
import EditUsersModal from "./EditUsersModal";

function SponsorshipsTable(props) {

  useEffect(() => {
    (async function() {
      const orgUsersData = await new CustomerOrgUsers().getAllEntities(false);
      const usersData = await new User().getAllUsers(orgUsersData);
      // console.log("users table data", JSON.parse(JSON.stringify(usersData)));
      props.setData(usersData);
    })();
  }, []);

  return (
      <>
          <CrudActionsTable
              data={props.data}
              dataModel={User}
              filterable={true}
              resizable={true}
              defaultPageSize={8}
              modalName="User"
              editModal={EditUsersModal}
              hasDuplicateAction={false}
          />
      </>
  );
}

const mapStateToProps = state => ({
  eventData: state.eventData
});

export default connect(mapStateToProps)(SponsorshipsTable);
