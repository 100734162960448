import classNames from "classnames";
import React, { useRef } from "react";
import NotificationAlert from "react-notification-alert";
import { Card, Col } from "reactstrap";

const SectionContainer = (props) => {
  const notificationRef = useRef("notificationAlert");
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationRef} />
      </div>
      <div className={classNames("content")}>
        <Col className={props.colClassName} xs={12} md={12}>
          <Card style={props.noShadow ? { boxShadow: "none" } : {}}>
            {props.children}
          </Card>
        </Col>
      </div>
    </>
  );
}

export default SectionContainer;
