import React from "react";

// PersistentBase class for all Feature models to extend (non-persistant)
export default class BaseFeature {

    /**
     * @description generic way to iterate over an object and set data attributes to the class
     * @param payload
     * @returns {{}}
     */
    createWithObject(payload) {
        const data   = payload || this;

        const keys   = Object.keys(data);
        const values = Object.values(data);

        const obj = {};
        keys.forEach((key, index) => {
            if (key !== "url" && key !== 'jsonUrl') {
                this[key] = values[index];
                obj[key] = values[index]
            }
        });

        return obj;
    }

    metaData(payload) {
        return this.createWithObject(payload)
    }
}
