import React, { useEffect, useState } from "react";

import CrudActionsTable from "../../shared/CrudActionsTable";
import AccountEmailTemplate from "models/database/AccountEmailTemplate";
import AccountEmailTemplates from "models/database/AccountEmailTemplates";
import EditEmailTemplateModal from "./EditEmailTemplateModal";

function EmailTemplatesTable(props) {
    const [fetchedData, setFetchedData] = useState(false);

    const getData = async () => {
        const emails = await new AccountEmailTemplates().getAllEntities();
        const validEmails = emails.filter(o => o.itemClass.id);
        // console.log("emails table data", JSON.parse(JSON.stringify(validEmails)));
        props.setData(validEmails);
    }

    useEffect(() => {
        (function() {
            if (fetchedData || props.data.length) return;
            setFetchedData(true);
            getData();
        })();
    });

    const pageSize = 8;

    return (
        <>
            <CrudActionsTable
                data={props.data}
                dataModel={AccountEmailTemplate}
                filterable={true}
                resizable={true}
                defaultPageSize={pageSize}
                modalName="Email"
                editModal={EditEmailTemplateModal}
                newItem={props.newItem}
                isExportable={false}
            />
        </>
    );
}

export default EmailTemplatesTable;
