import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label, CustomInput } from "reactstrap";
import ModalContainer from "../../../../shared/ModalContainer";
import DeliverablesDropdown from "../../../../shared/DeliverablesDropdown";
import { connect } from "react-redux";
import ReactDatetime from "react-datetime";
import moment from "moment";

const EditDeliverablesModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [deliverable, setDeliverable] = useState({
    index: 0,
    completed: false,
    rate: "",
    quantity: "",
    deadline: moment(""),
    description: "",
  });
  const [initialDeliverable, setInitialDeliverable] = useState({});

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  useEffect(() => {
    setDeliverable({
      ...props.item.data,
      rate: parseInt(props.item.data.rate || 0),
      quantity: parseInt(props.item.data.quantity || 0),
    });
    setInitialDeliverable(props.item.data);
  }, [props.item]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const deliverableChangeHandler = (value, type) => {
    setDeliverable((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const deliverablesDropdownCallback = (dropdownData) => {
    setDeliverable({
      ...dropdownData,
      rate: deliverable.rate,
      quantity: deliverable.quantity,
      name: dropdownData.label,
    });
  };

  const formIsValid = () => {
    const isValid = !!(
      deliverable.id &&
      Number.isInteger(deliverable.quantity) &&
      Number.isInteger(deliverable.rate) &&
      deliverable.deadline
    );
    return isValid;
  };

  const submitCallback = async () => {
    if (!formIsValid()) return;
    toggle();
    const payload = {
      deliverables: {
        [deliverable.id]: deliverable,
      },
    };

    if (
      !initialDeliverable ||
      !initialDeliverable.id ||
      initialDeliverable.id === deliverable.id
    ) {
      setInitialDeliverable({});
    }
    props.formSubmitCallBack(payload, props.item.index, initialDeliverable);
  };

  const childDeliverableLabel = () => {
    const col = deliverable.parentCollection;
    const collection = col.endsWith("s") ? col.slice(0, col.length - 1) : col;
    return (
      <Label className="mt-3" style={{ marginLeft: "18px" }}>
        {/* {deliverable.name} - child of {parentName}: {deliverable.parentName} */}
        {deliverable.name} (included in {deliverable.parentName} {collection})
        {/* {Deliverable Name} (included in {Booth Name} booth */}
      </Label>
    );
  };

  const partnerDeliverableLabel = () => (
    <Label className="mt-3" style={{ marginLeft: "18px" }}>
      {deliverable.name} - child of partner {deliverable.partnerOrg?.name || ""}
    </Label>
  );

  const dropdownElement = (
    <DeliverablesDropdown
      callback={deliverablesDropdownCallback}
      defaultValue={{ ...deliverable, label: deliverable.name, value: 0 }}
      filterItems={true}
    />
  );

  const deliverableInputs = (
    <>
      <Row>
        {deliverable.parentCollection
          ? childDeliverableLabel()
          : deliverable.partnerCollection
          ? partnerDeliverableLabel()
          : dropdownElement}
      </Row>
      <Row className="pl-3 pr-3 mt-2">
        <Col md="1" className="m-0 p-0 pt-1 mt-1">
          <CustomInput
            id={`deliverable-${deliverable.id}`}
            type="checkbox"
            inline={true}
            checked={deliverable.completed || false}
            title="Completed"
            onChange={(e) => {
              deliverableChangeHandler(e.target.checked, "completed");
            }}
            aria-label="Completed"
          />
        </Col>
        <Col md="3" className="m-0 p-0">
          <Input
            type="number"
            step="1"
            placeholder="Quantity"
            value={deliverable.quantity || ""}
            onChange={(e) =>
              deliverableChangeHandler(parseInt(e.target.value), "quantity")
            }
          />
        </Col>
        <Col md="3" className="m-0 p-0 pl-2">
          <Input
            type="number"
            step="1"
            placeholder="Rate"
            value={deliverable.rate || ""}
            onChange={(e) =>
              deliverableChangeHandler(parseInt(e.target.value), "rate")
            }
          />
        </Col>
        <Col md="5" className="m-0 p-0 pl-2">
          <ReactDatetime
            onChange={(date) =>
              deliverableChangeHandler(moment(date), "deadline")
            }
            value={deliverable.deadline}
            dateFormat="MMMM Do YYYY"
            timeFormat="h:mm a"
            inputProps={{
              className: "form-control",
              placeholder: "Deadline",
            }}
            closeOnSelect={true}
          />
        </Col>
      </Row>
      <Row className="pl-2 pr-3 mt-2">
        <Col md="12" className="m-0 p-0 pl-2">
          <Input
            type="text"
            placeholder="Description"
            value={deliverable.description || ""}
            onChange={(e) =>
              deliverableChangeHandler(e.target.value, "description")
            }
          />
        </Col>
      </Row>
    </>
  );

  return (
    <ModalContainer
      buttonLabel="+ Edit Deliverable"
      modalTitle="Edit Deliverable"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      isSubmitDisabled={!isSubmitReady}
    >
      <Label className="ml-1 mb-0 mt-0">Deliverable</Label>
      {deliverableInputs}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(EditDeliverablesModal);
