import React, { useEffect, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

const createSingleSelect = (
  value, 
  label, 
  deliverables,
  itemClass,
  ) => {
  return {
    value,
    label,
    deliverables,
    itemClass,
  };
};

const mapOptions = (sponsorships) =>
  sponsorships.map((value, index) => {
    return createSingleSelect(
      value?.id, 
      value?.name, 
      value?.deliverables,
      value,
    );
  });

function OfferingSponsorshipsDropdown(props) {
  const [value, setValue] = useState(null);
  const [sponsorshipOptions, setSponsorshipOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const handleChange = (val) => {
    props.callback({ ...val, index: props.index });
    setValue(val);
  };

  useEffect(() => {
    (async function () {
      if (!props.eventData || sponsorshipOptions.length || fetchedData) return;
      setFetchedData(true);
      let sponsorships = props.cloneData
        ? cloneDeep(props.eventData.sponsorships)
        : props.eventData.sponsorships;
      if (props.filterByType) {
        sponsorships = sponsorships.filter(
          (o) =>
            o.itemClass?.type &&
            o.itemClass.type.toLowerCase() ===
            props.filterByType.toLowerCase()
        );
      }
      setSponsorshipOptions(mapOptions(sponsorships));
    })();
  });

  return (
    <CustomSelect
      options={sponsorshipOptions}
      placeholder="Sponsorship"
      value={value}
      defaultSelected={props.defaultSelected}
      callback={handleChange}
      multiSelect={props.multiSelect}
      searchable={true}
      size="9"
      whiteSpace="normal"
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(OfferingSponsorshipsDropdown);
