import React, { useState, useEffect } from "react";
import ModalContainer from "../../../shared/ModalContainer";
import DeliverablesDropdown from "../../../shared/DeliverablesDropdown";
import AccountSponsorship from "../../../../../models/database/AccountSponsorship";
import { Row, Col, Button } from "reactstrap";
import { object } from "prop-types";

export default function AddDeliverablesModal({
  deliverables,
  accountEntityId,
  modalSubmitCallBack,
  defaultValue,
}) {
  const showButton = true;
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeliverables, setSelectedDeliverables] = useState([]);
  const [deliverableId, setDeliverableId] = useState("");

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = () => {
    return showButton === false ? (
      ""
    ) : (
      <Button color="info" onClick={() => toggle()}>
        + Add Deliverables
      </Button>
    );
  };
  const arrayToObject = (arr) => {
    return arr.reduce((map, obj) => {
      if (obj && obj.id) {
        if (obj.label) {
          obj.name = `${obj.label}`;
          delete obj.label;
        }
        map[obj.id] = obj;
        setDeliverableId(obj.id);
      }
      return map;
    }, {});
  };

  const modalContainerSubmitCallback = async (data) => {
    let deliverablesObject = arrayToObject(Object.values(selectedDeliverables));
    toggle();
    await modalSubmitCallBack(
      deliverablesObject,
      accountEntityId,
      deliverableId
    );
    setSelectedDeliverables([]);
  };

  return (
    <ModalContainer
      buttonLabel="+ Add Deliverables"
      modalTitle="Add New Deliverables"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={(data) => modalContainerSubmitCallback(data)}
      button={<OpenModalButton />}
    >
      <DeliverablesDropdown
        filterOfferingDeliverables={true}
        filterOfferingDeliverablesData={deliverables}
        callback={(res) => setSelectedDeliverables(res)}
        multiSelect={true}
        // defaultValue={{ label: selectedDeliverables.name, value: 0 }}
        // defaultValue={sel}
      />
    </ModalContainer>
  );
}
