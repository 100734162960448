import React, { useEffect, useState } from "react";

import CrudActionsTable from "../../../../shared/CrudActionsTable";
import Email from "models/database/Email";
import { CardTitle } from "reactstrap";
import { connect } from "react-redux";
import NewEmailModal from "./NewEmailModal";

function SentEmailsTable(props) {
  const [fetchedData, setFetchedData] = useState(false);

  const getData = async () => {
    if (!props.eventData.emails) return;
    const eventEmailIds = Object.keys(props.eventData.emails);
    const emails = await new Email().getAll(eventEmailIds, props.eventData.id);
    // console.log("emails table data", JSON.parse(JSON.stringify(emails)));
    props.setData(emails);
  };

  useEffect(() => {
    (function () {
      if (fetchedData || !props.eventData || props.data.length) return;
      setFetchedData(true);
      getData();
    })();
  });

  const pageSize = props.data.length + 5;

  return (
    <>
      <CrudActionsTable
        data={props.data}
        dataModel={Email}
        filterable={true}
        resizable={true}
        defaultPageSize={pageSize}
        modalName="Email"
        editModal={NewEmailModal}
        hasNoActions={!props.isEventEditable}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(SentEmailsTable);
