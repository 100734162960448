/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// react component for creating dynamic tables
import Organization from "../../../../models/database/Organization";
import Organizations from "../../../../models/database/Organizations";

import CrudActionsTable from "../../shared/CrudActionsTable";
import EditOrgModal from "./EditOrgModal";

class OrgTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const orgs = await new Organizations().getAllEntities();
    if (this._isMounted) this.setState({ data: orgs });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const pageSize = 10;

    return (
      <>
        <CrudActionsTable
          data={this.state.data}
          dataModel={Organization}
          newItem={this.props.newRow}
          defaultPageSize={pageSize}
          modalName="organization"
          editModal={EditOrgModal}
        />
      </>
    );
  }
}

export default OrgTable;
