import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardHeader, Label, Table } from "reactstrap";
import EditContactModal from "../editContactModal";
import ContactModel from "../../../../../models/database/Contact";
import Accordion from "layouts/Plan/shared/Accordion";
import IconPencilButton from "../../../shared/buttons/IconPencilButton";
import OrgModel from "../../../../../models/database/Organization";

export function TableRow({ label, heading }) {
  return (
    <thead>
      <tr>
        <td>
          <p>{heading} :</p>
        </td>
        <td>
          <Label>{label ? label : "-"}</Label>
        </td>
      </tr>
    </thead>
  );
}

function Contact(props) {
  const [eventsCollapse, setEventsCollapse] = useState(false);
  const [historyCollapse, setHistoryCollapse] = useState(false);
  const [contactId, setcontactId] = useState({});
  const [contactData, setcontactData] = useState({});
  const [orgData, setOrgData] = useState({});

  const [isShowingEditContactModal, setIsShowingEditContactModal] = useState(
    false
  );

  const getContactInfo = async () => {
    const [
      root,
      plan,
      section,
      fullName,
    ] = props.history.location.pathname.split("/");
    const [
      id,
      contactPayload,
    ] = await new ContactModel().getOrgContactFromFullName(fullName);

    const org = await new OrgModel().getOrgById(contactPayload.orgId);
    setOrgData(org);
    contactPayload.orgName = org.name;
    setcontactData(contactPayload);
  };
  useEffect(() => {
    const { state } = props.history.location;

    if (!state) {
      getContactInfo();
    } else {
      setcontactId(state.contact.id);
      setcontactData(state.contact);
      setOrgData(state.contact.orgData)
    }

  }, []);

  const toggleModifyContactModal = () => {
    setIsShowingEditContactModal((prevState) => !prevState);
  };

  const handleContactUpdate = async (data, index) => {
    let contactObj = await new ContactModel(contactId);
    let updatedData = contactObj.update(data);
    updatedData.then((res) => {
      setcontactData({ ...res });
      props.history.push(`/plan/contact/${data.fullName}`);
    });
  };

  function showEditContactModal() {
    return (
      <>
        <EditContactModal
          item={{ data: { ...contactData } }}
          formSubmitCallBack={(data, index) => handleContactUpdate(data, index)}
          toggle={() => {
            toggleModifyContactModal();
          }}
        />
      </>
    );
  }

  if (Object.keys(contactData).length) {
    var {
      firstName,
      lastName,
      title,
      contactType,
      phone,
      email,
      address1,
      address2,
      city,
      state,
      zip,
      orgName
    } = contactData;
  }

  const headingRow = (
    <Row>
      <Col xs={10} md={8} className="pr-0">
        <Card className="">
          <CardHeader className="my-0">
            <h3 className="mb-3">CONTACT INFORMATION</h3>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );

  const infoTableRow = (
    <Row>
      <Col xs={10} md={8} className=" pr-0">
        <Card className="mx-0">
          {/* Information Section */}
          <CardHeader
            className="pt-0 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3 className="my-0" style={{ textTransform: "uppercase" }}>
              {firstName} {lastName}
            </h3>
            <IconPencilButton
              onClick={() => setIsShowingEditContactModal(true)}
            />
          </CardHeader>
          <CardBody>
            <Table>
              <TableRow heading="Organization Name" label={orgName} />
              <TableRow heading="Title" label={title} />
              <TableRow heading="Contact Type" label={contactType} />
              <TableRow heading="Phone Number" label={phone} />
              <TableRow heading="Email" label={email} />
              <TableRow heading="Address 1" label={address1} />
              <TableRow heading="Address 2" label={address2} />
              <TableRow heading="City" label={city} />
              <TableRow heading="State" label={state} />
              <TableRow heading="Zip Code" label={zip} />
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
  const accordionRow = (
    <Row>
      <Col xs={10} md={8} className=" ml-1 pr-3 pl-4 ml-md-2 pl-md-4 pr-md-4">
        <Accordion orgData={orgData} />
      </Col>
    </Row>
  );
  return (
    <>
      {isShowingEditContactModal ? showEditContactModal() : ""}
      <div className="rna-container"></div>
      <div className="content">
        {headingRow}
        {infoTableRow}
        {accordionRow}
      </div>
    </>
  );
}

export default Contact;
