import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import AccountSponsorships from "models/database/AccountSponsorships";
import { connect } from "react-redux";

const createSingleSelect = (
  value,
  label,
  id,
  type,
  sponsorships,
  deliverables,
  itemClass
) => {
  return {
    value,
    label,
    id,
    type,
    sponsorships,
    deliverables,
    name: label,
    itemClass,
  };
};

const mapOptions = (sponsorships) =>
  sponsorships.map((value, index) =>
    createSingleSelect(
      index,
      value.sponsorship,
      value.itemClass.id,
      value.itemClass.type,
      value.itemClass.sponsorships,
      value.itemClass.deliverables,
      value.itemClass
    )
  );

function SponsorshipsDropdown(props) {
  const isMounted = useRef(false);
  const [value, setValue] = useState(null);
  const [sponsorshipOptions, setSponsorshipOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const handleChange = (val) => {
    props.callback({ ...val, index: props.index });
    setValue(val);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    (async function () {
      if (!props.eventData || sponsorshipOptions.length || fetchedData) return;
      setFetchedData(true);
      let sponsorships = await new AccountSponsorships().getAllEntities();

      if (props.filterByType) {
        sponsorships = sponsorships.filter(
          (o) =>
            o.itemClass.type &&
            o.itemClass.type.toLowerCase() ===
            props.filterByType.toLowerCase()
        );
      }

      if (!props.isUnfiltered) {
        const eventSponsorshipIds = props.eventData.sponsorships.map(
          (o) => o.id
        );
        sponsorships = sponsorships.filter(
          (o) =>
            (props.isEventLevel &&
              eventSponsorshipIds.includes(o.itemClass.id)) ||
            (!props.isEventLevel &&
              !eventSponsorshipIds.includes(o.itemClass.id)) ||
            (props.defaultValue && o.itemClass.id === props.defaultValue.id)
        );
      }

      //converting sponsorship value from object to string
      let formattedSponsorships = [];
      for (let item of sponsorships) {
        if (typeof item.sponsorship == "string") {
          formattedSponsorships.push(item);
        } else {
          formattedSponsorships.push({
            ...item,
            sponsorship:
              item.itemClass?.name || item.sponsorship?.props?.children,
          });
        }
      }
      
      if (isMounted.current) {
        setSponsorshipOptions(mapOptions(formattedSponsorships));
      }
    })();
  });

  return (
    <CustomSelect
      options={sponsorshipOptions}
      placeholder="Sponsorship"
      value={value}
      defaultSelected={props.defaultValue}
      callback={handleChange}
      multiSelect={props.multiSelect || props.type === "A La Carte"}
      searchable={true}
      size="9"
      isNotPadded={props.isNotPadded}
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(SponsorshipsDropdown);
