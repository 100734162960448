import React, { useState, useEffect } from "react";
import { Row, Col, Input, Label } from "reactstrap";
import ModalContainer from "../../Plan/shared/ModalContainer";
import RoleTypeDropDown from "layouts/Plan/shared/RoleTypeDropDownList";

const EditUsersModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  useEffect(() => {
    setUser(props.item.data);
  }, [props.item]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const userInputHandler = (key, value) => {
    setUser(prevData => ({ ...prevData, [key]: value }));
  };

  const formIsValid = () => {
    const isValid = !!(
      user.firstName &&
      user.lastName && 
      user.email &&
      user.roleType
    );
    return isValid;
  };
  
  const submitCallback = () => {
    if (!formIsValid()) return;
    toggle();
    props.formSubmitCallBack(user, props.item.index);
  };

  const userInputs = (
    <Row className="pl-3 pt-2 pb-2 pr-3">
      <Col md="6" className="p-0 pl-3 pr-3">
        <Label>First Name</Label>
        <Input
          type="text"
          placeholder="First Name"
          value={user.firstName || ""}
          onChange={(e) => userInputHandler("firstName", e.target.value)}
        />
      </Col>
      <Col md="6" className="p-0 pl-3 pr-3">
        <Label>Last Name</Label>
        <Input
          type="text"
          placeholder="Last Name"
          value={user.lastName || ""}
          onChange={(e) => userInputHandler("lastName", e.target.value)}
        />
      </Col>
      <Col md="12" className="p-0 mt-3 pl-3 pr-3">
        <Label>Email</Label>
        <Input
          type="text"
          placeholder="Email"
          value={user.email || ""}
          onChange={(e) => userInputHandler("email", e.target.value)}
        />
      </Col>
      <Col md="12" className="p-0 mt-3 pl-3 pr-3">
        <Label>Role Type</Label>
        <RoleTypeDropDown
          onSelectRoleType={(val) => userInputHandler("roleType", val?.label)}
          defaultSelected={{ label: user.roleType }}
        />
      </Col>
    </Row>
  );

  return (
    <ModalContainer
      buttonLabel="+ Edit User"
      modalTitle="Edit User"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      isSubmitDisabled={!isSubmitReady}
    >
      <Label className="ml-1 mb-0 mt-0">User</Label>
      {userInputs}
    </ModalContainer>
  );
};

export default EditUsersModal;
