import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label } from "reactstrap";

import ModalContainer from "../../../../shared/ModalContainer";
import SponsorshipsDropdown from "../../../../shared/SponsorshipsDropdown";
import { connect } from "react-redux";
import { CurrencyToNumber } from "layouts/Plan/shared/CurrencyFormatter";
import EventDeliverablesMap from "layouts/Plan/shared/EventDeliverablesMap";
import firebaseUid from "utilities/methods/firebaseUid";
import AccountDeliverable from "models/database/AccountDeliverable";

const EditSponsorshipsModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [sponsorship, setSponsorship] = useState({
    index: 0,
    rate: "",
    quantity: "",
  });
  const [initialSponsorship, setInitialSponsorship] = useState({});

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  useEffect(() => {
    setSponsorship({
      quantity: "",
      ...props.item.data,
      rate: CurrencyToNumber(props.item.data.rate),
    });
    setInitialSponsorship(props.item.data);
  }, [props.item]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const sponsorshipChangeHandler = (value, type, transform = parseInt) => {
    const newData = { ...sponsorship };
    newData[type] = transform(value);
    setSponsorship(newData);
  };

  const handleAddDeliverable = () => {
    const deliverableId = firebaseUid();
    setSponsorship({
      ...sponsorship,
      deliverables: {
        ...sponsorship.deliverables,
        [deliverableId]: {
          ...new AccountDeliverable().metaData(),
          id: deliverableId,
          isNewDeliverable: true
        }
      }
    });
  }

  const sponsorshipsDropdownCallback = (dropdownData) => {
    setSponsorship({
      ...dropdownData,
      rate: sponsorship.rate,
      quantity: sponsorship.quantity,
      name: dropdownData.label,
    });
  };

  const filterDeliverables = (deliverables) => (
    Object.entries(deliverables || {})
      .reduce((mapObj, [deliverableId, deliverable]) => {
        if (!deliverable.isNewDeliverable) {
          mapObj[deliverableId] = deliverable;
        }
        return mapObj;
      }, {})
  );

  const formIsValid = () => {
    const isValid = !!(
      sponsorship.id &&
      Number.isInteger(sponsorship.quantity) &&
      Number.isInteger(sponsorship.rate)
    );
    return isValid;
  };

  const submitCallback = async () => {
    if (!formIsValid()) return;
    toggle();
    const payload = {
      sponsorships: {
        [sponsorship.id]: {
          ...sponsorship,
          deliverables: filterDeliverables(sponsorship.deliverables)
        },
      },
    };
    if (
      !initialSponsorship ||
      !initialSponsorship.id ||
      initialSponsorship.id === sponsorship.id
    ) {
      setInitialSponsorship({});
    }
    props.formSubmitCallBack(payload, props.item.index, initialSponsorship);
  };

  const sponsorshipDropdown = (
    <Fragment>
      <Label className="ml-0 mb-0 mt-1">Name</Label>
      <Row className="pl-3">
        <SponsorshipsDropdown
          isNotPadded
          callback={sponsorshipsDropdownCallback}
          defaultValue={{ ...sponsorship, label: sponsorship.name, value: 0 }}
        />
      </Row>
      <Row className="pr-3 mt-2">
        <Col md="12">
          <Label className="ml-0 mb-0">Description</Label>
        </Col>
        <Col md="12" className="pl-3 m-0 p-0">
          <Input
            type="text"
            placeholder="Description"
            value={sponsorship.description || ""}
            onChange={(e) =>
              sponsorshipChangeHandler(e.target.value, "description", String)
            }
          />
        </Col>
      </Row>
      <Row className="pl-3 mt-2 mb-1">
        <Col md="3" className="m-0 p-0">
          <Label className="ml-0 mb-0">Quantity</Label>
          <Input
            type="number"
            step="1"
            placeholder="Quantity"
            value={sponsorship.quantity || ""}
            onChange={(e) =>
              sponsorshipChangeHandler(e.target.value, "quantity")
            }
          />
        </Col>
        <Col md="3" className="m-0 pl-2">
          <Label className="ml-0 mb-0">Rate</Label>
          <Input
            type="number"
            step="1"
            placeholder="Rate"
            value={sponsorship.rate || ""}
            onChange={(e) => sponsorshipChangeHandler(e.target.value, "rate")}
          />
        </Col>
      </Row>
      {!!Object.values(sponsorship.deliverables || {}).length && (
        <Row className="pl-5 mt-1 pr-3">
          <Label className="ml-0 mb-0 mt-1">Deliverables</Label>
          <EventDeliverablesMap
            deliverables={sponsorship.deliverables}
            setEntityState={setSponsorship}
            isLabeled
            isEventLevel
            isObj
          />
        </Row>
      )}
      <Button onClick={handleAddDeliverable}>+ Add Deliverable</Button>
    </Fragment>
  );

  return (
    <ModalContainer
      buttonLabel="+ Edit Sponsorship"
      modalTitle="Edit Sponsorship"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      isSubmitDisabled={!isSubmitReady}
    >
      {sponsorshipDropdown}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(EditSponsorshipsModal);
