const stringIt = (payload) => {
  return JSON.stringify(payload);
};

const fetchOptions = {
  GET: { method: "GET" },
  POST: { method: "POST" },
  PUT: { method: "PUT" },
  DELETE: { method: "DELETE" },
  PATCH: { method: "PATCH" },
};

const fetcher = async function (url, httpVerb, payload, headers) {
  // console.log('fetcher url ', url);
  if (!httpVerb) {
    httpVerb = "GET";
  }

  if (payload) {
    fetchOptions[httpVerb]["body"] = stringIt(payload);
  }

  if (headers) {
    fetchOptions[httpVerb]["headers"] = headers;
  }

  // console.log( url," fetchOptions = ", fetchOptions[httpVerb], " and payload = ", payload );
  //console.trace("API call")
  return fetch(url, fetchOptions[httpVerb])
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log("err = ", err);
      return err;
    })
    .then((parsedRes) => {
      // console.log('Success calling ', url);
      // console.log("parsedRes", parsedRes);
      if (!parsedRes) {
        return;
      }
      return parsedRes;
    });
};

export default fetcher;
