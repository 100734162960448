import ModalContainer from "layouts/Plan/shared/ModalContainer";
import Contact from "models/database/Contact";
import React, { useState, useEffect } from "react";
import AgendaForm from "./AgendaForm";


const EditAgendaModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [agenda, setAgenda] = useState(props.item);
  
  useEffect(() => {
    setAgenda(props.item);
  }, [props.item]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(false);
    const payload = {
      ...agenda,
      startTime: agenda.startTime.toISOString(),
      endTime: agenda.endTime.toISOString(),
      speaker: new Contact().createWithObject(agenda.speaker || {}).metaData()
    };
    props.formSubmitCallBack(payload, props.item.index);
    toggle();
  };

  return (
    <ModalContainer
      buttonLabel="+ Edit Agenda"
      modalTitle="Edit New Item"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={modalContainerSubmitCallback}
      isSubmitDisabled={!isSubmitReady}
    >
      <AgendaForm
        agenda={agenda}
        setIsSubmitReady={setIsSubmitReady}
        setAgenda={setAgenda}
      />
    </ModalContainer>
  );
};

export default EditAgendaModal;
