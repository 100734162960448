import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import RoleTypeDropDown from "layouts/Plan/shared/RoleTypeDropDownList";
import InputText from "../Plan/shared/inputs/InputText";
import { connect } from "react-redux";
import User from "../../models/database/User";
import CustomerOrgUser from "../../models/database/CustomerOrgUser";
import FirebaseAdmin from "../../models/backend/FirebaseAdmin";
import RegisterMarketingCopy from "layouts/Plan/shared/RegisterMarketingCopy";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      roleType: "",
      isLoading: false,
      isEmailInvalid: false,
      isPasswordInvalid: false,
      isFirstNameInvalid: false,
      isLastNameInvalid: false,
      isMaxUsers: false,
      errorMessage: "",
    };
  }

  async componentDidMount() {
    document.body.classList.toggle("register-page");
    let { id: orgId } = this.props.match.params;
    const isMaxUsers = await new FirebaseAdmin().getOrgContractualUsers(orgId);
    this.setState({ isMaxUsers });
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  onSelectRoleType = (roleType) => {
    this.setState({ roleType: roleType?.label });
  };

  logout = () => {
    this.props.firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userOrg");
        this.props.dispatch({ type: "logOut" });
        console.log("Logged out user");
        this.props.history.push("/auth/login");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleGetStarted = async () => {
    let { id: orgId } = this.props.match.params;
    let { firstName, lastName, email, roleType, password } = this.state;
    if (!firstName) {
      this.setState({
        errorMessage: "Enter First Name",
        isFirstNameInvalid: true,
      });
    } else if (!lastName) {
      this.setState({
        errorMessage: "Enter Last Name",
        isLastNameInvalid: true,
        isFirstNameInvalid: false,
      });
    } else if (!email) {
      this.setState({
        errorMessage: "Enter Email Address",
        isEmailInvalid: true,
        isLastNameInvalid: false,
        isFirstNameInvalid: false,
      });
    } else if (!roleType) {
      this.setState({
        errorMessage: "Please Select Role Type",
        isEmailInvalid: false,
        isLastNameInvalid: false,
        isFirstNameInvalid: false,
      });
    } else if (!password) {
      this.setState({
        errorMessage: "Enter Password",
        passwordInvalid: true,
        isEmailInvalid: false,
        isLastNameInvalid: false,
        isFirstNameInvalid: false,
      });
    } else {
      const isMaxUsers = await new FirebaseAdmin().getOrgContractualUsers(orgId);
      this.setState({ isMaxUsers });
      if (isMaxUsers) return;
      await this.props.firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then(async (res) => {
          let userPayload = {
            firstName,
            lastName,
            email,
            roleType,
            uid: res.user.uid,
            organization: orgId,
          };
          await new User().createUser(userPayload);
          await new CustomerOrgUser(null, orgId).createOrgUser({
            usersId: res?.user?.uid,
          });
          this.logout();
        })
        .catch((err) => {
          console.log("err :>> ", err);
          this.setState({ errorMessage: err.message });
        });
    }
  };

  render() {
    let {
      email,
      errorMessage,
      isEmailInvalid: isEmailInvalid,
      isPasswordInvalid: passwordInvalid,
      isFirstNameInvalid: isFirstNameInvalid,
      isLastNameInvalid: isLastNameInvalid,
    } = this.state;
    return this.state.isMaxUsers ? (
      <div className="content">
        <Container>
          <Row>
            <Col md="12">
              <Card>
                <CardBody className="p-5 text-center">
                  This account is at the maximum number of users. Please reach out to your Arcat admin.
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    ) : (
      <>
        <div className="content">
          <Container>
            <Row>
              <RegisterMarketingCopy />
              <Col className="mr-auto" md="7">
                <Card className="card-register card-white">
                  <CardHeader>
                    <CardImg
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <CardTitle
                      className="arcat text-primary pt-4 pl-3"
                      tag="h1"
                    >
                      Register.
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form className="form">
                      <div className="text-center">
                        <i className="font-weight-bold text-danger">
                          {errorMessage}
                        </i>
                      </div>

                      <InputText
                        type="text"
                        placeholder="First Name"
                        icon={`tim-icons icon-single-02`}
                        inputHandler={(val) =>
                          this.setState({ firstName: val })
                        }
                        withLabel={false}
                        value={this.state.firstName}
                        invalid={isFirstNameInvalid}
                      />

                      <InputText
                        type="text"
                        placeholder="Last Name"
                        icon={`tim-icons icon-single-02`}
                        inputHandler={(val) => this.setState({ lastName: val })}
                        withLabel={false}
                        value={this.state.lastName}
                        invalid={isLastNameInvalid}
                      />

                      <RoleTypeDropDown
                        onSelectRoleType={(val) => this.onSelectRoleType(val)}
                        onAuthPage={true}
                      />

                      <InputText
                        icon={`tim-icons icon-email-85`}
                        placeholder="Email"
                        inputHandler={(val) => this.setState({ email: val })}
                        withLabel={false}
                        value={this.state.email}
                        invalid={isEmailInvalid}
                      />

                      <InputText
                        type="password"
                        icon={`tim-icons icon-lock-circle`}
                        placeholder="Password"
                        inputHandler={(val) => this.setState({ password: val })}
                        isPasswordInput={true}
                        withLabel={false}
                        value={this.state.password}
                        isPasswordInput={true}
                        invalid={passwordInvalid}
                      />
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="primary"
                      size="lg"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleGetStarted();
                      }}
                    >
                      Get Started
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  userOrg: state.userOrg,
  firebase: state.firebase,
});

export default connect(mapStateToProps)(Register);
