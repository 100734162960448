import React, { useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import useClickOutside from "utilities/hooks/useClickOutside";

export const ColorPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  useClickOutside(popover, () => toggle(false));

  return (
    <div className="arcat color-picker" style={{ zIndex: 0 }}>
      <div
        className="color-swatch"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />
      {isOpen && (
        <div className="color-popover" ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};
