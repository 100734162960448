/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// core layout-components
import AdminNavbar from "../../creative-tim/layout-components/Navbars/AdminNavbar.js";
import Sidebar from "../../creative-tim/layout-components/Sidebar/Sidebar.js";
// import FixedPlugin from "../../creative-tim/layout-components/FixedPlugin/FixedPlugin.js";

import EventContainer from "./sections/events/eventPage/EventContainer";
import Organization from "./sections/organizations/organizationPage/OrgPage";
import Contact from "./sections/contacts/contactPage/ContactPage.js";
import ContactsSectionContainer from "./sections/contacts/contactsSectionContainer";
//import { routez } from "routes.js";
import { routes } from "routes.js";

import logo from "assets/img/arcat-logo.png";
import EntityPage from "./sections/offerings/EntityPage.js";
import AccountSponsorship from "models/database/AccountSponsorship.js";
import AccountBooth from "models/database/AccountBooth";

var ps;

class Plan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue", //"#005BAA",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      this.refs.mainPanel.addEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", this.showNavbarButton);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.classList.add("perfect-scrollbar-off");
      document.documentElement.classList.remove("perfect-scrollbar-on");
      this.refs.mainPanel.removeEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
    }
    window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      if (document.scrollingElement?.scrollTop) {
        document.scrollingElement.scrollTop = 0;
      }
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement?.scrollTop > 50 ||
      this.refs.mainPanel.scrollTop > 50
    ) {
      this.setState({ opacity: 1 });
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement?.scrollTop <= 50 ||
      this.refs.mainPanel.scrollTop <= 50
    ) {
      this.setState({ opacity: 0 });
    }
  };
  getRoutes = (routes) => {
    const routesArray = routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/plan") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
    // theres a nicer way to accomplish but currently everything in the routes.js file is displayed in sidebar
    const eventPageRoute = (
      <Route path={"/plan/event/:id"} component={EventContainer} key={"y"} />
    );
    routesArray.push(eventPageRoute);

    // organization page route that will redirect organization from organization table.
    const orgPageRoute = (
      <Route
        path={"/plan/organization/:id"}
        component={Organization}
        key={"z"}
      />
    );
    routesArray.push(orgPageRoute);

    // contact page route /plan/contacts
    const contactPageRoute = (
      <Route path={"/plan/contact/:id"} component={Contact} key={"z"} />
    );
    routesArray.push(contactPageRoute);

    // contacts home route /plan/contacts
    const contactsHomeRoute = (
      <Route
        path={"/plan/contacts/"}
        component={ContactsSectionContainer}
        key={"z"}
      />
    );
    routesArray.push(contactsHomeRoute);
    // Events sponsorship entity page route
    const sponEntityRoute = (
      <Route
        path={"/plan/offerings/sponsorship/:id"}
        render={(props) => (
          <EntityPage
            {...props}
            entity={"sponsorship"}
            entityClass={AccountSponsorship}
          />
        )}
        key={"s"}
      />
    );
    routesArray.push(sponEntityRoute);

    // Events booth entity page route
    const boothEntityRoute = (
      <Route
        path={"/plan/offerings/booth/:id"}
        render={(props) => (
          <EntityPage {...props} entity={"booth"} entityClass={AccountBooth} />
        )}
        key={"b"}
      />
    );
    routesArray.push(boothEntityRoute);

    return routesArray;
  };
  getActiveRoute = (routes) => {
    // let activeRoute = "Event Details";
    let activeRoute = "";

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleMiniClick = () => {
    let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      notifyMessage += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      notifyMessage += "activated...";
    }
    let options = {};
    options = {
      place: "tr",
      message: notifyMessage,
      type: "primary",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    //this.refs.notificationAlert.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };

  sideBar = () => {
    return (
      <Sidebar
        {...this.props}
        routes={routes}
        activeColor={this.state.activeColor}
        logo={{
          outterLink: "/plan/events",
          imgSrc: logo,
          text: "Arcat.xp",
        }}
        closeSidebar={this.closeSidebar}
      />
    );
  };

  adminNavBar = () => {
    return (
      <AdminNavbar
        {...this.props}
        handleMiniClick={this.handleMiniClick}
        brandText={this.getActiveRoute(routes)}
        sidebarOpened={this.state.sidebarOpened}
        toggleSidebar={this.toggleSidebar}
      />
    );
  };

  // fixedPlugin = () => {
  //   return (
  //     <FixedPlugin
  //       activeColor={this.state.activeColor}
  //       sidebarMini={this.state.sidebarMini}
  //       handleActiveClick={this.handleActiveClick}
  //       handleMiniClick={this.handleMiniClick}
  //     />
  //   );
  // };

  render() {
    return (
      <div className="wrapper">
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        {this.sideBar()}
        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.activeColor}
        >
          {this.adminNavBar()}

          <Switch>
            {this.getRoutes(routes)}
            {/*<Redirect from="*" to="/admin/dashboard" />*/}
          </Switch>
        </div>

        {/*{ this.fixedPlugin()}*/}
      </div>
    );
  }
}

export default Plan;
