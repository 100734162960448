import React, { useEffect, useState } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, CardBody, Row, Col } from "reactstrap";
import EventsDropdown from "./EventsDropdown";

const localizer = momentLocalizer(moment);

const Calendar = (props) => {
  const [events, setEvents] = useState(props.events || []);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    setEvents(props.events);
  }, [props.events]);

  const handleEventSelection = (event) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={event.title}
        onCancel={hideAlert}
        onConfirm={hideAlert}
        confirmBtnBsStyle="info"
      >
        {event.content || ""}
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const eventColors = (event, start, end, isSelected) => ({
    className: `event-${event.color || "default"}`,
    style: { backgroundColor: event.colorCode } 
  });

    return (
      <>
        <div className="content">
          {alert}
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-calendar" style={{ zIndex: 0 }}>
                <CardBody>
                  <BigCalendar
                    selectable
                    localizer={localizer}
                    events={events}
                    defaultView="month"
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={handleEventSelection}
                    eventPropGetter={eventColors}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default Calendar;
