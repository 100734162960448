import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Collapse,
  Button,
} from "reactstrap";
import { dateFormat } from "utilities/constants/date-format";
import User from "../../../models/database/User";
import FirebaseAdmin from "../../../models/backend/FirebaseAdmin";
import moment from "moment";

export default function CustomerOrgsAccordion(props) {
  const [usersData, setUsersData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [userCollapse, setUsersCollapse] = useState(false);
  const [eventsCollapse, seteventsCollapse] = useState(false);

  useEffect(() => {
    (async function() {
      setEventsData(Object.values(props.orgData?.events || {}));
      const response = await new User().getAllUsers(Object.values(props.orgData?.users || []), false);
      setUsersData(response);
    })();
  }, [props.orgData]);

  const handleToggleUser = async (user, isDisabled, index) => {
    const newUser = { ...user, isDisabled };
    const response = await new FirebaseAdmin().toggleUserActiveStatus(user.uid, isDisabled);
    if (!response?.success) return;
    new User().update(newUser);
    setUsersData((prevData) => prevData.map((prevUser, userIndex) => 
      userIndex === index ? newUser : prevUser));
  }

  const deactivateUserButton = (user, index) => (
    <Button color="danger" size="sm" onClick={() => handleToggleUser(user, true, index)}>
      Deactivate User
    </Button>
  );

  const activateUserButton = (user, index) => (
    <Button color="success" size="sm" onClick={() => handleToggleUser(user, false, index)}>
      Activate User
    </Button>
  );

  const populateUsersTable = (user, index) => {
    return (
      !!user && (
        <tr
          key={index}
          className="border border-top border-right-0 border-left-0 border-bottom-1"
        >
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>{user.email}</td>
          <td>{user.roleType}</td>
          <td>{user.isDisabled ? activateUserButton(user, index) : deactivateUserButton(user, index)}</td>
        </tr>
      )
    );
  };

  const populateEventsTable = (event, index) => {
    return (
      !!event?.code && (
        <tr
          key={index}
          className="border border-top border-right-0 border-left-0 border-bottom-1"
        >
          <td>{event.name}</td>
          <td>{moment(event.startDate).isValid() && moment(event.startDate).format(dateFormat)}</td>
          <td>{moment(event.startDate).isValid() && moment(event.endDate).format(dateFormat)}</td>
          <td>{`${event.city || ""} ${event.state || ""}`}</td>
          <td>{event.format || ""}</td>
        </tr>
      )
    );
  };

  const usersRow = (
    <Card className="card-plain">
      <CardHeader role="tab">
        <a
          aria-expanded={userCollapse}
          data-parent="#accordion"
          data-toggle="collapse"
          onClick={() => setUsersCollapse((prevState) => !prevState)}
        >
          Users <i className="tim-icons icon-minimal-down" />
        </a>
      </CardHeader>
      <Collapse role="tabpanel" isOpen={userCollapse}>
        <CardBody>
          <Row style={{ marginLeft: "1%" }}>
            <Table borderless>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Role Type</th>
                  <th>Deactivate User</th>
                </tr>
              </thead>
              <tbody>
                {!!usersData?.length && usersData.map((user, index) => 
                  populateUsersTable(user, index)
                )}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );

  const eventsRow = (
    <Card className="card-plain">
      <CardHeader role="tab">
        <a
          aria-expanded={eventsCollapse}
          data-parent="#accordion"
          data-toggle="collapse"
          onClick={(e) => seteventsCollapse((prevState) => !prevState)}
        >
          Events
          <i className="tim-icons icon-minimal-down" />
        </a>
      </CardHeader>
      <Collapse role="tabpanel" isOpen={eventsCollapse}>
        <CardBody>
          <Row style={{ marginLeft: "1%" }}>
            <Table borderless>
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Location</th>
                  <th>Format</th>
                </tr>
              </thead>
              <tbody>
                {eventsData?.map((event, index) =>
                  populateEventsTable(event, index)
                )}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );

  return (
    <Row>
      <Card md={8}>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          {usersRow}
          {eventsRow}
        </div>
      </Card>
    </Row>
  );
}
