import React from "react";
import moment from "moment";
import { dateFormat } from "utilities/constants/date-format";
import PersistentBase from "./PersistentBase";

/**
 * @description individual item that represent a discrete piece of work that is dictated in the Sales contract
 */
export default class MinifiedDeliverable extends PersistentBase {
  constructor() {
    super();
  }

  tableCsvData() {
    return {
      deliverable: this.name,
      "due date": moment(this.deadline).format(dateFormat),
    };
  }

  tableColumnsAndValues() {
    return {
      deliverable: this.name || "-",
      "due date": {
        cellText: this.centerAlignDiv(
          moment(this.deadline).format(dateFormat)
        ),
        width: 220,
      },
    };
  }
}
