import { mailgunEventsBackendUrl } from "../../utilities/constants/urls";
import PersistentBase from "./PersistentBase";

export default class MailgunEvents extends PersistentBase {
  constructor() {
    super();
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = mailgunEventsBackendUrl;
  }

  async getEmailEvents(emailId) {
    this.url = `${mailgunEventsBackendUrl}/email/${emailId}`;
    const response = await super.get();
    return response;
  }
}
