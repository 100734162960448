import React from "react";
import { Link } from "react-router-dom";

const StyledLink = (props) => {
    return (
        <Link style={{color: "#CF009F" }} to={props.to} onClick={props.onClick}>
            {props.children}
        </Link>
    )
};

export default StyledLink;
