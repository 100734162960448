import PersistentBase from "./PersistentBase";
import { storage } from "../../firebase-client/firebase-client";
export default class Documents extends PersistentBase {
  constructor() {
    super();
    this.orgId = null;
    this.getOrgId();
  }

  async getOrgId() {
    this.orgId = await super.getUserOrgId();
  }

  /**
   * @description this will be use in filepond library as server props
   * @param folderId
   * @returns server object
   */

  saveDocument(folderId) {
    const server = {
      // this uploads the image using firebase
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        // upload the image to firebase
        const task = storage
          .ref(`documents/${this.orgId}/${folderId}/${file.name}`)
          .put(file, {
            contentType: file.type,
          });

        // monitor the task to provide updates to FilePond
        task.on(
          "state_changed",
          (snap) => {
            // provide progress updates
            progress(true, snap.bytesTransferred, snap.totalBytes);
          },
          (err) => {
            // provide errors
            error(err.message);
          },
          () => {
            // the file has been uploaded
            task.snapshot.ref.getMetadata().then(async (metaData) => {
              load(`${this.orgId}/${folderId}/${file.name}`);
              return metaData;
            });
          }
        );
      },

      // this loads an already uploaded image to firebase
      load: (source, load, error, progress, abort) => {
        // reset our progress
        progress(true, 0, 1024);
      },
    };

    return server;
  }
}
