import PersistentBase from "./PersistentBase";

import { customerOrgsFirebaseUrl } from "../../utilities/constants/urls";
import MailgunEvents from "models/backend/MailgunEvents";

export default class Email extends PersistentBase {
  constructor(
    id,
    emailForm,
    contactsData,
    recipients,
    usedVariables,
    autoSend,
    sendDate,
    events,
    mailgunIds,
    eventId
  ) {
    super();
    this.id = id;
    this.emailForm = emailForm;
    this.contactsData = contactsData;
    this.recipients = recipients;
    this.usedVariables = usedVariables;
    this.autoSend = autoSend;
    this.sendDate = sendDate;
    this.events = events;
    this.mailgunIds = mailgunIds;
    this.eventId = eventId;
    this.csvTitle = "Email";
    this.setRestUrl();
  }

  async setRestUrl() {
    const userOrg = await super.getUserOrgId();
    this.url = `${customerOrgsFirebaseUrl}/${userOrg}/events/${this.eventId}/emails`;
    this.jsonUrl = `${customerOrgsFirebaseUrl}/${userOrg}/events/${this.eventId}/emails.json`;
  }

  metaData() {
    return typeof this.recipients === "string" ? {
      id: this.id,
      emailForm: this.emailForm,
      contactsData: this.contactsData,
      recipients: this.recipients,
      usedVariables: this.usedVariables,
      autoSend: this.autoSend,
      sendDate: this.sendDate,
      events: this.events,
      mailgunIds: this.mailgunIds,
    } : {
      id: this.id,
      emailForm: {
        ...this.emailForm,
        "recipient-variables": JSON.stringify(
          this.emailForm?.["recipient-variables"] || {}
        )
      },
      contactsData: JSON.stringify(this.contactsData || {}),
      recipients: JSON.stringify(this.recipients || []),
      usedVariables: JSON.stringify(this.usedVariables || []),
      autoSend: this.autoSend,
      sendDate: this.sendDate || new Date().toUTCString(),
      events: this.events,
      mailgunIds: this.mailgunIds,
    };
  }

  parseEmail() {
    if (typeof this.recipients !== "string") return this;
    return {
      id: this.id,
      emailForm: {
        ...this.emailForm,
        "recipient-variables": JSON.parse(
          this.emailForm?.["recipient-variables"] || ""
        )
      },
      contactsData: JSON.parse(this.contactsData || ""),
      recipients: JSON.parse(this.recipients || ""),
      usedVariables: JSON.parse(this.usedVariables || ""),
      autoSend: this.autoSend,
      sendDate: this.sendDate,
      events: this.events,
      mailgunIds: this.mailgunIds,
    };
  }

  async getAll(emailIds, eventId) {
    const userOrgId = await super.getUserOrgId();
    const emails = [];
    let index = 0;
    for (const emailId of emailIds) {
      this.jsonUrl = await super.appendAuth(
        `${customerOrgsFirebaseUrl}/${userOrgId}/events/${eventId}/emails/${emailId}.json`
      );
      const response = await super.get();
      response.id = emailId;
      if (response.mailgunIds) {
        const events = await this.getEvents(response.mailgunIds);
        response.events = events;
      }
      response.contactsData = JSON.parse(response.contactsData || {});
      response.emailForm["recipient-variables"] = JSON.parse(
        response.emailForm?.["recipient-variables"] || {}
      );
      response.recipients = JSON.parse(response.recipients || []);
      response.usedVariables = JSON.parse(response.usedVariables || []);
      const formattedEmail = new this.constructor()
        .createWithObject(response)
        .tableRowFormat(index++);
      emails.push(formattedEmail);
    }
    return emails;
  }

  async create(itemProps, eventData, index) {
    this.createWithObject(itemProps);
    this.eventId = eventData.id;
    await super.asyncCreate(this.metaData());
    return this.tableRowFormat(index);
  }

  async delete(id, eventId) {
    this.eventId = eventId;
    await this.setRestUrl();
    super.delete(id);
  }

  async getEvents(mailgunIds = this.mailgunIds) {
    const response = await new MailgunEvents().getEmailEvents(mailgunIds);
    return response;
  }

  formatDate(date) {
    if (super.isDateValid(date)) return new Date(date).toDateString();
    else return "";
  }

  tableCsvData() {
    return {
      subject: this.emailForm?.subject || "-",
      date: this.formatDate(this.sendDate),
      from: this.emailForm?.from || "-",
      to: this.emailForm?.to || "-",
      opened: this.events?.opened || 0,
      delivered: this.events?.delivered || 0,
      failed: this.events?.failed || 0,
      complained: this.events?.complained || 0,
    };
  }

  tableColumnsAndValues() {
    return {
      subject: this.emailForm?.subject,
      date: {
        cellText: this.centerAlignDiv(this.formatDate(this.sendDate)),
        width: 130,
      },
      from: { cellText: this.centerAlignDiv(this.emailForm?.from), width: 150 },
      opened: {
        cellText: this.centerAlignDiv(this.events?.opened || 0),
        width: 100,
      },
      delivered: {
        cellText: this.centerAlignDiv(this.events?.delivered || 0),
        width: 100,
      },
      failed: {
        cellText: this.centerAlignDiv(this.events?.failed || 0),
        width: 100,
      },
      complained: {
        cellText: this.centerAlignDiv(this.events?.complained || 0),
        width: 100,
      },
    };
  }
}
