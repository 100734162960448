import CustomSelect from "creative-tim/components/CustomSelect";
import React from "react";

export default function DropDownList({
  options,
  placeholder,
  defaultSelected,
  value,
  callback,
  multiSelect,
  searchable,
  size,
  onAuthPage
}) {
  return (
    <CustomSelect
      options={options}
      placeholder={placeholder}
      defaultSelected={defaultSelected}
      value={value}
      callback={(val) => callback(val)}
      multiSelect={multiSelect}
      searchable={searchable}
      size={size}
      onAuthPage={onAuthPage}
    />
  );
}
