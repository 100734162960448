/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react component for creating dynamic tables
import { CardBody, CardHeader, CardTitle } from "reactstrap";

import SectionContainer from "../SectionContainer";
import NewOrganizationModal from "./NewOrgModal";
import OrgsTable from "./OrgTable";

//model that holds the methods DB interaction.
import Organization from "../../../../models/database/Organization";
import Contact from "../../../../models/database/Contact";
import { connect } from "react-redux";
import Organizations from "models/database/Organizations";

class OrgSectionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue", //"#005BAA",
      sidebarMini: true,
      newOrg: "",
    };
  }

  async componentDidMount() {
    if (!this.props.orgData) {
      const res = await new Organizations().getAllEntities();
      this.props.dispatch({ type: "setOrgData", data: res });
    }
    if (!this.props.contactsData) {
      const contacts = await new Contact().getContacts();
      this.props.dispatch({ type: "setContactsData", data: contacts });
    }
  }

  async submitEventHandler(organizationProps, index, contactProps) {
    const { contactFormValue } = contactProps;
    const isContactFormValid = Boolean(contactFormValue?.contactType);
    let newContact;
    if (isContactFormValid) {
      newContact = await new Contact().asyncCreate(contactFormValue);
      organizationProps = {
        ...organizationProps,
        contactId: newContact["id"],
      }; // append contact key to organization doc.
    }
    const organization = await new Organization().asyncCreate(
      organizationProps
    );
    const formatOrganization = new Organization()
      .createWithObject(organization)
      .tableRowFormat(this.props.orgData.length);
    this.props.dispatch({ type: "addOrgData", data: formatOrganization });
    if (isContactFormValid) {
      newContact["orgId"] = organization["id"];
      await new Contact().update(newContact);
    }
    this.setState({ newOrg: organization });
  }

  tableHeader = () => (
    <CardHeader>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CardTitle tag="h4">Organizations</CardTitle>
        <NewOrganizationModal
          formSubmitCallBack={(data, index, contactData) =>
            this.submitEventHandler(data, index, contactData)
          }
        />
      </div>
    </CardHeader>
  );

  render() {
    return (
      <>
        <SectionContainer>
          <div>
            {this.tableHeader()}
            <CardBody>
              <OrgsTable newRow={this.state.newOrg} />
            </CardBody>
          </div>
        </SectionContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orgData: state.orgData,
  contactsData: state.contactsData,
});

export default connect(mapStateToProps)(OrgSectionContainer);
