import AccountEmailTemplate from "models/database/AccountEmailTemplate";
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import EmailTemplatesTable from "./EmailTemplatesTable";
import NewEmailTemplateModal from "./NewEmailTemplateModal";

const EmailTemplatesContainer = props => {
    const [newItem, setNewItem] = useState(null);
    const [tableData, setTableData] = useState([]);

    const submitCallback = async data => {
        const response = await new AccountEmailTemplate()
            .createWithObject(data.emailTemplate).create();
        setNewItem(response);
    }

    return (
        <div className="content">
            <Card>
                <CardHeader>
                    <div style={{display: 'flex', justifyContent: "space-between"}}>
                        <CardTitle tag="h4">Email Templates</CardTitle>
                        <NewEmailTemplateModal
                            submitCallback={submitCallback}
                        />
                    </div>
                </CardHeader>
                <CardBody>
                    <EmailTemplatesTable 
                        data={tableData}
                        setData={setTableData}
                        newItem={newItem}
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default EmailTemplatesContainer;
