import React, { useState } from "react";
import { contactTypes } from "../../../utilities/constants/event-terms";
import DropDownList from "./DropDownList";

const createSingleSelect = (value, label) => {
    return {
        value,
        label,
    };
};

export default function ContactTypeDropDown(props) {
    const createInitialState = (key, data) => {
        return props?.defaultSelected;
        // return (data.event && data.event.data && data.event.data[key]) || "";
    };

    const [type, setContactType] = useState(createInitialState("type", props));

    const contactTypeOptions = contactTypes.map((type, index) =>
        createSingleSelect(index, type)
    );
    let defaultSelected;

    let placeholder = "Contact Type";
    let index;
    if (type) {
        index = contactTypes.indexOf(type);
        defaultSelected = contactTypeOptions[index];
    }

    return (
        <DropDownList
            options={contactTypeOptions}
            placeholder={placeholder}
            defaultSelected={defaultSelected}
            value={type}
            callback={(val) => props.getContactType(val)}
            multiSelect={false}
            searchable={true}
            size="4"
        />
    );
}
