import AccountDeliverable from "./AccountDeliverable";

import { customerOrgsFirebaseUrl } from "utilities/constants/urls";
import PersistentBase from "./PersistentBase";

export default class AccountDeliverables extends PersistentBase {

    constructor() {
        super();
        this.collectionPath = "account-deliverables";
        this.dataModel = AccountDeliverable;
        this.setRestUrl();
    }

    setRestUrl() {
        this.url = customerOrgsFirebaseUrl;
        this.jsonUrl = customerOrgsFirebaseUrl;
    }

}
