import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label } from "reactstrap";
import ReactDatetime from "react-datetime";

import ModalContainer from "../../../../shared/ModalContainer";
import DeliverablesDropdown from "../../../../shared/DeliverablesDropdown";
import { connect } from "react-redux";

const DeliverablesModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deliverables, setDeliverables] = useState([{ index: 0 }]);

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  const toggle = () => {
    if (isModalOpen) {
      setDeliverables([{ index: 0 }]);
    }
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = (
    <Button color="info" size="md" onClick={toggle} disabled={!props.isEventEditable}>
      + Add Deliverable
    </Button>
  );

  const dateChangeHandler = (deliverable, date) => {
    setDeliverables((prevState) => {
      deliverable.deadline = new Date(date);
      const newState = [...prevState];
      newState[deliverable.index] = deliverable;
      return newState;
    });
  };

  const deliverableChangeHandler = (deliverable, value, type) => {
    setDeliverables((prevState) => {
      deliverable[type] = parseInt(value);
      const newState = [...prevState];
      newState[deliverable.index] = deliverable;
      return newState;
    });
  };

  const addDeliverable = () => {
    setDeliverables([...deliverables, { index: deliverables.length }]);
  };

  const deliverablesDropdownCallback = (dropdownData) => {
    setDeliverables((prevState) => {
      const newState = [...prevState];
      newState[dropdownData.index] = {
        ...prevState[dropdownData.index],
        ...dropdownData,
      };
      return newState;
    });
  };

  const formIsValid = () => {
    const isValid = !!deliverables.find(
      (deliverable) =>
        deliverable.id &&
        Number.isInteger(deliverable.quantity) &&
        Number.isInteger(deliverable.rate)
    );
    return isValid;
  };

  const arrayToObject = (arr) => {
    return arr.reduce((map, obj) => {
      if (obj.id) {
        if (obj.label) {
          obj.name = `${obj.label}`;
          delete obj.label;
        }
        map[obj.id] = obj;
      }
      return map;
    }, {});
  };

  const submitCallback = () => {
    if (!formIsValid()) return;
    const data = {
      deliverables: arrayToObject(deliverables),
    };
    props.submitCallback(data);
    toggle();
  };

  const deliverableDropdowns = deliverables.map((deliverable, index) => {
    return (
      <Fragment key={index}>
        <Row>
          <DeliverablesDropdown
            callback={deliverablesDropdownCallback}
            index={index}
            filterItems={true}
          />
          {deliverable.label && (
            <>
              <Row className="pl-3 mt-2 pr-3">
                <Col md="3" className="m-0 p-0 mr-1">
                  <Input
                    type="number"
                    step="1"
                    placeholder="Quantity"
                    onChange={(e) =>
                      deliverableChangeHandler(
                        deliverable,
                        e.target.value,
                        "quantity"
                      )
                    }
                  />
                </Col>
                <Col md="3" className="m-0 p-0">
                  <Input
                    type="number"
                    step="1"
                    placeholder="Rate"
                    onChange={(e) =>
                      deliverableChangeHandler(
                        deliverable,
                        e.target.value,
                        "rate"
                      )
                    }
                  />
                </Col>
                <Col className="m-0 p-0 ml-1">
                  <ReactDatetime
                    onChange={(date) => dateChangeHandler(deliverable, date)}
                    inputProps={{
                      className: "form-control",
                      placeholder: "Due Date",
                    }}
                    closeOnSelect={true}
                  />
                </Col>
              </Row>
            </>
          )}
        </Row>
        {deliverable.label && index + 1 === deliverables.length && (
          <Button
            className="mt-3"
            size="md"
            onClick={addDeliverable}
            title="Add Deliverable"
          >
            + Add Deliverable
          </Button>
        )}
      </Fragment>
    );
  });

  return (
    <ModalContainer
      buttonLabel="+ Add Deliverables"
      modalTitle="Add New Deliverables"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      button={OpenModalButton}
      isSubmitDisabled={!isSubmitReady}
    >
      <Label className="ml-1 mb-0 mt-0">Deliverables</Label>
      {deliverableDropdowns}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(DeliverablesModal);
