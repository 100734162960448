import React, { Component } from "react";
// react component for creating dynamic tables
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle, Col,
} from "reactstrap";

import AccountBooth from "models/database/AccountBooth";
import BoothsTable from "./BoothsTable";
import NewBoothModal from "./NewBoothModal";

class BoothsTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor : "blue", //"#005BAA",
            sidebarMini : true,
            newBooth    : '',
        };
    }

    async submitBoothHandler(boothProps) {
        const booth = await new AccountBooth().create(boothProps);

        this.setState((previousState) => {
            return { newBooth : booth }
        });
    }

    tableHeader = () => (
        <CardHeader>
            <div style={{display: 'flex', justifyContent: "space-between"}}>
                <CardTitle tag="h4">Booths</CardTitle>
                <NewBoothModal
                    formSubmitCallBack={(data) => this.submitBoothHandler(data)}
                />
            </div>
        </CardHeader>
    );

    render() {
        return (
            <>
                <Col xs={6} md={6}>
                    <Card>
                        { this.tableHeader() }
                        <CardBody>
                            <BoothsTable newRow={this.state.newBooth}/>
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    }
}

export default BoothsTableContainer;
