
const formatContactLabel = (contact, isTypeVisible = false) => {
  let label = contact.fullName?.trim?.() ? `${contact.firstName} ${contact.lastName}` : "-";
  if (isTypeVisible) {
    label = `${label} (${contact.contactType || "-"})`;
  }
  return label;
};

export default formatContactLabel;
