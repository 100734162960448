import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Row,
  Col,
  Button,
} from "reactstrap";

import DeliverablesModal from "./deliverables/DeliverablesModal";
import DeliverablesTable from "./deliverables/DeliverablesTable";
import SponsorshipsAndBooths from "./SponsorshipsAndBooths";
import SponsorshipsTable from "./sponsorships/SponsorshipsTable";
import SponsorshipsModal from "./sponsorships/SponsorshipsModal";
import BoothsTable from "./booths/BoothsTable";
import BoothsModal from "./booths/BoothsModal";

import Booths from "models/database/Booths";
import Sponsorships from "models/database/Sponsorships";
import Deliverables from "models/database/Deliverables";
import Organizations from "models/database/Organizations";
import { connect } from "react-redux";
import Deliverable from "models/database/Deliverable";
import SectionContainer from "../../SectionContainer";

import { NumberToCurrency } from "../../../shared/CurrencyFormatter";
import { ThreeDots } from "react-loading-icons";

function DashboardSection(props) {
  const [deliverablesData, setDeliverablesData] = useState([]);
  const [sponsorshipsData, setSponsorshipsData] = useState([]);
  const [boothsData, setBoothsData] = useState([]);

  useEffect(() => {
    (async function () {
      if (!props.orgData) {
        let orgModel = await new Organizations().getAllEntities();
        props.dispatch({ type: "setOrgData", data: orgModel });
      }
    })();
  }, []);

  const convertFirstValueToCurrency = (dataObj) => {
    let objectValue = Object.values(dataObj)[0];
    let objectKey = Object.keys(dataObj)[0];
    dataObj[objectKey].rate = NumberToCurrency(objectValue.rate);
    return dataObj;
  };

  const sponsorshipsAndBoothsSubmit = (data) => {
    const { sponsorships, booths } = data;
    convertFirstValueToCurrency(sponsorships);
    handleEntityCreate(
      { sponsorships },
      Sponsorships,
      sponsorshipsData.length,
      setSponsorshipsData,
      "sponsorships"
    );
    handleEntityCreate(
      { booths },
      Booths,
      boothsData.length,
      setBoothsData,
      "booths"
    );
    props.setHasSponsorships(true);
  };

  const handleEntityCreate = async (
    data,
    entityClass,
    index,
    setData,
    collection
  ) => {
    const response = await new entityClass().update(
      data,
      props.event,
      null,
      null,
      "offerings"
    );
    const tableData = response.map((d) => d.tableRowFormat(index++));
    props.dispatch({
      type: "addEventData",
      data: response,
      collection: collection,
    });
    setData((prevData) => [...prevData, ...tableData]);
    handleChildDeliverables(tableData);
  };

  const deliverablesSubmit = (data) => {
    let index = deliverablesData.length;
    handleEntityCreate(
      data,
      Deliverables,
      index,
      setDeliverablesData,
      "deliverables"
    );
  };

  const sponsorshipsSubmit = (data) => {
    convertFirstValueToCurrency(data?.sponsorships);
    let index = sponsorshipsData.length;
    handleEntityCreate(
      data,
      Sponsorships,
      index,
      setSponsorshipsData,
      "sponsorships"
    );
  };

  const boothsSubmit = (data) => {
    convertFirstValueToCurrency(data?.booths);

    let index = boothsData.length;
    handleEntityCreate(data, Booths, index, setBoothsData, "booths");
  };

  const handleSponsorshipsData = (data) => {
    setSponsorshipsData(data);
    handleChildDeliverables(data);
  };

  const handleBoothsData = (data) => {
    setBoothsData(data);
    handleChildDeliverables(data);
  };

  const filterChildDeliverables = (deletedItem) => {
    if (!deletedItem || !deletedItem.deliverables) return;
    // Remove child deliverables when their parent entity is deleted
    for (const deliverable of Object.values(deletedItem.deliverables)) {
      setDeliverablesData((prevData) => {
        prevData = prevData.filter(
          (d) =>
            !d.itemClass.parentId ||
            d.itemClass.id !== deliverable.id ||
            d.itemClass.parentId !== deletedItem.id
        );
        return prevData;
      });
      props.dispatch({
        type: "filterEventData",
        id: deliverable.id,
        parentId: deletedItem.id,
        collection: "deliverables",
      });
    }
  };

  const handleChildDeliverables = async (parentData) => {
    // If entities contain deliverables, add to the deliverables table
    for (const entity of Object.values(parentData)) {
      if (!entity.itemClass?.deliverables) continue;
      const parentCollection = entity.itemClass.constructor.name
        .toLowerCase()
        .includes("sponsor")
        ? "sponsorships"
        : "booths";
      const parentId = entity.itemClass.id;
      const parentName = entity.itemClass.name;

      const deliverables = Object.values(entity.itemClass.deliverables).flatMap((d) => {
          if (!d.partner) {
            return []
          }
          return new Deliverable().createWithObject({
            ...d,
            parentId,
            parentCollection,
            parentName,
            eventId: props.eventData.id,
          })
        }
      );
      let index = deliverablesData.length;

      const tableData = !deliverables.length ? [] : deliverables.map((d) => d.tableRowFormat(index++));
      props.dispatch({
        type: "addEventData",
        data: deliverables,
        collection: "deliverables",
      });
      setDeliverablesData((prevData) => [...prevData, ...tableData]);
    }
  };

  const initialView = (
    <Col xs={12} md={12} className="pl-0">
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardTitle tag="h4">Sponsorships and Booths</CardTitle>
          </div>
        </CardHeader>
        <CardBody>
          <SponsorshipsAndBooths
            submitCallback={sponsorshipsAndBoothsSubmit}
            hasSponsorships={props.hasSponsorships}
          />
        </CardBody>
      </Card>
    </Col>
  );
  const deliverablesCol = (
    <Col xs={12} md={12} className="pl-0">
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardTitle tag="h4">Deliverables</CardTitle>
            <DeliverablesModal submitCallback={deliverablesSubmit} />
          </div>
        </CardHeader>
        <CardBody>
          <DeliverablesTable
            data={deliverablesData}
            setData={setDeliverablesData}
          />
        </CardBody>
      </Card>
    </Col>
  );

  const sponsorshipsCol = (
    <Col xs={12} md={12} className="pl-0">
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardTitle tag="h4">Sponsorships</CardTitle>
            <SponsorshipsModal submitCallback={sponsorshipsSubmit} />
          </div>
        </CardHeader>
        <CardBody>
          <SponsorshipsTable
            data={sponsorshipsData}
            setData={handleSponsorshipsData}
            deleteCallback={filterChildDeliverables}
            editCallback={filterChildDeliverables}
          />
        </CardBody>
      </Card>
    </Col>
  );

  const boothsCol = (
    <Col xs={12} md={12} className="pl-0">
      <Card>
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardTitle tag="h4">Booths</CardTitle>
            <BoothsModal submitCallback={boothsSubmit} />
          </div>
        </CardHeader>
        <CardBody>
          <BoothsTable
            data={boothsData}
            setData={handleBoothsData}
            deleteCallback={filterChildDeliverables}
            editCallback={filterChildDeliverables}
          />
        </CardBody>
      </Card>
    </Col>
  );

  const dashboardView = (
    <Row>
      <Col className="px-0">
        <SectionContainer noShadow>
          {deliverablesCol}
          <hr className="mt-3 mb-5" />
          {sponsorshipsCol}
          <hr className="mt-3 mb-5" />
          {boothsCol}
        </SectionContainer>
      </Col>
    </Row>
  );

  const loadingIndicator = () => {
    return (
        <div style={{"height": "auto", "display" : "flex", "alignItems": "center", "justifyContent": "center"}}>
          <div style={{marginTop: "4px"}}>
            <ThreeDots width="5em" height="5em" fill="#cf009f"/>
          </div>
        </div>
    )
  };

  const initPage = () => {
    if (props.parentIsLoading) {
      return loadingIndicator()
    } else if (!props.parentIsLoading) {
      if (props.hasSponsorships) {
        return dashboardView
      } else {
        return initialView
      }
    }
  };

  return <>{initPage()}</>;
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  orgData: state.orgData,
});

export default connect(mapStateToProps)(DashboardSection);
