import React, { useState, useEffect } from "react";
import { Row, Col, Table, Card, CardHeader, CardBody } from "reactstrap";
import EditOrgModal from "../EditOrgModal";
import OrganizationModel from "../../../../../models/database/Organization";
import IconPencilButton from "../../../shared/buttons/IconPencilButton";
import Accordion from "layouts/Plan/shared/Accordion";
import { TableRow } from "../../contacts/contactPage/ContactPage";
import { connect } from "react-redux";
import Organizations from "models/database/Organizations";
import Contacts from "models/database/Contacts";

function Organization(props) {
  const [contacts, setContacts] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [isShowingEditOrgModal, setIsShowingEditOrgModal] = useState(false);

  const getOrgFromUrl = async () => {
    const orgName = props.match.params.id;
    const orgs = await new Organizations().getAllEntities(false);
    const orgData = orgs.find(org => org?.name === orgName);
    return orgData;
  }

  const getContacts = async () => {
    const contacts = await new Contacts().getAllEntities(false);
    return contacts.reduce((mapObj, contact) => {
      if (contact?.id) {
        mapObj[contact.id] = contact;
      }
      return mapObj;
    }, {});
  }

  useEffect(() => {
    (async function() {
      let org = props?.location?.state?.organization || await getOrgFromUrl();
      let contacts = props.contactsData || await getContacts();
      setOrgData({ ...org });
      setContacts(Object.entries(contacts || {}).filter(
        ([contactId, contact]) => contact.orgId === org.id
      ));
    })();
  }, []);

  const toggleModifyOrganizationModal = () => {
    setIsShowingEditOrgModal((prevState) => !prevState);
  };

  const handleOrgUpdate = async (data, index) => {
    let orgObj = await new OrganizationModel(
      props?.location?.state?.organization?.id
    );
    let updatedData = orgObj.update(data);
    updatedData.then((res) => {
      setOrgData({ ...res });
      const { history } = props;
      if (history.location && history.location.state) {
        const state = { ...history.location.state };
        // delete state.from;
        history.replace({
          ...history.location,
          state: { ...state, organization: res },
        });
      }
    });
  };

  function showEditOrganizationModal() {
    return (
      <EditOrgModal
        item={{ data: { ...props?.location?.state?.organization } }}
        formSubmitCallBack={(data, index) => handleOrgUpdate(data, index)}
        toggle={() => {
          toggleModifyOrganizationModal();
        }}
      />
    );
  }
  if (Object.keys(orgData).length) {
    var {
      URL,
      address1,
      address2,
      city,
      country,
      name,
      phone,
      state,
      zip,
      id,
      contactId,
    } = orgData;
  }

  // top heading
  const headingRow = (
    <Row>
      <Col xs={10} md={8} className="px-0">
        <Card className="">
          <CardHeader className="my-0">
            <h3 className="mb-3">ORGANIZATION INFORMATION</h3>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );

  // Information Section
  const infoSection = () => {
    return (
      <Row className="mb-0">
        <Card md={8}>
          <CardHeader
            className="pb-0"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3 style={{ textTransform: "uppercase" }}>{name}</h3>
            <IconPencilButton onClick={() => setIsShowingEditOrgModal(true)} />
          </CardHeader>
          <CardBody className="pt-0">
            <Row>
              <Col md={12} style={{ fontSize: "0.99 rem" }}>
                <Table>
                  <TableRow heading="Address 1" label={address1} />
                  <TableRow heading="Address 2" label={address2} />
                  <TableRow heading="City" label={city} />
                  <TableRow heading="State" label={state} />
                  <TableRow heading="Zip Code" label={zip} />
                  <TableRow heading="Phone" label={phone} />
                  <TableRow heading="Website" label={URL} />
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card />
      </Row>
    );
  };

  return (
    <>
      {isShowingEditOrgModal ? showEditOrganizationModal() : ""}
      <div className="rna-container"></div>
      <div className="content">
        {headingRow}
        <Row>
          <Col md={8}>
            {infoSection()}
            <Accordion contacts={contacts} orgData={orgData} />
          </Col>
          <Col md={4} />
        </Row>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  contactsData: state.contactsData,
});

export default connect(mapStateToProps)(Organization);
