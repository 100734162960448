import DeliverablesDropdown from "layouts/Plan/shared/DeliverablesDropdown";
import IconTooltip from "layouts/Plan/shared/IconTooltip";
import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, CustomInput } from "reactstrap";

const NewBoothForm = (props) => {
  const [name, setBoothName] = useState("");
  const [isAddingName, setIsAddingName] = useState(false);
  const [depth, setDepth] = useState("");
  const [width, setWidth] = useState("");
  const [deliverables, setDeliverables] = useState([]);

  const [editingIndex, setEditingIndex] = useState("");
  const [isPopulatingForm, setIsPopulatingForm] = useState(
    props.isPopulatingForm
  );

  const clearHooks = () => {
    setBoothName("");
    setIsAddingName(false);
    setDepth("");
    setWidth("");
    setDeliverables([]);
  };

  const arrayToObject = (arr) => {
    return arr.reduce((map, obj) => {
      if (obj && obj.id) {
        if (obj.label) {
          obj.name = `${obj.label}`;
          obj.reserved = 0;
          delete obj.label;
        }
        map[obj.id] = obj;
      }
      return map;
    }, {});
  };

  const submit = () => {
    const booth = {
      name,
      isAddingName,
      depth,
      width,
      deliverables: arrayToObject(Object.values(deliverables)),
    };
    props.submitCallback(booth, editingIndex);
    clearHooks();
  };

  useEffect(() => {
    if (props.isSubmitReady) {
      submit();
    }
    if (isPopulatingForm) {
      const { name, depth, width, deliverables } = props.booth.data;
      setEditingIndex(props.booth.index);

      setBoothName(name);
      setDepth(depth);
      setWidth(width);

      const deliverablesData = deliverables
        ? Object.values(deliverables)
        : [];
      setDeliverables(deliverablesData);

      if (name && !isAddingName) {
        setIsAddingName(true);
      }

      setIsPopulatingForm(false);
    }
  }, [props]);

  const onChangeHandler = (event, setState) => {
    const { value } = event.target;
    setState(value);
  };

  const deliverablesDropdownCallback = (dropdownData) => {
    setDeliverables(dropdownData);
  };

  const createSingleSelect = (value, label) => {
    return {
      value,
      label,
    };
  };

  const nameCheckboxChangeHandler = (event) => {
    setIsAddingName(event.target.checked);
    if (!event.target.checked) {
      // User unchecks box, make sure previously entered name isn't passed
      setBoothName("");
    }
  };

  const deliverablesDropdown = (
    <Row>
      <Col md="2"></Col>
      <DeliverablesDropdown
        callback={deliverablesDropdownCallback}
        filterItems={false}
        defaultValue={
          props?.booth?.data?.deliverables
            ? Object.values(props.booth.data.deliverables).map((d) => ({
                ...d,
                label: d.name,
              }))
            : null
        }
        multiSelect={true}
      />
    </Row>
  );

  const depthRow = (
    <Row>
      <Label md="3">Depth</Label>
      <Col md="9">
        <FormGroup>
          <Input
            type="number"
            value={depth}
            onChange={(event) => onChangeHandler(event, setDepth)}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const widthRow = (
    <Row>
      <Label md="3">Width</Label>
      <Col md="9">
        <FormGroup>
          <Input
            type="number"
            value={width}
            onChange={(event) => onChangeHandler(event, setWidth)}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const customNameCheckboxRow = (
    <Row>
      <Label md="3">Add Custom Name
      <IconTooltip 
        iconClass="fas fa-info-circle pl-1 arcat icon-md"
        text="Add a custom name for items that do not have dimensions (ie Tabletops, Kiosks, etc). 
        It can also be used if you have different price points for the same size booth for one event"
      />
      </Label>
      <Col md="9">
        <FormGroup id="customNameContainer" style={{ paddingTop: "5px" }}>
          <CustomInput
            id="customNameCheckbox"
            type="checkbox"
            inline={true}
            checked={isAddingName}
            onChange={nameCheckboxChangeHandler}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const customNameRow = (
    <Row>
      <Label md="3">Booth Name</Label>
      <Col md="9">
        <FormGroup>
          <Input
            type="text"
            value={name}
            onChange={(event) => onChangeHandler(event, setBoothName)}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const deliverablesRow = (
    <>
      <Label md="3">Deliverables</Label>
      {deliverablesDropdown}
    </>
  );

  const form = () => {
    return (
      <Fragment>
        {depthRow}
        {widthRow}
        {customNameCheckboxRow}
        {isAddingName && customNameRow}
        {deliverablesRow}
      </Fragment>
    );
  };

  return form();
};

export default NewBoothForm;
