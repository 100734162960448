import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Label, Button } from "reactstrap";
import { connect } from "react-redux";
import { variableCategories } from "utilities/constants/email-variable-categories";
import DraggableItem from "layouts/Plan/shared/DraggableItem";
import DeliverablesDropdown from "layouts/Plan/shared/DeliverablesDropdown";
import classNames from "classnames";
import { toString as _toString } from "lodash";
import OfferingSponDropDown from "layouts/Plan/shared/inputs/OfferingSponDropDown";
import IconTooltip from "./IconTooltip";

const EmailFormVariables = (props) => {
  const [variableCategory, setVariableCategory] = useState("");
  const [variableSearchData, setVariableSearchData] = useState({});

  const handleVariableDragEnd = (data) => {
    window.getSelection().removeAllRanges();
    let curHtml = props.html;
    if (document.activeElement.className.includes("jodit")) {
      curHtml = document.activeElement.innerHTML;
      document.activeElement.blur();
    }
    const content = `%${data.category}_${data.key}%`;
    if (props.subject.includes(content) || curHtml.includes(content)) {
      props.setUsedVariables((prevData) => [...prevData, data]);
    }
  };

  const handleVariableEntityDropdown = (value, categoryName) => {
    setVariableSearchData((prevData) => ({
      ...prevData,
      [categoryName]: value,
    }));
  };

  useEffect(() => {
    const initialSearchData = Object.entries(variableCategories).reduce(
      (mapObj, [categoryName, category]) => {
        if (category.hasSearchBar) {
          mapObj[categoryName] = "";
        } else {
          mapObj[categoryName] = true;
        }
        return mapObj;
      },
      {}
    );
    setVariableSearchData(initialSearchData);
  }, []);

  const variableCategoriesMap = Object.keys(variableCategories).map(
    (categoryName, index) => {
      return (
        <Button
          key={index}
          className={classNames(
            "arcat",
            variableCategory === categoryName
              ? "button-primary"
              : "button-tertiary"
          )}
          type="button"
          size="sm"
          onClick={() => setVariableCategory(categoryName)}
        >
          {categoryName}
        </Button>
      );
    }
  );

  const variablesMap = Object.keys(variableCategories).map(
    (categoryName, index) => {
      if (variableCategory !== categoryName) return;
      const obj = { ...variableCategories[categoryName] };
      const searchBar = obj.hasSearchBar && (
        <Col md="12" className="mb-2">
          {categoryName === "Deliverable" ? (
            // TODO: Pull event deliverable data
            <DeliverablesDropdown
              callback={(val) => handleVariableEntityDropdown(val, categoryName)}
              defaultValue={variableSearchData[categoryName]}
              index={3}
            />
          ) : categoryName === "Sponsorship" ? (
            <OfferingSponDropDown
              defaultSelected={variableSearchData[categoryName]}
              callback={(val) => handleVariableEntityDropdown(val, categoryName)}
              cloneData
            />
          ) : null}
        </Col>
      );
      delete obj.hasSearchBar;
      let entityId = variableSearchData[categoryName]?.itemClass?.id || "";
      return (
        <Fragment key={index}>
          {searchBar}
          {!!variableSearchData[categoryName] &&
            Object.keys(obj).map((keyName, index) => (
              <DraggableItem
                key={index}
                className="arcat button-secondary"
                textContent={variableCategories[categoryName][keyName]?.draggableName || `${keyName
                  .slice(0, 1)
                  .toUpperCase()}${keyName
                  .slice(1)
                  .replace(/([A-Z,0-9])/g, " $1")}`}
                dragData={`%${categoryName}_${keyName}${entityId}%`}
                callbackData={{
                  key: keyName + entityId,
                  value: _toString(variableSearchData[categoryName]?.itemClass?.[keyName]),
                  category: categoryName,
                }}
                onDragEnd={handleVariableDragEnd}
              />
            ))}
        </Fragment>
      );
    }
  );

  const variablesRow = (
    <Row className="mt-3 d-flex align-items-center">
      <Col md="2">
        <Label>Variables</Label>
        <IconTooltip 
          iconClass="fas fa-info-circle pl-1 arcat icon-md"
          text="Click on each category shown to see all available variables. To add a variable to 
          an email click on it and drag to desired location within the email editing box"
        />
      </Col>
      <Col 
        id="emailVariablesContainer" 
        md="10" 
        className="d-flex justify-content-center flex-wrap"
      >
        {variableCategoriesMap}
      </Col>
      <Col
        className="d-flex align-items-center justify-content-center flex-wrap mt-2"
        md="12"
      >
        {variablesMap}
      </Col>
    </Row>
  );

  return variablesRow;
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  eventContacts: state.eventContacts,
  user: state.user,
});

export default connect(mapStateToProps)(EmailFormVariables);
