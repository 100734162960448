import React from "react";
import { Col } from "reactstrap";
import { registerMarketingBullets } from "../../../utilities/constants/marketingCopy";

const RegisterMarketingCopy = () => {
  const createMarketingBullet = (title, description, icon, iconColor, index) => (
    <div key={index} className="info-area info-horizontal mt-5">
      <div className={`icon icon-${iconColor} m-0`}>
        <i className={`tim-icons icon-${icon}`}/>
      </div>
      <div className="description">
        <h3 className="info-title text-white">{title}</h3>
        <p className="description">
          {description}
        </p>
      </div>
    </div>
  );
  
  return (
    <Col className="ml-auto" md="5">
      {registerMarketingBullets.map(({title, description, icon, iconColor}, index) => createMarketingBullet(title, description, icon, iconColor, index))}
    </Col>
  );
}

export default RegisterMarketingCopy;
