import React, { useState } from "react";

import ModalContainer from "../../shared/ModalContainer";
import EditOrganizationForm from "./OrganizationForm";
// import Contact from "models/database/Contact";

// Shown when edit button of organization table gets clicked.
const EditOrgModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const modalContainerSubmitCallback = () => {
    setIsSubmitReady(true);
  };

  const editEventFormSubmitCallback = (data, index) => {
    setIsSubmitReady(false);
    props.formSubmitCallBack(data, index);
    props.toggle();
  };

  return (
    <ModalContainer
      modalTitle="Edit Organization"
      isModalOpen={isModalOpen}
      toggleCallBack={() => toggle()}
      submitCallback={() => modalContainerSubmitCallback()}
      modalClassName="modal-long"
    >
      <EditOrganizationForm
        isPopulatingForm={true}
        isSubmitReady={isSubmitReady}
        org={props.item}
        submitCallback={(data, index) =>
          editEventFormSubmitCallback(data, index)
        }
      />
    </ModalContainer>
  );
};

export default EditOrgModal;
