import PersistentBase from "./PersistentBase";
import {
  customerOrgsFirebaseUrl,
  customerOrgsFirebaseJsonUrl,
} from "../../utilities/constants/urls";
import React from "react";
import IconButton from "../../layouts/Plan/shared/IconButton";
import StyledLink from "layouts/Plan/shared/StyledLink";
import { defaultContractualUsers } from "utilities/constants/contractual-users";

export default class CustomerOrg extends PersistentBase {
  constructor(name) {
    super();
    this.name = name;
    this.csvTitle = "Customer Organization";
    this.setRestUrl();
  }

  setRestUrl() {
    this.url = customerOrgsFirebaseUrl;
    this.jsonUrl = customerOrgsFirebaseJsonUrl;
  }

  async update(payload) {
    this.jsonUrl = await super.appendAuth(`${customerOrgsFirebaseUrl}/${payload.orgId}.json`);
    delete payload.orgId;
    const response = await super.update(this.jsonUrl, payload);
    return this.createWithObject(response);
  }

  async newOrg(payload) {
    this.jsonUrl = await this.appendAuth(`${customerOrgsFirebaseUrl}.json`);
    return super.asyncCreate(payload);
  }

  async getOrgById(orgId) {
    this.jsonUrl = await super.appendAuth(
      `${customerOrgsFirebaseUrl}/${orgId}.json`
    );
    const response = await super.get();
    return response;
  }

  async getEventIds() {
    const userOrgId = await super.getUserOrgId();
    this.firebaseId = userOrgId;
    this.jsonUrl = await this.appendFirebaseIdAndAuth(this.url);
    const { events } = await super.get();
    const ids = typeof events === "string" ? [events] : Object.values(events);
    return ids;
  }

  getCount(entity) {
    return this.centerAlignDiv(entity ? Object.keys(entity).length : 0);
  }

  handleCopyInvite = (inviteUrl) => {
    const inviteElem = document.createElement("textarea");
    inviteElem.value = inviteUrl;
    document.body.appendChild(inviteElem);
    try {
      inviteElem.select();
      document.execCommand("copy");
    } catch (err) {
      console.error(err);
    } finally {
      document.body.removeChild(inviteElem);
    }
  }

  inviteLink(id) {
    const inviteUrl = `${window.location.origin}/auth/register/${id}`;
    return (
        <div>
          <a className="ml-1 mr-2" href={inviteUrl} target="_blank">
            {inviteUrl}
          </a>
          <div className="d-inline" title="Copy">
            <IconButton
                tooltipId="copy-btn"
                onClick={ () => this.handleCopyInvite(inviteUrl)}
                icon="icon-single-copy-04"
                color="info"
                tooltip="Copy"
            />
          </div>
        </div>
    )
  }

  tableColumnsAndValues() {
    const LinkTo = {
      pathname: `/admin/customer-org/${this.id}`,
    };
    
    return {
      "customer org": <StyledLink to={LinkTo}>{this.customerMetaData?.name}</StyledLink>,
      "# of users": {
        cellText: this.getCount(this.users),
        width: 150,
      },
      "# of contract users": {
        cellText: this.centerAlignDiv(
          this.customerMetaData?.contractualUsers || defaultContractualUsers
        ),
        width: 150,
      },
      "# of events": {
        cellText: this.getCount(this.events),
        width: 150,
      },
      "# of contacts": {
        cellText: this.getCount(this.contacts),
        width: 150,
      },
      "# of orgs": {
        cellText: this.getCount(this.organizations),
        width: 150,
      },
      "# acct s-ships": {
        cellText: this.getCount(this["account-sponsorships"]),
        width: 175,
      },
      "new user link": {
        cellText: this.inviteLink(this.id),
        width: 100,
      }
    };
  }
}
