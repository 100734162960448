import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import { connect } from "react-redux";
import AccountEmailTemplates from "models/database/AccountEmailTemplates";

const createSingleSelect = (value, label, itemClass) => {
  return {
    value,
    label,
    itemClass,
  };
};

const mapOptions = (templates) =>
  templates.map((value, index) =>
    createSingleSelect(index, value.name, value.itemClass)
  );

function EmailTemplatesDropdown(props) {
  const isMounted = useRef(false);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const handleChange = (val) => {
    props.onChange(val);
    setValue(val);
  };

  useEffect(() => {
    (async function () {
      if ((!props.eventData && props.filterItems) || fetchedData) return;
      setFetchedData(true);
      const templates = await new AccountEmailTemplates().getAllEntities();
      if (isMounted.current) {
        setOptions(mapOptions(templates));
      }
    })();
  });

  return (
    <CustomSelect
      options={options}
      placeholder="Template"
      value={value}
      defaultSelected={props.defaultValue}
      callback={handleChange}
      multiSelect={props.multiSelect}
      searchable={true}
      isNotPadded
      size="9"
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(EmailTemplatesDropdown);
