import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "creative-tim/components/CustomSelect";
import AccountBooths from "models/database/AccountBooths";
import { connect } from "react-redux";

const createSingleSelect = (
  value,
  label,
  id,
  deliverables,
  customName,
  itemClass
) => {
  return {
    value,
    label,
    id,
    deliverables,
    customName,
    name: label,
    itemClass,
  };
};

const mapOptions = (booths) =>
  booths.map((value, index) => {
    const { depth, width } = value.itemClass;
    let label = value.booth;
    if (depth && width) {
      label = `${depth} x ${width}`;
      if (value.booth || value.itemClass.name) {
        label = label + ` (${value.booth || value.itemClass.name})`;
      }
    } else {
      label = value.itemClass.name
    }
    return createSingleSelect(
      index,
      label,
      value.itemClass.id,
      value.itemClass.deliverables,
      value.booth,
      value.itemClass
    );
  });

function BoothsDropdown(props) {
  const isMounted = useRef(false);
  const [value, setValue] = useState(null);
  const [boothOptions, setBoothOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const handleChange = (val) => {
    props.callback({ ...val, index: props.index });
    setValue(val);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    (async function () {
      if (!props.eventData || boothOptions.length || fetchedData) return;
      setFetchedData(true);
      let booths = await new AccountBooths().getAllEntities();
      
      if (!props.isUnfiltered) {
        const eventBoothIds = props.eventData.booths.map((o) => o.id);
        booths = booths.filter(
          (o) =>
            (props.isEventLevel && eventBoothIds.includes(o.itemClass.id)) ||
            (!props.isEventLevel && !eventBoothIds.includes(o.itemClass.id)) ||
            (props.defaultValue && o.itemClass.id === props.defaultValue.id)
        );
      }

      //converting booth value from object to string
      let formattedBooths = [];
      for (let item of booths) {
        if (typeof item.booth == "string") {
          formattedBooths.push(item);
        } else {
          formattedBooths.push({
            ...item,
            booth: item.booth?.props?.children?.props?.children,
          });
        }
      }

      if (isMounted.current) {
        setBoothOptions(mapOptions(formattedBooths));
      }
    })();
  });

  return (
    <CustomSelect
      options={boothOptions}
      placeholder="Booth"
      value={value}
      defaultSelected={props.defaultValue}
      callback={handleChange}
      multiSelect={props.multiSelect}
      searchable={true}
      size="9"
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(BoothsDropdown);
