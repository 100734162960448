import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Col, Label, FormGroup } from "reactstrap";

function PhoneNumberInput({ getPhone, value, handleBlur }) {
  const [phone, setphone] = useState("");

  return (
    <>
      <FormGroup className="pt-2" row>
        <Col xs="12" sm="4" className="mr-sm-0 pr-sm-0">
          <Label className="text-sm-right float-sm-right">Phone</Label>
        </Col>
        <Col sm="8">
          <PhoneInput
            inputStyle={inputStyle}
            containerStyle={borderStyle}
            country={"us"}
            value={value ? value : phone}
            onChange={(phone) => setphone(phone)}
            onBlur={(e) => handleBlur(e.target.value)}
          />
        </Col>
      </FormGroup>
    </>
  );
}

const inputStyle = {
  borderColor: "#1d253b78",
  borderRadius: 6,
  height: "5.5vh",
  width: "100%",
  paddingLeft: 40,
};

const borderStyle = {
  borderColor: "#1d253b78",
  borderRadius: 8,
  height: "5.5vh",
  width: "100%",
};
export default React.memo(PhoneNumberInput);
