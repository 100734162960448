import React from 'react';
import { Label } from "reactstrap";

const CustomLabel = ({ label, required, isInverted, ...rest }) => {
  if (required) {
    label = isInverted ? 
      <><span style={{ fontWeight: "normal", fontSize: "larger" }}>*</span> {label}</> 
    : 
      <>{label} <span style={{ fontWeight: "normal", fontSize: "larger" }}>*</span></>;
  }
  return <Label {...rest} >{label}</Label>;
}

export default CustomLabel;