import React, { useState, useEffect } from "react";
import ContactInfoForm from "./ContactInfoForm";
import AddressInputForm from "./AddressInputForm";
import { FormGroup, Col, Input, Label, Row } from "reactstrap";
import OrgsNameDropDown from "../../shared/OrganizationsDropDownList";
import { connect } from "react-redux";

function ContactForm(props) {
  //initializations
  const [isPopulatingForm, setIsPopulatingForm] = useState(
    props.isPopulatingForm
  );
  const [contactAddressCheckbox, setContactAddressCheckbox] = useState(false);
  const [contactFormValues, setContactFormValues] = useState({});
  const [addressForm, setAddressForm] = useState({});
  const [updateAddress, setUpdateAddress] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [orgData, setOrgData] = useState(props.orgData);
  const [isOrgDropdownVisible, setIsOrgDropdownVisible] = useState(true);
  const [error, setError] = useState([]);

  const requiredFields = () => {
    const { firstName, lastName, contactType, email } = contactFormValues;

    const missingRequiredFields = [];

    if (firstName === "") {
      missingRequiredFields.push("First Name");
    }
    if (lastName === "") {
      missingRequiredFields.push("Last Name");
    }
    if (contactType === "") {
      missingRequiredFields.push("Contact Type");
    }
    if (!email) {
      missingRequiredFields.push("Email");
    }
    if (!orgId) {
      missingRequiredFields.push("Organization Name");
    }

    if (missingRequiredFields.length) {
      return missingRequiredFields;
    }

    return true;
  };

  const findOrg = (orgs, id) => orgs.find((obj) => obj?.itemClass?.id == id);

  const submitContact = () => {
    if (requiredFields() == true) {
      const { contact, submitCallback, orgData } = props;
      const { firstName, lastName } = contactFormValues;

      const fullName = firstName + lastName;
      const contactPayload = { ...contactFormValues, ...addressForm, fullName };

      const isUpdatingExistingContact = contact && contact.index >= 0;

      if (isUpdatingExistingContact) {
        submitCallback(
          { ...contactPayload, orgId: orgId?.value || orgId },
          contact.index,
          orgData
        );
      } else {
        submitCallback({ ...contactPayload, orgId: orgId?.value || orgId });
      }
    } else {
      props.submitForm(() => false);
      const fields = requiredFields();
      setError(fields.map((field) => `${field} can't be empty` + "\n"));
    }
  };

  const populateOrgAddress = () => {
    const org = findOrg(props.orgData, orgId?.value || orgId);
    const { address1, address2, city, country, state, zip } = org?.itemClass || {};
    setAddressForm({ address1, address2, city, country, state, zip });
    setUpdateAddress(!updateAddress);
  }

  useEffect(() => {
    if (contactAddressCheckbox && orgId) {
      populateOrgAddress();
    }
  }, [contactAddressCheckbox, orgId]);

  useEffect(() => {
    if (props?.isSubmitReady) {
      submitContact();
    }
    if (isPopulatingForm) {
      const {
        firstName,
        lastName,
        title,
        contactType,
        phone,
        orgId,
        email,
      } = props?.contact?.data;

      setContactFormValues({
        firstName,
        lastName,
        title,
        contactType,
        phone,
        email,
      });
      setOrgId(orgId);
      setIsPopulatingForm(false);
      setOrgData(findOrg(props.orgData, orgId));
    } else if (props.orgId) {
      setIsOrgDropdownVisible(false);
      setOrgId(props.orgId);
      setOrgData(findOrg(props.orgData, props.orgId));
    }
  });

  const contactRow = (
    <ContactInfoForm
      getContactFormValue={(val) => setContactFormValues(val)}
      isOrgName={false}
      sendBackValues={props?.contact?.data}
    />
  );

  const OrganizationDropdown = isOrgDropdownVisible && (
    <Row>
      <Col md={2} className="text-md-right pr-0 pl-sm-0 pt-md-2">
        <Label>Organization</Label>
      </Col>
      <Col md={8} className="px-0">
        <OrgsNameDropDown
          defaultSelected={{
            label: orgData?.itemClass?.name,
            value: orgData?.itemClass?.id,
          }}
          callback={setOrgId}
        />
      </Col>
    </Row>
  );

  const checkboxRow = (
    <Col md={12}>
      <Input
        type="checkbox"
        onClick={() => setContactAddressCheckbox(!contactAddressCheckbox)}
      />
      <Label>Contact Address Same as Organization Address checkbox</Label>
    </Col>
  );

  const addressRow = (
    <FormGroup row>
      <Col md={12}>
        <AddressInputForm
          sendBackValues={{ ...props?.contact?.data }}
          getAddressFormValue={(val) => setAddressForm(val)}
          sameAddress={addressForm}
          updateAddress={updateAddress}
          isSameAddress={contactAddressCheckbox}
        />
      </Col>
    </FormGroup>
  );

  const errorContainer = (
    <div className="d-flex flex-column justify-content-center">
      {error.map((er) => (
        <span className="text-danger font-weight-bold">{er}</span>
      ))}
    </div>
  );

  return (
    <span>
      {contactRow}
      {OrganizationDropdown}
      {checkboxRow}
      {addressRow}
      {errorContainer}
    </span>
  );
}

const mapStateToProps = (state) => ({
  orgData: state.orgData,
});

export default connect(mapStateToProps)(ContactForm);
