/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { connect } from "react-redux";

// react component for creating dynamic tables
import ReactTable from "react-table";

import ActionButtonsColumn from "./ActionButtonsColumn";

// react-component to show alert before an action.
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Button, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, NavLink, Input, Label } from "reactstrap";
import { ExportToCsv } from "export-to-csv";
import { csvOptions } from "utilities/constants/csv-export-options";
import moment from "moment";
import { dateFormat } from "utilities/constants/date-format";
import csvToJson from "csvtojson";
import { ThreeDots } from "react-loading-icons";
import createZip from "utilities/methods/createZip";
import pluralize from "pluralize";

class CrudActionsTable extends Component {
  constructor(props) {
    super(props);
    const dataModel = new this.props.dataModel();
    this.state = {
      isShowingEditItemtModal: false,
      editingItem: { data: {}, index: "" },
      isImportAvailable: !!dataModel.csvKeyMap,
      isExportAvailable: this.props.isExportable !== false,
      isAnyItemSelected: false,
      data: [],
      columnHeadings: dataModel.tableColumnHeadings(
        this.props.hasNoActions,
        this.customFilterMethod
      ),
      isShowingDeleteItemAlert: false,
      deleteAlert: "",
      editDuplicatedEvent: false,
      fileUpload: {
        message: "",
        color: "danger",
        isUploading: false,
      },
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { newItem, data, modalName } = this.props;
    if (newItem && prevProps.newItem !== newItem) {
      const newRow = newItem.tableRowFormat(data.length);
      let i = 0;
      const updatedTableData = [newRow, ...this.state.data].map((o) => ({
        ...o,
        index: i++,
      }));
      this.addActionsColumn(updatedTableData);
    }

    if (data && prevProps.data !== data) {
      this.addActionsColumn(this.props.data);
    }
    if (this.state.editDuplicatedEvent) {
      this.setState({ editDuplicatedEvent: false });
      this._editButtonClickHandler(this.state.data.length - 1);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hasNoActions !== this.props.hasNoActions) {
      const dataModel = new nextProps.dataModel();
      this.setState({
        columnHeadings: dataModel.tableColumnHeadings(nextProps.hasNoActions, this.customFilterMethod)
      });
    }
  }

  toggleModifyItemModal() {
    this.setState({
      isShowingEditItemtModal: !this.state.isShowingEditItemtModal,
    });
  }

  async _editItemHandler(
    itemProps,
    indexToEdit,
    deleteItem,
    orgData,
    orgUsers
  ) {
    const row = this.state.data[indexToEdit];
    const parent = {
      id: row?.itemClass?.parentId,
      collection: row?.itemClass?.parentCollection,
    };
    const partner = {
      id: row?.itemClass?.partnerId,
      collection: row?.itemClass?.partnerCollection,
      entityId: row?.itemClass?.entityId,
    };
    const item = await row.itemClass.update(
      itemProps,
      this.props.eventData,
      deleteItem,
      true,
      parent,
      orgData,
      orgUsers,
      partner
    );
    const formattedItem = item.tableRowFormat(indexToEdit);
    const tableData = this.state.data;
    tableData[indexToEdit] = formattedItem;
    this.addActionsColumn(tableData);
    if (this.props.editCallback) {
      this.props.editCallback(tableData, item, deleteItem, row);
    }
  }

  showEditModal() {
    return (
      <this.props.editModal
        item={this.state.editingItem}
        formSubmitCallBack={(data, index, deleteId, orgData, orgUsers) =>
          this._editItemHandler(data, index, deleteId, orgData, orgUsers)
        }
        toggle={() => {
          this.toggleModifyItemModal();
        }}
      />
    );
  }

  deleteButtonMiddleware(itemIndex) {
    this.setState({
      deleteItemIndex: itemIndex,
      isShowingDeleteItemAlert: true,
    });
  }

  warningWithConfirmAndCancelMessage = () => {
    return (
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.successDelete()}
        onCancel={() => this.cancelDelete()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        {/* You will not be able to recover this imaginary file! */}
      </ReactBSAlert>
    );
  };

  async _deleteButtonClickHandler(key) {
    const data = this.state.data;
    let item = data.find((o, i) => {
      if (o.index === key) {
        if (o?.itemClass?.parentId) {
          console.log(
            "trying to delete child deliverable of a sponsorship/booth"
          );
          return false;
        }
        if (o?.itemClass?.partnerId) {
          console.log("trying to delete child deliverable of a partner");
          return false;
        }
        o.itemClass.delete(o?.itemClass?.id, this.props.eventData?.id, o?.itemClass);
        data.splice(i, 1);
        return true;
      }
      return false;
    });
    if (!item) return;
    const newArr = [].concat(data);
    this.setState((prevState) => {
      return { data: newArr };
    });
    if (this.props.deleteCallback) {
      this.props.deleteCallback(item.itemClass);
    }
  }

  successDelete = () => {
    this._deleteButtonClickHandler(this.state.deleteItemIndex);
    this.setState({ isShowingDeleteItemAlert: false });
  };

  cancelDelete = () => {
    this.setState({ isShowingDeleteItemAlert: false });
  };

  eventDuplicateHandler(item) {
    this._addItemHandler(item.itemClass.duplicationData());
    this.setState({ editDuplicatedEvent: true });
  }

  _duplicateButtonClickHandler(itemIndex) {
    let item = this.state.data.find((o) => o.index === itemIndex);
    if (item?.itemClass?.duplicationData) return this.eventDuplicateHandler(item);
    this._addItemHandler(item.itemClass.metaData());
  }

  _editButtonClickHandler(itemIndex) {
    let obj = this.state.data.find((o) => o.index === itemIndex);
    this.setState({
      isShowingEditItemtModal: true,
      editingItem: {
        data: obj.itemClass.metaData(),
        index: itemIndex,
      },
    });
  }

  _downloadButtonClickHandler(itemIndex) {
    const item = this.state.data.find((o) => o.index === itemIndex);
    item?.itemClass.download(this.props?.eventData.id);
  }

  async completedCheckboxHandler(indexToEdit, completed) {
    const row = this.state.data[indexToEdit];
    const parent = {
      id: row?.itemClass?.parentId,
      collection: row?.itemClass?.parentCollection,
    };
    const partner = {
      id: row?.itemClass?.partnerId,
      collection: row?.itemClass?.partnerCollection,
      entityId: row?.itemClass?.entityId,
    };
    row?.itemClass.update(
      { [row?.itemClass.id]: { ...row?.itemClass, completed } },
      this.props.eventData,
      null,
      null,
      parent,
      null,
      null,
      partner
    );

    const tableData = this.state.data;
    if (!tableData[indexToEdit]?.itemClass) return;
    tableData[indexToEdit].itemClass.completed = completed;
    this.setState({ data: tableData })
    this.addActionsColumn(tableData);
  }

  selectionCheckboxHandler(indexToEdit, isSelected) {
    const tableData = this.state.data;
    if (!tableData[indexToEdit]?.itemClass) return;
    tableData[indexToEdit].itemClass.isSelected = isSelected;
    const isAnyItemSelected = isSelected || tableData.some((item) => item?.itemClass?.isSelected);
    this.setState({ data: tableData, isAnyItemSelected });
    this.addActionsColumn(tableData);
  }

  actions = (key) => (
    <ActionButtonsColumn
      hasDownloadAction={this.props.hasDownloadAction}
      hasEditAction={this.props.hasEditAction}
      hasDuplicateAction={this.props.hasDuplicateAction}
      itemIndex={key}
      editHandler={(itemIndex) => this._editButtonClickHandler(itemIndex)}
      deleteHandler={(itemIndex) => this.deleteButtonMiddleware(itemIndex)}
      duplicateHandler={(itemIndex) =>
        this._duplicateButtonClickHandler(itemIndex)
      }
      downloadHandler={(itemIndex) => {
        this._downloadButtonClickHandler(itemIndex);
      }}
    />
  );

  checkboxInput = (index, rowData, title, property, onChange) => {
    const inputId = `${rowData?.itemClass?.id}${rowData?.itemClass?.entityId}${rowData?.itemClass?.partnerId}`;
    const isDisabled = property === "completed" && !this.props.isEventEditable;
    const callback = isDisabled ? null : onChange?.bind(this);
    return (
      <div className="text-right">
        <CustomInput
          id={inputId}
          className={(isDisabled) ? "arcat disabled-checkbox" : undefined}
          itemID={inputId}
          type="checkbox"
          inline={false}
          checked={!!rowData?.itemClass?.[property]}
          title={title}
          onChange={(e) => callback?.(index, e.target.checked)}
          aria-label={title}
        />
      </div>
    )
  };

  addActionsColumn(prevTableData) {
    const tableData = prevTableData.map((row, index) => {
      row.index = index;
      row.actions = this.actions(row.index);
      if (row.completed) {
        row.completed = this.checkboxInput(index, row, "Completed", "completed", this.completedCheckboxHandler);
      } else if (row.selection) {
        row.selection = this.checkboxInput(index, row, "Selected", "isSelected", this.selectionCheckboxHandler);
      }
      return row;
    });
    this.setState({ data: tableData });
  }

  _addItemHandler(itemProps) {
    const item = new this.props.dataModel()
      .create(itemProps, this.props.eventData)
      .tableRowFormat(this.state.data.length);
    this.addActionsColumn([...this.state.data, item]);
  }

  // customFilterMethod is used to handle type differences and to make search case insensitive.
  customFilterMethod = (filter, columnName) => {
    if (!filter || filter === "") {
      this.setState({ data: this.props.data });
      return
    }

    const filteredRows = this.state.data
        .filter( (row) => {
          const rowElement = row[columnName];

          let rowValue;
          if (typeof rowElement === 'string') {
            rowValue = rowElement.toLowerCase();
          } else {
            const value = rowElement?.props?.children?.props?.value || rowElement?.props?.children || "";
            rowValue = value.toString().toLowerCase();
          }

          return rowValue.includes(filter.toLowerCase())
        })
        .map((row, index, filteredArr) => {
          if (row.completed) {
            row.completed = this.completedCheckbox(index, filteredArr);
          }
          return row;
        });
    
    this.addActionsColumn(filteredRows);
    this.setState({ data: filteredRows });
  };

  getRowColumnValue(row, column) {
    let value = [
      row.itemClass?.[column],
      row[column]?.props?.children,
      row[column]?.props?.children?.props?.children,
      row[column]?.props?.children?.props?.value,
      row[column],
    ].find((val) => val !== undefined && typeof val !== "object");
    if (value === undefined) {
      value = "";
    }
    return value;
  }

  getSortCriterion(cell, columnId) {
    let value = [
      cell[columnId]?.props?.children?.props?.["data-sort"],
      cell.itemClass?.[columnId],
      cell[columnId]?.props?.children?.props?.children,
      cell[columnId]?.props?.children?.props?.value,
      cell[columnId]?.props?.children,
      cell[columnId],
    ].find(
      (val) =>
        val !== undefined &&
        (moment(val, dateFormat).isValid() || typeof val !== "object")
    );
    if (value === undefined || value === "-") {
      return "";
    }
    if (moment(value, dateFormat, true).isValid()) {
      return moment(value, dateFormat).valueOf();
    }
    if (typeof value === "string") {
      return Number(value) || value.toLowerCase();
    }
    return value;
  }

  sortHandler(sortColumn, _, desc) {
    const columnId = sortColumn[0].id;
    const tableData = this.state.data.sort((a, b) => {
      const criterionA = this.getSortCriterion(a, columnId);
      const criterionB = this.getSortCriterion(b, columnId);
      let sortValue = 0;
      if (criterionA < criterionB) {
        sortValue = -1;
      } else if (criterionA > criterionB) {
        sortValue = 1;
      }
      if (desc) {
        sortValue = -sortValue;
      }
      return sortValue;
    });
    this.addActionsColumn(tableData);
  }

  exportToCsvHandler() {
    if (!this.props.dataModel) return;
    const dataModel = new this.props.dataModel();
    const columns = Object.keys(dataModel.tableColumnsAndValues() || {});
    const data = this.props.data.map(
      (row) =>
        row.itemClass?.tableCsvData?.() ||
        columns.reduce((mapObj, column) => {
          mapObj[column] = this.getRowColumnValue(row, column);
          return mapObj;
        }, {})
    );
    const name = pluralize(dataModel.csvTitle || dataModel.constructor.name);
    const title = `${name}_${moment().format("MMM-DD-YYYY-h-mm-ss")}`;
    new ExportToCsv({
      ...csvOptions,
      title,
      filename: title,
    }).generateCsv(data);
  }

  exportButton() {
    return (
      <Button
        size="md"
        color="info"
        onClick={() => this.exportToCsvHandler()}
      >
        Export
      </Button>
    );
  }

  downloadButton() {
    return (
      <Button
        size="md"
        color="info"
        onClick={() => this.downloadZip()}
        disabled={!this.state.isAnyItemSelected}
      >
        Download Selection
      </Button>
    );
  }

  async downloadZip() {
    const tableData = this.state.data;
    const selectedItems = tableData.filter((item) => item?.itemClass?.isSelected);
    if (!selectedItems.length) return;
    if (selectedItems.length === 1) {
      return selectedItems[0]?.itemClass.download(this.props?.eventData.id);
    }
    const files = [];
    for (const item of selectedItems) {
      const blob = await item.itemClass.getBlob();
      files.push(blob);
    }
    const zip = await createZip(files, "Documents.zip");
    zip.download();
  }

  downloadImportTemplate() {
    if (!this.props.dataModel) return;
    const { data, title } = new this.props.dataModel().csvImportTemplate?.() || {};
    new ExportToCsv({
      ...csvOptions,
      title,
      filename: title,
    }).generateCsv(data);
  }

  setUploadStatus(message = "", color = "danger", isUploading = false) {
    this.setState({ fileUpload: { message, color, isUploading } });
  }

  async importHandler(e) {
    this.setUploadStatus("", "info", "true");
    const file = e?.target?.files?.[0];
    if (!file) return;
    if (file.size > 31457280) this.setUploadStatus("Import file too large (max 30MB)");
    const csvStr = await file.text();
    const dataModel = new this.props.dataModel();
    const headers = Object.keys(dataModel.tableCsvData() || {});
    const data = await csvToJson({ headers, maxRowLength: 65535 }).fromString(csvStr);
    const payload = data?.filter((row) => {
      return !(
        Object.entries(row || {}).every(([key, value]) => key === value) ||
        Object.values(row || {}).every((value) => !value?.length)
      );
    });
    if (!payload?.length) this.setUploadStatus("Invalid import data");
    if (payload.length > 2500) this.setUploadStatus("Import file too large (max 2,500 rows)");
    const response = await dataModel.uploadCsvData(payload, this.state.data.length);
    if (!response?.length) return this.setUploadStatus("CSV upload failed");
    this.addActionsColumn([...this.state.data, ...response]);
    const pluralRow = response.length > 1 ? "rows" : "row";
    this.setUploadStatus(`Imported ${response.length} ${pluralRow} from CSV`, "info");
  }

  importExportDropdown() {
    return (
      <UncontrolledDropdown>
        <Input id="fileInput" type="file" accept="text/csv" style={{ opacity: 0, height: 0 }} onChange={(e) => this.importHandler(e)} />
        <DropdownToggle
          caret
          color="info"
          data-toggle="dropdown"
          onClick={e => e.preventDefault()}
        >
          Import / Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem className="nav-item" onClick={() => this.exportToCsvHandler()}>
            Export
          </DropdownItem>
          <DropdownItem className="nav-item" onClick={() => document.getElementById("fileInput").click()}>
            Import
          </DropdownItem>
          <DropdownItem className="nav-item" onClick={() => this.downloadImportTemplate()}>
            Download Import Template
          </DropdownItem>
        </DropdownMenu>
        <Label 
          className={`ml-2 text-${this.state.fileUpload.color || "danger"}`}
        >
          {this.state.fileUpload.message}
        </Label>
        {this.state.fileUpload.isUploading && <ThreeDots className="ml-2" width="5em" fill="#cf009f"/>}
      </UncontrolledDropdown>
    );
  }

  render() {
    const pageSize = 10;

    return (
      <>
        {this.state.isShowingEditItemtModal && this.showEditModal()}
        {this.state.isShowingDeleteItemAlert &&
          this.warningWithConfirmAndCancelMessage()}
        {this.state.isImportAvailable ? this.importExportDropdown() :  this.state.isExportAvailable && this.exportButton()}
        {this.props.hasDownloadAction && this.downloadButton()}
        <ReactTable
          data={this.state.data}
          filterable={true}
          resizable={true}
          columns={this.state.columnHeadings}
          defaultPageSize={this.props.defaultPageSize}
          showPaginationTop={false}
          showPaginationBottom={this.props.showPaginationBottom}
          className="-striped -highlight arcat react-table"
          sortable={true}
          defaultSortMethod={() => {}}
          onSortedChange={this.sortHandler.bind(this)}
          defaultFilterMethod={(filter, row) =>
            this.customFilterMethod(filter, row)
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(CrudActionsTable);
