import React from "react";
import BaseFeature from "./BaseFeature";

/**
 * @description React-Table cell creator
 */
export class TableRow extends BaseFeature {
  constructor(columnName, cellText) {
    super();
    this.columnName = columnName;
    this.cellText = cellText;
  }

  metaData() {
    return {
      [this.columnName]: this.cellText,
    };
  }
}
