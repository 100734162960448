import React, { useState, useEffect } from "react";
// Import React FilePond
import { FilePond, File, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { storage } from "../.../../../../../../../firebase-client/firebase-client";
import { connect } from "react-redux";
import Document from "../../../../../../models/database/Document";
import Documents from "../../../../../../models/database/Documents";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function DocumentForm(props) {
  const [files, setFiles] = useState([]);
  const [eventId, setEventId] = useState("");
  const [orgId, setOrgId] = useState("");
  let { eventData } = props;
  useEffect(() => {
    (async function getEventId() {
      let event_id = await eventData.id;
      setEventId(event_id);
      let org_id = await eventData.getUserOrgId();
      setOrgId(org_id);
    })();
  }, []);

  function getFromBucket(file) {
    storage
      .ref(`documents/${orgId}/${eventId}/${file.filename}`)
      .getMetadata()
      .then(async (metaData) => {
        const formattedData = await new Document().createWithObject(metaData);
        props.submitCallback(formattedData);
      });
  }

  const handleFinishUpload = (file, progress) => {
    if (progress == 1) {
      getFromBucket(file);
    }
  };

  const server = new Documents().saveDocument(eventId);

  return (
    <FilePond
      files={files}
      onupdatefiles={setFiles}
      allowMultiple={true}
      maxFiles={3}
      server={server}
      onprocessfileprogress={handleFinishUpload}
      //   sets the file input name, it's filepond by default
      name="files"
      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      credits=""
    />
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(DocumentForm);
