import React, {useState, Fragment} from 'react';
import {Button} from 'reactstrap';

import ModalContainer from "../../../shared/ModalContainer";
import NewDeliverableForm from "./NewDeliverableForm";

const NewDeliverableModal = (props) => {

    const showButton = true;

    const [isSubmitReady, setIsSubmitReady] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const OpenModalButton = () => {
        return (
            showButton === false ? "" : <Button color="info" onClick={() => toggle()}>+ Add Deliverable</Button>
        )
    };

    const modalContainerSubmitCallback = () => {
        setIsSubmitReady(true)
    };

    const newDeliverableFormSubmitCallback = (data, index) => {
        setIsSubmitReady(false);
        props.formSubmitCallBack(data, index);
        toggle();
    };

    return (
        <ModalContainer
            buttonLabel="+ Add Deliverable"
            modalTitle="Add New Deliverable"
            isModalOpen={isModalOpen}
            toggleCallBack={() => toggle()}
            submitCallback={() => modalContainerSubmitCallback()}
            button={<OpenModalButton/>}
        >
            <NewDeliverableForm
                isSubmitReady={isSubmitReady}
                submitCallback={ (data, index) => newDeliverableFormSubmitCallback(data, index)}
            />
        </ModalContainer>
    )

};

export default NewDeliverableModal
