import CustomSelect from "creative-tim/components/CustomSelect";
import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

const NewDeliverableForm = (props) => {
  const [name, setDeliverableName] = useState("");
  const [description, setDescription] = useState("");

  const [editingIndex, setEditingIndex] = useState("");
  const [isPopulatingForm, setIsPopulatingForm] = useState(
    props.isPopulatingForm
  );

  const clearHooks = () => {
    setDeliverableName("");
    setDescription("");
  };

  const submit = () => {
    const deliverable = {
      name,
      description,
    };

    props.submitCallback(deliverable, editingIndex);
    clearHooks();
  };

  useEffect(() => {
    if (props.isSubmitReady) {
      submit();
    }
    if (isPopulatingForm) {
      const { name, description } = props.deliverable.data;
      setEditingIndex(props.deliverable.index);

      setDeliverableName(name);
      setDescription(description);

      setIsPopulatingForm(false);
    }
  }, [props]);

  const onChangeHandler = (event, setState) => {
    const { value } = event.target;
    setState(value);
  };

  const createSingleSelect = (value, label) => {
    return {
      value,
      label,
    };
  };

  const nameRow = (
    <Row>
      <Label md="3">Deliverable Name</Label>
      <Col md="9">
        <FormGroup>
          <Input
            type="text"
            value={name}
            onChange={(event) => onChangeHandler(event, setDeliverableName)}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const descriptionRow = (
    <Row>
      <Label md="3">Description</Label>
      <Col md="9">
        <FormGroup>
          <Input
            type="text"
            value={description}
            onChange={(event) => onChangeHandler(event, setDescription)}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const form = () => {
    return (
      <Fragment>
        {nameRow}
        {descriptionRow}
      </Fragment>
    );
  };

  return form();
};

export default NewDeliverableForm;
