import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import updateReduxData from "utilities/methods/updateReduxData";
import AgendaTable from "./AgendaTable";
import NewAgendaModal from "./NewAgendaModal";
import moment from "moment";
import Agenda from "models/database/Agenda";

function AgendaSection(props) {
  const isMounted = useRef(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [agendaData, setAgendaData] = useState([]);
  const [newRow, setNewRow] = useState(null);
  
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const handleSubmit = async (data) => {
    const payload = {
      ...data,
      startTime: data.startTime.toISOString(),
      endTime: data.endTime.toISOString(),
    };
    const response = await new Agenda().createNew(payload, props.eventData.id);
    setNewRow(response);
  };

  const errorNotification = (
    <Alert
      style={{
        position: "fixed",
        top: "20px",
        right: "20px",
        zIndex: "99999999",
        minWidth: "300px",
      }}
      color="info"
      isOpen={isNotificationVisible}
      toggle={() => setIsNotificationVisible(!isNotificationVisible)}
    >
      <span style={{ marginRight: "12px" }} data-notify="message">
        {notificationMessage}
      </span>
    </Alert>
  );

  return (
    <>
      {errorNotification}
      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardHeader
              className="mr-2"
              style={{ display: 'flex', justifyContent: "space-between" }}>
              <Col className="d-flex justify-content-end">
                  <NewAgendaModal submitCallback={handleSubmit} />
              </Col>
            </CardHeader>
            <CardBody>
              <AgendaTable newRow={newRow} data={agendaData} setData={(data) => {
                if (isMounted.current) {
                  setAgendaData(data);
                }
              }} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(AgendaSection);
