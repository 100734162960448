import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label } from "reactstrap";

import ModalContainer from "../../../../shared/ModalContainer";
import BoothsDropdown from "../../../../shared/BoothsDropdown";
import { connect } from "react-redux";
import { CurrencyToNumber } from "layouts/Plan/shared/CurrencyFormatter";

const EditBoothsModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [booth, setBooth] = useState({ index: 0, rate: "", quantity: "" });
  const [initialBooth, setInitialBooth] = useState({});

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  useEffect(() => {
    setBooth({
      quantity: "",
      ...props.item.data,
      rate: CurrencyToNumber(props?.item?.data.rate),
    });
    setInitialBooth(props.item.data);
  }, [props.item]);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    props.toggle();
  };

  const boothChangeHandler = (value, type) => {
    const newData = { ...booth };
    newData[type] = parseInt(value);
    setBooth(newData);
  };

  const boothsDropdownCallback = (dropdownData) => {
    setBooth({
      ...dropdownData,
      rate: booth.rate,
      quantity: booth.quantity,
      name: dropdownData.label,
    });
  };

  const formIsValid = () => {
    const isValid = !!(
      booth.id &&
      Number.isInteger(booth.quantity) &&
      Number.isInteger(booth.rate)
    );
    return isValid;
  };
  
  const submitCallback = async () => {
    if (!formIsValid()) return;
    toggle();
    const payload = {
      booths: {
        [booth.id]: booth,
      },
    };

    if (!initialBooth || !initialBooth.id || initialBooth.id === booth.id) {
      setInitialBooth({});
    }
    props.formSubmitCallBack(payload, props.item.index, initialBooth);
  };

  const boothDropdown = (
    <Fragment>
      <Row>
        <BoothsDropdown
          callback={boothsDropdownCallback}
          defaultValue={{ ...booth, label: booth.name, value: 0 }}
          multiSelect={false}
        />
      </Row>
      <Row className="pl-3 mt-2">
        <Col md="3" className="m-0 p-0">
          <Input
            type="number"
            step="1"
            placeholder="Quantity"
            value={booth.quantity || ""}
            onChange={(e) => boothChangeHandler(e.target.value, "quantity")}
          />
        </Col>
        <Col md="3" className="m-0 pl-2">
          <Input
            type="number"
            step="1"
            placeholder="Rate"
            value={booth.rate || ""}
            onChange={(e) => boothChangeHandler(e.target.value, "rate")}
          />
        </Col>
      </Row>
    </Fragment>
  );

  return (
    <ModalContainer
      buttonLabel="+ Edit Booth"
      modalTitle="Edit Booth"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      isSubmitDisabled={!isSubmitReady}
    >
      <Label className="ml-1 mb-0 mt-0">Booth</Label>
      {boothDropdown}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(EditBoothsModal);
