import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import CustomerOrgUsers from "../../../models/database/CustomerOrgUsers";
import Contacts from "models/database/Contacts";
import User from "../../../models/database/User";

const createSingleSelect = (value, label) => {
  return {
    value,
    label,
  };
};

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

function CreatableUsersDropdown(props) {
  const isMounted = useRef(false);
  const [usersData, setUsersData] = useState([]);
  const [value, setValue] = useState(props.fixedOptions || []);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const usersNameOptions = () => {
    return usersData
      .filter((val) => {
        if (props.roleFilter)
          return (
            Boolean(val) &&
            val?.roleType.toLowerCase() == props.roleFilter.toLowerCase()
          );
        else return Boolean(val);
      })
      .map((type, index) =>
        createSingleSelect(type?.uid || type?.itemClass?.id, type?.email)
      );
  };

  const filterUsers = (allUsers) => {
    if (!props.isPartnersOnly) return allUsers;
    if (!props.eventData?.partners) return [];
    const partnerOrgIds = Object.values(props.eventData.partners).reduce(
      (mapArr, partner) => {
        if (partner?.orgId) {
          mapArr.push(partner.orgId);
        }
        return mapArr;
      },
      []
    );
    const partnerContacts = allUsers.filter((user) =>
      partnerOrgIds.includes(user?.itemClass?.orgId) || 
      partnerOrgIds.includes(user?.itemClass?.organization)
    );
    return partnerContacts;
  };

  const handleChange = (newValue = [], { action, removedValue }) => {
    if (removedValue?.isFixed) {
      return;
    } else if (action === "clear") {
      newValue = value.filter((v) => v.isFixed);
    }
    props.onChange(newValue);
    setValue(newValue);
  };

  async function fetchUser(id) {
    const getUser = await new User().get(id);
    return getUser && Object.values(getUser)[0];
  }

  let placeholder = props.multiSelect ? "Emails" : "Email";
  useEffect(() => {
    async function getUsers() {
      const users = await new CustomerOrgUsers().getAllEntities();
      let idx = await users.map(({ usersId }) => {
        if (usersId) {
          return fetchUser(usersId);
        }
      });

      await Promise.all(idx).then(async (res) => {
        const contacts = props.eventContacts || await new Contacts().getAllEntities(false);
        const emailContacts = contacts.filter((c) => c.email);
        const allUsers = res.concat(emailContacts);
        if (isMounted.current) {
          setUsersData(filterUsers([...allUsers]));
        }
        props.dispatch({
          type: "setOrgUsers",
          data: allUsers,
        });
      });
    }
    if (!props.orgUsers) {
      getUsers();
    } else {
      (async function () {
        const contacts = props.eventContacts || await new Contacts().getAllEntities(false);
        const prevEmails = props.orgUsers.map((o) => o.email);
        const emailContacts = contacts.filter(
          (c) => c.email && !prevEmails.includes(c.email)
        );
        const allUsers = props.orgUsers.concat(emailContacts);
        if (isMounted.current) {
          setUsersData(filterUsers([...allUsers]));
        }
      })();
    }
  }, []);

  return (
    <CreatableSelect
      value={value}
      options={usersNameOptions()}
      styles={styles}
      defaultSelected={props.defaultSelected}
      placeholder={placeholder}
      onChange={handleChange}
      isMulti={props.multiSelect}
      isClearable={value?.some((v) => !v.isFixed)}
      searchable={true}
      size="9"
    />
  );
}

const mapStateToProps = (state) => ({
  orgUsers: state.orgUsers,
  eventData: state.eventData,
  eventContacts: state.eventContacts,
});

export default connect(mapStateToProps)(React.memo(CreatableUsersDropdown));
