import React, { Component } from "react";
// react component for creating dynamic tables
import {
    CardBody,
    CardHeader,
    CardTitle,
} from "reactstrap";

import AccountDeliverable from "models/database/AccountDeliverable";
import DeliverablesTable from "./DeliverablesTable";
import SectionContainer from "../../SectionContainer";
import NewDeliverableModal from "./NewDeliverableModal";

class DeliverablesTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor : "blue", //"#005BAA",
            sidebarMini : true,
            newDeliverable    : '',
        };
    }

    async submitDeliverableHandler(deliverableProps) {
        const deliverable = await new AccountDeliverable().create(deliverableProps);

        this.setState((previousState) => {
            return { newDeliverable : deliverable }
        });
    }

    tableHeader = () => (
        <CardHeader>
            <div style={{display: 'flex', justifyContent: "space-between"}}>
                <CardTitle tag="h4">Deliverables</CardTitle>
                <NewDeliverableModal
                    formSubmitCallBack={(data) => this.submitDeliverableHandler(data)}
                />
            </div>
        </CardHeader>
    );

    render() {
        return (
            <>
                <SectionContainer colClassName="pl-0 pr-0">
                    <div>
                        { this.tableHeader() }
                        <CardBody>
                            <DeliverablesTable newRow={this.state.newDeliverable}/>
                        </CardBody>
                    </div>
                </SectionContainer>
            </>
        )
    }
}

export default DeliverablesTableContainer;
