import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "../../../creative-tim/components/CustomSelect";
import Organizations from "../../../models/database/Organizations";

const createSingleSelect = (value, label) => {
  return {
    value,
    label,
  };
};

function OrgsNameDropDown({ callback, defaultSelected, event }) {
  //Data will be get form Firebase
  const isMounted = useRef(false);
  const [orgsData, setOrgsData] = useState([]);

  const [currentEventState, setCurrentEventState] = useState(event)

  useEffect(() => {
    setCurrentEventState(event)
  }, [event])

  const filteredOrgs = event?.partners 
  ? 
  orgsData.filter((org) => {
    const alreadySelectedOrgs = Object.values(event.partners).map((org) => org.orgId)
    const orgId = org?.itemClass?.id
    const isOrgAPartner = alreadySelectedOrgs.includes(orgId)
    return !isOrgAPartner
  })
  : 
  orgsData

  const OrgsNameOptions = filteredOrgs.map((type, index) => {
    return createSingleSelect(type?.itemClass?.id, type?.itemClass?.name);
  });

  let placeholder = "Organizations name";
  useEffect(() => {
    isMounted.current = true;
    async function getOrgs() {
      const orgs = await new Organizations().getAllEntities();
      if (isMounted.current) {
        setOrgsData(orgs);
      }
    }
    getOrgs();
    return () => { isMounted.current = false; }
  }, []);
  return (
    <CustomSelect
      options={OrgsNameOptions}
      defaultSelected={defaultSelected}
      placeholder={placeholder}
      callback={(val) => callback(val)}
      multiSelect={false}
      searchable={true}
      size="9"
    />
  );
}

export default React.memo(OrgsNameDropDown);
