import React, { useState, Fragment, useEffect } from "react";
import { Button, Row, Col, Input, Form, Label } from "reactstrap";

import ModalContainer from "../../../shared/ModalContainer";
import SponsorshipsDropdown from "../../../shared/SponsorshipsDropdown";
import BoothsDropdown from "../../../shared/BoothsDropdown";
import arrayToObject from "utilities/methods/arrayToObject";
import IconButton from "layouts/Plan/shared/IconButton";
import ReactDatetime from "react-datetime";
import classNames from "classnames";
import CustomLabel from "layouts/Plan/shared/CustomLabel";
import { connect } from "react-redux";

const SponsorshipsAndBooths = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sponsorships, setSponsorships] = useState([{ index: 0 }]);
  const [booths, setBooths] = useState([{ index: 0 }]);

  useEffect(() => {
    setIsSubmitReady(formIsValid());
  });

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = (
    <Button
      block={!props.hasSponsorships}
      color="info"
      size="lg"
      onClick={toggle}
      disabled={!props.isEventEditable}
    >
      + Add Sponsorships and Booths
    </Button>
  );

  const sponsorshipChangeHandler = (sponsorship, value, type, transform = parseInt) => {
    setSponsorships((prevState) => {
      sponsorship[type] = transform(value);
      const newState = [...prevState];
      newState[sponsorship.index] = sponsorship;
      return newState;
    });
  };

  const sponsorshipDeadlineHandler = (sponsorship, date) => {
    if (!sponsorship.deliverables) return;
    setSponsorships((prevState) => {
      for (const deliverableId of Object.keys(sponsorship.deliverables)) {
        if (sponsorship.deliverables[deliverableId].deadline) continue;
        sponsorship.deliverables[deliverableId].deadline = date;
      }
      sponsorship.deadline = date;
      const newState = [...prevState];
      newState[sponsorship.index] = sponsorship;
      return newState;
    });
  };

  const boothDeadlineHandler = (booth, date) => {
    if (!booth.deliverables) return;
    setBooths((prevState) => {
      for (const deliverableId of Object.keys(booth.deliverables)) {
        if (booth.deliverables[deliverableId].deadline) continue;
        booth.deliverables[deliverableId].deadline = date;
      }
      const newState = [...prevState ];
      newState[booth.index] = booth;
      return newState;
    });
  };

  const boothInputHandler = (index, value, propertyName) => {
    if (!booths[index]) return;
    setBooths((prevState) => {
      const newState = [...prevState];
      newState[index][propertyName] = value;
      return newState;
    });
  };

  const addSponsorship = () => {
    setSponsorships([
      ...sponsorships,
      { index: sponsorships.length },
    ]);
  };

  const sponsorshipsDropdownCallback = (dropdownData) => {
    setSponsorships((prevState) => {
      const newState = [...prevState];
      newState[dropdownData.index] = {
        ...prevState[dropdownData.index],
        ...dropdownData,
      };
      return newState;
    });
  };

  const sponsorshipDeleteHandler = (index) => {
    setSponsorships((prevState) => {
      let newState = [...prevState];
      delete newState[index];
      return newState;
    });
  };

  const boothsDropdownCallback = (dropdownData) => {
    setBooths((prevState) => {
      const newState = [...prevState];
      newState[dropdownData.index] = {
        ...prevState[dropdownData.index],
        ...dropdownData,
      };
      return newState;
    });
  };

  const boothDeleteHandler = (index) => {
    setBooths((prevState) => {
      let newState = [...prevState];
      delete newState[index];
      return newState;
    });
  };

  const addBooth = () => {
    setBooths([
      ...booths,
      { index: booths.length },
    ]);
  };

  const deliverableChangeHandler = (
    deliverable,
    value,
    keyName,
    entityIndex,
    setEntityState,
    isObj
  ) => {
    setEntityState((prevState) => {
      const newState = isObj ? { ...prevState } : [...prevState];
      if (newState.deliverables?.[deliverable.id]) {
        newState.deliverables[deliverable.id][keyName] = value;
      } else {
        newState[entityIndex].deliverables[deliverable.id][keyName] = value;
      }
      return newState;
    });
  };

  const deliverableDeleteHandler = (
    deliverable,
    entityIndex,
    setEntityState,
    isObj
  ) => {
    setEntityState((prevState) => {
      const newState = isObj ? { ...prevState } : [...prevState];
      if (newState.deliverables?.[deliverable.id]) {
        delete newState.deliverables?.[deliverable.id];
      } else {
        delete newState[entityIndex]?.deliverables?.[deliverable.id];
      }
      return newState;
    });
  };

  const formIsValid = () => {
    const hasInvalidDeliverables = !!booths.find(
      (booth) => 
        booth?.deliverables &&
        Object.values(booth.deliverables).find((deliverable) => 
            !deliverable.quantity
        )
    );
    if (hasInvalidDeliverables) return false;
    const isValid = !!sponsorships.find(
      (sponsorship) =>
        sponsorship &&
        Number.isInteger(sponsorship.quantity) &&
        (!sponsorship.deliverables ||
          !Object.values(sponsorship.deliverables).find((deliverable) => {
            return (
              !deliverable.quantity
            );
          }))
    );
    return isValid;
  };
  
  const modalSubmitCallback = () => {
    if (!formIsValid()) return;
    const data = {
      sponsorships: arrayToObject(sponsorships),
      booths: arrayToObject(booths),
    };
    props.submitCallback(data);
    toggle();
  };

  const deliverablesMap = (
    deliverables,
    entityIndex,
    setEntityState,
    isObj = false
  ) =>
    Object.values(deliverables).map(
      (deliverable, index) => {
        if (deliverable.quantity == null) {
          deliverableChangeHandler(
            deliverable,
            "1",
            "quantity",
            entityIndex,
            setEntityState,
            isObj
          );
        }
        return (
          deliverable?.itemClass?.name && (
            <Col key={index} md="12" className="m-0 p-0 pl-3 mb-2">
              <Label className="ml-0 mb-0 mt-0">
                {deliverable.itemClass.name}
              </Label>
              <Row className="pl-3 mt-0 pr-3">
                <Col md="3" className="m-0 p-0">
                  <Input
                    type="number"
                    step="1"
                    value={deliverable.quantity || ""}
                    placeholder="Quantity"
                    onChange={(e) =>
                      deliverableChangeHandler(
                        deliverable,
                        e.target.value,
                        "quantity",
                        entityIndex,
                        setEntityState,
                        isObj
                      )
                    }
                  />
                </Col>
                <Col md="3" className="m-0 pl-2 pr-0">
                  <Input
                    type="number"
                    step="1"
                    value={deliverable.rate || ""}
                    placeholder="Rate"
                    onChange={(e) =>
                      deliverableChangeHandler(
                        deliverable,
                        e.target.value,
                        "rate",
                        entityIndex,
                        setEntityState,
                        isObj
                      )
                    }
                  />
                </Col>
                <Col md="3" className="m-0 pl-2 pr-0">
                  <ReactDatetime
                    onChange={(date) =>
                      deliverableChangeHandler(
                        deliverable,
                        date,
                        "deadline",
                        entityIndex,
                        setEntityState,
                        isObj
                      )
                    }
                    value={deliverable.deadline || ""}
                    inputProps={{
                      className: "form-control",
                      placeholder: "Deadline",
                    }}
                    closeOnSelect={true}
                  />
                </Col>
                <Col md="1" className="m-0 pl-2 pr-0">
                  <IconButton
                    tooltipId={index}
                    indexKey={index}
                    onClick={() =>
                      deliverableDeleteHandler(
                        deliverable,
                        entityIndex,
                        setEntityState,
                        isObj
                      )
                    }
                    icon="icon-trash-simple"
                    color="danger"
                    tooltip="Delete"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={"11"} className="m-0 mt-2 p-0 pl-3 pr-2">
                  <Input
                    type="text"
                    placeholder="Description"
                    defaultValue={deliverable.itemClass?.description || ""}
                    onChange={(e) =>
                      deliverableChangeHandler(
                        deliverable,
                        e.target.value,
                        "description",
                        entityIndex,
                        setEntityState,
                        isObj
                      )
                    }
                  />
              </Col>
              </Row>
            </Col>
          )
        )
      }
    );

  const sponsorshipDropdowns = sponsorships.map((sponsorship, index) => {
    const hasDeliverables = !!Object.keys(sponsorship?.deliverables || {})
      ?.length;
    return (
      <Fragment key={index}>
        <Row className={classNames(sponsorship ? "mb-2 mt-2" : "m-0 p-0")}>
          {sponsorship && (
            <Col md={sponsorship.label ? "4" : "12"} className="m-0 p-0">
              <SponsorshipsDropdown
                callback={sponsorshipsDropdownCallback}
                isUnfiltered
                index={index}
              />
            </Col>
          )}
          {sponsorship?.label && (
            <>
              <Col md={hasDeliverables ? "2" : "3"} className="m-0 mt-2 p-0">
                <Input
                  type="number"
                  step="1"
                  required
                  placeholder="Quantity *"
                  onChange={(e) =>
                    sponsorshipChangeHandler(
                      sponsorship,
                      e.target.value,
                      "quantity"
                    )
                  }
                />
              </Col>
              <Col
                md={hasDeliverables ? "2" : "3"}
                className="m-0 mt-2 pl-2 pr-2"
              >
                <Input
                  type="number"
                  step="1"
                  placeholder="Rate"
                  onChange={(e) =>
                    sponsorshipChangeHandler(
                      sponsorship,
                      e.target.value,
                      "rate"
                    )
                  }
                />
              </Col>
              {hasDeliverables && (
                <>
                  <Col md="3" className="m-0 mt-2 pl-2 pr-2">
                    <ReactDatetime
                      onChange={(date) =>
                        sponsorshipDeadlineHandler(sponsorship, date)
                      }
                      value={sponsorship.deadline || ""}
                      inputProps={{
                        className: "form-control",
                        placeholder: "Deadline *",
                      }}
                      closeOnSelect={true}
                    />
                  </Col>
                </>
              )}
              {index > 0 && (
                <Col md="1" className="m-0 mt-2 pl-0">
                  <IconButton
                    tooltipId={index}
                    indexKey={index}
                    onClick={(itemIndex) =>
                      sponsorshipDeleteHandler(index, itemIndex)
                    }
                    icon="icon-trash-simple"
                    color="danger"
                    tooltip="Delete"
                  />
                </Col>
              )}
              <Col md={"11"} className="m-0 mt-2 p-0 pl-3 pr-2">
                <Input
                  type="text"
                  placeholder="Description"
                  defaultValue={sponsorship.itemClass?.description || ""}
                  onChange={(e) =>
                    sponsorshipChangeHandler(
                      sponsorship,
                      e.target.value,
                      "description",
                      String
                    )
                  }
                />
              </Col>
            </>
          )}
        </Row>
        {hasDeliverables && (
          <Row className="pl-5 mt-1 pr-3">
            <Label className="ml-0 mb-0 mt-0">Deliverables</Label>
            {deliverablesMap(sponsorship.deliverables, index, setSponsorships)}
          </Row>
        )}
      </Fragment>
    );
  });

  const addSponsorshipButton = (
    <Row className="ml-1">
      <Button
        className="mt-3"
        size="md"
        onClick={addSponsorship}
        title="Add Sponsorship"
      >
        + Add Sponsorship
      </Button>
    </Row>
  );

  const boothDropdowns = booths.map((booth, index) => {
    const hasDeliverables = !!Object.keys(booth?.deliverables || {})
      ?.length;
    return (
      <Fragment key={index}>
        <Row className={classNames(booth ? "mb-2 mt-2" : "m-0 p-0")}>
          {booth && (
            <Col md={hasDeliverables ? "8" : "11"} className="m-0 p-0">
              <BoothsDropdown
                callback={boothsDropdownCallback}
                index={index}
                multiSelect={false}
              />
            </Col>
          )}
          {booth?.label && (
            <>
              {hasDeliverables && (
                <Col md="3" className="m-0 mt-2 pl-2 pr-2">
                  <ReactDatetime
                    onChange={(date) =>
                      boothDeadlineHandler(booth, date)
                    }
                    value={booth.deadline || ""}
                    inputProps={{
                      className: "form-control",
                      placeholder: "Deadline",
                    }}
                    closeOnSelect={true}
                  />
                </Col>
              )}
              {index > 0 && (
                <Col md="1" className="m-0 mt-2 pl-0">
                  <IconButton
                    tooltipId={index}
                    indexKey={index}
                    onClick={(itemIndex) =>
                      boothDeleteHandler(index)
                    }
                    icon="icon-trash-simple"
                    color="danger"
                    tooltip="Delete"
                  />
                </Col>
              )}
            </>
          )}
        </Row>
        {booth?.label && (
          <Row className="mb-2 mt-1 pl-2">
            <Col md="3" className="m-0 pl-2 pr-2">
              <Input
                type="number"
                step="1"
                placeholder="Quantity"
                value={booth.quantity || ""}
                onChange={(e) => boothInputHandler(index, e.target.value, "quantity")}
              />
            </Col>
            <Col md="3" className="m-0 pl-2 pr-2">
              <Input
                type="number"
                step="1"
                placeholder="Rate"
                value={booth.rate || ""}
                onChange={(e) => boothInputHandler(index, e.target.value, "rate")}
              />
            </Col>
          </Row>
        )}
        {hasDeliverables && (
          <Row className="pl-5 mt-1 pr-3">
            <Label className="ml-0 mb-0 mt-0">Deliverables</Label>
            {deliverablesMap(booth.deliverables, index, setBooths)}
          </Row>
        )}
      </Fragment>
    );
  });

  const addBoothButton = (
    <Row className="ml-1">
      <Button
        className="mt-3"
        size="md"
        onClick={addBooth}
        title="Add Booth"
      >
        + Add Booth
      </Button>
    </Row>
  );

  const requiredFieldsRow = (
    <Row className="pt-3">
      <Col>
        <CustomLabel label="Required Fields" required isInverted />
      </Col>
    </Row>
  );

  return (
    <ModalContainer
      buttonLabel="+ Add Sponsorships"
      modalTitle="Add New Sponsorships and Booths"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={modalSubmitCallback}
      button={OpenModalButton}
      isSubmitDisabled={!isSubmitReady}
    >
      <CustomLabel label="Sponsorships" className="mb-0" required />
      {sponsorshipDropdowns}
      {addSponsorshipButton}
      <Label className="mb-0 mt-3">Booths</Label>
      {boothDropdowns}
      {addBoothButton}
      {requiredFieldsRow}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(SponsorshipsAndBooths);
