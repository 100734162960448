import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Sponsorship from "models/database/Sponsorship";
import CrudActionsTable from "../../../../shared/CrudActionsTable";
import EditSponsorshipsModal from "./EditSponsorshipsModal";
import updateReduxData from "utilities/methods/updateReduxData";

function SponsorshipsTable(props) {
  const [sponsorships, setSponsorships] = useState(null);

  const deleteCallback = item => {
    props.dispatch({ 
        type: "filterEventData", 
        id: item.id, 
        collection: "sponsorships"
    });
    getData();
    props.deleteCallback(item);
  }

  const editCallback = (data, item, deleteItem) => {
    props.setData(data);
    updateReduxData.event(props.eventData.code);
    props.editCallback(deleteItem);
  }

  const getData = async () => {
    const sponsorshipsData = await new Sponsorship().getAll(props.eventData.sponsorships, props.eventData.id);
    // console.log("sponsorships table data", JSON.parse(JSON.stringify(sponsorshipsData)))
    props.setData(sponsorshipsData);
    setSponsorships(props.eventData.sponsorships);
  }

  useEffect(() => {
    if (!props.eventData?.sponsorships) return;
    if (sponsorships == null) {
      getData();
      return;
    }
    const sponsorshipsData = props.eventData.sponsorships.map((sponsorship, index) => 
      new Sponsorship().createWithObject(sponsorship).tableRowFormat(index))
    props.setData(sponsorshipsData);
    setSponsorships(props.eventData.sponsorships);
  }, [props.eventData]);

  const pageSize = props.data.length + 5;
  
  return (
    <>
      <CrudActionsTable
        data={props.data}
        dataModel={Sponsorship}
        filterable={true}
        newItem={props.newRow}
        resizable={true}
        defaultPageSize={pageSize}
        modalName="sponsorship"
        editModal={EditSponsorshipsModal}
        editCallback={editCallback}
        deleteCallback={deleteCallback}
        hasNoActions={!props.isEventEditable}
      />
    </>
  );
}

const mapStateToProps = state => ({
  eventData: state.eventData,
  isEventEditable: state.isEventEditable,
});

export default connect(mapStateToProps)(SponsorshipsTable);
