import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import Event from "../../../../../models/database/Event";
import DashboardSection from "./DashboardSection";
import PartnersSection from "./EventPartner/EventsPartnerSection";
import EmailsSection from "./EmailsSection";
import DocumentsSection from "./DocumentsSection/DocumentsSectionContainer";
// import DocumentsSection from "./DocumentsSection";
import Organizations from "../../../../../models/database/Organizations";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import IconPencilButton from "../../../shared/buttons/IconPencilButton";
import EditEventModal from "../EditEventModal";
import updateReduxData from "utilities/methods/updateReduxData";
import CustomCard from "layouts/Plan/shared/CustomCard";
import { CurrencyToNumber } from "layouts/Plan/shared/CurrencyFormatter";
import { NumberToCurrency } from "layouts/Plan/shared/CurrencyFormatter";
import toPercentage from "utilities/methods/toPercentage";
import CalendarSection from "./calendar/CalendarSection";
import AgendaSection from "./agenda/AgendaSection";

const eventPageHeaders = [
  {
    title: "Dashboard",
    href: "",
    baseRoute: true,
    component: DashboardSection,
  },
  {
    title: "Partners",
    href: "/partners",
    component: PartnersSection,
  },
  {
    title: "Emails",
    href: "/emails",
    component: EmailsSection,
  },
  {
    title: "Calendar",
    href: "/calendar",
    component: CalendarSection,
  },
  {
    title: "Agenda",
    href: "/agenda",
    component: AgendaSection,
  },
  {
    title: "Documents",
    href: "/documents",
    component: DocumentsSection,
  },
];

function EventContainer(props) {
  const eventCode = useParams().id;
  const eventRoute = useRouteMatch().url;

  const [event, setEvent] = useState(
    new Event().createWithObject(props.eventData)
  );
  const [dateRange, setDateRange] = useState(event.displayFormat().dateRange);
  const [location, setLocation] = useState(event.displayFormat().location);
  const [name, setName] = useState(event.name);
  const [type, setType] = useState(event.type);
  const [format, setFormat] = useState(event.format);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalPartners, setTotalPartners] = useState(Object.keys(event.partners || {}).length);
  const [revenueGoal, setRevenueGoal] = useState(CurrencyToNumber(event.revenueGoal) || 0);
  const [eventUrl, setEventUrl] = useState(event.eventUrl);
  const [hasSponsorships, setHasSponsorships] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);
  const [isShowingEditEventModal, setIsShowingEditEventModal] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultTabIndex);

  useEffect(() => {
    // set the url path tab when page reload
    let path = props?.location?.pathname.split("/");
    path = path[path.length - 1];
    let index = eventPageHeaders.findIndex((obj) => obj.href == `/${path}`);
    setDefaultTabIndex(() => (index == -1 ? 0 : index));
    setActiveTab(() => (index == -1 ? 0 : index));

    // get event data from database if it's not in redux state (user navigated here directly)
    (async function () {
      let orgModel = [];
      if (!props.eventData) {
        const eventModel = await new Event().getSingleEvent(eventCode);
        if (!props.orgData) {
          orgModel = await new Organizations().getAllEntities();
        }
        if (!props.orgUsers) {
          updateReduxData.orgUsers();
        }
        if (!eventModel) return;
        const displayFormat = eventModel.displayFormat();
        setEvent(eventModel);
        setDateRange(displayFormat.dateRange);
        setLocation(displayFormat.location);
        setName(eventModel.name);
        setType(eventModel.type);
        setFormat(eventModel.format);
        setTotalRevenue(getTotalRevenue(eventModel.partners));
        setTotalPartners(Object.keys(eventModel.partners || {}).length);
        setRevenueGoal(CurrencyToNumber(eventModel.revenueGoal) || 0);
        setHasSponsorships(checkHasSponsorships(eventModel));
        setEventUrl(eventModel.eventUrl);
        setIsLoading(false)
        updateReduxData.eventContacts(eventModel);
        props.dispatch({ type: "setOrgData", data: orgModel });
        props.dispatch({ type: "setEventData", data: eventModel });
      } else {
        updateReduxData.eventContacts(props.eventData);
        setHasSponsorships(checkHasSponsorships(event));
        setTotalRevenue(getTotalRevenue(event.partners));
        setIsLoading(false)
      }
    })();
  }, []);

  function checkHasSponsorships(eventData) {
    return Boolean(
      !eventData || (eventData.sponsorships.length && eventData.booths)
    );
  }

  const getTotalRevenue = (partners) => {
    if (!partners) return 0;
    return Object.values(partners).reduce((mapInt, partner) => {
      const partnerSpend = CurrencyToNumber(partner?.totalSpend);
      if (typeof partnerSpend === "number") {
        mapInt += partnerSpend;
      }
      return mapInt;
    }, 0);
  }

  const handleEventUpdate = async (data, index) => {
    let event = await props.eventData;
    let updatedData = await new Event(event.id).update(data);
    setFieldsData(updatedData);
  };

  const eventRoutes = (
    <Switch>
      {[...eventPageHeaders].reverse().map((header, index) => (
        <Route
          path={header.baseRoute ? eventRoute : eventRoute + header.href}
          render={() => (
            <header.component
              event={event}
              hasSponsorships={hasSponsorships}
              parentIsLoading={isLoading}
              setHasSponsorships={setHasSponsorships}
            />
          )}
          key={index}
        />
      ))}
    </Switch>
  );
  
  const header = (
    <Tabs
      defaultIndex={defaultTabIndex}
      defaultFocus={true}
      style={{ borderBottom: "1px solid #AAAAAA" }}
    >
      <Row>
        <Col md={12}>
          <TabList className="pl-0 mb-0 pb-0">
            {eventPageHeaders.map((header, index) => {
              const isActive =
                (!header.baseRoute &&
                  props.location.pathname.includes(header.href)) ||
                (header.baseRoute &&
                  props.location.pathname.endsWith(eventCode));
              return (
                <Tab
                  onClick={() => {
                    props.history.push(eventRoute + header.href);
                    setActiveTab(index);
                  }}
                  style={
                    isActive
                      ? { backgroundColor: "rgba(255, 255, 255, 255)" }
                      : {}
                  }
                  tag="a"
                  disabled={isActive}
                  key={index}
                >
                  {header.title}
                </Tab>
              );
            })}
          </TabList>
        </Col>
      </Row>
      {eventPageHeaders.map((_, index) => <TabPanel key={index} />)}
    </Tabs>
  );

  const infoSection = (
    <Row>
      <Col className="pr-1" md="7">
        <Card>
          <CardHeader>
            <CardTitle tag="h3">
              <Row className="d-flex flex-row justify-content-between">
                <Col>
                  {name} ({eventCode})
                </Col>
                <Col className="text-right">
                  <IconPencilButton
                    onClick={() =>
                      setIsShowingEditEventModal((prevState) => !prevState)
                    }
                    disabled={!props.isEventEditable}
                  />
                </Col>
              </Row>
            </CardTitle>
            <CardTitle tag="h4">
              Date{dateRange.includes(" - ") ? `s: ${dateRange}` : `: ${dateRange}`}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CardText>
              {" "}
              <b className="p-1">Location:</b> {location}
            </CardText>
            <CardText>
              {" "}
              <b className="p-1">Type:</b> {type}
            </CardText>
            <CardText>
              {" "}
              <b className="p-1">Format:</b> {format}
            </CardText>
            <CardText>
              {" "}
              <b className="p-1">URL:</b> {eventUrl}
            </CardText>
            <CardText>
              {" "}
              <b className="p-1">Partner Revenue Goal:</b> {NumberToCurrency(revenueGoal)}
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col md="5">
        <Row>
        <Col md="4" sm="6">
          <CustomCard 
            className="text-center"
            title="Total Revenue" 
            body={NumberToCurrency(totalRevenue)}
          />
        </Col>
        <Col md="4" sm="6">
          <CustomCard 
            className="text-center"
            title="Revenue To Goal" 
            body={toPercentage(totalRevenue, revenueGoal, { fallback: 100 })}
          />
        </Col>
        <Col md="4" sm="6">
          <CustomCard 
            className="text-center"
            title="Total Partners" 
            body={`${totalPartners}`}
          /> 
        </Col>
        </Row>
      </Col>
    </Row>
  );

  const setFieldsData = (eventModel) => {
    props.dispatch({ type: "setEventData", data: eventModel });
    const displayFormat = eventModel.displayFormat();
    setEvent(eventModel);
    setDateRange(displayFormat.dateRange);
    setLocation(displayFormat.location);
    setName(eventModel.name);
    setType(eventModel.type);
    setFormat(eventModel.format);
    setTotalRevenue(getTotalRevenue(eventModel.partners));
    setTotalPartners(Object.keys(eventModel.partners || {}).length);
    setRevenueGoal(CurrencyToNumber(eventModel.revenueGoal) || 0);
    setEventUrl(eventModel.eventUrl);
  };

  const toggleModifyEventModal = () => {
    setIsShowingEditEventModal((prevState) => !prevState);
  };

  const showEditEventModal = () => (
    <>
      <EditEventModal
        item={{ data: { ...props.eventData } }}
        formSubmitCallBack={(data, index) => handleEventUpdate(data, index)}
        toggle={() => {
          toggleModifyEventModal();
        }}
      />
    </>
  );
  return (
    <>
      {isShowingEditEventModal ? showEditEventModal() : ""}

      <div id="event-page" className="content">
        {infoSection}
        {hasSponsorships && header}
        {eventRoutes}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
  isEventEditable: state.isEventEditable,
  orgData: state.orgData,
  orgUsers: state.orgUsers,
  eventContacts: state.eventContacts,
});

export default connect(mapStateToProps)(EventContainer);
