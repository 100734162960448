import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

// react component for creating dynamic tables
import CrudActionsTable from "layouts/Plan/shared/CrudActionsTable";
import Agenda from "models/database/Agenda";
import EditAgendaModal from "./EditAgendaModal";

const AgendaTable  = (props) => {
  const isMounted = useRef(false);
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  });

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false };
  }, []);

  const fetchData = async () => {
    if (isFetched || !props.eventData?.id) return;
    setIsFetched(true);
    const agendas = await new Agenda().getEventAgenda(props.eventData.id);
    if (isMounted.current) setData(agendas);
  }

  return (
    <>
      <CrudActionsTable
        data={data}
        dataModel={Agenda}
        newItem={props.newRow}
        defaultPageSize={10}
        modalName="agenda"
        editModal={EditAgendaModal}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventData,
});

export default connect(mapStateToProps)(AgendaTable);
