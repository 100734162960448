import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Rings, ThreeDots } from 'react-loading-icons'

import WithSplashScreen from '../layouts/WithSplashScreen';

const loadingScreen = (
        <div style={{"minHeight": "100vh", "height": "auto", "display" : "flex", "alignItems": "center", "justifyContent": "center"}}>
            <div style={{}}>
                <Rings width="10em" height="10em" stroke="#7fd959" />
                <div style={{"color": "#cf009f", "textAlign": "center", "marginTop": "20px", "fontSize": "16px", "display" : "flex", "justifyContent": "center"}}>
                    <p style={{"color": "#cf009f"}}>Loading</p>
                    <div style={{marginTop: "4px", marginLeft: "2px"}}>
                        <ThreeDots width="1em" height="1em" fill="#cf009f"/>
                    </div>
                </div>
            </div>
        </div>
    );

const ProtectedRoutes = ({ children }) => {
    const firebase = useSelector(state => state.firebase);
    const [user, loading, error] = useAuthState(firebase.auth());
    if (user && !user.isAnonymous) return children;
    else if (loading) return loadingScreen;
    else if (error) return <div>Authentication error</div>;
    return <Redirect to="/auth/login" />;
};

export default ProtectedRoutes;
