/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux-store/store";
import ProtectedRoutes from "./firebase-client/ProtectedRoutes";
import "./firebase-client/firebase-client";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import Plan from "./layouts/Plan/Plan";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";
import "assets/css/arcat-styles.css";

const hist = createBrowserHistory();

console.log('v1')

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <ProtectedRoutes>
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
          <Route path="/rtl" render={props => <RTLLayout {...props} />} />
          <Route path="/plan" render={props => <Plan {...props} />} />
          <Route exact path="/" render={props => <Plan {...props} />} />
          {/*<Redirect from="/" to="/plan/events" />*/}
        </ProtectedRoutes>
      </Switch>
    </Router>
  </Provider>
  , document.getElementById("root")
);
