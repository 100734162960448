import React, { useState, useEffect } from "react";
import { 
  Button, 
  Row, 
  Col, 
  Input, 
  Label, 
} from "reactstrap";
import ModalContainer from "layouts/Plan/shared/ModalContainer";
import { connect } from "react-redux";
import IconButton from "layouts/Plan/shared/IconButton";

const UsersModal = (props) => {
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [inviteUrl, setInviteUrl] = useState(`${window.location.origin}/auth/register/${props.userOrg}`);
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    setIsSubmitReady(isEmailValid);
  }, [isEmailValid]);

  useEffect(() => {
    setInviteUrl(`${window.location.origin}/auth/register/${props.userOrg}`);
  }, [props.userOrg]);

  const toggle = () => {
    if (isModalOpen) {
      setEmail("");
      setIsEmailValid(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  const OpenModalButton = (
    <Button color="info" size="md" onClick={toggle}>
      + Add User
    </Button>
  );

  const handleCopyInvite = () => {
    const inviteElem = document.createElement("textarea");
    inviteElem.value = inviteUrl;
    document.body.appendChild(inviteElem);
    try {
      inviteElem.select();
      document.execCommand("copy");
    } catch (err) {
      console.error(err);
    } finally {
      document.body.removeChild(inviteElem);
    }
  }

  const submitCallback = async () => {
    if (!isEmailValid) return;

    const emailForm = {
      bcc: props.user.email,
      from: props.user.email,
      html: `<p>Click here to create your Arcat.XP account: ${inviteUrl}</p>`,
     "recipient-variables": { [email]: {} },
      subject: "Create your Arcat.XP account",
      to: email,
    };
    props.submitCallback(emailForm);
    toggle();
  };

  const emailInput = (
    <Row className="mt-3 mb-3">
      <Col xs="12" md="2">
        <Label>User Email</Label>
      </Col>
      <Col md="6">
        <Input
          type="email"
          value={email}
          required
          onChange={(e) => {
            setEmail(e.target.value);
            setIsEmailValid(e.target.validity?.valid);
          }}
        />
      </Col>
    </Row>
  );

  const inviteLink = (
    <Row className="mt-3 mb-3">
      <Col md="12">
        <Label>Invitation link:
          <a className="ml-1 mr-2" href={inviteUrl} target="_blank">
            {inviteUrl}
          </a>
        </Label>
        <div className="d-inline" title="Copy">
          <IconButton
            tooltipId="copy-btn"
            onClick={handleCopyInvite}
            icon="icon-single-copy-04"
            color="info"
            tooltip="Copy"
          />
        </div>
      </Col>
    </Row>
  );

  return (
    <ModalContainer
      buttonLabel="+ Add Users"
      modalTitle="Add New Users"
      modalClassName="modal-long"
      isModalOpen={isModalOpen}
      toggleCallBack={toggle}
      submitCallback={submitCallback}
      button={OpenModalButton}
      isSubmitDisabled={!isSubmitReady}
      submitButtonText="Send Invitation Email"
    >
      {emailInput}
      {inviteLink}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  userOrg: state.userOrg,
  user: state.user,
});

export default connect(mapStateToProps)(UsersModal);
